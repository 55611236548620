import {useEffect, useState} from "react";
import Axios from "axios";
import {Loading} from "./Loading";
import {InfoDetail} from "./InfoDetail";
import {Grid, Typography,useTheme,Card,CardActions,ButtonBase,CardMedia,makeStyles,Box} from '@material-ui/core';
import parse from 'html-react-parser';
import {Link,Route} from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Helmet  } from 'react-helmet-async';

const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";
const $ = require("jquery");
const useStyles = makeStyles({
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 #ffd24d, 0 4px 8px 0 #ffd24d" },
      //"&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.19), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
    },
  });
export const EngInfoHome=(props)=>{
    const theme = useTheme();
    const classes = useStyles();
    const [loading,setLoading]=useState(true);
    const [infoGrid,setInfoGrid]=useState();
    
     
    const [metadata,setMetadata] = useState();
     

    useEffect(()=>{
      window.scrollTo(0, 0);
      Axios.post("/new_apis/getsinglemetadata", {'type':"enginfo"},{
        headers: { key: key },
       }).then((res) => {
          
         if(res.data.status){
           
          setMetadata(res.data.metadata[0])
         } else{
            setMetadata({'seo_title':"Home - ESAY | Contacts and Info in the World of Construction",
            'seo_desc':"Get all the information and contacts of various types of contractors, consultants, machinery/material suppliers, engineers, and workforce in the construction sector",
            "seo_keywords":"best  contractors in Telangana, schedule of rates, Tenders in India,   construction architect engineers and labor near me, latest trends in construction sector, top contractors and consultants in Hyderabad,   interior designers in Hyderabad, building construction materials and machinery suppliers, soil metal materials testing labs around me"});
         }
          
       });
        Axios.get("/new_apis/get-enginfo-grid",{ 
          headers: { key: key },
         }).then((res) => {
            props.hideParents();
            setInfoGrid(res.data.infogrid);
             
          
         
           setLoading(false);
         }); 
                   
      },[]);

    return (<div>
         {metadata && <Helmet>
  <title>{metadata.seo_title}</title>
    <meta name="description" content={metadata.seo_desc} />
    <meta name="keywords" content={metadata.seo_keywords}></meta>
  </Helmet>}
        {loading?<Loading/>:<div className="container" style={{margin:"10px"}}>
             
            <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={3}
            >
                {infoGrid.map((item ) => (
                    <Grid item xl={3} lg={3} md={4} sm={6} xs={12} className="text-center">
                    <ButtonBase className={classes.nounderline}
                                component={Link}   
                                to={"/eng-info/info-detail/"+item?.info_data_slug+"/"+item?.info_data_id}
                            >
                            <Card className={classes.cardshadow} style={{width: "190px",border:"2px groove #ffd24d",backgroundColor:theme.palette.primary.main}}>
                            
                                <CardMedia
                                style={{height: 200}}
                                image={item.image}
                                title={item.info_child_cat_ids} 
                                />
                                 
                             
                            <CardActions>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                                //spacing={1}
                                >
                                    <Grid item xs={12}>
                                    <Typography variant="body2" className="text-center" style={{color:theme.palette.secondary.main,width:"100%"}}>
                                   
                                   {item.info_child_cat_ids} 
                                   </Typography>
                                    </Grid>
                                     
                                     
                                </Grid>
                                 
                                  
                            </CardActions>
                            </Card>
                            </ButtonBase>
                            </Grid>
                ))}
                </Grid>
                
            </div>}
          
    </div>);
}


 