import {useEffect,useState} from "react";
//import Loading from './Loading';
//import Axios from "axios";
import Grid from '@material-ui/core/Grid';
import {TextField,Box} from '@material-ui/core';
import { Container } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
 
export const ConvTemp=()=>{
     
useEffect(()=>{
  window.scrollTo(0, 0);
},[]);
return  (  <div>
    
    <Grid
  container
  direction="column"
  justify="space-evenly"
  alignItems="stretch"
  spacing={3}
>
<Grid item xs={12}>
         
   </Grid>
<Grid item xs={12}>
        <Typography variant="h5" component="h4" className="text-center" color="primary" gutterBottom>
          TEMPERATURE 
        </Typography>
   </Grid>
  <Grid item xs={12}>
  <Temp />
  </Grid>
  <Grid item xs={12}>
   
  </Grid>
  <Grid item xs={12}>
  <SimpleCard/>
  </Grid>
  </Grid>
      
     
    

</div>

 );
}

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "& .MuiFormLabel-root":{
      color:"#ffd24d"
    },
     
    "& .MuiInputLabel-shrink":{
      
      transform: "translate(14px, -9px) scale(0.90)",
      transformOrigin: "top left",
      backgroundColor:"rgb(41, 38, 38)",
      paddingLeft:"1%",
      paddingRight:"1%",
    } 
     
  }

});
 
  
  export default function SimpleCard() {
     
    
  
    return (
      <Box m={3} >
      <Card style={{ border:"2px groove #ffd24d"}}>
        <CardContent>
          
        <Typography variant="h6"  className="text-center">
           UNITS OF TEMPERATURE 
          </Typography>
          <br></br>
          <Typography variant="body2" >
          F= 9/5 C+32 The measurement of temperature in the English system is usually in Fahrenheit (F), the above equation is used to convert to Celsius for 10F of temperature, the temperature in Celsius is -17.220C.
          </Typography>
        </CardContent>
        
      </Card>
      </Box>
    );
  }
    
   


  const Temp=()=>{
    const classes = useStyles();
    const [cent,setCent] = useState();
    const [frn,setFrn] = useState();



    return (
        <Container maxWidth="lg">
        <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={4}
      >
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
       
       <TextField className={classes.root}  label="Centigrade"  variant="outlined" fullWidth
       size="small"
           type="number"
           value={cent}
           
           InputProps={{
               style: {
                   color: "#ffd24d"
               }
           }}
           InputLabelProps={{  shrink: true,focused:true }} 
         
           onChange={(e) => {
                
                   var val = parseFloat(e.target.value);
                   setCent(val);
                   val=(val*1.8)+32
                    
                   setFrn(val);
               
               
           }}
            
       />
     </Grid>
     <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
     <TextField  className={classes.root} label="Fahrenheit" variant="outlined"  size="small" fullWidth
       type="number"
       value={frn}
       InputProps={{
         style: {
             color: "#ffd24d"
         }
     }}
       InputLabelProps={{  shrink: true,focused:true }} 
       onChange={(e) => {
           
               var val = parseFloat(e.target.value);
               setFrn(val);
               val=(val-32)/1.8
               setCent(val);
           
       }}
        
     />
           </Grid>
          
           
          
           
    </Grid></Container>);
}