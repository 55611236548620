
import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';

export const Loading=()=>{
    return ( 
    <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{height:"70vh"}}
        >
            <CircularProgress />
        </Grid>
    );

}

 