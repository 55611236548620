 
import {useState,useEffect, useContext,useLayoutEffect,React} from 'react';
import {Navbar} from "./navbar";
import {Route,useNavigate} from "react-router-dom";
import {Grid,Typography,useTheme,ButtonBase,Card,CardMedia,CardActions,makeStyles,Box,TextField,Button,FormControl,Select,InputLabel,MenuItem} from "@material-ui/core";
import {Loading} from "./Loading";
 
import { UserContext ,SnackbarContext} from './userContext';
import Axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from "react-responsive-carousel";
import { AutoCompLoc } from './AutoCompLoc.jsx';
import {Link} from 'react-router-dom';
import { Helmet  } from 'react-helmet-async';
import {Recommended} from "./Recommended";

import {Featured} from "./Featured";
const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "& .MuiFormLabel-root":{
      color:"#ffd24d"
    },
    "& .Mui-error":{
      color:"red  ",
     // borderColor:"red !important"
    },
     
    "& .MuiInputLabel-shrink":{
      
      transform: "translate(14px, -9px) scale(0.90)",
      transformOrigin: "top left",
      backgroundColor:"#2e2b2b",
      paddingLeft:"1%",
      paddingRight:"1%",
    } 
     
  },
  nounderline: {
    "&:hover": { textDecoration:"none"},
  },
  cardshadow: {
    "&:hover": { boxShadow: "0 4px 8px 0 #ffd24d, 0 4px 8px 0 #ffd24d" },
      //"&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.19), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
     
  },

});
const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";

 


export const Home=()=>{
  const theme = useTheme();
  const classes = useStyles();

  const history = useNavigate();

    const [loading,setLoading]=useState(true);
    const {user,setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);
    const [slides,setSlides]=useState();
    const [cats,setCats]=useState();
    const [subcats,setSubcats]=useState();
    const [rec,setRec]=useState();
    const [fea, setFea]=useState();
    const [error,setError] = useState(0);

    const [loc,setLoc] = useState(localStorage.getItem('loc'));
    
    const [cat,setCat] = useState("0");
    const [subCat,setSubcat] = useState("0");
    const [search,setSearch] = useState("");

    const [size, setSize] = useState(0);
    const [active, setActive] = useState(0);

    const [metadata,setMetadata] = useState();

  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(()=>{
    window.scrollTo(0, 0);
    
    Axios.post("/new_apis/getsinglemetadata", {'type':"home"},{
      headers: { key: key },
     }).then((res) => {
        
       if(res.data.status){
         
        setMetadata(res.data.metadata[0])
       } else{
          setMetadata({'seo_title':"Home - ESAY | Contacts and Info in the World of Construction",
          'seo_desc':"Get all the information and contacts of various types of contractors, consultants, machinery/material suppliers, engineers, and workforce in the construction sector",
          "seo_keywords":"best  contractors in Telangana, schedule of rates, Tenders in India,   construction architect engineers and labor near me, latest trends in construction sector, top contractors and consultants in Hyderabad,   interior designers in Hyderabad, building construction materials and machinery suppliers, soil metal materials testing labs around me"});
       }
        
     });

    Axios.get("/new_apis/get-home-data",{ 
      headers: { key: key },
     }).then((res) => {
        setSlides(res.data.slides);
         
        setCats(res.data.cats);
        
        setSubcats(res.data.subcats);
        
        setFea(res.data.featured);
        
        setRec(res.data.recommended);
         
        setLoading(false);
     }); 
               
  },[]);

 
   const updateLoc = (loc)=>{
    console.log(loc)
      setLoc(loc);
   }

    return (<div>
       {metadata && <Helmet>
        <title>{metadata.seo_title}</title>
          <meta name="description" content={metadata.seo_desc} />
          <meta name="keywords" content={metadata.seo_keywords}></meta>
        </Helmet>}
     {loading?<Loading />:<div>
      {slides && (<Carousel
      animation="slide"
      timeout={1200}
      autoPlay={true}
      showIndicators={true}
      showArrows={false}
      centerMode={true}
      centerSlidePercentage={size<960?100:50}
      infiniteLoop
      
      stopOnHover
      swipeable
      showThumbs={false}
       
      transitionTime={1200}
      statusFormatter={(current, total) => {
        setActive(current - 1);
        return ``;
      }}
    >
      {slides.map((item, i) => (
        <div id={`${
          i === active && `carousel_image_id_active`
        }`}
        className="MuiPaper-elevation1"
        style={{
           margin:"5px",
           border:"2px groove #ffd24d",
        //  paddingRight:"5px",
          borderRadius:"5px",
          filter: "blur(5px)",
        }}
        >
        
              <img src={item.image_url} style={{borderRadius:"5px"}} />
              
          
      
    </div>
      ))}
    </Carousel>)}
     
    
    <Box  style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.secondary.main,borderRadius:"10px"}}   p={3}>
   

    <Grid
  container
  direction="row"
  justify="space-evenly"
  alignItems="flex-start"
  spacing={3}
>
  <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
        <AutoCompLoc error={error==1?true:false} loc={loc} setloc={updateLoc}/>
         
       </Grid>
  <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
    <FormControl variant="outlined" className={classes.root}
     size="small"
     error={error===2&&true}
    fullWidth required>
                  <InputLabel >
                    Category
                  </InputLabel>
                  <Select
                  
                   // native
                    value={cat}
                    onChange={(e) => {
                      setCat(e.target.value);
                      setSubcat("0");
                    }}
                    style={{color:theme.palette.primary.main}}
                    //error={errcode === 2 ? true : false}
                    label="Category"
                     
                  >
                    
                    <MenuItem key="0"  value="0">Please select a Category</MenuItem>
                    {cats &&
                      cats.map((cat) => {
                        return (
                          <MenuItem
                            key={cat.category_id}
                            value={cat.category_id}
                            color="primary"
                          >
                            {cat.category_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                 
                </Grid>
  {cat!=='0'&&(<Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
  <FormControl variant="outlined" className={classes.root}
     size="small"
    fullWidth >
                  <InputLabel >
                    Sub Category
                  </InputLabel>
                  <Select
                    //native
                    value={subCat}
                    onChange={(e) => {
                      setSubcat(e.target.value);
                    }}
                    style={{color:theme.palette.primary.main}}
                    //error={errcode === 2 ? true : false}
                    label="Sub Category"
                     
                  >
                    <MenuItem  key="0" value="0">Please select a Sub Category</MenuItem>
                    { (subcats && cat!=='0') &&
                      subcats.map((subcat) => {
                        if(cat===subcat.category_ids){
                          return (
                            <MenuItem
                              key={subcat.sub_category_id}
                              value={subcat.sub_category_id}
                              color="primary"
                            >
                              {subcat.sub_category_name}
                            </MenuItem>
                          );
                        }
                        
                      })}
                  </Select>
                </FormControl>
  </Grid>)}
  <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
  <TextField className={classes.root}  label="Search"  variant="outlined" fullWidth
       size="small"
           
           value={search}
           
           InputProps={{
               style: {
                   color: "#ffd24d"
               }
           }}
           //InputLabelProps={{  shrink: true,focused:true }} 
         
           onChange={(e) => {
                    setSearch(e.target.value);
 
           }}
            
       />
  </Grid>
  <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
  <Button variant="contained"   fullWidth={true} color="primary"  className="linkstyle" onClick={()=>{
    setError(0);
    if(loc===''){
      setError(1);
      setSnack({
        message: 'Please Enter your Location...',
        type: 'error',
        open: true,
        direction:"center"
      });
    }else if(cat==="0"){
      setError(2);
      setSnack({
        message: 'Please Select a Category...',
        type: 'error',
        open: true,
        direction:"center"
      });
    }else{
      history("/searchresults/"+loc+"/"+cat+"/"+subCat+"/"+search);
    }
    
  }} >SEARCH</Button>
  </Grid>
  </Grid>
  </Box>
  
     <div className="container">
     <br /><br /><br />
     <Typography variant="h4" className="divider line glow" gutterBottom style={{color:theme.palette.primary.main}}>
          CATEGORIES 
     </Typography>
     <br/>
     {cats && <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={3}
      >
        

        
        {cats.map((item, i) => (
         <Grid item xl={3} lg={3} md={3} sm={4} xs={12} className="text-center">
           <ButtonBase className={classes.nounderline}
                                component={Link} to={"/searchresults/"+loc+"/"+item?.category_id}
                            >
                            <Card className={classes.cardshadow} style={{ width:"200px",border:"2px groove #ffd24d",backgroundColor:theme.palette.primary.main}}>
                            
                                <CardMedia
                                style={{height: 150}}
                                image={item.category_image}
                                title={item.category_name} 
                                />
                                 
                             
                            <CardActions>
                                 <Typography variant="body1" className="text-center" style={{color:theme.palette.secondary.main,width:"100%"}}>
                                   
                                  {item.category_name} 
                                  </Typography>
                             
                            </CardActions>
                            </Card>
                            </ButtonBase>
         </Grid>
      ))}
     </Grid>}
     <br/>
     <br/>
     <br/>
     <br/>
     <br/>
     {rec&&(<Recommended recs={rec}/>)}
     <br/><br/>
     {fea&&(<Featured fdata={fea}/>)}
      </div>
      <br/><br/>
     <br/><br/>
     </div>} 
    </div>);

}

 