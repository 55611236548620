
import {Grid,Box, Typography,useTheme,Button,Container,makeStyles,TextField,InputAdornment,IconButton} from '@material-ui/core';
import {useEffect,useState,useContext} from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { AutoCompLoc } from './AutoCompLoc.jsx';
import {Loading} from "./Loading";
import {BackDropLoad} from "./LoadingBackDrop";
import { UserContext,SnackbarContext } from './userContext';
import {useNavigate} from "react-router-dom";
import Axios from "axios";

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiFormLabel-root":{
        color:"#ffd24d"
      },
       
      "& .Mui-error":{
        color:"red  ",
       // borderColor:"red !important"
      },
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"#2e2b2b",
        paddingLeft:"1%",
        paddingRight:"1%",
      },
     
      
       
    },
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });


export const MyAccUpdateRUserDetails =()=>{
    const theme = useTheme();
    const classes = useStyles();
    const history=useNavigate();

    const [loading,setLoading]=useState(true);

     
    const [fname,setFname]=useState('');
    const [lname,setLname]=useState('');
    const [email,setEmail]=useState('');
    const [loc,setLoc]=useState('');

    const [data,setData]=useState();
     
    const [error,setError] = useState(0);

    const [wait,setWait] = useState(false);

     

    const {user, setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);


    useEffect(()=>{
        getData();
    },[])

    const getData=()=>{
        const formData={};
        var tkn=user.token;
        formData.uid=user.user_id;
        formData.role=user.user_role;
        formData.cat=user.category_ids;
        Axios.post("/new_apis/getuserdata",formData,{ 
            headers: { tkn: tkn },
           }).then((res) => {
                
                if(res.data.status===1){
                    
                     
                    if(res.data.data.user_full_name!==null){
                        setFname(res.data.data.user_full_name);
                    }
                    if(res.data.data.user_last_name!==null){
                        setLname(res.data.data.user_last_name);
                    }
                    if(res.data.data.user_email!==null){
                        setEmail(res.data.data.user_email);
                    }
                    if(res.data.data.area_ids!==null){
                        setLoc(res.data.data.area_ids);
                    }
                    
                    setLoading(false);
                }else if(res.data.status===3){
                   
                  history('/');
                   
                  setSnack({
                      message: res.data.msg,
                      type: 'warning',
                      open: true,
                      direction:"left"
                    });
              }else if(res.data.status===4){
                    setUser(null);
                    history('/');
                    localStorage.removeItem("estkn");
                    setSnack({
                        message: res.data.msg,
                        type: 'warning',
                        open: true,
                        direction:"left"
                      });
                }
                
              
           }); 
    }

    const updatedetails=(e)=>{
        e.preventDefault();
        setError(0);
            if(fname===''){
                setError(2);
                setSnack({
                    message: 'Enter your First Name',
                    type: 'error',
                    open: true,
                    direction:"center"
                  });
            }else if(loc===''){
                setError(4);
                setSnack({
                    message: 'Enter your Location',
                    type: 'error',
                    open: true,
                    direction:"center"
                  });
            }else{
                 
                const formData= {};
                var tkn=user.token;
                 
                formData.fname=fname;
                formData.lname=lname;
                formData.email=email;
                formData.loc=loc;
                formData.uid=user.user_id;
                formData.role=user.user_role;
                formData.cat=user.category_ids;
                setWait(true);
                Axios.post("/new_apis/updatedetails",formData,{ 
                    headers: { tkn: tkn },
               }).then((res) => {
                    if(res.data.status===1){
                        setUser(prevdata => {
                            return { 
                              ...prevdata, 
                              user_full_name: res.data.fname,
                              user_last_name:res.data.lname,
                              user_email:res.data.email,
                              name:res.data.fname+" "+res.data.lname 
                            }
                          })
                          setWait(false);
                          setSnack({
                            message: res.data.msg,
                            type: 'success',
                            open: true,
                            direction:"center"
                          });
                    }else if(res.data.status===3){
                        setWait(false);
                        history('/');
                        setSnack({
                            message: res.data.msg,
                            type: 'error',
                            open: true,
                            direction:"center"
                          });
                    }else if(res.data.status===4){
                        setUser(null);
                        setWait(false);
                        localStorage.removeItem("estkn");
                        setSnack({
                            message: res.data.msg,
                            type: 'warning',
                            open: true,
                            direction:"left"
                          });
                        history('/');
                    }
                    
                  
               }); 
            }
            
        
    }

    const updateLoc = (loc)=>{
        setLoc(loc);
     }

    return (<div  >
         <Container maxWidth="md">
             { loading ?  <Loading   />:(
        <Box boxShadow={3} p={3}  style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.secondary.main,borderRadius:"10px" }}> 
        <form onSubmit={updatedetails}>
       <Grid
        container
        direction="row"
            justify="space-between"
            alignItems="center"
        spacing={3}
        >
            <Grid item xs={12}>
            <Typography variant="h4" className="text-center" gutterBottom style={{color:theme.palette.primary.main}}>
                 Update Details 
            </Typography>
            <hr style={{color:theme.palette.primary.main}}/>
            </Grid>
             
            <Grid item xs={12}  sm={12} md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label="First Name *"  variant="outlined" fullWidth
                    size="small"
                         
                        error={error===2 && true }
                        type={ 'text' }
                        value={fname}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                            
                        }}
                         
                        onChange={(e) => {
                                setFname(e.target.value);

                        }}
                        
                    />
            </Grid>
            <Grid item xs={12}  sm={12} md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label="Last Name"  variant="outlined" fullWidth
                    size="small"
                         
                        
                        type={  'text' }
                        value={lname}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                             
                        
                             
                        }}
                         
                        onChange={(e) => {
                                setLname(e.target.value);

                        }}
                        
                    />
            </Grid>
            <Grid item xs={12}  sm={12} md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label="Email-ID "  variant="outlined" fullWidth
                    size="small"
                         
                         
                        type={ 'text' }
                        value={email}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                            
                        }}
                         
                        onChange={(e) => {
                                setEmail(e.target.value);

                        }}
                        
                    />
            </Grid>
            <Grid item xs={12}  sm={12} md={6} lg={6} xl={6}>
                <AutoCompLoc error={error==4?true:false} loc={loc} setloc={updateLoc}/>
            </Grid>
            <Grid item xs={12}>
              
            </Grid>
            <BackDropLoad wait={wait}/>
            <Grid item xs={12} className="text-center">
            <Button variant="contained" type="submit"  style={{width:"60%"}} fullWidth={false} color="primary"  className="linkstyle" disabled={wait} >{wait?"Please Wait..":"Update"}</Button>
            </Grid>
    </Grid>
    </form>
    </Box>)}
    </Container> 
    </div>);

}