
import {Grid,Box, Typography,useTheme,Button,Container,makeStyles,TextField,FormControl,Switch,InputLabel,Select,MenuItem} from '@material-ui/core';
import {useEffect,useState,useContext} from 'react';
 
 
import {Loading} from "./Loading";
import {BackDropLoad} from "./LoadingBackDrop";
import { UserContext,SnackbarContext } from './userContext';
import {useNavigate} from "react-router-dom";
import Axios from "axios";

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiFormLabel-root":{
        color:"#ffd24d"
      },
       
      "& .Mui-error":{
        color:"red  ",
       // borderColor:"red !important"
      },
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"#2e2b2b",
        paddingLeft:"1%",
        paddingRight:"1%",
      },
     
      
       
    },
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });


export const MyAccUpgradeProfile =()=>{
    const theme = useTheme();
    const classes = useStyles();
    const history=useNavigate();

    const [loading,setLoading]=useState(true);

    const [cats,setCats]=useState();
    const [subcats,setSubcats]=useState();

    const [cat,setCat]=useState('0');
    const [subcat,setSubcat]=useState('0');

    const [supname,setSupname]=useState('');
    
    const [email,setEmail]=useState('');

    const [exp,setExp]=useState('');
    const [qual,setQual]=useState('');

    const [courses,setCourses]=useState('');
    
    const [showhide,setShowhide]=useState('1');

    
     
    const [error,setError] = useState(0);

    const [wait,setWait] = useState(false);

     

    const {user, setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);


    useEffect(()=>{
       if(user.user_email!==null){
            setEmail(user.user_email);
       }
       getData();
    },[])

    const getData=()=>{
        const formData={};
        var tkn=user.token;
        
        formData.role=user.user_role;
         
        Axios.post("/new_apis/getupgradedata",formData,{ 
            headers: { tkn: tkn },
           }).then((res) => {
                if(res.data.status===1){
                    setCats(res.data.cats);
                    setSubcats(res.data.subcats); 
                    setLoading(false);
                }else if(res.data.status===3){
                   
                  history('/');
                   
                  setSnack({
                      message: res.data.msg,
                      type: 'error',
                      open: true,
                      direction:"left"
                    });
                }else if(res.data.status===4){
                  setUser(null);
                  history('/');
                  localStorage.removeItem("estkn");
                  setSnack({
                      message: res.data.msg,
                      type: 'warning',
                      open: true,
                      direction:"left"
                    });
                }
                
              
           }); 
    }

    const updatedetails=(e)=>{
        e.preventDefault();
        setError(0);
            if(cat==='0'){
                setError(1);
                setSnack({
                  message: 'Select a Category',
                  type: 'error',
                  open: true,
                  direction:"center"
                });
            }else if( cat!=='6' && cat!=='7' && supname===''){
              setSnack({
                message: 'Enter Reg. Supplier / Service provider name',
                type: 'error',
                open: true,
                direction:"center"
              });
                setError(2);
            }else if( cat!=='6' && cat!=='7' && !email.includes('@')){
              setSnack({
                message: 'Enter valid Email-ID',
                type: 'error',
                open: true,
                direction:"center"
              });
                setError(3);
            }else if( (cat==='6' || cat==='7') && subcat==='0'){
              setSnack({
                message: 'Select a Sub-Category',
                type: 'error',
                open: true,
                direction:"center"
              });
                setError(4);
            }else{
                 
                const formData= {};
                var tkn=user.token;
                formData.supname=supname;
                
                formData.email=email;
                 
                formData.uid=user.user_id;
                formData.role=user.user_role;
                formData.cat=cat;
                formData.subcat=subcat;
                formData.email=email;
                formData.qual=qual;
                formData.exp=exp;
                formData.courses=courses;
                formData.showhide=showhide;
                setWait(true);
                Axios.post("/new_apis/upgradeprofile",formData,{ 
                    headers: { tkn: tkn },
               }).then((res) => {
                    if(res.data.status===1){
                        if(res.data.cat==='6' || res.data.cat==='7'){
                            setUser(prevdata => {
                                return { 
                                  ...prevdata, 
                                  category_ids: res.data.cat,
                                  cat_name:res.data.catname,
                                  user_role:"2" 
                                }
                            })
                        }else{
                            setUser(prevdata => {
                                return { 
                                  ...prevdata, 
                                  category_ids: res.data.cat,
                                  cat_name:res.data.catname,
                                  user_email:res.data.email,
                                  user_role:"2" 
                                }
                            })
                        }  
                        setSnack({
                          message: res.data.msg,
                          type: 'success',
                          open: true,
                          direction:"center"
                        });
                        history('/myaccount/profile');
                        setWait(false);
                    }else if(res.data.status===3){
                      setWait(false);
                      history('/');
                      setSnack({
                          message: res.data.msg,
                          type: 'error',
                          open: true,
                          direction:"center"
                        });
                  }else if(res.data.status===4){
                      setUser(null);
                      setWait(false);
                      localStorage.removeItem("estkn");
                      setSnack({
                          message: res.data.msg,
                          type: 'warning',
                          open: true,
                          direction:"left"
                        });
                      history('/');
                    }
                    
                  
               }); 
            }
            
        
    }

   

    return (<div  >
         <Container maxWidth="md">
             { loading ?  <Loading   />:(
        <Box boxShadow={3} p={3}  style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.secondary.main,borderRadius:"10px" }}> 
        <form onSubmit={updatedetails}>
       <Grid
        container
        direction="row"
            justify="space-between"
            alignItems="center"
        spacing={3}
        >
            <Grid item xs={12}>
            <Typography variant="h4" className="text-center" gutterBottom style={{color:theme.palette.primary.main}}>
                 Upgrade Profile 
            </Typography>
            <hr style={{color:theme.palette.primary.main}}/>
            </Grid>
            <Grid item xs={12}  sm={12} md={6} lg={6} xl={6}>
             <FormControl variant="outlined" className={classes.root}
                size="small"
                error={error===1&&true}
                fullWidth  >
                 <InputLabel >
                   Category *
                 </InputLabel>
                 <Select
                  // native
                   value={cat}
                   onChange={(e) => {
                     setCat(e.target.value);
                     setSubcat("0");
                   }}
                   style={{color:theme.palette.primary.main}}
                    error={error === 1 ? true : false}
                   label="Category"
                    
                 >
                   
                   <MenuItem key="0"  value="0">Please select a Category</MenuItem>
                   {cats &&
                     cats.map((cat) => {
                       return (
                         <MenuItem
                           key={cat.category_id}
                           value={cat.category_id}
                           color="primary"
                         >
                           {cat.category_name}
                         </MenuItem>
                       );
                     })}
                 </Select>
               </FormControl>
             </Grid>
            {(cat==='7' || cat==='6') && (<Grid item xs={12}  sm={12} md={6} lg={6} xl={6}>
             <FormControl variant="outlined" className={classes.root}
                size="small"
                error={error===4&&true}
                fullWidth  >
                 <InputLabel >
                   Sub-Category *
                 </InputLabel>
                 <Select
                  // native
                   value={subcat}
                   onChange={(e) => {
                     setSubcat(e.target.value);
                     
                   }}
                   style={{color:theme.palette.primary.main}}
                    error={error === 1 ? true : false}
                   label="Category"
                    
                 >
                   
                   <MenuItem key="0"  value="0">Please select a Sub-Category</MenuItem>
                   {subcats &&
                     subcats.map((subcat) => {
                         if(cat===subcat.category_ids){
                            return (
                                <MenuItem
                                  key={subcat.sub_category_id}
                                  value={subcat.sub_category_id}
                                  color="primary"
                                >
                                  {subcat.sub_category_name}
                                </MenuItem>
                              );
                         }
                       
                     })}
                 </Select>
               </FormControl>
             </Grid>)}
             {(cat!=='7' && cat!=='6' && cat!=='0') && (<Grid item xs={12}  sm={12} md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label="Service Provider / Supplier Name *"  variant="outlined" fullWidth
                    size="small"
                         
                        error={error===2 && true }
                        type={ 'text'  }
                        value={supname}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                             
                        
                             
                        }}
                         
                        onChange={(e) => {
                                setSupname(e.target.value);

                        }}
                        
                    />
            </Grid>)}
             
            
            {(  cat!=='7' && cat!=='6') && (<Grid item xs={12}  sm={12} md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label="Email-ID *" variant="outlined" fullWidth
                    size="small"
                         
                        error={error===3 && true }
                        type={ 'text' }
                        value={email}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                            
                        }}
                         
                        onChange={(e) => {
                                setEmail(e.target.value);

                        }}
                        
                    />
            </Grid>)}
            {(cat==='7'  ) && (<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label="Experience in Years"  variant="outlined" fullWidth
                    size="small"
                         
                        
                        type={  'text' }
                        value={exp}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                             
                        
                             
                        }}
                         
                        onChange={(e) => {
                                setExp(e.target.value);

                        }}
                        
                    />
            </Grid>)}
            {(cat==='7'  ) && (<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label="Qualification"  variant="outlined" fullWidth
                    size="small"
                         
                        
                        type={  'text' }
                        value={qual}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                             
                        
                             
                        }}
                         
                        onChange={(e) => {
                                setQual(e.target.value);

                        }}
                        
                    />
            </Grid>)}
            {( cat==='6'  ) && (<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label="Courses"  variant="outlined" fullWidth
                    size="small"
                         
                        
                        type={  'text' }
                        value={courses}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                             
                        
                             
                        }}
                         
                        onChange={(e) => {
                            setCourses(e.target.value);

                        }}
                        
                    />
            </Grid>)}
             
            {(cat==='7' || cat==='6' ) && (<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <span style={{color:theme.palette.primary.main}}>Show / Hide your Profile from Public: </span>
            <br/>
            <Switch
              checked={showhide==='1'?true:false}
              onChange={()=>{
                  if(showhide==='1'){
                    setShowhide('0');
                  }else{
                    setShowhide('1'); 
                  }
                  
              }}
              color="primary"
              inputProps={{'aria-label':"primary checkbox"}}
              >

              </Switch><span style={{color:theme.palette.primary.main}}>Status: {showhide==='1'?(<span   >Show</span>):(<span   >Hide</span>)}</span>
            </Grid>)}
            <Grid item xs={12}>
              
            </Grid>
            <BackDropLoad wait={wait}/>
            <Grid item xs={12} className="text-center">
            <Button variant="contained" type="submit"  style={{width:"60%"}} fullWidth={false} color="primary"  className="linkstyle" disabled={wait} >{wait?"Please Wait..":"Upgrade"}</Button>
            </Grid>
    </Grid>
    </form>
    </Box>)}
    </Container> 
    </div>);

}