
import {useState,useEffect, useContext, React} from 'react';
import {Grid,Typography,useTheme,InputAdornment,IconButton, makeStyles,Box,TextField,Button,FormControl,Select,InputLabel,MenuItem, Container,Switch,Checkbox,Dialog,DialogTitle,DialogContent} from "@material-ui/core";
import {useNavigate,Link} from "react-router-dom";
import {Loading} from "./Loading";
import { Helmet  } from 'react-helmet-async';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LockIcon from '@material-ui/icons/Lock';
import PhoneIcon from '@material-ui/icons/Phone';
import { UserContext,SnackbarContext,EngInfoContext } from './userContext';
import {BackDropLoad} from "./LoadingBackDrop";
import { AutoCompLoc } from './AutoCompLoc.jsx';
import Axios from "axios";
 

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiFormLabel-root":{
        color:"#ffd24d"
      },
       
      "& .Mui-error":{
        color:"red  ",
       // borderColor:"red !important"
      },
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"#2e2b2b",
        paddingLeft:"1%",
        paddingRight:"1%",
      },
      
       
    },
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });


const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";
    

export const Signup=()=>{
    const history=useNavigate();
    const theme = useTheme();
    const classes = useStyles();

    const [loading,setLoading]=useState(true);

    const {user, setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);
    const {engInfoList,setEngInfoList} = useContext(EngInfoContext);

    const [metadata,setMetadata] = useState();

    const [cats,setCats]=useState();
    const [subcats,setSubcats]=useState();

    const [cat,setCat]=useState('0');
    const [subcat,setSubcat]=useState('0');

    const [supname,setSupname]=useState('');
    const [fname,setFname]=useState('');
    const [lname,setLname]=useState('');
    const [loc,setLoc] = useState("");
    const [email,setEmail]=useState('');
    const [num,setNum] = useState("");
    const [pass,setPass] = useState("");
    const [repass,setRePass] = useState("");
    const [wait,setWait] = useState(false);
    const [showPass,setShowPass] = useState(false);
    const [exp,setExp]=useState('');
    const [qual,setQual]=useState('');

    const [courses,setCourses]=useState('');
    
    const [showhide,setShowhide]=useState('1');
    const [terms,setTerms]=useState(true);
    const [error,setError] = useState(0);

    const [otp,setOtp]=useState('');
    const [otptoken,setOtptoken]=useState('');

    const [showOtpBox,setShowOtpBox]=useState(false);


    useEffect(()=>{
      window.scrollTo(0, 0);
      Axios.post("/new_apis/getsinglemetadata", {'type':"signup"},{
        headers: { key: key },
       }).then((res) => {
          
         if(res.data.status){
           
          setMetadata(res.data.metadata[0])
         } else{
            setMetadata({'seo_title':"Home - ESAY | Contacts and Info in the World of Construction",
            'seo_desc':"Get all the information and contacts of various types of contractors, consultants, machinery/material suppliers, engineers, and workforce in the construction sector",
            "seo_keywords":"best  contractors in Telangana, schedule of rates, Tenders in India,   construction architect engineers and labor near me, latest trends in construction sector, top contractors and consultants in Hyderabad,   interior designers in Hyderabad, building construction materials and machinery suppliers, soil metal materials testing labs around me"});
         }
          
       });
        getData();
     },[])
 
     const getData=()=>{
           
          
         Axios.get("/new_apis/getsignupdata",{ 
          headers: { key: "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#" }
         }).then((res) => {
                 if(res.data.status===1){
                     setCats(res.data.cats);
                     
                     setSubcats(res.data.subcats); 
                      
                     setLoading(false);
                 } 
                 
               
            }); 
     }


     const updateLoc = (loc)=>{
        setLoc(loc);
     }

     const getEngInfodata=async(tkn,uid)=>{
      const formData= {};
      var tkn=tkn;
      formData.user_id=uid;
      Axios.post("/new_apis/get_bookmarks",formData,{ 
        headers: { tkn: tkn },
       }).then((res) => {
          if(res.data.status==1){
             
              var arr=[];
              for(var i=0;i<res.data.data.length;i++){
                arr.push(res.data.data[i].id);
                 
              }
              setEngInfoList(arr);
                
          }else{
  
          }
                
       });
    }



    const signupNow=(e)=>{
        e.preventDefault();
          setError(0);
           
          if(cat==='0' ){
              setError(1);  
              setSnack({
                message: "Please Select your Category..",
                type: 'error',
                open: true,
                direction:"center"
              });
          }else if((cat==='6' || cat==='7') && subcat==='0'){
              setError(2);  
              setSnack({
                message: "Please Select your Sub-Category..",
                type: 'error',
                open: true,
                direction:"center"
              });
          }else if((cat!=='6' && cat!=='7' && cat!=='a') && supname===''){
            setError(3);  
            setSnack({
              message: "Enter Reg. Supplier / Service provider name..",
              type: 'error',
              open: true,
              direction:"center"
            });
          }else if( fname===''){
            setError(10);  
            setSnack({
              message: "Enter your First Name..",
              type: 'error',
              open: true,
              direction:"center"
            });
          }else if((cat!=='6' && cat!=='7' && cat!=='a') && !email.includes('@')){
            setError(4);  
            setSnack({
              message: "Enter valid Email-ID..",
              type: 'error',
              open: true,
              direction:"center"
            });
          }else if(num.length!==10 ){
            setError(5);  
            setSnack({
              message: "Enter Valid 10-Digit Mobile number...",
              type: 'error',
              open: true,
              direction:"center"
            });
          }else if(pass.length<5){
            setError(6);
            setSnack({
              message: 'Enter your Password should have atleast 5 characters..',
              type: 'error',
              open: true,
              direction:"center"
            });
        }else if(pass!==repass){
            setError(7);
            setSnack({
              message: 'Re-Entered Password not matching..',
              type: 'error',
              open: true,
              direction:"center"
            });
        }else if(loc===''){
            setError(8);
            setSnack({
              message: 'Please select your Location from the Suggestions..',
              type: 'error',
              open: true,
              direction:"center"
            });
        }else if(terms===false){
            setError(9);
            setSnack({
              message: 'Please Read and Agree to our Terms and Conditions..',
              type: 'error',
              open: true,
              direction:"center"
            });
        }else{
           setWait(true);
          const formData= {};
          formData.mob=num;
          formData.name=fname;
          Axios.post("/new_apis/generateotp",formData,{ 
              headers: { key: "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#" },
             }).then(  (res) => {
                 
                  if(res.data.status===1){
                      setWait(false); 
                       
                      setOtptoken(res.data.otptoken);
                      setShowOtpBox(true);
                      setSnack({
                        message: res.data.msg,
                        type: 'success',
                        open: true,
                        direction:"center"
                      });
                      

                       
                  }else if(res.data.status===2){
                    setWait(false);
                    setSnack({
                      message: res.data.msg,
                      type: 'error',
                      open: true,
                      direction:"center"
                    });
                  }
                  
                
             }); 
        } 
    }


    const submitOtp=(e)=>{
      e.preventDefault();
          setError(0);
          if(otp===''){
            setError(11);
            setSnack({
              message: 'Please Enter your One Time Password...',
              type: 'error',
              open: true,
              direction:"center"
            });
          }else{
            setShowOtpBox(false);
            setWait(true);
            const formData= {};
           
           formData.cat=cat;
           formData.subcat=subcat;
           formData.fname=fname;
           formData.lname=lname;
           formData.mob=num;
           formData.pass=pass;
           formData.supname=supname;
           formData.email=email;
           formData.otp=otp;
           formData.loc=loc;
           formData.qual=qual;
           formData.exp=exp;
           formData.showhide=showhide;
           formData.courses=courses;
           formData.otptoken=otptoken;
          Axios.post("/new_apis/validateotp",formData,{ 
              headers: { key: "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#" },
             }).then(  (res) => {
                 
                  if(res.data.status===1){
                    setWait(false);
                    setUser(res.data.user_data);
                     
                    localStorage.setItem('estkn',res.data.token);
                    getEngInfodata(res.data.token,res.data.user_data.user_id);
                    setSnack({
                      message: res.data.msg,
                      type: 'success',
                      open: true,
                      direction:"left"
                    });
                    history('/myaccount/profile');
                      

                       
                  }else if(res.data.status===2){
                    setWait(false);
                    setShowOtpBox(true);
                    setSnack({
                      message: res.data.msg,
                      type: 'error',
                      open: true,
                      direction:"center"
                    });
                  }
                  
                
             }); 
         
          }
    }

    return (<div className="container">
        {metadata && <Helmet>
  <title>{metadata.seo_title}</title>
    <meta name="description" content={metadata.seo_desc} />
    <meta name="keywords" content={metadata.seo_keywords}></meta>
  </Helmet>}
                <Container maxWidth="md">
        <Box boxShadow={3} p={3}  style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.secondary.main,marginTop:"10%",marginBottom:"10%",borderRadius:"10px"}}> 
        { loading ?  <Loading   />:(
        <div>
          <Dialog
        open={showOtpBox}
        onClose={()=>{

        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
            style: {
              backgroundColor: theme.palette.secondary.main,
              boxShadow: 'none',
            },
          }}
        //style={{backgroundColor:theme.palette.secondary.main}}
      >
           
          <DialogTitle id="alert-dialog-title"> <Typography variant="h4" className="text-center"  style={{color:theme.palette.primary.main   }}>
                                      Enter your OTP            
                        </Typography>
                        <hr style={{color:theme.palette.primary.main}}/>
                        </DialogTitle>
          <DialogContent  style={{overflow:"hidden"}}>
          <form onSubmit={submitOtp}>
           <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={3}
            >
                <Grid item xs={12}   >
                <TextField className={classes.root}  label="OTP *"  variant="outlined" fullWidth
                    size="small"
                         
                        value={otp}
                        type="number"
                        error={error===11 && true }
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                             
                        }}
                      
                        
                        onChange={(e) => {
                                 
                                    setOtp(e.target.value);
                                
                                

                        }}
                        
                    />
                </Grid>
                 
                 
                 
                <Grid item xs={12}    >
                 
                </Grid>
                <Grid item xs={12}  className="text-center" >
                <Button variant="contained" type="submit"  style={{width:"60%"}} fullWidth={false} color="primary"  className="linkstyle"  >Validate OTP</Button>
                </Grid>
                <Grid item xs={12}   >
                 <br/>
                </Grid>
            </Grid>
            </form>
            </DialogContent>
             
      </Dialog>
        <form onSubmit={signupNow}>
        <Grid
        container
        direction="row"
            justify="space-between"
            alignItems="center"
        spacing={3}
        >
            <Grid item xs={12}>
                <Typography variant="h4" style={{color:theme.palette.primary.main}} className="text-center" gutterBottom>
                                SIGNUP  
                </Typography>
                <hr style={{color:theme.palette.primary.main}}/>
            </Grid>    
            
            <Grid item xs={12}  sm={12} md={6} lg={6} xl={6}>
             <FormControl variant="outlined" className={classes.root}
                size="small"
                error={error===1&&true}
                fullWidth  >
                 <InputLabel >
                   Category *
                 </InputLabel>
                 <Select
                  // native
                   value={cat}
                   onChange={(e) => {
                     setCat(e.target.value);
                     setSubcat("0");
                   }}
                   style={{color:theme.palette.primary.main}}
                    error={error === 1 ? true : false}
                   label="Category"
                    
                 >
                   
                   <MenuItem key="0"  value="0">Please select a Category</MenuItem>
                   <MenuItem key="a"  value="a" style={{color:"white"}}>Regular User</MenuItem>
                   {cats &&
                     cats.map((cat) => {
                       return (
                         <MenuItem
                           key={cat.category_id}
                           value={cat.category_id}
                           color={"primary"}
                           style={{color:cat.category_id==='6'||cat.category_id==='7'?"white":theme.palette.primary.main}}
                         >
                           {cat.category_name}
                         </MenuItem>
                       );
                     })}
                 </Select>
               </FormControl>
             </Grid>
             {(cat==='7' || cat==='6') && (<Grid item xs={12}  sm={12} md={6} lg={6} xl={6}>
             <FormControl variant="outlined" className={classes.root}
                size="small"
                error={error===2&&true}
                fullWidth  >
                 <InputLabel >
                   Sub-Category *
                 </InputLabel>
                 <Select
                  // native
                   value={subcat}
                   onChange={(e) => {
                     setSubcat(e.target.value);
                     
                   }}
                   style={{color:theme.palette.primary.main}}
                    error={error === 1 ? true : false}
                   label="Category"
                    
                 >
                   
                   <MenuItem key="0"  value="0">Please select a Sub-Category</MenuItem>
                   {subcats &&
                     subcats.map((subcat) => {
                         if(cat===subcat.category_ids){
                            return (
                                <MenuItem
                                  key={subcat.sub_category_id}
                                  value={subcat.sub_category_id}
                                  color="primary"
                                >
                                  {subcat.sub_category_name}
                                </MenuItem>
                              );
                         }
                       
                     })}
                 </Select>
               </FormControl>
             </Grid>)}
             {(cat!=='7' && cat!=='6' && cat!=='0' && cat!=='a') && (<Grid item xs={12}  sm={12} md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label="Service Provider / Supplier Name *"  variant="outlined" fullWidth
                    size="small"
                         
                        error={error===3 && true }
                        type={ 'text'  }
                        value={supname}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                             
                        
                             
                        }}
                         
                        onChange={(e) => {
                                setSupname(e.target.value);

                        }}
                        
                    />
            </Grid>)}
            <Grid item xs={12}  sm={12} md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label="First Name *"  variant="outlined" fullWidth
                    size="small"
                         
                        error={error===10 && true }
                        type={ 'text'  }
                        value={fname}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                             
                        
                             
                        }}
                         
                        onChange={(e) => {
                                setFname(e.target.value);

                        }}
                        
                    />
            </Grid>
            <Grid item xs={12}  sm={12} md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label="Last Name "  variant="outlined" fullWidth
                    size="small"
                         
                         
                        type={ 'text'  }
                        value={lname}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                             
                        
                             
                        }}
                         
                        onChange={(e) => {
                                setLname(e.target.value);

                        }}
                        
                    />
            </Grid>
            <Grid item xs={12}  sm={12} md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label={(cat!=='7' && cat!=='6' && cat!=='0' && cat!=='a')?"Email-ID *":"Email-ID"} variant="outlined" fullWidth
                    size="small"
                         
                        error={error===4 && true }
                        type={ 'text' }
                        value={email}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                            
                        }}
                         
                        onChange={(e) => {
                                setEmail(e.target.value);

                        }}
                        
                    />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label="Mobile Number *"  variant="outlined" fullWidth
                    size="small"
                         
                        value={num}
                        type="number"
                        error={error===5 && true }
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                            startAdornment:(<InputAdornment position="end">
                                <span style={{marginRight:"8px"}}>
                                <PhoneIcon color="primary"/>  
                                </span>
                             
                          </InputAdornment>),
                        }}
                        //InputLabelProps={{  shrink: true,focused:true }} 
                        
                        onChange={(e) => {
                                 
                                    setNum(e.target.value);
                                
                                

                        }}
                        
                    />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
             
            <TextField className={classes.root}  label="Password *"  variant="outlined" fullWidth
                    size="small"
                         
                        error={error===6 && true }
                        type={showPass ? 'text' : 'password'}
                        value={pass}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                            startAdornment:(<InputAdornment position="end">
                                <span style={{marginRight:"8px"}}>
                                <LockIcon color="primary"/>  
                                </span>
                             
                          </InputAdornment>),
                        
                            endAdornment:(<InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={()=>{
                                  setShowPass(!showPass);
                              }}
                              onMouseDown={()=>{
                                  setShowPass(!showPass);
                              }}
                              edge="end"
                            >
                              {showPass ? <Visibility color="primary"/> : <VisibilityOff  color="primary"/>}
                            </IconButton>
                          </InputAdornment>)
                        }}
                         
                        onChange={(e) => {
                                setPass(e.target.value);

                        }}
                        
                    />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
             
            <TextField className={classes.root}  label="Re-Enter Password *"  variant="outlined" fullWidth
                    size="small"
                         
                        error={error===7 && true }
                        type={showPass ? 'text' : 'password'}
                        value={repass}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                            startAdornment:(<InputAdornment position="end">
                                <span style={{marginRight:"8px"}}>
                                <LockIcon color="primary"/>  
                                </span>
                             
                          </InputAdornment>),
                        
                            endAdornment:(<InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={()=>{
                                  setShowPass(!showPass);
                              }}
                              onMouseDown={()=>{
                                  setShowPass(!showPass);
                              }}
                              edge="end"
                            >
                              {showPass ? <Visibility color="primary"/> : <VisibilityOff  color="primary"/>}
                            </IconButton>
                          </InputAdornment>)
                        }}
                         
                        onChange={(e) => {
                                setRePass(e.target.value);

                        }}
                        
                    />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
       <AutoCompLoc error={error==8?true:false} loc={loc} setloc={updateLoc}/>
        
      </Grid>
            {(cat==='7'  ) && (<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label="Experience in Years"  variant="outlined" fullWidth
                    size="small"
                         
                        
                        type={  'text' }
                        value={exp}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                             
                        
                             
                        }}
                         
                        onChange={(e) => {
                                setExp(e.target.value);

                        }}
                        
                    />
            </Grid>)}
            {(cat==='7'  ) && (<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label="Qualification"  variant="outlined" fullWidth
                    size="small"
                         
                        
                        type={  'text' }
                        value={qual}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                             
                        
                             
                        }}
                         
                        onChange={(e) => {
                                setQual(e.target.value);

                        }}
                        
                    />
            </Grid>)}
            {( cat==='6'  ) && (<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label="Courses"  variant="outlined" fullWidth
                    size="small"
                         
                        
                        type={  'text' }
                        value={courses}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                             
                        
                             
                        }}
                         
                        onChange={(e) => {
                            setCourses(e.target.value);

                        }}
                        
                    />
            </Grid>)}
            {(cat==='7' || cat==='6' ) && (<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <span style={{color:theme.palette.primary.main}}>Show / Hide your Profile from Public: </span>
            <br/>
            <Switch
              checked={showhide==='1'?true:false}
              onChange={()=>{
                  if(showhide==='1'){
                    setShowhide('0');
                  }else{
                    setShowhide('1'); 
                  }
                  
              }}
              color="primary"
              inputProps={{'aria-label':"primary checkbox"}}
              >

              </Switch><span style={{color:theme.palette.primary.main}}>Status: {showhide==='1'?(<span   >Show</span>):(<span   >Hide</span>)}</span>
            </Grid>)}
            <Grid item xs={12}  className="text-center">
            <Checkbox
                    checked={terms}
                     
                    onChange={()=>{
                        setTerms(!terms);
                    }}
                    name="checkedB"
                    color="primary"
                />&nbsp;&nbsp;I here by read and agree to the <Link to="/terms"   className="anchorTag" style={{color:"white",textDecoration:"underline"}} >Terms and Conditions</Link> .{error===9 && (<span style={{color:"red"}}><br/>Read and agree to our Terms and Conditions.</span>)}
            </Grid>
            <BackDropLoad wait={wait}/>
            <Grid item xs={12} className="text-center">
            <Button variant="contained" type="submit"  style={{width:"60%"}} color="primary" disabled={wait} className="linkstyle"  >{wait?"Please Wait..":"Register Now"}</Button>
            </Grid>
        </Grid>
        </form></div>)}
        </Box>
        </Container>
    </div>);
}