import {useEffect,useState} from "react";
//import Loading from './Loading';
//import Axios from "axios";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Container ,Box} from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
 
export const ConvMoment=()=>{
    
     
    useEffect(()=>{
      window.scrollTo(0, 0);
        
    },[]);

return  (  <div>
    
     
    <Grid
  container
  direction="column"
  justify="space-evenly"
  alignItems="center"
  spacing={3}
>
<Grid item xs={12}>
         
   </Grid>
<Grid item xs={12}>
        <Typography variant="h5" component="h4" className="text-center" color="primary" gutterBottom>
          MOMENT 
        </Typography>
   </Grid>
  <Grid item xs={12}>
  <Moment />
  </Grid>
  <Grid item xs={12}>
   
  </Grid>
  <Grid item xs={12}>
  <SimpleCard/>
  </Grid>
  </Grid>
    
    
</div>

 );
}

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiFormLabel-root":{
        color:"#ffd24d"
      },
       
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"rgb(41, 38, 38)",
        paddingLeft:"1%",
        paddingRight:"1%",
      } 
       
    }
  
  });
  
  export default function SimpleCard() {
    const classes = useStyles();
    
  
    return (
        <Box m={3} >
      <Card className={classes.root}  style={{ border:"2px groove #ffd24d"}}>
        <CardContent>
          
          <Typography variant="h6"  className="text-center">
           UNITS OF MOMENT 
          </Typography>
          <br></br>
          <Typography variant="body2" >
          1 lb.feet = 0.138 kg metre The measurement of the moment in the English system of one 
          lb.feet is equal to 0.138 times one kg metre of the metric system.
          </Typography>
        </CardContent>
        
      </Card>
      </Box>
    );
  }
    
 


const Moment=()=>{
    const classes = useStyles();
  const [lbfeet,setlbfeet] = useState();
  const [lbinch,setlbinch] = useState();
  const [kgcm,setkgcm] = useState();
  const [kgm,setkgm] = useState();
  const [tonmeter,settonmeter] = useState();
  const [knewmeter,setknewmeter] = useState();
  const [nm,setnm] = useState();

  return (
      <Container maxWidth="lg">
      <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      spacing={4}
    >
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <TextField className={classes.root}  size="small"   label="lb feet" variant="outlined" shrink="true" fullWidth 
              type="number"
              InputProps={{
                  style: {
                      color: "#ffd24d"
                  }
              }}
              InputLabelProps={{  shrink: true,focused:true }}
              value={lbfeet}
              onChange={(e) => {
                      
                      var val = parseFloat(e.target.value);
                      var val1 = parseFloat(e.target.value);
                      setlbfeet(val1);
                      val=(val1*12)
                      setlbinch(val);
                      val=val1/0.07223
                      setkgcm(val);
                      val=val1/7.233
                      setkgm(val);
                      val=val1/7233.014
                      settonmeter(val);
                      val=val1/737.56214927727
                      setknewmeter(val);
                      val=val1/7375621.4927727
                      setnm(val);
                  
              }}
               
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField className={classes.root}  size="small" label="lb inch" variant="outlined" fullWidth
        InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          type="number"
          value={lbinch}
          onChange={(e) => {
              
                  var val = parseFloat(e.target.value);
                  var val1 = parseFloat(e.target.value);
                  setlbinch(val1);
                  val=(val1/12)
                  setlbfeet(val);
                  val=(val1/0.86796165979664)
                  setkgcm(val);
                  val=(val1/86.796165979664)
                  setkgm(val);
                  val=(val1*0.000012700000077934)
                  settonmeter(val);
                  val=(val1/8850.7457673787 )
                  setknewmeter(val);
                  val=(val1/8.8507457673787)
                  setnm(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField className={classes.root}  size="small" label="kg cm" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={kgcm}
          onChange={(e) => {
              
                  var val = parseFloat(e.target.value);
                  var val1 = parseFloat(e.target.value);
                  setkgcm(val1);
                  val=(val1*0.07223)
                  setlbfeet(val);
                  val=val1*0.86796165979664
                  setlbinch(val);
                  val=val1/100
                  setkgm(val);
                  val=val1*0.00001
                  settonmeter(val);
                  val=val1/10197.162129779
                  setknewmeter(val);
                  val=val1/10.197162129779
                  setnm(val);

              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField className={classes.root}  size="small" label="kg meter" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={kgm}
          onChange={(e) => {
             
                  var val = parseFloat(e.target.value);
                  var val1 = parseFloat(e.target.value);
                  setkgm(val1);
                  val=(val1*7.223)
                  setlbfeet(val);
                  val= val1*86.796165979664
                  setlbinch(val);
                  val= val1*100
                  setkgcm(val);
                  val=val1/1000
                  settonmeter(val);
                  val=val1/101.972
                  setknewmeter(val);
                  val=val1/0.10197162129779
                  setnm(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField className={classes.root}  size="small" label="ton meter" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={tonmeter}
          onChange={(e) => {
              
                  var val = parseFloat(e.target.value);
                  var val1 = parseFloat(e.target.value);
                  settonmeter(val1);
                  val=(val1*7233.014)
                  setlbfeet(val);
                  val=val1/0.000012700000077934
                  setlbinch(val);
                  val=val1*90718.47
                  setkgcm(val);
                  val=val1*907.18
                  setkgm(val);
                  val=val1*9.807
                  setknewmeter(val);
                  val=val1/0.00011240447193548
                  setnm(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField className={classes.root}  size="small" label="kilo newton meter" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={knewmeter}
          onChange={(e) => {
              
                  var val = parseFloat(e.target.value);
                  var val1 = parseFloat(e.target.value);
                  setknewmeter(val1);
                  val=(val1*737.56214927727)
                  setlbfeet(val);
                  val=val1/0.00011298482933333
                  setlbinch(val);
                  val=val1/0.0000980665
                  setkgcm(val);
                  val=val1/0.00980665
                  setkgm(val);
                  val=val1/9.807
                  settonmeter(val);
                  val=val1*1000
                  setnm(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField className={classes.root}  size="small" label="newton meter" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={nm}
          onChange={(e) => {
             
                  var val = parseFloat(e.target.value);
                  var val1 = parseFloat(e.target.value);
                  setnm(val1);
                  val=(val1*0.737)
                  setlbfeet(val);
                  val=val1*8.851
                  setlbinch(val);
                  val=val1/0.0980665 
                  setkgcm(val);
                  val=val1/9.807
                  setkgm(val);
                  val=val1/9806.65
                  settonmeter(val);
                  val=val1/1000
                  setknewmeter(val);
              }
          }
           
        />
        </Grid>
  </Grid></Container>);
}

