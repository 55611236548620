
import {useState,useEffect, useContext, React} from 'react';
import {Grid,Typography,useTheme,InputAdornment,IconButton, makeStyles,Box,TextField,Button,FormControl,Select,InputLabel,MenuItem, Container,Switch,Checkbox,Dialog,DialogTitle,DialogContent} from "@material-ui/core";
import {useNavigate,Link} from "react-router-dom";
import {Loading} from "./Loading";
import { Helmet  } from 'react-helmet-async';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LockIcon from '@material-ui/icons/Lock';
import PhoneIcon from '@material-ui/icons/Phone';
import { UserContext,SnackbarContext,EngInfoContext } from './userContext';
import {BackDropLoad} from "./LoadingBackDrop";
import { AutoCompLoc } from './AutoCompLoc.jsx';
import Axios from "axios";
 

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiFormLabel-root":{
        color:"#ffd24d"
      },
       
      "& .Mui-error":{
        color:"red  ",
       // borderColor:"red !important"
      },
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"#2e2b2b",
        paddingLeft:"1%",
        paddingRight:"1%",
      },
      
       
    },
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });


const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";
    

export const RequestCategoryChange=()=>{
    const history=useNavigate();
    const theme = useTheme();
    const classes = useStyles();

    const [loading,setLoading]=useState(true);

    const {user, setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);
    const {engInfoList,setEngInfoList} = useContext(EngInfoContext);

    const [metadata,setMetadata] = useState();

    const [cats,setCats]=useState();
    const [subcats,setSubcats]=useState();

    const [cat,setCat]=useState('0');
    const [subcat,setSubcat]=useState('0');

    const [supname,setSupname]=useState('');
    const [fname,setFname]=useState('');
    const [lname,setLname]=useState('');
    const [loc,setLoc] = useState("");
    const [email,setEmail]=useState('');
    const [num,setNum] = useState("");
    const [pass,setPass] = useState("");
    const [repass,setRePass] = useState("");
    const [wait,setWait] = useState(false);
    const [showPass,setShowPass] = useState(false);
    const [exp,setExp]=useState('');
    const [qual,setQual]=useState('');

    const [courses,setCourses]=useState('');
    
    const [showhide,setShowhide]=useState('1');
     
    const [error,setError] = useState(0);

    

  


    useEffect(()=>{
      window.scrollTo(0, 0);
       
        getData();
     },[])
 
     const getData=()=>{
           
          
         Axios.get("/new_apis/getsignupdata",{ 
          headers: { key: "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#" }
         }).then((res) => {
                 if(res.data.status===1){
                     setCats(res.data.cats);
                     
                     setSubcats(res.data.subcats); 
                      
                     setLoading(false);
                 } 
                 
               
            }); 
     }


     const updateLoc = (loc)=>{
        setLoc(loc);
     }

      



    const finishProfile=(e)=>{
        e.preventDefault();
          setError(0);
           
          if(cat==='0' ){
              setError(1);  
              setSnack({
                message: "Please Select your Category..",
                type: 'error',
                open: true,
                direction:"center"
              });
          }else if((cat==='6' || cat==='7') && subcat==='0'){
              setError(2);  
              setSnack({
                message: "Please Select your Sub-Category..",
                type: 'error',
                open: true,
                direction:"center"
              });
          } else{
           setWait(true);
           const formData= {};
           var tkn=user.token;
           formData.user_id=user.user_id;
           formData.cat=cat;
           
           formData.subcat=subcat;
           
          Axios.post("/new_apis/request_category_change",formData,{ 
              headers: { tkn: tkn  },
             }).then(  (res) => {
                 
                  if(res.data.status===1){
                      setWait(false); 
                      setSnack({
                        message: res.data.msg,
                        type: 'success',
                        open: true,
                        direction:"left"
                      });
                       
                      
                  }else if(res.data.status===2){
                    setWait(false);
                    setSnack({
                      message: res.data.msg,
                      type: 'error',
                      open: true,
                      direction:"center"
                    });
                  }
                  
                
             }); 
        } 
    }


    // const finishProfile=(e)=>{
    //   e.preventDefault();
    //       setError(0);
    //       if(otp===''){
    //         setError(11);
    //         setSnack({
    //           message: 'Please Enter your One Time Password...',
    //           type: 'error',
    //           open: true,
    //           direction:"center"
    //         });
    //       }else{
    //         setShowOtpBox(false);
    //         setWait(true);
    //         const formData= {};
           
    //        formData.cat=cat;
    //        formData.subcat=subcat;
    //        formData.fname=fname;
    //        formData.lname=lname;
    //        formData.mob=num;
    //        formData.pass=pass;
    //        formData.supname=supname;
    //        formData.email=email;
    //        formData.otp=otp;
    //        formData.loc=loc;
    //        formData.qual=qual;
    //        formData.exp=exp;
    //        formData.showhide=showhide;
    //        formData.courses=courses;
    //        formData.otptoken=otptoken;
    //       Axios.post("/new_apis/validateotp",formData,{ 
    //           headers: { key: "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#" },
    //          }).then(  (res) => {
                 
    //               if(res.data.status===1){
    //                 setWait(false);
    //                 setUser(res.data.user_data);
                     
    //                 localStorage.setItem('estkn',res.data.token);
    //                 getEngInfodata(res.data.token,res.data.user_data.user_id);
    //                 setSnack({
    //                   message: res.data.msg,
    //                   type: 'success',
    //                   open: true,
    //                   direction:"left"
    //                 });
    //                 history('/myaccount/profile');
                      

                       
    //               }else if(res.data.status===2){
    //                 setWait(false);
    //                 setShowOtpBox(true);
    //                 setSnack({
    //                   message: res.data.msg,
    //                   type: 'error',
    //                   open: true,
    //                   direction:"center"
    //                 });
    //               }
                  
                
    //          }); 
         
    //       }
    // }

    return (<div className="container">
         
                <Container maxWidth="md">
        <Box boxShadow={3} p={3}  style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.secondary.main, marginBottom:"10%",borderRadius:"10px"}}> 
        { loading ?  <Loading   />:(
        <div>
           
        <form onSubmit={finishProfile}>
        <Grid
        container
        direction="row"
            justify="space-between"
            alignItems="center"
        spacing={3}
        >
            <Grid item xs={12}>
                <Typography variant="h4" style={{color:theme.palette.primary.main}} className="text-center" gutterBottom>
                                REQUEST CATEGORY CHANGE  
                </Typography>
                <hr style={{color:theme.palette.primary.main}}/>
            </Grid>    
            
            <Grid item xs={12}  sm={12} md={6} lg={6} xl={6}>
             <FormControl variant="outlined" className={classes.root}
                size="small"
                error={error===1&&true}
                fullWidth  >
                 <InputLabel >
                   Category *
                 </InputLabel>
                 <Select
                  // native
                   value={cat}
                   onChange={(e) => {
                     setCat(e.target.value);
                     setSubcat("0");
                   }}
                   style={{color:theme.palette.primary.main}}
                    error={error === 1 ? true : false}
                   label="Category"
                    
                 >
                   
                   <MenuItem key="0"  value="0">Please select a Category</MenuItem>
                  
                   {cats &&
                     cats.map((cat) => {
                       return (
                         <MenuItem
                           key={cat.category_id}
                           value={cat.category_id}
                           color={"primary"}
                           style={{color:cat.category_id==='6'||cat.category_id==='7'?"white":theme.palette.primary.main}}
                         >
                           {cat.category_name}
                         </MenuItem>
                       );
                     })}
                 </Select>
               </FormControl>
             </Grid>
             {(cat==='7' || cat==='6') && (<Grid item xs={12}  sm={12} md={6} lg={6} xl={6}>
             <FormControl variant="outlined" className={classes.root}
                size="small"
                error={error===2&&true}
                fullWidth  >
                 <InputLabel >
                   Sub-Category *
                 </InputLabel>
                 <Select
                  // native
                   value={subcat}
                   onChange={(e) => {
                     setSubcat(e.target.value);
                     
                   }}
                   style={{color:theme.palette.primary.main}}
                    error={error === 1 ? true : false}
                   label="Category"
                    
                 >
                   
                   <MenuItem key="0"  value="0">Please select a Sub-Category</MenuItem>
                   {subcats &&
                     subcats.map((subcat) => {
                         if(cat===subcat.category_ids){
                            return (
                                <MenuItem
                                  key={subcat.sub_category_id}
                                  value={subcat.sub_category_id}
                                  color="primary"
                                >
                                  {subcat.sub_category_name}
                                </MenuItem>
                              );
                         }
                       
                     })}
                 </Select>
               </FormControl>
             </Grid>)}
            
           
             <Grid item xs={12}  >
                <br/>
             </Grid>
            <BackDropLoad wait={wait}/>
            <Grid item xs={12} className="text-center">
            <Button variant="contained" type="submit"  style={{width:"60%"}} color="primary" disabled={wait} className="linkstyle"  >{wait?"Please Wait..":"Submit Request"}</Button>
            </Grid>
        </Grid>
        </form></div>)}
        </Box>
        </Container>
    </div>);
}