import {useEffect,useState,useContext} from "react";
import {Loading} from './Loading';
import { UserContext,SnackbarContext } from './userContext';
import Axios from "axios";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Container ,useTheme,Box,Typography,Card,CardMedia,CardActions,Button} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {useNavigate} from "react-router-dom";
import {BackDropLoad} from "./LoadingBackDrop";
import PublishIcon from '@material-ui/icons/Publish';

import {withStyles} from '@material-ui/core/styles';

import Compressor from 'compressorjs';
import { Helmet  } from 'react-helmet-async';

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiFormLabel-root":{
        color:"#ffd24d"
      },
       
      "& .Mui-error":{
        color:"red  ",
       // borderColor:"red !important"
      },
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"#2e2b2b",
        paddingLeft:"1%",
        paddingRight:"1%",
      },
      
       
    },
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });
const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";

export const QandA=()=>{
  
    const history=useNavigate();

  const theme = useTheme();
  const classes = useStyles();
  const [metadata,setMetadata] = useState();

  const [loading,setLoading]=useState(true);

  const {user, setUser} = useContext(UserContext);
  const {snack, setSnack} = useContext(SnackbarContext);

  const [error,setError] = useState(0);
  const [wait,setWait] = useState(false);
  const [data, setData] = useState([])

  const [filteredData, setFilteredData] = useState([])

  const [quesTitle, setQuesTitle] = useState("");
  const [quesDesc, setQuesDesc] = useState("");
  const [images,setImages]=useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  
  useEffect(()=>{
    window.scrollTo(0, 0);
    getData();
  },[])


  const getData=async()=>{
    await Axios.post("/new_apis/getsinglemetadata", {'type':"about"},{
        headers: { key: key },
       }).then((res) => {
          
         if(res.data.status){
           
          setMetadata(res.data.metadata[0])
         } else{
            setMetadata({'seo_title':"Home - ESAY | Contacts and Info in the World of Construction",
            'seo_desc':"Get all the information and contacts of various types of contractors, consultants, machinery/material suppliers, engineers, and workforce in the construction sector",
            "seo_keywords":"best  contractors in Telangana, schedule of rates, Tenders in India,   construction architect engineers and labor near me, latest trends in construction sector, top contractors and consultants in Hyderabad,   interior designers in Hyderabad, building construction materials and machinery suppliers, soil metal materials testing labs around me"});
         }
          
       });

       await Axios.get("/new_apis/get_all_questions", {
        headers: { key: key },
       }).then((res) => {
          
         if(res.data.status===1){
         
            setData(res.data.data)
            setLoading(false)
         }  
          
       });  


  }

  let compImage =   (image) => {
    return new Promise((resolve,reject)=>{
      new Compressor(image, {
        quality: 0.8,  
        success: async (compressedResult) => {
          return resolve(compressedResult);  
        }
      });
    })
    
  };

  const askQuestion=(e)=>{
    e.preventDefault();
    setError(0);
    if(quesTitle===''){
        setError(1);
        setSnack({
            message: "Please Enter Question Title..",
            type: 'error',
            open: true,
            direction:"center"
          });
    } else if(quesDesc===''){
        setError(2);
        setSnack({
            message: "Please Enter Question Description..",
            type: 'error',
            open: true,
            direction:"center"
          });
    }else{
        setWait(true);
        var tkn=user.token;
        const formData=new FormData();
        formData.append('user_id',user.user_id);
        formData.append('question_title',quesTitle);
        formData.append('question_desc',quesDesc);
        if(videoUrl!==''){
            formData.append('video_url',videoUrl);
        }
        if(images.length!==0){
            for(var i=0;i<images.length;i++){     
                formData.append('images',images[i].raw);
            }
        }
        Axios.post("/new_apis/ask_question",formData,{ 
            headers: { tkn: tkn},
        }).then((res) => {
            if(res.data.status===1){
                setWait(false);
                setImages([])
                setQuesDesc("")
                setQuesTitle("")
                setVideoUrl("")
                setSnack({
                  message: res.data.msg,
                  type: 'success',
                  open: true,
                  direction:"center"
                });
                 
            }else if(res.data.status===2){
                setWait(false);
                setSnack({
                  message: res.data.msg,
                  type: 'error',
                  open: true,
                  direction:"center"
                });
            }else if(res.data.status===3){
              setWait(false);
              history('/');
              setSnack({
                message: res.data.msg,
                type: 'error',
                open: true,
                direction:"center"
              });
          }else if(res.data.status===4){
                setUser(null);
                setWait(false);
                localStorage.removeItem("estkn");
                history('/');
                setSnack({
                  message: res.data.msg,
                  type: 'warning',
                  open: true,
                  direction:"center"
                });

            }
        });
    }
  }
return  (  <div>{metadata && <Helmet>
  <title>{metadata.seo_title}</title>
    <meta name="description" content={metadata.seo_desc} />
    <meta name="keywords" content={metadata.seo_keywords}></meta>
  </Helmet>} <Container maxWidth="lg">
  <br/><br/>
        <Typography variant="h4" className="divider line glow" gutterBottom style={{color:theme.palette.primary.main}}>
        Community Q&A 
     </Typography>
     <Container maxWidth="sm">
     <Typography variant="body2" className="text-center subHeading" gutterBottom style={{color:"white",margin:"10px",padding:"10px",  border:"1px solid #ffd24d",borderRadius:"100px",backgroundColor:theme.palette.secondary.main}}>
     Your Space for <span style={{color:theme.palette.primary.main }}>Questions</span> and <span style={{color:theme.palette.primary.main }}>Collaborative Answers</span>.
      </Typography>
      </Container>
      <br/><br/>
      <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            spacing={4}
            >
                <Grid item xl={9} lg={8} md={8} sm={12} xs={12}>
                    <Container  >
                <Box boxShadow={3} p={3}  style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.secondary.main, marginBottom:"10%",borderRadius:"10px"}}>
                <form onSubmit={askQuestion}>
                <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start" 
                spacing={3}
                >
                    <Grid item xs={12}>
                    <Typography variant="h4" style={{color:theme.palette.primary.main}} className="text-center" gutterBottom>
                                Ask a Question
                    </Typography>
                    <hr style={{color:theme.palette.primary.main}}/>
                    </Grid> 
                    <Grid item xs={12} sm={6}>
                    <TextField className={classes.root}  label="Question Title *"  variant="outlined" fullWidth
                    size="small"
                         
                        error={error===1 && true }
                        type={ 'text'  }
                        value={quesTitle}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                             
                        
                             
                        }}
                         
                        onChange={(e) => {
                                setQuesTitle(e.target.value);

                        }}
                        
                    />  
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <TextField className={classes.root}  label="Question Description *"  variant="outlined" fullWidth
                    size="small"
                         
                    error={error===2 && true }
                        type={ 'text'  }
                        value={quesDesc}
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                        }}
                         multiline
                         rows={4}
                        onChange={(e) => {
                                setQuesDesc(e.target.value);

                        }}
                        
                    />
                    </Grid>
                    <Grid item xs={12}  sm={6}   >
              <input accept="image/*,capture=camera" 
              capture="camera"
              style={{display:"none"}} 
              onChange={ async(e)=>{
                  if(e.target.files.length!=0){
                     
                    if(images.length+e.target.files.length<=3){
                         
                         var arr=[];
                        for(var i=0;i<e.target.files.length;i++){
                            var type= e.target.files[i].type;
                            if(type==="image/jpeg" || type==="image/jpg" ||type==="image/png" ){
                              const image=e.target.files[i];
                              var img= await compImage(image);  
                              arr.push({raw:img,preview:URL.createObjectURL(img)});  
                            }else{
                                alert("Please select only JPEG, JPG, PNG Images..")
                            }
                        }
                        setImages([...images,...arr]); 
                         
                    }else{
                        alert("Maximum Image limit is 3.");
                        
                    }
                }
                e.target.value=""
              }}
              id="contained-button-file"
              multiple
              type="file"
              />
              <label htmlFor="contained-button-file">
                    <Button variant="contained" color="primary" component="span">
                       <PublishIcon/>&nbsp;&nbsp;Upload Images (Optional)
                    </Button>
              </label>
               
            </Grid>
            <Grid item xs={12}  >
            {(images.length!==0  ) ? (<Grid item xs={12}>
                <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={3}
                >
    
               {images.map((image)=>{
                   
                   return <Grid item>
                       <Card className={classes.cardshadow} style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.primary.main}}>
                       <CardMedia
                                style={{height: 200,width:200}}
                                image={image.preview}
                                title={image.preview} 
                                />  
                                <Button variant="contained" color="primary" component="span" style={{ border:"1px solid black"}} fullWidth onClick={()=>{
                                    
                                    setImages(images.filter(item => item.preview !== image.preview));
                                     
                                }}>
                       DELETE IMAGE
                    </Button>
                       </Card>
                       </Grid>
               })}
               </Grid>
            </Grid>):""}
            </Grid>
                    <Grid item xs={12}>
                    <TextField className={classes.root}  label="Video Url (Optional)"  variant="outlined" fullWidth
                    size="small"
                         
                        
                        type={ 'text'  }
                        value={videoUrl}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                             
                        
                             
                        }}
                         
                        onChange={(e) => {
                               
                                setVideoUrl(e.target.value);

                        }}
                        
                    />  
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <BackDropLoad wait={wait}/>
                        <Grid item xs={12} className="text-center">
                    <Button variant="contained" type="submit"  style={{width:"60%"}} color="primary" disabled={wait} className="linkstyle"  >
                    {wait?"Please Wait..":"Submit Question"}
                    </Button>
            </Grid>
                </Grid>
                </form>
                </Box>
                </Container>
                <Container>
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    >
                        <Grid item xs={12}>
                            
                        </Grid>
                </Grid>       
                </Container>
                 
                </Grid>
                <Grid item xl={3} lg={4} md={4} sm={12} xs={12}>
                    <Box boxShadow={3} p={3}  style={{backgroundColor:"white",border:"2px groove #ffd24d",borderRadius:"10px"}}> 
                    <Typography variant="body1" className="text-center" gutterBottom style={{color:"black" }}>
                        <b>Trending Questions:</b>
                    </Typography>
                    </Box>
                </Grid>
      </Grid>
  </Container>
      
     
</div> );
}

 

 

 

 