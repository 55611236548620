import {useEffect,useState} from "react";
//import Loading from './Loading';
//import Axios from "axios";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Container,Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
 
export const ConvStress=()=>{
    
     
    useEffect(()=>{
         
      window.scrollTo(0, 0);
    },[]);

return  (  <div>
 
 <Grid
  container
  direction="column"
  justify="space-evenly"
  alignItems="center"
  spacing={3}
>
<Grid item xs={12}>
         
   </Grid>
<Grid item xs={12}>
        <Typography variant="h5" component="h4" className="text-center" color="primary" gutterBottom>
          STRESS 
        </Typography>
   </Grid>
  <Grid item xs={12}>
  <Stress />
  </Grid>
  <Grid item xs={12}>
   
  </Grid>
  <Grid item xs={12}>
  <SimpleCard/>
  </Grid>
  </Grid>
</div>

 );
}

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiFormLabel-root":{
        color:"#ffd24d"
      },
       
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"rgb(41, 38, 38)",
        paddingLeft:"1%",
        paddingRight:"1%",
      } 
       
    }
  
  });
  
  export default function SimpleCard() {
    const classes = useStyles();
    
  
    return (
        <Box  m={3}>
      <Card className={classes.root}  style={{ border:"2px groove #ffd24d"}}>
        <CardContent>
          
          <Typography variant="h6"  className="text-center">
          UNITS OF STRESS 
          </Typography>
          <br></br>
          <Typography variant="body2" >
          1 t/ft2 = 1000 N/mm2 The measurement of stress, which is the average force per unit area 
          in the English system for one ton per square foot is equal to 1000 times the Newton per
           millimetre square (metric system).
          </Typography>
        </CardContent>
        
      </Card>
      </Box>
    );
  }
    
 


const Stress=()=>{
    const classes = useStyles();
  const [lbinch,setLbinch] = useState();
  const [lbfeet,setLbfeet] = useState();
  const [kgcm,setKgcm] = useState();
  const [kgm,setKgm] = useState();
  const [tm,setTm] = useState();
  const [nmm,setNmm] = useState();

  return (
      <Container maxWidth="lg">
      <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      spacing={4}
    >
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <TextField  className={classes.root}  size="small" label="lb per sq inch" variant="outlined" fullWidth 
              type="number"
              InputProps={{
                style: {
                    color: "#ffd24d"
                }
            }}
            InputLabelProps={{  shrink: true,focused:true }}

              value={lbinch}
              onChange={(e) => {
                  
                      var val = parseFloat(e.target.value);
                      var val1 = parseFloat(e.target.value);
                      setLbinch(val1);
                      val=val1*144
                      setLbfeet(val);
                      val=val1*0.07031
                      setKgcm(val);
                      val=val1*703.07
                      setKgm(val)
                      val=val1*0.703
                      setTm(val);
                      val=val1*0.00689
                      setNmm(val);
                  }
                  
              }
               
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField  className={classes.root}  size="small" label="lb per sq feet" variant="outlined" fullWidth
          type="number"
          InputProps={{
            style: {
                color: "#ffd24d"
            }
        }}
        InputLabelProps={{  shrink: true,focused:true }}

          value={lbfeet}
          onChange={(e) => {
              
                  var val = parseFloat(e.target.value);
                  var val1 = parseFloat(e.target.value);
                  setLbfeet(val1);
                  val=val1*144
                  setLbinch(val);
                  val=val1/2048.161440137
                  setKgcm(val);
                  val=val1*4.88
                  setKgm(val);
                  val=val1*0.004805
                  setTm(val);
                  val=val1/20885.434273039
                  setNmm(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField  className={classes.root}  size="small" label="kg/cm2" variant="outlined" fullWidth
          type="number"
          InputProps={{
            style: {
                color: "#ffd24d"
            }
        }}
        InputLabelProps={{  shrink: true,focused:true }}

          value={kgcm}
          onChange={(e) => {
             
                  var val = parseFloat(e.target.value);
                  var val1 = parseFloat(e.target.value);
                  setKgcm(val1);
                  val=val1/0.070306957829636
                  setLbinch(val);
                  val=val1/0.00048824276270581
                  setLbfeet(val);
                  val=val1*10000
                  setKgm(val);
                  val=val1/0.1 
                  setTm(val);
                  val=val1/10.197162129779 
                  setNmm(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField  className={classes.root}  size="small" label="kg/m2" variant="outlined" fullWidth
          type="number"
          InputProps={{
            style: {
                color: "#ffd24d"
            }
        }}
        InputLabelProps={{  shrink: true,focused:true }}

          value={kgm}
          onChange={(e) => {
              
                  var val = parseFloat(e.target.value);
                  var val1 = parseFloat(e.target.value);
                  setKgm(val1);
                  val=val1*0.0014
                  setLbinch(val);
                  val=val1*0.2
                  setLbfeet(val);
                  val=val1/10000
                  setKgcm(val);
                  val=val1/1000 
                  setTm(val);
                  val=val1*0.00000980665 
                  setNmm(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField  className={classes.root}  size="small" label="t/m2(t:Metric ton)" variant="outlined" fullWidth
          type="number"
          InputProps={{
            style: {
                color: "#ffd24d"
            }
        }}
        InputLabelProps={{  shrink: true,focused:true }}

          value={tm}
          onChange={(e) => {
             
                  var val = parseFloat(e.target.value);
                  var val1 = parseFloat(e.target.value);
                  setTm(val1);
                  val=val1/0.70306957829636
                  setLbinch(val);
                  val=val1*204.81
                  setLbfeet(val);
                  val=val1/10
                  setKgcm(val);
                  val=val1*1000
                  setKgm(val);
                  val=val1*0.00980665
                  setNmm(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField  className={classes.root}  size="small" label="n/mm2" variant="outlined" fullWidth
          type="number"
          InputProps={{
            style: {
                color: "#ffd24d"
            }
        }}
        InputLabelProps={{  shrink: true,focused:true }}

          value={nmm}
          onChange={(e) => {
             
                  var val = parseFloat(e.target.value);
                  var val1 = parseFloat(e.target.value);
                  setNmm(val1);
                  val=val1*145.03774
                  setLbinch(val);
                  val=val1/4.7880258888889e-5
                  setLbfeet(val);
                  val=val1*10.19716
                  setKgcm(val);
                  val=val1/9.80665E-6
                  setKgm(val);
                  val=val1*101.97162
                  setTm(val);
              }
          }
           
        />
        </Grid>
  </Grid></Container>);
}