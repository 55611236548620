import {useEffect,useState} from "react";
import {Loading} from './Loading';
import Axios from "axios";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Container ,useTheme,Box,Typography,Card,CardMedia,CardActions} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {withStyles} from '@material-ui/core/styles';
import { Helmet  } from 'react-helmet-async';

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "& .MuiFormLabel-root":{
      color:"#ffd24d"
    },
     
    "& .MuiInputLabel-shrink":{
      
      transform: "translate(14px, -9px) scale(0.90)",
      transformOrigin: "top left",
      backgroundColor:"black",
      paddingLeft:"1%",
      paddingRight:"1%",
    },
    nounderline: {
        "&:hover": { textDecoration:"none"},
      },
      cardshadow: {
        "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
         
      }, 
     
  }
});

const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";

export const Ackdg=()=>{
  
  const theme = useTheme();
  const classes = useStyles();


  const [metadata,setMetadata] = useState();


  useEffect(()=>{
    window.scrollTo(0, 0);
    Axios.post("/new_apis/getsinglemetadata", {'type':"thanks"},{
      headers: { key: key },
     }).then((res) => {
        
       if(res.data.status){
         
        setMetadata(res.data.metadata[0])
       } else{
          setMetadata({'seo_title':"Home - ESAY | Contacts and Info in the World of Construction",
          'seo_desc':"Get all the information and contacts of various types of contractors, consultants, machinery/material suppliers, engineers, and workforce in the construction sector",
          "seo_keywords":"best  contractors in Telangana, schedule of rates, Tenders in India,   construction architect engineers and labor near me, latest trends in construction sector, top contractors and consultants in Hyderabad,   interior designers in Hyderabad, building construction materials and machinery suppliers, soil metal materials testing labs around me"});
       }
        
     });
  },[])

  const handleContextMenu = (e) => {
    e.preventDefault(); // Disable right-click
  };

  const handleCopy = (e) => {
    e.preventDefault(); // Disable copy
  };

return  (  <div>{metadata && <Helmet>
  <title>{metadata.seo_title}</title>
    <meta name="description" content={metadata.seo_desc} />
    <meta name="keywords" content={metadata.seo_keywords}></meta>
  </Helmet>}<Container maxWidth="lg">
<Box boxShadow={3} p={3}  style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.secondary.main,marginTop:"5%",marginBottom:"5%",borderRadius:"10px" }}> 
<Typography variant="h5" className="text-center"  style={{color:theme.palette.primary.main   }}>
Our Thanks To
     
                        </Typography>
                        <hr style={{color:theme.palette.primary.main}}/>
                        <br/>
                        <Grid
                          container
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          spacing={3}

                          style={{ userSelect: "none", // CSS to disable text selection
                            MozUserSelect: "none", // For Firefox
                            WebkitUserSelect: "none", // For Safari
                            msUserSelect: "none", // For older IE
                            }}
                            onContextMenu={handleContextMenu}
                            onCopy={handleCopy}
                        >
                          <Grid item xs={12} >
                          <Typography variant="body2"   gutterBottom  style={{color:"white"   }}>
                              We are grateful to many professionals and organizations. We are thankful to many professionals advised and assisted in our work in this engineering based useful information to the construction sector.
                             </Typography>
                          </Grid>
                          <Grid item xs={12}>
                          <Typography variant="body2"   gutterBottom  style={{color:"white"   }}>
                              We also gratefully acknowledge the Central Govt. departments such as Ministry of Urban Development, Ministry of Water Resources, Various state Govt. agencies/undertakings/boards, Central public works department and various research and development Institutes such as BIS and IRC for placing valuable information in the public domain aiming to provide an interface for all construction resources.
                             </Typography>
                          </Grid>
                          <Grid item xs={12}>
                          <Typography variant="body2"   gutterBottom  style={{color:"white"   }}>
                              In addition to our expertise in the field, the content of this portal reflects the professional experience of the following renowned engineers. We would like to express our deepest appreciation to all the below, who provided us the necessary and vital information to launch this portal.
                             </Typography>
                          </Grid>
                          <Grid item xs={12}>
                          <Typography variant="h6"  className="text-center" gutterBottom  style={{color:theme.palette.primary.main,marginBottom:"3%"  }}>
                             OUR SPECIAL THANKS TO 
                           </Typography>
                           <Grid
                           container
                           direction="row"
                           justify="flex-start"
                           alignItems="flex-start"
                           spacing={3}
                            >
                                <Grid item xs={12} sm={6} md={6} lg={3} xl={3}  style={{ display:"flex", justifyContent:"center"}}>
                                    <Card className={classes.cardshadow} style={{width: "250px", border:"2px groove #ffd24d",backgroundColor:theme.palette.primary.main }}>
                                    <CardMedia
                                style={{height: 200}}
                                image={"/assets/newhome/images/pic1.jpg"}
                                title="Prof. P. SRINIVASA RAO" 
                                />
                                 
                             
                            <CardActions>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                                spacing={1}
                                >
                                    <Grid item xs={12}>
                                    <Typography variant="body2" className="text-center" style={{color:theme.palette.secondary.main,width:"100%",overflow:"hidden",fontWeight:"bold"}}>
                                    Prof. P. SRINIVASA RAO
                                   </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2" className="text-center" style={{color:theme.palette.secondary.main,width:"100%",textDecoration:"underline"}}>
                                    Former Dean & Board of Governors, IIT-MADRAS
                                    
                                   </Typography>
                                    </Grid>
                                     
                                  
                                </Grid>
                                 
                                  
                            </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={3} xl={3} style={{ display:"flex", justifyContent:"center"}}>
                                <Card className={classes.cardshadow} style={{width: "250px", border:"2px groove #ffd24d",backgroundColor:theme.palette.primary.main}}>
                                    <CardMedia
                                style={{height: 200}}
                                image={"/assets/newhome/images/pic3.jpg"}
                                title="Dr. I. S. N. RAJU" 
                                />
                                 
                             
                            <CardActions>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                                 spacing={1}
                                >
                                    <Grid item xs={12}>
                                    <Typography variant="body2" className="text-center" style={{color:theme.palette.secondary.main,width:"100%",overflow:"hidden",fontWeight:"bold"}}>
                                    Dr. I. S. N. RAJU
                                   </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2" className="text-center" style={{color:theme.palette.secondary.main,width:"100%",textDecoration:"underline"}}>
                                    Senior Project Manager.(CSDGs)
Former Chief Engineer ( I&CAD.Dept.).A.P.
Former Chairman IEI- HYDERABAD.
                                    
                                   </Typography>
                                    </Grid>
                                     
                                  
                                </Grid>
                                 
                                  
                            </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={3} xl={3} style={{ display:"flex", justifyContent:"center"}}>
                                <Card className={classes.cardshadow} style={{width: "250px", border:"2px groove #ffd24d",backgroundColor:theme.palette.primary.main}}>
                                    <CardMedia
                                style={{height: 200}}
                                image={"/assets/newhome/images/picnew.jpg"}
                                title="Dr. G. RAMESHWAR RAO" 
                                />
                                 
                             
                            <CardActions>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                                 spacing={1}
                                >
                                    <Grid item xs={12}>
                                    <Typography variant="body2" className="text-center" style={{color:theme.palette.secondary.main,width:"100%",overflow:"hidden",fontWeight:"bold"}}>
                                    Dr. G. RAMESHWAR RAO
                                   </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2" className="text-center" style={{color:theme.palette.secondary.main,width:"100%",textDecoration:"underline"}}>
                                    Chairaman IEI, TSC: Hyderabad & Director: Engineering Staff College of India
                                    
                                   </Typography>
                                    </Grid>
                                     
                                  
                                </Grid>
                                 
                                  
                            </CardActions>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={3} xl={3} style={{ display:"flex", justifyContent:"center"}}>
                                <Card className={classes.cardshadow} style={{width: "250px", border:"2px groove #ffd24d",backgroundColor:theme.palette.primary.main}}>
                                    <CardMedia
                                style={{height: 200}}
                                image={"/assets/newhome/images/pic2.jpg"}
                                title="Er. D. V. BHAVANA RAO" 
                                />
                                 
                             
                            <CardActions>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                                spacing={1}
                                >
                                    <Grid item xs={12}>
                                    <Typography variant="body2" className="text-center" style={{color:theme.palette.secondary.main,width:"100%",overflow:"hidden",fontWeight:"bold"}}>
                                   Er. D. V. BHAVANA RAO (Late)
                                   </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2" className="text-center" style={{color:theme.palette.secondary.main,width:"100%",textDecoration:"underline"}}>
                                    Former Chief Engineer.
Quality Control
Roads & Buildings Dept..A.P.                      
                                   </Typography>
                                    </Grid>
                                     
                                  
                                </Grid>
                                 
                                  
                            </CardActions>
                                    </Card>
                                </Grid>
                            </Grid>
                          </Grid>
                         <Grid item xs={12}></Grid>
                          
                          <Grid item xs={12}>
                          <Typography variant="body2" className="text-center"   style={{color:theme.palette.primary.main}}>
                              ****
                            </Typography>
                          </Grid>

                        </Grid>
  </Box>
  </Container>
      
     
</div> );
}

 

 

 

 