import {useEffect,useState} from "react";
import {Loading} from './Loading';
import Axios from "axios";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Container ,useTheme,Box,Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {withStyles} from '@material-ui/core/styles';
import { Helmet  } from 'react-helmet-async';

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "& .MuiFormLabel-root":{
      color:"#ffd24d"
    },
     
    "& .MuiInputLabel-shrink":{
      
      transform: "translate(14px, -9px) scale(0.90)",
      transformOrigin: "top left",
      backgroundColor:"black",
      paddingLeft:"1%",
      paddingRight:"1%",
    } 
     
  }
});
const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";

export const Guide=()=>{
  
  const theme = useTheme();
  const [metadata,setMetadata] = useState();

  useEffect(()=>{
    window.scrollTo(0, 0);
    Axios.post("/new_apis/getsinglemetadata", {'type':"guide"},{
      headers: { key: key },
     }).then((res) => {
        
       if(res.data.status){
         
        setMetadata(res.data.metadata[0])
       } else{
          setMetadata({'seo_title':"Home - ESAY | Contacts and Info in the World of Construction",
          'seo_desc':"Get all the information and contacts of various types of contractors, consultants, machinery/material suppliers, engineers, and workforce in the construction sector",
          "seo_keywords":"best  contractors in Telangana, schedule of rates, Tenders in India,   construction architect engineers and labor near me, latest trends in construction sector, top contractors and consultants in Hyderabad,   interior designers in Hyderabad, building construction materials and machinery suppliers, soil metal materials testing labs around me"});
       }
        
     });
  },[])

return  (  <div>{metadata && <Helmet>
  <title>{metadata.seo_title}</title>
    <meta name="description" content={metadata.seo_desc} />
    <meta name="keywords" content={metadata.seo_keywords}></meta>
  </Helmet>}<Container maxWidth="md">
<Box boxShadow={3} p={3}  style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.secondary.main,marginTop:"5%",marginBottom:"5%",borderRadius:"10px" }}> 
<Typography variant="h5" className="text-center"  style={{color:theme.palette.primary.main   }}>
                                     Guide          
                        </Typography>
                        <hr style={{color:theme.palette.primary.main}}/>
                        
                        <Grid
                          container
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          spacing={1}
                        >
                           <Grid item xs={12} style={{marginTop:"5%"}}>
                          <Typography variant="body2"    style={{color:theme.palette.primary.main}}>
                          1. Registration Procedure.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                          <Typography variant="body2"    style={{color:theme.palette.primary.main,paddingLeft:"10px"}}>
                          1.1. Registration Procedure as a Contractor / Consultant / Machinery Supplier / Material Supplier / Testing Laboratory.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                          <Typography variant="body2"    style={{paddingLeft:"20px"}}>
                          In the SignUp Screen - Please fill Mandatory Fields like Category, First Name, Mobile Number, Email-ID, Password, Re-Enter Password & Location and Click the 'REGISTER NOW' Button. Now an OTP will be send to your Given Mobile Number, Please Enter that OTP to Successfully Complete your Registration Procedure.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                          <Typography variant="body2"    style={{color:theme.palette.primary.main,paddingLeft:"10px"}}>
                          1.2. Registration Procedure as an Engineer.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                          <Typography variant="body2"    style={{paddingLeft:"20px"}}>
                          In the SignUp Screen - Please fill Mandatory Fields like Category, Sub-Category, First Name, Mobile Number, Password, Re-Enter Password & Location and Click the 'REGISTER NOW' Button.
                                        Now an OTP will be send to your Given Mobile Number, Please Enter that OTP to Successfully Complete your Registration Procedure.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                          <Typography variant="body2"    style={{color:theme.palette.primary.main,paddingLeft:"10px"}}>
                          1.3. Registration Procedure as a Workforce.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                          <Typography variant="body2"    style={{paddingLeft:"20px"}}>
                          In the SignUp Screen - Please fill Mandatory Fields like Category, Sub-Category, First Name, Mobile Number, Password, Re-Enter Password & Location and Click the 'REGISTER NOW' Button.
                                        Now an OTP will be send to your Given Mobile Number, Please Enter that OTP to Successfully Complete your Registration Procedure.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                          <Typography variant="body2"    style={{color:theme.palette.primary.main,paddingLeft:"10px"}}>
                          1.4. Registration Procedure as a Regular User.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                          <Typography variant="body2"    style={{paddingLeft:"20px"}}>
                          In the SignUp Screen - Please fill Mandatory Fields like Category(to Regular User), First Name, Mobile Number, Password, Re-Enter Password & Location and Click the 'REGISTER NOW' Button.
                                        Now an OTP will be send to your Given Mobile Number, Please Enter that OTP to Successfully Complete your Registration Procedure.
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{marginTop:"5%"}}>
                          <Typography variant="body2"    style={{color:theme.palette.primary.main }}>
                          2. Adding Service / Product Details For Contractor / Consultant / Machinery Supplier / Material Supplier / Testing Laboratory.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                          <Typography variant="body2"    style={{color:theme.palette.primary.main,paddingLeft:"10px"}}>
                          2.1. Adding Product / Service Details.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                          <Typography variant="body2"    style={{paddingLeft:"20px"}}>
                          Go to - 'My Account -> My Products / Services -> Tap on 'Add Product / Service',  The Candidate should give details about Product or Service like: Name of Product / Service, SubCategory Name, 
                                    Images of Service / Product (Maximum Image Limit is 3), Mobile Number, Email, Location, Short Description & Long Description and Tap on 'Add Details'. After Successfully Adding Details, it will be verified by our Backend team. Once, Approved it will be for your Customers to View your Service / Product Details.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                          <Typography variant="body2"    style={{color:theme.palette.primary.main,paddingLeft:"10px"}}>
                          2.2. Maximum Limit for Adding Product / Service Details.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                          <Typography variant="body2"    style={{paddingLeft:"20px"}}>
                          The max limit for Adding Product / Service is 3.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                          <Typography variant="body2"    style={{color:theme.palette.primary.main,paddingLeft:"10px"}}>
                          2.3. Approval of Added Product / Service.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                          <Typography variant="body2"    style={{paddingLeft:"20px"}}>
                          Everytime when a Candidate Adds / Updates details of a Product / Service Details,  it will be verified by our Backend team. Once, Approved it will be for your Customers to View your Service / Product Details. Please note that the Title, Images and Descriptions provided must not contain contact details (Emails, contact numbers, locations etc). If found, they will be Altered or Rejected.
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{marginTop:"5%"}}>
                          <Typography variant="body2"    style={{color:theme.palette.primary.main}}>
                          3. Subscription for Contractor / Consultant / Machinery Supplier / Material Supplier / Testing Laboratory.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                          <Typography variant="body2"    style={{color:theme.palette.primary.main,paddingLeft:"10px"}}>
                          3.1. Getting a 'Contact Now' Button for Your Product / Service.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                          <Typography variant="body2"    style={{paddingLeft:"20px"}}>
                          To a Contact Now Button for your Product / Service to get Desired Customers for their Product / Service, the Candidate should choose a subscription plan 
                                      from 'My Account' -> 'My Subscription'.
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{marginTop:"5%"}}>
                          <Typography variant="body2"    style={{color:theme.palette.primary.main}}>
                          4. Upgrade Profile to Contractor / Consultant / Machinery Supplier / Material Supplier / Testing Laboratory / Engineer / Workforce.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                          <Typography variant="body2"    style={{color:theme.palette.primary.main,paddingLeft:"10px"}}>
                          4.1. Registered as a Regular user and want to Upgrade your Profile ?
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                          <Typography variant="body2"    style={{paddingLeft:"20px"}}>
                          Go to 'My Account' -> 'Upgrade Profile',  Choose your desired Category that you want to upgrade to, fill all the Mandatory Fields and tap on 'Upgrade Profile' to Upgrade your Profile Successfully.
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{marginTop:"5%"}}>
                          <Typography variant="body2"    style={{color:theme.palette.primary.main}}>
                          5. How to Show / Hide Your Engineer / Workforce Profile from Public ?
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                          <Typography variant="body2"    style={{paddingLeft:"20px"}}>
                          You can change your profile's public visibility (Show / Hide your profile from public) at 'My Account' -> 'Update Details'.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}></Grid>
                          <Grid item xs={12}>
                          <Typography variant="body2" className="text-center"   style={{color:theme.palette.primary.main}}>
                              ****
                            </Typography>
                          </Grid>

                        </Grid>
  </Box>
  </Container>
      
     
</div> );
}

 

 

 

 