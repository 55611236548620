import {useEffect,React,useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
 import Axios from 'axios';
import {CircularProgress} from '@material-ui/core';
 /*
function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}
*/
//const autocompleteService = { current: null };

const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#ffd24d"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#ffd24d"
        },
        "& .MuiOutlinedInput-root .Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#ffd24d"
        },
        "& .MuiFormLabel-root":{
          color:"#ffd24d"
        },
        "&  .Mui-error  ":{
          color:"red !important",
          borderColor:"red !important"
        },
        "& .MuiInputLabel-shrink":{
          
          transform: "translate(14px, -9px) scale(0.90)",
          transformOrigin: "top left",
          backgroundColor:"#2e2b2b",
          paddingLeft:"1%",
          paddingRight:"1%",
        } 
         
      },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

 

export const AutoCompLoc = (props)=>{
  
    const classes = useStyles();
     
    const [open, setOpen] =  useState(false);
  const [options, setOptions] =  useState([]);
  const loadingLoc = open && options.length === 0;

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

    const onChangeHandle = async (value) => {
     
       await Axios.get("/new_apis/get-location",{ params:{
            search:value
          },
           headers: { key: key },
         }).then((res) => {
            
            const places= res.data.preds;
             
            setOptions(Object.keys(places).map(key => places[key].description));
             
            
         }); 

        
     
     };

    return (<Autocomplete
        id="location"
        error={props.error}
        disableClearable={true}
        defaultValue={props?.loc}
        noOptionsText="Location no found.."
         open={open}
       //  disableClearable={false}
         loadingText="Fetching Location...."
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
            
          setOpen(false);
        }}
        onChange={(event, newValue) => {
         
          props?.setloc(newValue);
        }}
          
            getOptionSelected={(option, value) => option === value }
        getOptionLabel={option => option}
        options={options}
        loading={loadingLoc}
         

        
         
        renderInput={params => (
            <TextField
              {...params}
              label="Location *"
              error={props.error}
              className={classes.root} 
              
              size="small"
              variant="outlined"
              value={props?.loc}
              onChange={ev => {
                
                if (ev.target.value !== "") {
                  onChangeHandle(ev.target.value);
                }
              }}
              onFocus={()=>{
                  if(props?.loc!==''){
                    onChangeHandle(props?.loc);
                  }
                
              }} 
               
              InputProps={{
                ...params.InputProps,
                style: {
                    color: "#ffd24d"
                },
                endAdornment: (
                  <>
                    {loadingLoc ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
          
          renderOption={(option) => {
           
           
            const matches = option;
             
            return (
              <Grid container alignItems="center">
                <Grid item>
                  <LocationOnIcon className={classes.icon} />
                </Grid>
                <Grid item xs>

                {(matches).substring(0, 20)}
                                    {(matches).length>20&&"..."}
    
                  <Typography variant="body2" color="textSecondary">
                    {matches}
                  </Typography>
                </Grid>
              </Grid>
            );
          }}
           
      />);
}