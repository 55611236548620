import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

export const LoadingLogo=()=>{
    return ( 
               

               
                <div className="container-fluid" style={{ height:"100vh",backgroundColor:"#ffd24d"  }}>
                     <div className="container" style={{ height:"100vh"}}> 
                    <div className="row" style={{position:"relative",top:"25%"}}>
                        <div className="col-xs-12 text-center">
                        <img src="https://www.esay.in/assets/logoanim.gif" alt="ESAY LOADING LOGO"   height="400px"/>
                         
                          
                        </div>
                    </div>
                    </div>
                </div>
                        
    );

}

 