import {useEffect, useState} from "react";
import Axios from "axios";
import {Loading} from "./Loading";
import {Grid, Typography,useTheme,Card,CardContent,ButtonBase,Button,makeStyles, Container} from '@material-ui/core';
import parse from 'html-react-parser';
import {Link} from 'react-router-dom';
import { Helmet  } from 'react-helmet-async';
const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";


function convertToSlug(Text)
{
    return Text.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');
}


const useStyles = makeStyles({
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 #ffd24d, 0 4px 8px 0 #ffd24d" },
      //"&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.19), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
    },
  });
export const Careers=()=>{
    const theme = useTheme();
    const classes = useStyles();
    const [loading,setLoading]=useState(true);
    const [careers,setCareers]=useState();
  const [metadata,setMetadata] = useState();


    useEffect(()=>{
      window.scrollTo(0, 0);
      Axios.post("/new_apis/getsinglemetadata", {'type':"careers"},{
        headers: { key: key },
       }).then((res) => {
          
         if(res.data.status){
           
          setMetadata(res.data.metadata[0])
         } else{
            setMetadata({'seo_title':"Home - ESAY | Contacts and Info in the World of Construction",
            'seo_desc':"Get all the information and contacts of various types of contractors, consultants, machinery/material suppliers, engineers, and workforce in the construction sector",
            "seo_keywords":"best  contractors in Telangana, schedule of rates, Tenders in India,   construction architect engineers and labor near me, latest trends in construction sector, top contractors and consultants in Hyderabad,   interior designers in Hyderabad, building construction materials and machinery suppliers, soil metal materials testing labs around me"});
         }
          
       });
        Axios.get("/new_apis/get-careers",{ 
          headers: { key: key },
         }).then((res) => {
            setCareers(res.data.careers);
             
            setLoading(false);
         }); 
                   
      },[]);

    return (<div>
         {metadata && <Helmet>
  <title>{metadata.seo_title}</title>
    <meta name="description" content={metadata.seo_desc} />
    <meta name="keywords" content={metadata.seo_keywords}></meta>
  </Helmet>}
        <div className="container">
            <br/><br/>
        <Typography variant="h4" className="divider line glow pageHeading" gutterBottom style={{color:theme.palette.primary.main}}>
          Careers
     </Typography>
     <Container maxWidth="sm">
     <Typography variant="body2" className="text-center pageSubtitle" gutterBottom style={{color:"white",margin:"10px",padding:"10px",  border:"1px solid #ffd24d",borderRadius:"100px",backgroundColor:theme.palette.secondary.main}}>
     <span style={{color:theme.palette.primary.main }}>Job Openings:</span> Discover the Latest Opportunities in Public and Private Sectors.
      </Typography>
      </Container>
      <br/><br/>
      {loading?<Loading/>: <Grid
  container
  direction="row"
  justify="flex-start"
  alignItems="flex-start"
  spacing={4}
>  
{(careers?.length!==0) && (<Grid
    container
    direction="row"
    justify="flex-start"
    alignItems="flex-start"
    spacing={4}
   >
       {careers?.map((career ) => (
           <Grid item xs={12} sm={6} md={4} lg={3} xl={3} 
           className="text-center"
           >
              <ButtonBase className={classes.nounderline}
                          component={Link} to={"/job-details/"+convertToSlug(career?.job_title)+"/"+career?.job_id}
                      >
                      <Card className={classes.cardshadow} style={{width: "230px" , border:"2px groove #ffd24d",backgroundColor:theme.palette.primary.main}}>
                      <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="stretch"
                                 
                                >
                                    <Grid item xs={12}>
                                        <br/>
                                    <Typography variant="body2" className="text-center" style={{color:theme.palette.secondary.main,width:"100%",overflow:"hidden",fontWeight:"bold"}}>
                                        {career?.job_title} - {career?.posted_by}
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2" className="text-center" style={{color:theme.palette.secondary.main,width:"100%" }}>
                                       <b>Type:</b> {career?.job_type}
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2" className="text-center" style={{color:theme.palette.secondary.main,width:"100%"}}>
                                    <b>No. of Openings:</b> {career?.current_openings}
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2" className="text-center" style={{color:theme.palette.secondary.main,width:"100%"}}>
                                    <b>Posted Date:</b> {career?.date}
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{padding:"10px"}}>
                                        <Button variant="contained"    style={{padding:"5px",marginBottom:"10px" }}   color="secondary"  className="linkstyle"  fullWidth>
                                            {career?.type==='1'?"View Job Details":"Apply Now"}
                                        </Button>
                                    </Grid>
                      </Grid>
                      </Card>
              </ButtonBase>
            </Grid>
       ))}
   </Grid>)}
 
 </Grid>}
<br/><br/><br/>
        </div>
    </div>);
}