import {Grid,Box, Typography,useTheme,Button,Container,makeStyles,TextField,FormControl,Switch,CardMedia,Card,Tabs,Fab,CardActions,DialogTitle,Dialog,DialogContent} from '@material-ui/core';
import {useEffect,useState,useContext} from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import {Loading} from "./Loading";
import {BackDropLoad} from "./LoadingBackDrop";
import { UserContext,SnackbarContext } from './userContext';
import {useNavigate} from "react-router-dom";
import Axios from "axios";
import {Link} from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles({
     
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });

 
  
   

export const MyAccMyProds =()=>{
    const history=useNavigate();
    const theme = useTheme();
    const classes = useStyles();
    const {user, setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);
    const [data,setData] = useState([]); 
    const [wait,setWait] = useState(false);

    const [deleteId,setDeleteId] = useState("");
    const [deleteName,setDeleteName] = useState("");

    const [showAlert,setShowAlert] = useState(false);
    const [showPlan,setShowPlan] = useState(false);

    const [loading,setLoading] = useState(true);
     

    useEffect(()=>{
        getData();
    },[])


    const getData=()=>{
        const formData={};
        var tkn=user.token;
        formData.uid=user.user_id;
        formData.cat=user.category_ids;
        formData.role=user.user_role;
        Axios.post("/new_apis/getvendorproducts",formData,{ 
            headers: { tkn: tkn },
           }).then((res) => {
              if(res.data.status===1){
                  setData(res.data.data);
                   
                    setShowPlan(res.data.substatus);
                setLoading(false);
                   
              }else if(res.data.status===3){
                history('/');
                setSnack({
                  message: res.data.msg,
                  type: 'error',
                  open: true,
                  direction:"center"
                });
              }else if(res.data.status===4){
                setUser(null);
                history('/');
                localStorage.removeItem("estkn");
                setSnack({
                    message: res.data.msg,
                    type: 'warning',
                    open: true,
                    direction:"left"
                  });
              }
           });

    }


    const DeleteProduct=()=>{
        setLoading(true);
        const formData={};
        var tkn=user.token;
        
        formData.role=user.user_role;
        formData.cat=user.category_ids;
        formData.uid=user.user_id;
        formData.pid=deleteId;
        Axios.post("/new_apis/deletevendorproduct",formData,{ 
            headers: { tkn: tkn },
           }).then((res) => {
                if(res.data.status===1){
                    setSnack({
                        message: res.data.msg,
                        type: 'success',
                        open: true,
                        direction:"center"
                      });
                      getData();
                }else if(res.data.status===3){
                  history('/');
                  setSnack({
                    message: res.data.msg,
                    type: 'error',
                    open: true,
                    direction:"center"
                  });
                } else if(res.data.status===4){
                  setUser(null);
                        
                        localStorage.removeItem("estkn");
                        history('/');
                  setSnack({
                    message: res.data.msg,
                    type: 'error',
                    open: true,
                    direction:"center"
                  });
                }  
                
              
           }); 
    }

  

    return (<div>
        {loading?<Loading/>:(<Container maxWidth="md">
        <Box boxShadow={3} p={3}  style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.secondary.main,borderRadius:"10px" }}> 
        <Grid
        container
        direction="row"
            justify="space-between"
            alignItems="center"
        spacing={3}
        >
            <Dialog
        open={showPlan}
        onClose={()=>{
          setShowPlan(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
            style: {
              backgroundColor: theme.palette.secondary.main,
              boxShadow: 'none',
            },
          }}
         
      >
           
          <DialogTitle id="alert-dialog-title"> <Typography variant="h4" className="text-center"  style={{color:theme.palette.primary.main   }}>
                                 <ErrorOutlineIcon fontSize="large" />&nbsp;Choose a Plan
                        </Typography>
                        <hr style={{color:theme.palette.primary.main}}/>
                        </DialogTitle>
          <DialogContent>
          
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={2}
            > <Grid item xs={12}>
              <Typography variant="body1" className="text-center" style={{color:theme.palette.primary.main   }}>
               Choose a <b>Subscription Plan</b> to join the <b>ESAY</b> Community and reach your Desired Customers.
              </Typography>
            </Grid>
            <Grid item xs={12}   >
                 <br/>
                </Grid>
              <Grid item xs={12}   >
              <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
                spacing={2}
                >
                    <Grid item xs={6} className="text-center">
                    <Button variant="contained" type="submit"  style={{width:"60%"}} fullWidth={false} color="primary"  className="linkstyle" onClick={()=>{
                        setShowPlan(false);
                         
                    }} >close</Button>

                    </Grid>
                    <Grid item xs={6}  className="text-center">
                    <Button variant="contained" type="submit"  style={{width:"60%"}} fullWidth={false} color="primary"  className="linkstyle"  component={Link} to={"/myaccount/subscription"} >Subscribe Now</Button>
                    </Grid>
              </Grid>
                
                </Grid>
                <Grid item xs={12}   >
                 <br/>
                </Grid>
          </Grid>
            
            </DialogContent>
             
      </Dialog>
            <Dialog
        open={showAlert}
        onClose={()=>{
          setShowAlert(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
            style: {
              backgroundColor: theme.palette.secondary.main,
              boxShadow: 'none',
            },
          }}
         
      >
           
          <DialogTitle id="alert-dialog-title"> <Typography variant="h4" className="text-center"  style={{color:theme.palette.primary.main   }}>
                                 <ErrorOutlineIcon fontSize="large" />&nbsp;Please Confirm
                        </Typography>
                        <hr style={{color:theme.palette.primary.main}}/>
                        </DialogTitle>
          <DialogContent>
          
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={2}
            > <Grid item xs={12}>
              <Typography variant="body1" className="text-center" style={{color:theme.palette.primary.main   }}>
              Are you sure to Delete "{deleteName}" ?
              </Typography>
            </Grid>
            <Grid item xs={12}   >
                 <br/>
                </Grid>
              <Grid item xs={12}   >
              <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
                spacing={2}
                >
                    <Grid item xs={6} className="text-center">
                    <Button variant="contained" type="submit"  style={{width:"60%"}} fullWidth={false} color="primary"  className="linkstyle" onClick={()=>{
                        setShowAlert(false);
                        DeleteProduct();
                    }} >Yes</Button>

                    </Grid>
                    <Grid item xs={6}  className="text-center">
                    <Button variant="contained" type="submit"  style={{width:"60%"}} fullWidth={false} color="primary"  className="linkstyle" onClick={()=>{
                  setShowAlert(false)
                }} >No</Button>
                    </Grid>
              </Grid>
                
                </Grid>
                <Grid item xs={12}   >
                 <br/>
                </Grid>
          </Grid>
            
            </DialogContent>
             
      </Dialog>
            <Grid item xs={12}>
            <Typography variant="h4" className="text-center" gutterBottom style={{color:theme.palette.primary.main}}>
                 My Products / Services
            </Typography>
            <hr style={{color:theme.palette.primary.main}}/>
            </Grid>
            {data.length!==0? <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={4}
                   >
                       {data.map((item) => (
                       <Grid item xs={12} sm={6} md={6} lg={4} xl={4} 
                       className="text-center"
                       >
                           <Card className={classes.cardshadow} style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.primary.main}}>
                            
                           <CardMedia
                                style={{height: 200}}
                                image={item.product_image}
                                title={item.product_name} 
                                />
                            
                         
                        <CardActions>
                         
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            spacing={1}
                            >
                                  <Grid item xs={12}>
                                    <Typography variant="body1" className="text-center" style={{color:theme.palette.secondary.main,width:"100%",overflow:"hidden"}}>
                                    <b>{item.product_name}</b>
                                     
                                   </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2"   style={{color:theme.palette.secondary.main,width:"100%",textAlign:"center"}}>
                                     {item.sub_category_name}
                                     
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="h7" className="text-center"  style={{color:theme.palette.secondary.main,width:"100%" }}>
                                    <b>Status: </b>{item.product_status==='1'&& "Active"}{item.product_status==='0'&& "Waiting for Approval"}
                                    </Typography>
                                    </Grid>
                                     
                                    
                                     
                                     
                                     
                                    <Grid item xs={12}>
                                    <Typography variant="body2"   style={{color:theme.palette.secondary.main,width:"100%",textAlign:"left"}}>
                                   <b>Added Date:</b> {item.product_register_date}  
                                     
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Button  variant="contained" component={Link} fullWidth={true} color="primary" to={"/myaccount/edit_prods_servs/"+item.product_id} className="linkstyle" style={{justifyContent: "flex-start",border:"1px solid black"}} >
                                    <EditIcon  />&nbsp;&nbsp;Edit
                                    </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Button  variant="contained" component={Link} fullWidth={true} color="primary"  onClick={()=>{
                                    setDeleteName(item.product_name);
                                    setDeleteId(item.product_id);
                                    setShowAlert(true);
                                       // DeleteProduct(item.product_id);
                                    }}  className="linkstyle" style={{justifyContent: "flex-start",border:"1px solid black"}} >
                                    <DeleteIcon  />&nbsp;&nbsp;Delete
                                    </Button>
                                    </Grid>
                                </Grid>
                        </CardActions>
                        </Card>
                        </Grid>
                       ))}
                   </Grid>:(<Typography variant="body1" className="text-center" style={{width:"100%"}}>You have not added Any Product / Service details....</Typography> )}
                <Grid item xs={12}>
                
                {data.length<3 && (<Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    spacing={6}
                    >
                    <Grid item  >
                        <Fab variant="extended" color="primary" aria-label="add" className="linkstyle" component={Link} to={"/myaccount/add_prods_servs"}  >
                            <AddIcon   />
                            Add Product / Service
                        </Fab>
                    </Grid>
                </Grid>)}
                </Grid>
                <BackDropLoad wait={wait}/>
        </Grid>
        </Box>
        </Container>)}
    </div>);
}