import {useEffect, useState,useContext} from "react";
import Axios from "axios";
import {Loading} from "./Loading";
import {BackDropLoad} from "./LoadingBackDrop";
import {EngInfoHome} from "./EngInfoHome";
import { SingleInfo} from "./EngInfoSingleInfo";
import {Grid, Typography,useTheme,Card,CardActions,DialogContent,Dialog,DialogTitle,makeStyles,Box,Button, Container} from '@material-ui/core';
import parse from 'html-react-parser';
import {Link,Route,Routes,useNavigate} from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { UserContext,SnackbarContext,EngInfoContext } from './userContext';
import { Helmet  } from 'react-helmet-async';

const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";
const $ = require("jquery");
const useStyles = makeStyles({
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
    },
  });
export const EngInfo=()=>{
    const theme = useTheme();
    let history = useNavigate();
    const classes = useStyles();
    const [loading,setLoading]=useState(true);
   
    const [infoList,setInfoList]=useState();
    
    const [parentId, setParentId] =  useState(0);

    const [metadata,setMetadata] = useState();



    const showParents = (parentId) =>{
      $(".list-group > ul").hide();
      $(".list-group-item").css({
        "font-weight": "normal",
        "text-decoration": "none",
      });
      $("#infoid"+parentId).parents().show();
      $("#infoid" + parentId).css({
        "font-weight": "bold",
        "text-decoration": "underline",
         
      });
    }

    const hideParents = () =>{
      $(".list-group > ul").hide();
      $(".list-group-item").css({
        "font-weight": "normal",
        "text-decoration": "none",
      });
       
    }

    const setParents = (parentIdNew) => {
        
       // console.log(parentIdNew+" called");
        $("#infoid" + parentId).css({
          "font-weight": "",
          "text-decoration": "",
          color: "",
        });
        if ($("#infoid" + parentIdNew).next("ul").length) {
          $("#infoid" + parentIdNew)
            .next()
            .toggle(500);
        }
        $("#infoid" + parentIdNew).css({
          "font-weight": "bold",
          "text-decoration": "underline",
           
        });
         
        setParentId(parentIdNew);
      };

    useEffect(()=>{
      Axios.post("/new_apis/getsinglemetadata", {'type':"enginfo"},{
        headers: { key: key },
       }).then((res) => {
          
         if(res.data.status){
           
          setMetadata(res.data.metadata[0])
         } else{
            setMetadata({'seo_title':"Home - ESAY | Contacts and Info in the World of Construction",
            'seo_desc':"Get all the information and contacts of various types of contractors, consultants, machinery/material suppliers, engineers, and workforce in the construction sector",
            "seo_keywords":"best  contractors in Telangana, schedule of rates, Tenders in India,   construction architect engineers and labor near me, latest trends in construction sector, top contractors and consultants in Hyderabad,   interior designers in Hyderabad, building construction materials and machinery suppliers, soil metal materials testing labs around me"});
         }
          
       });
        Axios.get("/new_apis/get-enginfo-list",{ 
          headers: { key: key },
         }).then((res) => {
            
          
            setInfoList(res.data.infolist);
            
            setLoading(false);
         }); 
                   
      },[]);

    return (<div>
         {metadata && <Helmet>
  <title>{metadata.seo_title}</title>
    <meta name="description" content={metadata.seo_desc} />
    <meta name="keywords" content={metadata.seo_keywords}></meta>
  </Helmet>}
        {loading?<Loading/>:<div className="container-fluid">
            <br/><br/>
        <Typography variant="h4" className="divider line glow pageHeading" gutterBottom style={{color:theme.palette.primary.main }}>
          Engineering Info 
     </Typography>
     <Container maxWidth="md">
     <Typography variant="body2" className="text-center  pageSubtitle" gutterBottom style={{color:"white",margin:"10px",padding:"10px",  border:"1px solid #ffd24d",borderRadius:"100px",backgroundColor:theme.palette.secondary.main}}>
     <span style={{color:theme.palette.primary.main }}>Eng Info for Professionals:</span> Access Comprehensive Resources and Essential Information.
      
       
      </Typography>
      </Container>
       <br/> 
      <Box boxShadow={3} p={1} m={1} style={{ border:"2px groove #ffd24d",borderRadius:"10px"}}>
      <Grid
    container
    direction="row"
    justify="space-between"
    alignItems="flex-start"
     
    >
        <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <div className="engineering-info-list-dropdown" style={{margin:"10px"}}>

            
           {infoList.map((cats) => {
            return (
              <ul class="infolist list-group " style={{ margin: "0px" }}>
                <li
                  class="list-group-item"
                  style={{
                    backgroundColor: "#ffd24d",
                    border: "2px solid black",
                    borderRadius: "0px ",
                    padding:"2%"
                  }}
                  id={"infoid" + cats.info_data_id}
                  onClick={() => {
                    setParents(cats.info_data_id);
                  }}
                >
                 
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    >
                        <Grid item><span>
                        <Link  to={"/eng-info/info-detail/"+cats?.info_data_slug+"/"+cats?.info_data_id}  className="anchorTag"   >
                   {cats.info_child_cat_ids}  
                   </Link>
                 </span></Grid>
                        <Grid item><ExpandMoreIcon /></Grid>
                    </Grid>
                  
                  
                   
                </li>

                {cats.sub && (
                  <SubMenu data={cats.sub} 
                   setParents={setParents} 
                  />
                )}
              </ul>
            );
          })}
          </div>
          <br/><br/><br/>
        </Grid>
        <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
           <Routes>
        <Route path={"info-detail/:slug?/:id?"}  element={<SingleInfo openlist={showParents} />}   />
        
            
             <Route path={"home"}  element={<EngInfoHome hideParents={hideParents} />}   />
             </Routes>
        </Grid>
    </Grid>
    </Box>
<br/><br/><br/>
        </div>}
         
    </div>);
}


const SubMenu = (props) => {
  const history=useNavigate();
  const theme = useTheme();
  const {user, setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);
    const {engInfoList, setEngInfoList} = useContext(EngInfoContext);


    const [id,setId] = useState("");
    const [type,setType] = useState("");
    const [msg,setMsg] = useState("");
    const [showDialog,setShowDailog] = useState(false);
    const [wait,setWait] = useState(false);


    useEffect(()=>{

    },[])

    const add_delete=()=>{
      setShowDailog(false);
      setWait(true);
       
        const formData= {};
        var tkn=user.token;
        formData.user_id=user.user_id;
        formData.info_id=id;
        if(type=='add'){
          Axios.post("/new_apis/add_bookmark",formData,{ 
            headers: { tkn: tkn },
           }).then((res) => {
              if(res.data.status==1){
                
                setSnack({
                  message: res.data.msg,
                  type: 'success',
                  open: true,
                  direction:"center"
                });
                 

                engInfoList.push(id);
                 
                 
              setWait(false);
              }else if(res.data.status===3){
                 
                history('/');
                 
                setSnack({
                    message: res.data.msg,
                    type: 'warning',
                    open: true,
                    direction:"left"
                  });
              }else if(res.data.status===4){
                setUser(null);
                history('/');
                localStorage.removeItem("estkn");
                setSnack({
                    message: res.data.msg,
                    type: 'warning',
                    open: true,
                    direction:"left"
                  });
              } else{
                setSnack({
                  message: res.data.msg,
                  type: 'error',
                  open: true,
                  direction:"center"
                });
                 
                setWait(false);
              }
                    
           });
        }else{
          Axios.post("/new_apis/delete_bookmark",formData,{ 
            headers: { tkn: tkn },
           }).then((res) => {
              if(res.data.status==1){
                
                setSnack({
                  message: res.data.msg,
                  type: 'success',
                  open: true,
                  direction:"center"
                });
                 const index=engInfoList.indexOf(id);
                engInfoList.splice(index,1);
                 
                setWait(false);
              }else if(res.data.status===3){
                 
                history('/');
                 
                setSnack({
                    message: res.data.msg,
                    type: 'warning',
                    open: true,
                    direction:"left"
                  });
              }else if(res.data.status===4){
                setUser(null);
                history('/');
                localStorage.removeItem("estkn");
                setSnack({
                    message: res.data.msg,
                    type: 'warning',
                    open: true,
                    direction:"left"
                  });
              } else{
                setSnack({
                  message: res.data.msg,
                  type: 'error',
                  open: true,
                  direction:"center"
                });
                 
                setWait(false);
              }
                    
           });
        }
        
       
    }

    return (
      <ul className="infolist  list-group " style={{ margin: "0px" }}>
        <BackDropLoad wait={wait}/>
        <Dialog
        open={showDialog}
        onClose={()=>{
          setShowDailog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
            style: {
              backgroundColor: theme.palette.secondary.main,
              boxShadow: 'none',
            },
          }}
         
      >
           
          <DialogTitle id="alert-dialog-title"> <Typography variant="h4" className="text-center"  style={{color:theme.palette.primary.main   }}>
                                 <ErrorOutlineIcon fontSize="large" />&nbsp;Please Confirm
                        </Typography>
                        <hr style={{color:theme.palette.primary.main}}/>
                        </DialogTitle>
          <DialogContent>
          
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={2}
            > <Grid item xs={12}>
              <Typography variant="body1" className="text-center" style={{color:theme.palette.primary.main   }}>
               {msg}
              </Typography>
            </Grid>
            <Grid item xs={12}   >
                 <br/>
                </Grid>
              <Grid item xs={12}   >
              <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
                spacing={2}
                >
                    <Grid item xs={6}  className="text-center">
                    <Button variant="contained" type="submit"  style={{width:"60%"}} fullWidth={false} color="primary"  className="linkstyle"   onClick={()=>{
                        add_delete();
                    }} >Yes</Button>
                    </Grid>
                    <Grid item xs={6} className="text-center">
                    <Button variant="contained" type="submit"  style={{width:"60%"}} fullWidth={false} color="primary"  className="linkstyle" onClick={()=>{
                        setShowDailog(false);
                         
                    }} >No</Button>

                    </Grid>
                    
              </Grid>
                
                </Grid>
                <Grid item xs={12}   >
                 <br/>
                </Grid>
          </Grid>
            
            </DialogContent>
             
      </Dialog>
        {props.data.map((subs) => {
          return (
            <div>
              {subs.sub ? (
                <li
                  id={"infoid" + subs.info_data_id}
                  className="list-group-item"
                  style={{ borderRadius: "0px", backgroundColor: "#f5dc93",padding:"2%" }}
                  onClick={() => {
                     props.setParents(subs.info_data_id);
                  }}
                >
                    <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    >
                        <Grid item> <Link to={"/eng-info/info-detail/"+subs?.info_data_slug+"/"+subs?.info_data_id}   className="anchorTag"  > {subs?.info_child_cat_ids}</Link></Grid>
                        <Grid item> <ExpandMoreIcon /></Grid>
                    </Grid>
                  
                 
                </li>
              ) : (
                <li
                  id={"infoid" + subs.info_data_id}
                  className="list-group-item"
                  style={{ borderRadius: "0px", backgroundColor: "#f5dc93",padding:"2%" }}
                  onClick={() => {
                     props.setParents(subs.info_data_id);
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    >
                        <Grid item><Link to={"/eng-info/info-detail/"+subs?.info_data_slug+"/"+subs?.info_data_id}   className="anchorTag"  >
                  {subs?.info_child_cat_ids} </Link> </Grid>
                  {/* Chethan {JSON.stringify(user)} */}
                 {user &&<Grid item> {engInfoList?.includes(subs?.info_data_id)?<BookmarkIcon onClick={()=>{
                            setMsg("Are you sure to Remove '"+subs?.info_child_cat_ids+"' from your Bookmarks?");
                            setId(subs?.info_data_id);
                            setType("remove");
                            setShowDailog(true);
                  }}/>:<BookmarkBorderIcon  onClick={()=>{
                    setMsg("Are you sure to add '"+subs?.info_child_cat_ids+"' to your Bookmarks?");
                    setId(subs?.info_data_id);
                    setType("add");
                    setShowDailog(true);
                  }}/>}</Grid>}
                  </Grid>      
                 
                  
                 
                   
                </li>
              )}
  
              {subs.sub ? (
                <SubMenu data={subs.sub} setParents={props.setParents} />
              ) : null}
            </div>
          );
        })}
      </ul>
    );
  };