import {Dialog,Grid,Typography,TextField,DialogTitle,DialogContent,useTheme,makeStyles,Button} from '@material-ui/core';

import {useState, useEffect, useContext} from 'react';

import { UserContext,SnackbarContext } from './userContext';
import {useNavigate} from "react-router-dom";

import Axios from "axios";

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiFormLabel-root":{
        color:"#ffd24d"
      },
       
      "& .Mui-error":{
        color:"red  ",
       // borderColor:"red !important"
      },
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"#2e2b2b",
        paddingLeft:"1%",
        paddingRight:"1%",
      },
     
      
       
    },
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });

export const ContactForm=(props)=>{
    const theme = useTheme();
    const classes = useStyles();
    const history=useNavigate();

    const {user, setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);


    const [name,setName] = useState("");

    const [data,setData] = useState();

    const [url,setUrl] = useState("");

    const [mob,setMob] = useState("");

    const [email,setEmail] = useState("");

    const [error,setError] = useState(0);

    useEffect(()=>{
        setData(props.data);
        setUrl(props.url);
        if(user){
            if(user.name!==null){
                setName(user.name);
            }
            if(user.user_mobile!==null){
                setMob(user.user_mobile);
            }
            setMob(user.user_mobile);
            if(user.user_email!==null){
                setEmail(user.user_email);
            }
        }
        
        
    },[])


    const submitDetails=(e)=>{
        e.preventDefault();
        setError(0);
         
        if(name===''){
            setError(1);
            setSnack({
                message: 'Enter the Name',
                type: 'error',
                open: true,
                direction:"center"
              });
        }else if(mob.length!==10 ){
            setError(2);  
            setSnack({
              message: "Enter Valid 10-Digit Mobile number...",
              type: 'error',
              open: true,
              direction:"center"
            });
        }else{
            if(url==='prod'){
                contactProd();
            }
            else if(url==='engwf' ){
                contactEngWf();
            }else if(url==='realestate' ){
                contactRealEstate();
            }else if(url==='careers'){
                ApplyJobNow();
            }
        }
    }


    const contactEngWf=()=>{
        props.closeform();
        props.showload();
        const formData={};
        var tkn=user.token;
        formData.uid=user.user_id;
        formData.catid=user.category_ids;
        formData.name=name;
        formData.mob=mob;
        formData.email=email;
        if(data.category_ids==='7'){
            formData.eid=data.user_id;
        }
        else if(data.category_ids==='6'){
            formData.wid=data.user_id;
        }
        var urlll="";
        if(data.category_ids==='7'){
            urlll="/new_apis/contactnoweng"
        }
        else if(data.category_ids==='6'){
            urlll="/new_apis/contactnowwf"
        }
        Axios.post(urlll,formData,{ 
            headers: { tkn: tkn },
           }).then((res) => {
                
                if(res.data.status===1){
                    props.hideload();
                    setSnack({
                        message: res.data.msg,
                        type: 'success',
                        open: true,
                        direction:"center"
                      });
                     
                }else if(res.data.status===2){
                    props.hideload();
                    setSnack({
                        message: res.data.msg,
                        type: 'error',
                        open: true,
                        direction:"center"
                      });
                     
                }else if(res.data.status===3){
                    props.hideload();
                     
                     
                    setSnack({
                        message: res.data.msg,
                        type: 'warning',
                        open: true,
                        direction:"left"
                      });
                }else if(res.data.status===4){
                    props.hideload();
                    setUser(null);
                    localStorage.removeItem("estkn");
                    setSnack({
                        message: res.data.msg,
                        type: 'warning',
                        open: true,
                        direction:"left"
                      });
                }
                
              
           });
    }

    const ApplyJobNow=()=>{
        props.closeform();
        props.showload();
        const formData={};
        var tkn=user.token;
        formData.uid=user.user_id;
        formData.name=name;
        formData.mob=mob;
        formData.email=email;
        formData.jid=data.job_id;
         
        Axios.post("/new_apis/apply-job",formData,{ 
            headers: { tkn: tkn },
           }).then((res) => {
                
                if(res.data.status===1){
                    props.hideload();
                    setSnack({
                        message: res.data.msg,
                        type: 'success',
                        open: true,
                        direction:"center"
                      });
                     
                }else if(res.data.status===2){
                    props.hideload();
                    setSnack({
                        message: res.data.msg,
                        type: 'error',
                        open: true,
                        direction:"center"
                      });
                     
                }else if(res.data.status===3){
                    props.hideload();
                    setSnack({
                        message: res.data.msg,
                        type: 'warning',
                        open: true,
                        direction:"left"
                      });
                }else if(res.data.status===4){
                    props.hideload();
                    setUser(null);
                    localStorage.removeItem("estkn");
                    setSnack({
                        message: res.data.msg,
                        type: 'warning',
                        open: true,
                        direction:"left"
                      });
                }
                
              
           });
    }

    const contactRealEstate=()=>{
        props.closeform();
        props.showload();
        const formData={};
        var tkn=user.token;
        formData.uid=user.user_id;
        formData.name=name;
        formData.mob=mob;
        formData.email=email;
        formData.reid=data.re_id;
         
        Axios.post("/new_apis/contactnowrealestate",formData,{ 
            headers: { tkn: tkn },
           }).then((res) => {
                
                if(res.data.status===1){
                    props.hideload();
                    setSnack({
                        message: res.data.msg,
                        type: 'success',
                        open: true,
                        direction:"center"
                      });
                     
                }else if(res.data.status===2){
                    props.hideload();
                    setSnack({
                        message: res.data.msg,
                        type: 'error',
                        open: true,
                        direction:"center"
                      });
                     
                }else if(res.data.status===3){
                    props.hideload();
                     
                    setSnack({
                        message: res.data.msg,
                        type: 'warning',
                        open: true,
                        direction:"left"
                      });
                }else if(res.data.status===4){
                    props.hideload();
                    setUser(null);
                    localStorage.removeItem("estkn");
                    setSnack({
                        message: res.data.msg,
                        type: 'warning',
                        open: true,
                        direction:"left"
                      });
                }
                
              
           });
    }

    const contactProd=()=>{
        props.closeform();
        props.showload();
        const formData={};
        var tkn=user.token;
        formData.uid=user.user_id;
        formData.catid=user.category_ids;
        formData.vid=data.user_ids;
        formData.pid=data.product_id;
        formData.pname=data.product_name;
        formData.name=name;
        formData.mob=mob;
        formData.email=email;
        Axios.post("/new_apis/contactnowprod",formData,{ 
            headers: { tkn: tkn },
           }).then((res) => {
                
                if(res.data.status===1){
                    props.hideload();
                    setSnack({
                        message: res.data.msg,
                        type: 'success',
                        open: true,
                        direction:"center"
                      });
                     
                }else if(res.data.status===2){
                    props.hideload();
                    setSnack({
                        message: res.data.msg,
                        type: 'error',
                        open: true,
                        direction:"center"
                      });
                     
                }else if(res.data.status===3){
                    props.hideload();
                    
                    setSnack({
                        message: res.data.msg,
                        type: 'warning',
                        open: true,
                        direction:"left"
                      });
                }else if(res.data.status===4){
                    props.hideload();
                    setUser(null);
                    localStorage.removeItem("estkn");
                    setSnack({
                        message: res.data.msg,
                        type: 'warning',
                        open: true,
                        direction:"left"
                      });
                }
                
              
           }); 
    }   

    return (
        <Dialog
        open={props.open}
        onClose={props.closeform}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
            style: {
              backgroundColor: theme.palette.secondary.main,
              boxShadow: 'none',
            },
          }}
        //style={{backgroundColor:theme.palette.secondary.main}}
      >
           
          <DialogTitle id="alert-dialog-title"> <Typography variant="h4" className="text-center"  style={{color:theme.palette.primary.main   }}>
         { url==='careers'  ? "Apply Now":"Contact Form"}              
                        </Typography>
                        <hr style={{color:theme.palette.primary.main}}/>
                        </DialogTitle>
          <DialogContent style={{overflow:"hidden"}}>
          <form onSubmit={submitDetails}>
           <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={3}
            >
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                <TextField className={classes.root}  label="Name *"  variant="outlined" fullWidth
                    size="small"
                        error={error===1 && true }
                        type={'text'}
                        value={name}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                        }}
                         
                        onChange={(e) => {
                                setName(e.target.value);

                        }}
                        
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                <TextField className={classes.root}  label="Mobile *"  variant="outlined" fullWidth
                    size="small"
                        error={error===2 && true }
                        type="number"
                        value={mob}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                        }}
                         
                        onChange={(e) => {
                                setMob(e.target.value);

                        }}
                        
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                <TextField className={classes.root}  label="Email "  variant="outlined" fullWidth
                    size="small"
                         
                        type={'text'}
                        value={email}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                        }}
                         
                        onChange={(e) => {
                                setEmail(e.target.value);

                        }}
                        
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  >
                 
                </Grid>
                <Grid item xs={12}  className="text-center" >
                <Button variant="contained" type="submit"  style={{width:"60%"}} fullWidth={false} color="primary"  className="linkstyle"  >Submit Details</Button>
                </Grid>
                <Grid item xs={12}   >
                 <br/>
                </Grid>
            </Grid>
            </form>
            </DialogContent>
             
      </Dialog>
    );
}