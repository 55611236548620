import {Grid,Box, Typography,useTheme,Button,Container,makeStyles,DialogContent,Dialog,DialogTitle,CardMedia,Card,Tabs,Tab,CardActions} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {useEffect,useState,useContext} from 'react';
import PropTypes from 'prop-types';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import DeleteIcon from '@material-ui/icons/Delete';
import {Loading} from "./Loading";
import {BackDropLoad} from "./LoadingBackDrop";
import { UserContext,SnackbarContext,EngInfoContext } from './userContext';
import {useNavigate,Link} from "react-router-dom";
import Axios from "axios";
import moment from 'moment';

const useStyles = makeStyles({
     
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });

 
  
  function convertToSlug(Text)
  {
      return Text.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');
  }

export const MyAccEngInfoBookmarks =()=>{
    const history=useNavigate();
    const theme = useTheme();
    const classes = useStyles();
    const {user, setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);
    const {engInfoList,setEngInfoList} = useContext(EngInfoContext);

    const [data,setData] = useState([]); 

    const [id,setId] = useState("");
    
    const [msg,setMsg] = useState("");
    const [showDialog,setShowDailog] = useState(false);

    const [loading,setLoading] = useState(true);
     

    useEffect(()=>{
        getData();
    },[])


    const getData=()=>{
        const formData={};
        var tkn=user.token;
        formData.user_id=user.user_id;
         
        Axios.post("/new_apis/get_bookmarks",formData,{ 
            headers: { tkn: tkn },
           }).then((res) => {
              if(res.data.status===1){
                  setData(res.data.data);
                    
                   setLoading(false);
                   
              }else if(res.data.status===3){
                 
                history('/');
                 
                setSnack({
                    message: res.data.msg,
                    type: 'warning',
                    open: true,
                    direction:"left"
                  });
              }else if(res.data.status===4){
                setUser(null);
                history('/');
                localStorage.removeItem("estkn");
                setSnack({
                    message: res.data.msg,
                    type: 'warning',
                    open: true,
                    direction:"left"
                  });
              }
           });

    }

    const getEngInfodata=async(tkn,uid)=>{
        const formData= {};
        var tkn=tkn;
        formData.user_id=uid;
        Axios.post("/new_apis/get_bookmarks",formData,{ 
          headers: { tkn: tkn },
         }).then((res) => {
            if(res.data.status==1){
               
                var arr=[];
                for(var i=0;i<res.data.data.length;i++){
                  arr.push(res.data.data[i].id);
                   
                }
                setEngInfoList(arr);
                  
            }else{
    
            }
                  
         });
      }

    const  deleteBookmark=()=>{
        setShowDailog(false);
        setLoading(true);
         
          const formData= {};
          var tkn=user.token;
          formData.user_id=user.user_id;
          formData.info_id=id;
           
            Axios.post("/new_apis/delete_bookmark",formData,{ 
              headers: { tkn: tkn },
             }).then((res) => {
                if(res.data.status==1){
                     
                  setSnack({
                    message: res.data.msg,
                    type: 'success',
                    open: true,
                    direction:"center"
                  });
                  getEngInfodata(tkn,user.user_id);
                  getData();
                   
                  
                }else if(res.data.status===3){
                 
                    history('/');
                     
                    setSnack({
                        message: res.data.msg,
                        type: 'warning',
                        open: true,
                        direction:"left"
                      });
                  }else if(res.data.status===4){
                    setUser(null);
                    history('/');
                    localStorage.removeItem("estkn");
                    setSnack({
                        message: res.data.msg,
                        type: 'warning',
                        open: true,
                        direction:"left"
                      });
                  } 
                      
             });
          
          
         
      }

  

    return (<div>
        {loading?<Loading/>:(<Container maxWidth="md">
        <Dialog
        open={showDialog}
        onClose={()=>{
          setShowDailog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
            style: {
              backgroundColor: theme.palette.secondary.main,
              boxShadow: 'none',
            },
          }}
         
      >
           
          <DialogTitle id="alert-dialog-title"> <Typography variant="h4" className="text-center"  style={{color:theme.palette.primary.main   }}>
                                 <ErrorOutlineIcon fontSize="large" />&nbsp;Please Confirm
                        </Typography>
                        <hr style={{color:theme.palette.primary.main}}/>
                        </DialogTitle>
          <DialogContent>
          
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={2}
            > <Grid item xs={12}>
              <Typography variant="body1" className="text-center" style={{color:theme.palette.primary.main   }}>
               {msg}
              </Typography>
            </Grid>
            <Grid item xs={12}   >
                 <br/>
                </Grid>
              <Grid item xs={12}   >
              <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
                spacing={2}
                >
                    <Grid item xs={6}  className="text-center">
                    <Button variant="contained" type="submit"  style={{width:"60%"}} fullWidth={false} color="primary"  className="linkstyle"   onClick={()=>{
                        deleteBookmark();
                    }} >Yes</Button>
                    </Grid>
                    <Grid item xs={6} className="text-center">
                    <Button variant="contained" type="submit"  style={{width:"60%"}} fullWidth={false} color="primary"  className="linkstyle" onClick={()=>{
                        setShowDailog(false);
                         
                    }} >No</Button>

                    </Grid>
                    
              </Grid>
                
                </Grid>
                <Grid item xs={12}   >
                 <br/>
                </Grid>
          </Grid>
            
            </DialogContent>
             
      </Dialog>
        <Box boxShadow={3} p={3}  style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.secondary.main ,borderRadius:"10px"}}> 
        <Grid
        container
        direction="row"
            justify="space-between"
            alignItems="center"
        spacing={3}
        >
            <Grid item xs={12}>
            <Typography variant="h4" className="text-center" gutterBottom style={{color:theme.palette.primary.main}}>
                 My "Eng.. Info" Bookmarks
            </Typography>
            <hr style={{color:theme.palette.primary.main}}/>
            </Grid>
            {data.length!==0? <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={4}
                   >
                       {data.map((item) => (
                       <Grid item xs={12} sm={6} md={6} lg={4} xl={4} 
                       className="text-center"
                       >
                           <Card className={classes.cardshadow} style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.primary.main}}>
                            
                            
                            
                         
                        <CardActions>
                         
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            >
                                   
                                     
                                     
                                     
                                    <Grid item xs={12}  style={{padding:"5px"}}>
                                    <Typography variant="body2" className="text-center"  style={{color:theme.palette.secondary.main,width:"100%",textAlign:"left"}}>
                                   <b>{item.title} - {item.parent}</b>
                                     
                                    </Typography>
                                    </Grid>
                                    
                                    <Grid item xs={12}  style={{padding:"5px"}}>
                                    <Typography variant="body2"   style={{color:theme.palette.secondary.main,width:"100%",textAlign:"left"}}>
                                   <b>Bookmarked Date:</b> {item.added_date}  
                                     
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={12}  style={{padding:"5px"}}>
                                    <Button variant="contained"     fullWidth={true} color="primary"   className="linkstyle" style={{justifyContent: "flex-start",border:"1px solid black"}}
                                    onClick={()=>{
                                        setMsg("Are you sure to Remove '"+item.title+"' from your Bookmarks?");
                                        setId(item.id);
                                        
                                        setShowDailog(true);
                                    }}>
                                    <DeleteIcon/>&nbsp;&nbsp; Remove this
                                      </Button>
                                    </Grid>
                                    <Grid item xs={12}  style={{padding:"5px"}}>
                                    <Button variant="contained" component={Link}   fullWidth={true} color="primary" to={"/eng-info/info-detail/"+convertToSlug(item?.title)+"/"+item?.id} className="linkstyle" style={{justifyContent: "flex-start",border:"1px solid black"}}>
                                    <MenuBookIcon/>&nbsp;&nbsp; Continue Reading
                                      </Button>
                                    </Grid>
                                </Grid>
                        </CardActions>
                        </Card>
                        </Grid>
                       ))}
                   </Grid>:(<Typography variant="body1" className="text-center" style={{width:"100%"}}>You haven't added any bookmarks yet.</Typography> )}
        </Grid>
        </Box>
        </Container>)}
    </div>);
}