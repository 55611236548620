import {Grid,Box, Typography,useTheme,Button,Container,makeStyles,TextField,FormControl,Switch,CardMedia,Card,Tabs,Tab,CardActions} from '@material-ui/core';
import {useEffect,useState,useContext} from 'react';
import PropTypes from 'prop-types';
 
import {Loading} from "./Loading";
import {BackDropLoad} from "./LoadingBackDrop";
import { UserContext,SnackbarContext } from './userContext';
import {useNavigate} from "react-router-dom";
import Axios from "axios";
import moment from 'moment';

const useStyles = makeStyles({
     
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });

 
  
   

export const MyAccContactedCustomersEngWf =()=>{
    const history=useNavigate();
    const theme = useTheme();
    const classes = useStyles();
    const {user, setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);
    const [data,setData] = useState([]); 


    const [loading,setLoading] = useState(true);
     

    useEffect(()=>{
        getData();
    },[])


    const getData=()=>{
        const formData={};
        var tkn=user.token;
        formData.uid=user.user_id;
        formData.cat=user.category_ids;
        formData.role=user.user_role;
        
        Axios.post("/new_apis/getcontactedcustomers-eng-wf",formData,{ 
            headers: { tkn: tkn },
           }).then((res) => {
              if(res.data.status===1){
                  setData(res.data.custhist);
                   
                   setLoading(false);
                   
              }else if(res.data.status===3){
                 
                history('/');
                 
                setSnack({
                    message: res.data.msg,
                    type: 'warning',
                    open: true,
                    direction:"left"
                  });
              }else if(res.data.status===4){
                setUser(null);
                history('/');
                localStorage.removeItem("estkn");
                setSnack({
                    message: res.data.msg,
                    type: 'warning',
                    open: true,
                    direction:"left"
                  });
              }
           });

    }

  

    return (<div>
        <Container maxWidth="md">
        <Box boxShadow={3} p={3}  style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.secondary.main ,borderRadius:"10px"}}> 
        {loading?<Loading/>:(<Grid
        container
        direction="row"
            justify="space-between"
            alignItems="center"
        spacing={3}
        >
            <Grid item xs={12}>
            <Typography variant="h4" className="text-center" gutterBottom style={{color:theme.palette.primary.main}}>
                 Contacted Users
            </Typography>
            <hr style={{color:theme.palette.primary.main}}/>
            </Grid>
            {data.length!==0? <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={4}
                   >
                       {data.map((item) => (
                       <Grid item xs={12} sm={6} md={6} lg={4} xl={4} 
                       className="text-center"
                       >
                           <Card className={classes.cardshadow} style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.primary.main}}>
                            
                            
                            
                         
                        <CardActions>
                         
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            >
                                   
                                     
                                    
                                    <Grid item xs={12}>
                                    <Typography variant="body2"   style={{color:theme.palette.secondary.main,width:"100%",textAlign:"left"}}>
                                   <b>Name:</b> {item.first_name}
                                     
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2"   style={{color:theme.palette.secondary.main,width:"100%",textAlign:"left"}}>
                                   <b>Mobile no:</b> {item.phone}
                                     
                                    </Typography>
                                    </Grid>
                                    {(item.email!==null && item.email!=="")&&(<Grid item xs={12}>
                                    <Typography variant="body2"     style={{color:theme.palette.secondary.main,width:"100%",textAlign:"left"}}>
                                   <b>Email:</b> {item.email }
                                     
                                    </Typography>
                                    </Grid>)}
                                    <Grid item xs={12}>
                                    <Typography variant="body2"   style={{color:theme.palette.secondary.main,width:"100%",textAlign:"left"}}>
                                   <b>Contacted Date:</b> {item.date}  
                                     
                                    </Typography>
                                    </Grid>
                                </Grid>
                        </CardActions>
                        </Card>
                        </Grid>
                       ))}
                   </Grid>:(<Typography variant="body1" className="text-center" style={{width:"100%"}}>No one have Contacted any of your Profile till now.</Typography> )}
        </Grid>)}
        </Box>
        </Container> 
    </div>);
}