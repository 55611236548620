import {useEffect,useState} from "react";
//import Loading from './Loading';
//import Axios from "axios";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Container,Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
 
export const ConvPower=()=>{
    
     
    useEffect(()=>{
      window.scrollTo(0, 0);
       
    },[]);

return  ( <div>

<Grid
  container
  direction="column"
  justify="space-evenly"
  alignItems="center"
  spacing={3}
>
<Grid item xs={12}>
         
   </Grid>
<Grid item xs={12}>
        <Typography variant="h5" component="h4" className="text-center" color="primary" gutterBottom>
          POWER 
        </Typography>
   </Grid>
  <Grid item xs={12}>
  <Power />
  </Grid>
  <Grid item xs={12}>
   
  </Grid>
  <Grid item xs={12}>
  <SimpleCard/>
  </Grid>
  </Grid>
    
    
</div>

 );
}

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiFormLabel-root":{
        color:"#ffd24d"
      },
       
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"rgb(41, 38, 38)",
        paddingLeft:"1%",
        paddingRight:"1%",
      } 
       
    }
  
  });
  
  export default function SimpleCard() {
    const classes = useStyles();
    
  
    return (
        <Box m={3} >
      <Card className={classes.root}  style={{ border:"2px groove #ffd24d"}}>
        <CardContent>
          
          <Typography variant="h6"  className="text-center">
           UNITS OF POWER 
          </Typography>
          <br></br>
          <Typography variant="body2"  >
          1 HP = 0.7457 KW The English system for measurement of the power of one 
          Horse Power is equal to 0.7457 times the Kilo Watt of the metric system.
          </Typography>
        </CardContent>
        
      </Card>
      </Box>
    );
  }
    
   


const Power=()=>{
    const classes = useStyles();
  const [hp,setHP] = useState();
  const [kw,setKW] = useState();
  const [mhp,setMHP] = useState();
  const [watt,setWatt] = useState();
  const [jsec,setJsec] = useState();
  const [kcal,setKcal] = useState();
  const [kwatt,setKwatt] = useState();
  const [kgsec,setKgsec] = useState();

  return (
      <Container maxWidth="lg">
      <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      spacing={4}
    >
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <TextField  className={classes.root}  size="small"  label="H.P" variant="outlined" shrink="true" fullWidth 
              type="number"
              InputProps={{
                  style: {
                      color: "#ffd24d"
                  }
              }}
              InputLabelProps={{  shrink: true,focused:true }}
              value={hp}
              onChange={(e) => {
                      
                      var val = parseFloat(e.target.value);
                      var val1 = parseFloat(e.target.value);
                      setHP(val1);
                      val=(val1*0.7457)
                      setKW(val);
                      val=val1*1.01389 
                      setMHP(val);
                      val=val1*745.7
                      setWatt(val);
                      val=val1*745.70
                      setJsec(val);
                      val=val1*641.19
                      setKcal(val);
                      val=val1*0.7457
                      setKwatt(val);
                      val=val1*76.07
                      setKgsec(val);
                  
              }}
               
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField  className={classes.root}  size="small"  label="K.W" variant="outlined" fullWidth
        InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          type="number"
          value={kw}
          onChange={(e) => {
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setKW(val1);
            val=(val1*1.34102)
            setHP(val);
            val=val1*1.3596 
            setMHP(val);
            val=val1*1000
            setWatt(val);
            val=val1*1000.00 
            setJsec(val);
            val=val1*859.85 
            setKcal(val);
            val=val1*3600000
            setKwatt(val);
            val=val1*101.97162129779
            setKgsec(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField  className={classes.root}  size="small"  label="M.H.P" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={mhp}
          onChange={(e) => {
              
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setMHP(val1);
            val=(val1*0.98632)
            setHP(val);
            val=val1*0.735499
            setKW(val);
            val=val1*0.74
            setWatt(val);
            val=val1*735.5 
            setJsec(val);
            val=val1*632.42
            setKcal(val);
            val=val1*0.73549875
            setKwatt(val);
            val=val1*77.12821
            setKgsec(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField  className={classes.root}  size="small"  label="Watts" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={watt}
          onChange={(e) => {
             
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setWatt(val1);
            val=(val1/745.7)
            setHP(val);
            val=val1*0.001 
            setKW(val);
            val=val1/745.699
            setMHP(val);
            val=val1*1 
            setJsec(val);
            val=val1/1.162 
            setKcal(val);
            val=val1/1000
            setKwatt(val);
            val=val1*0.1019
            setKgsec(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField  className={classes.root}  size="small"  label="Joules/Sec" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={jsec}
          onChange={(e) => {
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setJsec(val1);
            val=(val1*0.00000003725)
            setHP(val);
            val=val1*0.001 
            setKW(val);
            val=val1*0.0000003725
            setMHP(val);
            val=val1*1 
            setWatt(val);
            val=val1/4184 
            setKcal(val);
            val=val1/3600000
            setKwatt(val);
            val=val1*0.1026
            setKgsec(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField  className={classes.root}  size="small"  label="Kilo Calories" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={kcal}
          onChange={(e) => {
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setKcal(val1);
            val=(val1*0.001561)
            setHP(val);
            val=val1*4.187 
            setKW(val);
            val=val1*0.001561
            setMHP(val);
            val=val1*1.162
            setWatt(val);
            val=val1*1.1622
            setJsec(val);
            val=val1/860.421
            setKwatt(val);
            val=val1*429.600007
            setKgsec(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField  className={classes.root}  size="small"  label="Kilo watt" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={kwatt}
          onChange={(e) => {
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setKwatt(val1);
            val=(val1*1.341)
            setHP(val);
            val=val1*3600 
            setKW(val);
            val=val1*1.341
            setMHP(val);
            val=val1*1000 
            setWatt(val);
            val=val1*1000 
            setJsec(val);
            val=val1*860.421
            setKcal(val);
            val=val1*369390
            setKgsec(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField  className={classes.root}  size="small"  label="Kg.m/sec" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={kgsec}
          onChange={(e) => {
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setKgsec(val1);
            val=(val1*0.00000363)
            setHP(val);
            val=val1*0.009747 
            setKW(val);
            val=val1*0.00000363
            setMHP(val);
            val=val1*9.746
            setWatt(val);
            val=val1*9.746 
            setJsec(val);
            val=val1*0.002328
            setKcal(val);
            val=val1*0.000002707
            setKwatt(val);
              }
          }
           
        />
        </Grid>
  </Grid></Container>);
}