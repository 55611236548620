import {useEffect,useState} from "react";
import {Loading} from './Loading';
import Axios from "axios";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Container ,useTheme,Box,Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {withStyles} from '@material-ui/core/styles';
import { Helmet  } from 'react-helmet-async';

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "& .MuiFormLabel-root":{
      color:"#ffd24d"
    },
     
    "& .MuiInputLabel-shrink":{
      
      transform: "translate(14px, -9px) scale(0.90)",
      transformOrigin: "top left",
      backgroundColor:"black",
      paddingLeft:"1%",
      paddingRight:"1%",
    } 
     
  }
});
const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";

export const Privacy=()=>{
  
  const theme = useTheme();
  const [metadata,setMetadata] = useState();

  useEffect(()=>{
    window.scrollTo(0, 0);
    Axios.post("/new_apis/getsinglemetadata", {'type':"privacy"},{
      headers: { key: key },
     }).then((res) => {
        
       if(res.data.status){
         
        setMetadata(res.data.metadata[0])
       } else{
          setMetadata({'seo_title':"Home - ESAY | Contacts and Info in the World of Construction",
          'seo_desc':"Get all the information and contacts of various types of contractors, consultants, machinery/material suppliers, engineers, and workforce in the construction sector",
          "seo_keywords":"best  contractors in Telangana, schedule of rates, Tenders in India,   construction architect engineers and labor near me, latest trends in construction sector, top contractors and consultants in Hyderabad,   interior designers in Hyderabad, building construction materials and machinery suppliers, soil metal materials testing labs around me"});
       }
        
     });
  },[])

  const handleContextMenu = (e) => {
    e.preventDefault(); // Disable right-click
  };

  const handleCopy = (e) => {
    e.preventDefault(); // Disable copy
  };

return  (  <div>{metadata && <Helmet>
  <title>{metadata.seo_title}</title>
    <meta name="description" content={metadata.seo_desc} />
    <meta name="keywords" content={metadata.seo_keywords}></meta>
  </Helmet>}<Container maxWidth="md">
<Box boxShadow={3} p={3}  style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.secondary.main,marginTop:"5%",marginBottom:"5%",borderRadius:"10px" }}> 
<Typography variant="h5" className="text-center"  style={{color:theme.palette.primary.main   }}>
                                     Privacy Policy         
                        </Typography>
                        <hr style={{color:theme.palette.primary.main}}/>
                        <br/>
                        <Grid
                          container
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          spacing={3}

                          style={{ userSelect: "none", // CSS to disable text selection
                            MozUserSelect: "none", // For Firefox
                            WebkitUserSelect: "none", // For Safari
                            msUserSelect: "none", // For older IE
                            }}
                            onContextMenu={handleContextMenu}
                            onCopy={handleCopy}
                        >
                           
                           <Grid item xs={12} >
                          <Typography variant="body2" className="text-center"  gutterBottom  style={{color:"white"   }}>
                              “To Ensure that all the Vital information are available at one Location ESAY was formed.”
                             </Typography>
                             <br/>
                             <Typography variant="body2"   gutterBottom  style={{color:"white"   }}>
                              We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from http://esay.in.
                             </Typography>
                             <br/>
                             <Typography variant="body2"   gutterBottom  style={{color:"white"   }}>
                              ESAY including its affiliates is committed to respect your privacy and to comply with applicable data protection and privacy laws. This privacy policy (“Policy”) describes how we collect and use personal data. ESAY describes herein the applicability of this Policy.
                             </Typography>
                          </Grid>
                          <Grid item xs={12} >
                          <Typography variant="h6"   gutterBottom  style={{color:theme.palette.primary.main,marginBottom:"1%"  }}>
                            Information Collection and Usage:
                           </Typography>
                           <Typography variant="body2"   gutterBottom  style={{color:"white"   }}>
                              We collect different types of information for various purposes from multiple sources intending to provide and improve our Services online. Our products or services may contain links to, or may be embedded within, other companies’ websites and services that have privacy policies of their own. ESAY’s products or services are embedded to products and services of its customers, and we require our customers to provide necessary transparency. This might include linking to this Policy and the relevant Supplements, or providing the transparency in integrated and embedded notices which identify us as the service provider or controller. If you do not agree with this Policy, do not use our products and services or provide ESAY with your personal data.
                              
                             </Typography>
                          </Grid>
                          <Grid item xs={12} >
                          <Typography variant="h6"   gutterBottom  style={{color:theme.palette.primary.main,marginBottom:"1%"  }}>
                            Types of Data Collected:
                           </Typography>
                           <Typography variant="body2"   gutterBottom  style={{color:"white"   }}>
                               <ul>
                                   <li><b style={{color:theme.palette.primary.main,marginTop:"3%"}}>Personal: </b>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to: Email, Mobile number, First name and Last name.</li>
                                   <li><b style={{color:theme.palette.primary.main,marginTop:"3%"}}>Use of Data: </b>ESAY uses the collected data for various purposes: <br/>
                                   <ol>
                                            <li>To provide and maintain the Service</li>
                                            <li>To notify you about changes to our Service</li>
                                            <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
                                            <li>To provide customer care and support</li>
                                            <li>To provide analysis or valuable information so that we can improve the Service</li>
                                            <li>To monitor the usage of the Service</li>
                                            <li>To detect, prevent and address technical issues</li>
                                            <li>Transfer Of Data</li>
                                   </ol></li>
                               </ul>
                              
                             </Typography>
                          </Grid>
                          <Grid item xs={12} >
                          <Typography variant="h6"   gutterBottom  style={{color:theme.palette.primary.main,marginBottom:"1%"  }}>
                            What information do we collect?
                           </Typography>
                           <Typography variant="body2"   gutterBottom  style={{color:"white"   }}>
                           <ul>
                                <li><b style={{color:theme.palette.primary.main,marginTop:"3%"}}>Product and service activations - </b>ESAY products and services may require electronic activation in future as we constantly update our products and services, ESAY your device and application type, as well as unique device, application, network and subscription identifiers are sent to ESAY.</li>
                                <li><b style={{color:theme.palette.primary.main,marginTop:"3%"}}>Use of products and services - </b>When you access our services online, our web servers automatically create records of your visit. These records typically include IP-address, access times, the sites linked from, pages visited, the links and features used, the content viewed or requested, browser or application type, language and other such information.<br/> &nbsp;&nbsp;Our applications may contact our servers periodically, for example to check for updates or to send us information relating to service usage. Additionally, we may invite you to join voluntary product and service improvement or research programs ESAY detailed information is collected. See Supplements to this Policy for more details.</li>
                                <li><b style={{color:theme.palette.primary.main,marginTop:"3%"}}>Information you provide us with - </b>When you create an account, make a purchase, request services, participate in research or campaigns or otherwise interact with us, we may ask for information such as your name, email address, phone number, street address, user names and passwords, feedback, information relating to your devices, age, gender, and language, bank account number, credit card details and other such financial information.<br/> &nbsp;&nbsp;We also maintain records of your consents, preferences and settings relating to, for example, location data, marketing and sharing of personal data.</li>
                                <li><b style={{color:theme.palette.primary.main,marginTop:"3%"}}>Your transactions with us - </b>We maintain records of your purchases, downloads, the content you have provided us with, your requests, agreements between you and ESAY, the products and services provided to you, payment and delivery details, your contacts and communications and other interactions with us. We may, in accordance with applicable law, record your communication with our customer care or with other such contact points.</li>
                                <li><b style={{color:theme.palette.primary.main,marginTop:"3%"}}>Information provided by partners - </b>We obtain information from industry partners and a variety of other sources, including but not limited to publicly available sources such as websites of educational institutions, research institutes, technology providers, construction majors, business registries, etc. ESAY has a legal basis for the transfer, use and application of variety of data in accordance with applicable laws prior to disclosure of the data to us.</li>
                            </ul>
                           </Typography>
                          </Grid>
                          <Grid item xs={12} >
                          <Typography variant="h6"   gutterBottom  style={{color:theme.palette.primary.main,marginBottom:"1%"  }}>
                            Why do we process Personal Data?
                           </Typography>
                           <Typography variant="body2"   gutterBottom  style={{color:"white"   }}>
                              ESAY may process your personal data for the following purposes. One or more purposes may apply simultaneously.
                              <ul>
                                <li><b style={{color:theme.palette.primary.main,marginTop:"3%"}}>Providing products and services - </b>We may use your personal data to provide you with our products and services, to process your requests or as otherwise may be necessary to perform the contract between you and ESAY, to ensure the functionality and security of our products and services, to identify you as well as to prevent and investigate fraud and other misuses.</li>
                                <li><b style={{color:theme.palette.primary.main,marginTop:"3%"}}>Accounts - </b>Some services may require an account to help you manage your content and preferences. Depending on the service, an account creation may be either required or voluntary. Account creation requires you to provide us with basic contact details about yourself, such as name, email address, country of residence and date of birth. You may also be able to voluntarily provide more information about yourself while creating a profile, such as a photo or display preference of your choice.</li>
                                <li><b style={{color:theme.palette.primary.main,marginTop:"3%"}}>Developing and managing products and services - </b>We may use your personal data to develop and manage our products, services, customer care, sales and marketing. We may combine personal data collected in connection with your use of a particular ESAY product and/or service with other personal data we may have about you, unless such personal data was collected for a purpose, ESAY the original purpose is incompatible with this purpose.</li>
                                <li><b style={{color:theme.palette.primary.main,marginTop:"3%"}}>Communicating with you - </b>We may use your personal data to communicate with you, for example to inform you that our services have changed or to send you critical alerts and other such notices relating to our products and/or services and to contact you for customer care related purposes.</li>
                                <li><b style={{color:theme.palette.primary.main,marginTop:"3%"}}>Marketing, advertising and making recommendations - </b>We may contact you to inform you of new products, services or promotions we may offer and to conduct market research when we have your consent or it is otherwise allowed. We may use your personal data to personalize our offering and to provide you with more relevant services, for example, to make recommendations and to display customized content and advertising in our services. This may include displaying ESAY and third party content.</li>
                              </ul>
                           </Typography>
                           </Grid>
                          <Grid item xs={12} >
                          <Typography variant="h6"   gutterBottom  style={{color:theme.palette.primary.main,marginBottom:"1%"  }}>
                            What is our legal basis for processing your Personal Data?
                           </Typography>
                           <Typography variant="body2"   gutterBottom  style={{color:"white"   }}>
                             Our legal basis for processing your personal data is dependent on the purpose for processing and may vary as described in the Supplement applicable to the product or service you are using. In general, we process your personal data under the following legal bases:
                              <ul>
                                <li><b style={{color:theme.palette.primary.main,marginTop:"3%"}}>Performance of a contract with you - </b>We process your personal data to perform our obligations under the Service Terms applicable to the product or service you are using, provided by us or our customers. Our Service Terms are available at our website.</li>
                                <li><b style={{color:theme.palette.primary.main,marginTop:"3%"}}>We process your personal data to perform our obligations under the Service Terms applicable to the product or service you are using, provided by us or our customers. Our Service Terms are available at our website - </b>We process your personal data if you have consented to the processing activity. You may revoke your consent at any time. Doing so will bar us from further processing of your personal data based on your consent, but will not impact the lawfulness of processing based on your consent before it was withdrawn. Some of the features of our products and services might be only available based on consent.</li>
                                <li><b style={{color:theme.palette.primary.main,marginTop:"3%"}}>Legal obligations - </b>We process your personal data as needed to comply with laws and regulations.</li>
                                <li><b style={{color:theme.palette.primary.main,marginTop:"3%"}}>Legitimate interests - </b>We process your personal data to further our legitimate interests, such as in connection with managing, developing, building, securing, and in limited circumstances marketing, advertising, and making recommendations regarding our products and services. Any such processing is conducted subject to appropriate measures to protect your fundamental rights and freedoms related to your personal data, and in any event will be subject to the restrictions provided in this Policy. Further information or specification of our legitimate interests may be provided in relevant Supplements applicable to the product or service.</li>
                              </ul>
                             </Typography>
                          </Grid>
                          <Grid item xs={12} >
                          <Typography variant="h6"   gutterBottom  style={{color:theme.palette.primary.main,marginBottom:"1%"  }}>
                            How long do we retain Personal Data:
                           </Typography>
                           <Typography variant="body2"   gutterBottom  style={{color:"white"   }}>
                              We endeavor to only collect personal data that are necessary for the purposes for which they are collected, and to retain such data for no longer than is necessary for such purposes. The length of time personal data is retained, and criteria for determining that time, are dependent on the nature of the personal data and the purpose for which it was provided. For example, for your personal data related to managing your account (such as name, email address, and account content and preferences) are maintained for as long as they are retained by you within your account. Other data, such as records of your activity within the application, are typically maintained only for a short period.
                              
                             </Typography>
                          </Grid>

                          <Grid item xs={12} >
                          <Typography variant="h6"   gutterBottom  style={{color:theme.palette.primary.main,marginBottom:"1%"  }}>
                            Your consent and social sharing services:
                           </Typography>
                           <Typography variant="body2"   gutterBottom  style={{color:"white"   }}>
                              We may share your personal data if we have your consent to do so. Some services may allow you to share your personal data with other users of the service or with other services and their users. Please consider carefully before disclosing any personal data or other information that might be accessible to other users.
                             
                             </Typography>
                          </Grid>

                          <Grid item xs={12} >
                          <Typography variant="h6"   gutterBottom  style={{color:theme.palette.primary.main,marginBottom:"1%"  }}>
                            Mandatory disclosures:
                           </Typography>
                           <Typography variant="body2"   gutterBottom  style={{color:"white"   }}>
                              We may be obligated by mandatory law to disclose your personal data to certain authorities or other third parties, for example, to law enforcement agencies in the countries ESAY or third parties acting on our behalf operate. We may also disclose and otherwise process your personal data in accordance with applicable law to defend ESAY’s legitimate interests, for example, in legal proceedings or in connection with governmental requests and filings.
                              
                             </Typography>
                          </Grid>

                          <Grid item xs={12} >
                          <Typography variant="h6"   gutterBottom  style={{color:theme.palette.primary.main,marginBottom:"1%"  }}>
                            How do we address Data Quality:
                           </Typography>
                           <Typography variant="body2"   gutterBottom  style={{color:"white"   }}>
                              We take reasonable steps to keep the personal data we possess accurate and to delete incorrect or unnecessary personal data. We encourage you to access your personal data through your account from time to time to ensure that it is up to date.
<br/><br/>
ESAY will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless ESAY are adequate controls in place including the security of your data and other personal information.
<br/><ul>
    <li>Disclosure Of Data</li><li>Legal Requirements</li>
</ul>
 
ESAY may disclose your Personal Data in good faith and belief that such action is necessary to:
<ol>
    <li>To comply with a legal obligation</li>
    <li>To protect and defend the rights or property of ESAY</li>
    <li>To prevent or investigate possible wrong doing in connection with the Service</li>
    <li>To protect the personal safety of users of the Service or the public</li>
    <li>To protect against legal liability</li>
</ol>
                             </Typography>
                          </Grid>

                          <Grid item xs={12} >
                          <Typography variant="h6"   gutterBottom  style={{color:theme.palette.primary.main,marginBottom:"1%"  }}>
                            Security of Data:
                           </Typography>
                           <Typography variant="body2"   gutterBottom  style={{color:"white"   }}>
                              The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
                             <br/><br/>
                             <b style={{color:theme.palette.primary.main}}>Contact us by Email:</b> contact@esay.in, contact@arawinz.com
                             </Typography>
                          </Grid>

                          <Grid item xs={12} >
                          <Typography variant="h6"   gutterBottom  style={{color:theme.palette.primary.main,marginBottom:"1%"  }}>
                            Changes to this Privacy Policy:
                           </Typography>
                           <Typography variant="body2"   gutterBottom  style={{color:"white"   }}>
                              ESAY may from time to time change this Policy or change, modify or withdraw access to this site at any time with or without notice. However, if this Policy is changed in a material, adverse way, ESAY will post a notice advising of such change at the beginning of this Policy and on this site's home page for 30 days. We recommend that you re-visit this Policy from time to time to learn of any such changes to this Policy.
                             </Typography>
                          </Grid>

                          <Grid item xs={12}>
                          <Typography variant="body2" className="text-center"   style={{color:theme.palette.primary.main}}>
                              ****
                            </Typography>
                          </Grid>

                        </Grid>
  </Box>
  </Container>
      
     
</div> );
}

 

 

 

 