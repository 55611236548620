
import {useState,useEffect, useContext, React} from 'react';
import {Grid,Typography,useTheme,InputAdornment,IconButton, makeStyles,Box,TextField,Button,Dialog,DialogTitle,DialogContent,Select,InputLabel,OutlinedInput, Container} from "@material-ui/core";
import {useNavigate,Link} from "react-router-dom";
import {Loading} from "./Loading";
import { Helmet  } from 'react-helmet-async';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LockIcon from '@material-ui/icons/Lock';
import PhoneIcon from '@material-ui/icons/Phone';
import { UserContext,SnackbarContext ,EngInfoContext} from './userContext';
import {BackDropLoad} from "./LoadingBackDrop";
import Axios from "axios";

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiFormLabel-root":{
        color:"#ffd24d"
      },
       
      "& .Mui-error":{
        color:"red  ",
       // borderColor:"red !important"
      },
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"#2e2b2b",
        paddingLeft:"1%",
        paddingRight:"1%",
      },
      
       
    },
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });


const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";
    

export const Login=()=>{
    const history=useNavigate();
    const theme = useTheme();
    const classes = useStyles();

    const {user, setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);
    const {engInfoList,setEngInfoList} = useContext(EngInfoContext);

    const [num,setNum] = useState("");
    const [frnum,setFrNum] = useState("");
    const [pass,setPass] = useState("");
    const [wait,setWait] = useState(false);
    const [showPass,setShowPass] = useState(false);
    const [error,setError] = useState(0);


    const [otp,setOtp] = useState("");
    const [otptoken,setOtpToken] = useState("");
    const [newpass,setNewPass] = useState("");
    const [renewpass,setReNewPass] = useState("");

    const [forgotMobileDailog,setForgotMobileDailog] = useState(false);
    const [valotpDailog,setValotpDailog] = useState(false);
    
    useEffect(()=>{
      window.scrollTo(0, 0);
    },[]);


    const getEngInfodata=async(tkn,uid)=>{
      const formData= {};
      var tkn=tkn;
      formData.user_id=uid;
      Axios.post("/new_apis/get_bookmarks",formData,{ 
        headers: { tkn: tkn },
       }).then((res) => {
          if(res.data.status==1){
             
              var arr=[];
              for(var i=0;i<res.data.data.length;i++){
                arr.push(res.data.data[i].id);
                 
              }
              setEngInfoList(arr);
                
          }else{
  
          }
                
       });
    }


    const loginNow=(e)=>{
        e.preventDefault();
          setError(0);
           
          if(num.length!==10 ){
             
              setError(1);  
              setSnack({
                message: "Enter Valid 10-Digit Mobile number...",
                type: 'error',
                open: true,
                direction:"center"
              });
          }else if(pass.length<3){
              setError(2);  
              setSnack({
                message: "Enter your Password...",
                type: 'error',
                open: true,
                direction:"center"
              });
          }else{
            setWait(true);
            const formData= {};
            formData.num=num;
            formData.pass=pass;
            Axios.post("/new_apis/new_login",formData,{ 
                headers: { key: key },
               }).then((res) => {
                   
                    if(res.data.status){
                        setWait(false);
                        setUser(res.data.user_data);                       
                         
                        localStorage.setItem('estkn',res.data.token);

                        getEngInfodata(res.data.token,res.data.user_data.user_id);
                        setSnack({
                          message: res.data.msg,
                          type: 'success',
                          open: true,
                          direction:"left"
                        });
                        if(res.data.user_data.profile_done==='0'){
                          history('/myaccount/complete_profile');
                        }else{
                          history('/myaccount/profile');
                        }
                        
                    }else{
                      setWait(false);
                      setSnack({
                        message: res.data.msg,
                        type: 'error',
                        open: true,
                        direction:"center"
                      });
                    }
                    
                  
               }); 
          } 
    }

    const generateotp=(e)=>{
      e.preventDefault();
          setError(0);
      if(frnum.length!==10){
        setError(3)
        setSnack({
          message: "Enter Valid 10-digit Mobile number..",
          type: 'error',
          open: true,
          direction:"center"
        })
      }else{
        setForgotMobileDailog(false);
        setWait(true);
        const formData= {};
        formData.mob=frnum;
        Axios.post("/new_apis/checkusermobile",formData,{ 
          headers: { key: key },
         }).then((res) => {
             
              if(res.data.status===1){
                  setWait(false);
                  setOtpToken(res.data.otptoken)
                  setValotpDailog(true);
                  setSnack({
                    message: res.data.msg,
                    type: 'success',
                    open: true,
                    direction:"center"
                  });
              }else{
                setWait(false);
                setForgotMobileDailog(true);
                setSnack({
                  message: res.data.msg,
                  type: 'error',
                  open: true,
                  direction:"center"
                });
              }
              
            
         }); 
      }
    }


    const validateotp=(e)=>{
      e.preventDefault();
          setError(0);
      if(otp===''){
        setError(4)
        setSnack({
          message: "Enter Valid 10-digit Mobile number..",
          type: 'error',
          open: true,
          direction:"center"
        })
      }else if(newpass.length<5){
        setError(5)
        setSnack({
          message: "Password should have atleast 5 characters..",
          type: 'error',
          open: true,
          direction:"center"
        })
      }else if(newpass!==renewpass){
        setError(6)
        setSnack({
          message: "Password not Matching..",
          type: 'error',
          open: true,
          direction:"center"
        })
      }else{
        setValotpDailog(true);
        setWait(true);
        const formData= {};
        formData.mob=frnum;
        formData.otp=otp;
        formData.otptoken=otptoken;
        formData.pass=newpass;
        Axios.post("/new_apis/forgotpassword_validateotp",formData,{ 
          headers: { key: key },
         }).then((res) => {
             
              if(res.data.status===1){
                  setWait(false);
                   
                  setValotpDailog(false);
                  setSnack({
                    message: res.data.msg,
                    type: 'success',
                    open: true,
                    direction:"center"
                  });
              }else{
                setWait(false);
                setValotpDailog(true);
                setSnack({
                  message: res.data.msg,
                  type: 'error',
                  open: true,
                  direction:"center"
                });
              }
              
            
         }); 
      }
    }

    return (<div className="container">
        <Helmet>
                    <title>Login | ESAY - Engineering Sources Around You</title>
                    
                </Helmet>
                <Container maxWidth="sm">
                  
                <Dialog
        open={valotpDailog}
        onClose={()=>{
           
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
            style: {
              backgroundColor: theme.palette.secondary.main,
              boxShadow: 'none',
            },
          }}
        //style={{backgroundColor:theme.palette.secondary.main}}
      >
           
          <DialogTitle id="alert-dialog-title"> <Typography variant="h5" className="text-center"  style={{color:theme.palette.primary.main   }}>
                                      Update Password           
                        </Typography>
                        <hr style={{color:theme.palette.primary.main}}/>
                        </DialogTitle>
          <DialogContent  style={{overflow:"hidden"}}>
          <form onSubmit={validateotp}>
           <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={3}
            >
                <Grid item xs={12}   >
                <TextField className={classes.root}  label="OTP *"  variant="outlined" fullWidth
                    size="small"
                         
                        value={otp}
                        type="number"
                        error={error===4 && true }
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                            startAdornment:(<InputAdornment position="end">
                                <span style={{marginRight:"8px"}}>
                                <PhoneIcon color="primary"/>  
                                </span>
                             
                          </InputAdornment>),
                        }}
                        //InputLabelProps={{  shrink: true,focused:true }} 
                        onChange={(e) => {
                                    setOtp(e.target.value);
                        }}
                        
                    />
                </Grid>
                <Grid item xs={12}>
             
             <TextField className={classes.root}  label="New Password *"  variant="outlined" fullWidth
                     size="small"
                          
                         error={error===5 && true }
                         type={showPass ? 'text' : 'password'}
                         value={newpass}
                          
                         InputProps={{
                             style: {
                                 color: "#ffd24d"
                             },
                             startAdornment:(<InputAdornment position="end">
                                 <span style={{marginRight:"8px"}}>
                                 <LockIcon color="primary"/>  
                                 </span>
                              
                           </InputAdornment>),
                         
                             endAdornment:(<InputAdornment position="end">
                             <IconButton
                               aria-label="toggle password visibility"
                               onClick={()=>{
                                   setShowPass(!showPass);
                               }}
                               onMouseDown={()=>{
                                   setShowPass(!showPass);
                               }}
                               edge="end"
                             >
                               {showPass ? <Visibility color="primary"/> : <VisibilityOff  color="primary"/>}
                             </IconButton>
                           </InputAdornment>)
                         }}
                          
                         onChange={(e) => {
                                 setNewPass(e.target.value);
 
                         }}
                         
                     />
             </Grid>
             <Grid item xs={12}>
             
             <TextField className={classes.root}  label="Re-Enter New Password *"  variant="outlined" fullWidth
                     size="small"
                          
                         error={error===6 && true }
                         type={showPass ? 'text' : 'password'}
                         value={renewpass}
                          
                         InputProps={{
                             style: {
                                 color: "#ffd24d"
                             },
                             startAdornment:(<InputAdornment position="end">
                                 <span style={{marginRight:"8px"}}>
                                 <LockIcon color="primary"/>  
                                 </span>
                              
                           </InputAdornment>),
                         
                             endAdornment:(<InputAdornment position="end">
                             <IconButton
                               aria-label="toggle password visibility"
                               onClick={()=>{
                                   setShowPass(!showPass);
                               }}
                               onMouseDown={()=>{
                                   setShowPass(!showPass);
                               }}
                               edge="end"
                             >
                               {showPass ? <Visibility color="primary"/> : <VisibilityOff  color="primary"/>}
                             </IconButton>
                           </InputAdornment>)
                         }}
                          
                         onChange={(e) => {
                                 setReNewPass(e.target.value);
 
                         }}
                         
                     />
             </Grid>  
                 
                
                <Grid item xs={12}  className="text-center" >
                <Button variant="contained" type="submit"  style={{width:"60%"}} fullWidth={false} color="primary"  className="linkstyle"  >UPDATE PASSWORD</Button>
                </Grid>
                <Grid item xs={12}   >
                 <br/>
                </Grid>
            </Grid>
            </form>
            </DialogContent>
             
      </Dialog>
                <Dialog
        open={forgotMobileDailog}
        onClose={()=>{
          setForgotMobileDailog(false)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
            style: {
              backgroundColor: theme.palette.secondary.main,
              boxShadow: 'none',
            },
          }}
        //style={{backgroundColor:theme.palette.secondary.main}}
      >
           
          <DialogTitle id="alert-dialog-title"> <Typography variant="h5" className="text-center"  style={{color:theme.palette.primary.main   }}>
                                      Enter your Mobile Number           
                        </Typography>
                        <hr style={{color:theme.palette.primary.main}}/>
                        </DialogTitle>
          <DialogContent  style={{overflow:"hidden"}}>
          <form onSubmit={generateotp}>
           <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={3}
            >
                <Grid item xs={12}   >
                <TextField className={classes.root}  label="Mobile Number *"  variant="outlined" fullWidth
                    size="small"
                         
                        value={frnum}
                        type="number"
                        error={error===3 && true }
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                            startAdornment:(<InputAdornment position="end">
                                <span style={{marginRight:"8px"}}>
                                <PhoneIcon color="primary"/>  
                                </span>
                             
                          </InputAdornment>),
                        }}
                        //InputLabelProps={{  shrink: true,focused:true }} 
                        
                        onChange={(e) => {
                                 
                                    setFrNum(e.target.value);
                                
                                

                        }}
                        
                    />
                </Grid>
                 
                 
                 
                
                <Grid item xs={12}  className="text-center" >
                <Button variant="contained" type="submit"  style={{width:"60%"}} fullWidth={false} color="primary"  className="linkstyle"  >GENERATE OTP</Button>
                </Grid>
                <Grid item xs={12}   >
                 <br/>
                </Grid>
            </Grid>
            </form>
            </DialogContent>
             
      </Dialog>


        <Box boxShadow={3} p={3}  style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.secondary.main,marginTop:"10%",marginBottom:"10%",borderRadius:"10px"}}> 
        <form onSubmit={loginNow}>
        <Grid
        container
        direction="column"
        justify="center"
        alignItems="stretch"
        spacing={3}
        >
            <Grid item xs={12}>
                <Typography variant="h4" style={{color:theme.palette.primary.main}} className="text-center" gutterBottom>
                                LOGIN  
                </Typography>
                <hr style={{color:theme.palette.primary.main}}/>
            </Grid>    
            <Grid item xs={12}>
            <TextField className={classes.root}  label="Mobile Number *"  variant="outlined" fullWidth
                    size="small"
                         
                        value={num}
                        type="number"
                        error={error===1 && true }
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                            startAdornment:(<InputAdornment position="end">
                                <span style={{marginRight:"8px"}}>
                                <PhoneIcon color="primary"/>  
                                </span>
                             
                          </InputAdornment>),
                        }}
                        //InputLabelProps={{  shrink: true,focused:true }} 
                        
                        onChange={(e) => {
                                 
                                    setNum(e.target.value);
                                
                                

                        }}
                        
                    />
            </Grid>
            <Grid item xs={12}>
             
            <TextField className={classes.root}  label="Password *"  variant="outlined" fullWidth
                    size="small"
                         
                        error={error===2 && true }
                        type={showPass ? 'text' : 'password'}
                        value={pass}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                            startAdornment:(<InputAdornment position="end">
                                <span style={{marginRight:"8px"}}>
                                <LockIcon color="primary"/>  
                                </span>
                             
                          </InputAdornment>),
                        
                            endAdornment:(<InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={()=>{
                                  setShowPass(!showPass);
                              }}
                              onMouseDown={()=>{
                                  setShowPass(!showPass);
                              }}
                              edge="end"
                            >
                              {showPass ? <Visibility color="primary"/> : <VisibilityOff  color="primary"/>}
                            </IconButton>
                          </InputAdornment>)
                        }}
                         
                        onChange={(e) => {
                                setPass(e.target.value);

                        }}
                        
                    />
            </Grid>
             
            <Grid item xs={12} className="text-right" >
              <Typography variant="body1" style={{float:"right"}}>
              <Link onClick={()=>{
                   setForgotMobileDailog(true);
                 }} style={{color:theme.palette.primary.main}}  >
                        Forgot Password ?
                 </Link>
              </Typography>
                 
                </Grid>
            <BackDropLoad wait={wait}/>
            <Grid item xs={12}>
            <Button variant="contained" type="submit"  fullWidth={true} color="primary" disabled={wait} className="linkstyle"  >{wait?"Please Wait..":"Login"}</Button>
            </Grid>
        </Grid>
        </form>
        </Box>
        </Container>
    </div>);
}