
import {Grid,Box, Typography,useTheme,Button,Container,makeStyles,TextField,InputAdornment,IconButton} from '@material-ui/core';
import {useEffect,useState,useContext} from 'react';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LockIcon from '@material-ui/icons/Lock';
import {BackDropLoad} from "./LoadingBackDrop";
import { UserContext,SnackbarContext } from './userContext';
import {useNavigate} from "react-router-dom";

import Axios from "axios";

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiFormLabel-root":{
        color:"#ffd24d"
      },
       
      "& .Mui-error":{
        color:"red  ",
       // borderColor:"red !important"
      },
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"#2e2b2b",
        paddingLeft:"1%",
        paddingRight:"1%",
      },
      "& .Mui-disabled":{
        color:"#ffd24d",
      }
      
       
    },
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });


export const MyAccUpdatePassword =()=>{
    const theme = useTheme();
    const classes = useStyles();
    const history=useNavigate();
    const [curpass,setCurPass]=useState('');
    const [newpass,setNewPass]=useState('');
    const [newrepass,setNewRePass]=useState('');

    const [showPass,setShowPass] = useState(false);
    const [error,setError] = useState(0);

    const [wait,setWait] = useState(false);

     

    const {user, setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);

    const updatepass=(e)=>{
        e.preventDefault();
        setError(0);
        if(curpass==='' || curpass.length<3){
            setError(1);
            setSnack({
              message: 'Enter your Current Password',
              type: 'error',
              open: true,
              direction:"center"
            });
        }else if(newpass==='' || newpass.length<3 ){
            setError(2);
            setSnack({
              message: 'Enter your New Password',
              type: 'error',
              open: true,
              direction:"center"
            });
        }else if(newpass!==newrepass){
            setError(3);
            setSnack({
              message: 'Re-Enter your Current Password',
              type: 'error',
              open: true,
              direction:"center"
            });
        }else{
            const formData= {};
            setWait(true);
            var tkn=user.token;
            formData.uid=user.user_id;
            formData.curpass=curpass;
            formData.newpass=newpass;
            Axios.post("/new_apis/changepass",formData,{ 
                headers: { tkn: tkn },
               }).then((res) => {
                    
                    if(res.data.status===1){
                        setWait(false);
                        setCurPass("");
                        setNewPass("");
                        setNewRePass("");
                        setSnack({
                          message: res.data.msg,
                          type: 'success',
                          open: true,
                          direction:"center"
                        });
                        //console.log(res.data.msg);
                    }else if(res.data.status===2){
                      setWait(false);
                      
                      setSnack({
                        message: res.data.msg,
                        type: 'error',
                        open: true,
                        direction:"center"
                      });
                  }else if(res.data.status===3){
                        setWait(false);
                        history('/');
                        setSnack({
                          message: res.data.msg,
                          type: 'error',
                          open: true,
                          direction:"center"
                        });
                    }else if(res.data.status===4){
                        setUser(null);
                        setWait(false);
                        localStorage.removeItem("estkn");
                        history('/');
                        setSnack({
                          message: res.data.msg,
                          type: 'warning',
                          open: true,
                          direction:"center"
                        });

                    }
                    
                  
               }); 
        }
    }

    return (<div  >
        <Container maxWidth="md">
        <Box boxShadow={3} p={3}  style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.secondary.main ,borderRadius:"10px"}}> 
        <form onSubmit={updatepass}>
       <Grid
        container
        direction="row"
            justify="space-between"
            alignItems="center"
        spacing={3}
        >
            <Grid item xs={12}>
            <Typography variant="h4" className="text-center" gutterBottom style={{color:theme.palette.primary.main}}>
                 Update Password 
            </Typography>
            <hr style={{color:theme.palette.primary.main}}/>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
            <TextField className={classes.root}  label="Current Password *"  variant="outlined" fullWidth
                    size="small"
                         
                        error={error===1 && true }
                        type={showPass ? 'text' : 'password'}
                        value={curpass}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                             
                        
                            endAdornment:(<InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={()=>{
                                  setShowPass(!showPass);
                              }}
                              onMouseDown={()=>{
                                  setShowPass(!showPass);
                              }}
                              edge="end"
                            >
                              {showPass ? <Visibility color="primary"/> : <VisibilityOff  color="primary"/>}
                            </IconButton>
                          </InputAdornment>)
                        }}
                         
                        onChange={(e) => {
                                setCurPass(e.target.value);

                        }}
                        
                    />
            </Grid>
            <Grid  item xs={12} sm={12}  md={6} lg={6} xl={6} style={{padding:"0px"}} >

            </Grid>
            <Grid  item xs={12} sm={12}  md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label="New Password *"  variant="outlined" fullWidth
                    size="small"
                         
                        error={error===2 && true }
                        type={showPass ? 'text' : 'password'}
                        value={newpass}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                             
                        
                            endAdornment:(<InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={()=>{
                                  setShowPass(!showPass);
                              }}
                              onMouseDown={()=>{
                                  setShowPass(!showPass);
                              }}
                              edge="end"
                            >
                              {showPass ? <Visibility color="primary"/> : <VisibilityOff  color="primary"/>}
                            </IconButton>
                          </InputAdornment>)
                        }}
                         
                        onChange={(e) => {
                                setNewPass(e.target.value);

                        }}
                        
                    />
            </Grid>
            <Grid  item xs={12} sm={12}  md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label="Re-Enter New Password *"  variant="outlined" fullWidth
                    size="small"
                         
                        error={error===3 && true }
                        type={showPass ? 'text' : 'password'}
                        value={newrepass}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                             
                        
                            endAdornment:(<InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={()=>{
                                  setShowPass(!showPass);
                              }}
                              onMouseDown={()=>{
                                  setShowPass(!showPass);
                              }}
                              edge="end"
                            >
                              {showPass ? <Visibility color="primary"/> : <VisibilityOff  color="primary"/>}
                            </IconButton>
                          </InputAdornment>)
                        }}
                         
                        onChange={(e) => {
                                setNewRePass(e.target.value);

                        }}
                        
                    />
            </Grid>
            <Grid item xs={12}>
              
            </Grid>
            <BackDropLoad wait={wait}/>
            <Grid item xs={12} className="text-center">
            <Button variant="contained" type="submit" style={{width:"60%"}} fullWidth={false} color="primary"  className="linkstyle" disabled={wait} >{wait?"Please Wait..":"Update"}</Button>
            </Grid>
    </Grid>
    </form>
    </Box>
    </Container>
    </div>);

}