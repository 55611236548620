import { useEffect, useState, useContext } from "react";

import { Grid, Hidden, useTheme, Card, CardContent, ButtonBase, Button, makeStyles } from '@material-ui/core';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockIcon from '@material-ui/icons/Lock';
import ListIcon from '@material-ui/icons/List';
import EditIcon from '@material-ui/icons/Edit';
import PeopleIcon from '@material-ui/icons/People';
import HistoryIcon from '@material-ui/icons/History';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { BackDropLoad } from "./LoadingBackDrop";
import { Link, Route, Navigate, useNavigate, Routes, useLocation } from 'react-router-dom';
import { UserContext, SnackbarContext } from './userContext';
import Axios from "axios";

import { MyProfile } from './MyAccProfile';
import { MyAccUpgradeProfile } from './MyAccUpgradeProfile';
import { MyAccUpdatePassword } from './MyAccUpdatePassword';
import { MyAccSubscription } from './MyAccSubscription';
import { MyAccMyProds } from './MyAccMyProds';
import { MyAccAddPro } from './MyAccAddProd';
import { MyAccEditPro } from './MyAccEditProd';
import { MyAccUpdateSupDetails } from './MyAccUpdateSuppDetails';
import { MyAccUpdateEngDetails } from './MyAccUpdateEngDetails';
import { MyAccUpdateWfDetails } from './MyAccUpdateWfDetails';
import { MyAccUpdateRUserDetails } from './MyAccUpdateRUserDetails';
import { MyAccContactHistory } from './MyAccContactHistory';
import { MyAccEngInfoBookmarks } from './MyAccEngInfoBookmarks';
import { MyAccContactedCustomers } from './MyAccContactCustomers';
import { MyAccContactedCustomersEngWf } from './MyAccContactCustomersEngWF';
import { CompleteProfile} from './MyAccCompleteProfile'
import {RequestCategoryChange} from './MyAccRequestCategoryChange'

import { Helmet } from 'react-helmet-async';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";

const useStyles = makeStyles({
    nounderline: {
        "&:hover": { textDecoration: "none" },
    },
    cardshadow: {
        "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
    },
});
export const MyAccountList = () => {
    const location = useLocation();
    const theme = useTheme();
    const history = useNavigate();
    const classes = useStyles();
    const { user, setUser } = useContext(UserContext);
    const { snack, setSnack } = useContext(SnackbarContext);

    const [wait, setWait] = useState(false);

    const logout = () => {

        let tkn = localStorage.getItem('estkn');
        setWait(true);
        Axios.get("/new_apis/logout", {
            headers: { tkn: user.token },
        }).then((res) => {
            if (res.data.status) {
                setUser(null);
                localStorage.removeItem("estkn");
                setWait(false);
                setSnack({
                    message: "Logged out Successfully..",
                    type: 'success',
                    open: true,
                    direction: "left"
                });
                history('/');
            } else {
                setUser(null);
                setWait(false);
                localStorage.removeItem("estkn");
                setSnack({
                    message: "Logged out Successfully..",
                    type: 'success',
                    open: true,
                    direction: "left"
                });
                history('/');
            }
        });
    }

    return (<div>

        <div className="container">
            <br />

            <br /><br />
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
                spacing={2}
            >

                <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
                    <BackDropLoad wait={wait} />
                    {/* <Hidden smDown> */}
                        <Grid
                            container
                            direction="row"
            justify="flex-start"
            alignItems="flex-start"
                            // direction="column"
                            // justify="center"
                            // alignItems="stretch"
                            spacing={3}
                        >


                            {user && (<Grid item md={12}>
                                <Button variant="contained" component={Link} fullWidth={true} color={location.pathname === "/myaccount/profile"?"white":"primary"} to={"/myaccount/profile"} className="linkstyle" style={{ justifyContent: "flex-start" }}><AccountCircleIcon />&nbsp;&nbsp;My Profile</Button>
                            </Grid>)}
                            {(user && user.profile_done === '0') && (<Grid item md={12}>
                                <Button variant="contained" component={Link} fullWidth={true} color={location.pathname === "/myaccount/complete_profile"?"white":"primary"} to={"/myaccount/complete_profile"} className="linkstyle" style={{ justifyContent: "flex-start" }}><CheckCircleIcon />&nbsp;&nbsp;Finish Your Profile Setup</Button>
                            </Grid>)}
                            {(user && user.user_role === '1' && user.profile_done==='1') && (<Grid item md={12}>
                                <Button variant="contained" component={Link} fullWidth={true} color={location.pathname === "/myaccount/upgradeprofile"?"white":"primary"} to={"/myaccount/upgradeprofile"} className="linkstyle" style={{ justifyContent: "flex-start" }}><ArrowUpwardIcon />&nbsp;&nbsp;Upgrade Profile</Button>
                            </Grid>)}
                            {(user && user.user_role === '2' && user.category_ids !== '6' && user.category_ids !== '7'  && user.profile_done==='1') && (<Grid item md={12}>
                                <Button variant="contained" component={Link} fullWidth={true} color={(location.pathname === "/myaccount/my_prods_servs" || location.pathname === "/myaccount/add_prods_servs" || location.pathname.startsWith("/myaccount/edit_prods_servs/"))?"white":"primary"} to={"/myaccount/my_prods_servs"} className="linkstyle" style={{ justifyContent: "flex-start" }}><ListIcon />&nbsp;&nbsp;My Products / Services</Button>
                            </Grid>)}
                            {(user && user.user_role === '2' && user.category_ids !== '6' && user.category_ids !== '7') && (<Grid item md={12}>
                                <Button variant="contained" component={Link} fullWidth={true} color={location.pathname === "/myaccount/subscription"?"white":"primary"} to={"/myaccount/subscription"} className="linkstyle" style={{ justifyContent: "flex-start" }}><CardMembershipIcon />&nbsp;&nbsp;My Subscription</Button>
                            </Grid>)}
                            {user && (<Grid item md={12}>
                                <Button variant="contained" component={Link} fullWidth={true} color={location.pathname === "/myaccount/my_bookmarks"?"white":"primary"} to={"/myaccount/my_bookmarks"} className="linkstyle" style={{ justifyContent: "flex-start" }}><BookmarkIcon />&nbsp;&nbsp;ENG.. INFO Bookmarks</Button>
                            </Grid>)}
                            {(user && user.user_role === '2') && (<Grid item md={12}>
                                <Button variant="contained" component={Link} fullWidth={true} color={location.pathname === "/myaccount/contacted_customers"?"white":"primary"} to={"/myaccount/contacted_customers"} className="linkstyle" style={{ justifyContent: "flex-start" }}><PeopleIcon />
                                    &nbsp;&nbsp;Contacted {(user.category_ids === '6' || user.category_ids === '7') ? "Users" : "Customers"}</Button>
                            </Grid>)}
                            {user && (<Grid item md={12}>
                                <Button variant="contained" component={Link} fullWidth={true} color={location.pathname === "/myaccount/contact_history"?"white":"primary"} to={"/myaccount/contact_history"} className="linkstyle" style={{ justifyContent: "flex-start" }}><HistoryIcon />&nbsp;&nbsp;Contacted History</Button>
                            </Grid>)}
                            {(user && user.user_role === '2' && user.profile_done==='1') && (<Grid item md={12}>
                                <Button variant="contained" component={Link} fullWidth={true} color={location.pathname === "/myaccount/request_category_change"?"white":"primary"} to={"/myaccount/request_category_change"} className="linkstyle" style={{ justifyContent: "flex-start" }}><ChangeCircleIcon />&nbsp;&nbsp;Request Category Change</Button>
                            </Grid>)}
                            {(user  && user.profile_done==='1') && (<Grid item md={12}>
                                <Button variant="contained" component={Link} fullWidth={true} color={location.pathname === "/myaccount/updatedetails"?"white":"primary"} to={"/myaccount/updatedetails"} className="linkstyle" style={{ justifyContent: "flex-start" }}><EditIcon />&nbsp;&nbsp;Update Details</Button>
                            </Grid>)}
                            {user && (<Grid item md={12}>
                                <Button variant="contained" component={Link} fullWidth={true} color={location.pathname === "/myaccount/updatepassword"?"white":"primary"} to={"/myaccount/updatepassword"} className="linkstyle" style={{ justifyContent: "flex-start" }}><LockIcon />&nbsp;&nbsp;Update Password</Button>
                            </Grid>)}
                            {user && (<Grid item md={12}>
                                <Button variant="contained" fullWidth={true} color="primary" className="linkstyle" style={{ justifyContent: "flex-start" }} onClick={logout} ><ExitToAppIcon />&nbsp;&nbsp;LOGOUT</Button>
                            </Grid>)}

                        </Grid>
                    {/* </Hidden> */}
                </Grid>
                <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                <Routes>
                <Route path="complete_profile"
                        element={ (user && user.profile_done==='0')?<><Helmet><title>Complete Your Profile | ESAY - Engineering Sources Around You</title></Helmet><CompleteProfile /></>:<Navigate to="/" />} />
                    <Route path="subscription"
                        element={ (user && user.user_role === '2' && user.category_ids !== '6' && user.category_ids !== '7'  && user.profile_done==='1')?<><Helmet><title>My Subscription | ESAY - Engineering Sources Around You</title></Helmet><MyAccSubscription /></>:<Navigate to="/" />} />
                    <Route path="profile" element={user ? <><Helmet><title>My Profile | ESAY - Engineering Sources Around You</title></Helmet><MyProfile /></> : <Navigate to="/" />} />
                    <Route path="contact_history" element={user ? <><Helmet><title>My Contact History | ESAY - Engineering Sources Around You</title></Helmet><MyAccContactHistory /></> : <Navigate to="/" />} />
                    <Route path="my_bookmarks" element={user ? <><Helmet><title>My Eng.. Info Bookmarks | ESAY - Engineering Sources Around You</title></Helmet><MyAccEngInfoBookmarks /></> : <Navigate to="/" />} />
                    {/* <Route path="contacted_customers"
      element={()=>{
                if(user && user.user_role==='2' && user.category_ids!=='6' && user.category_ids!=='7'){
                    return (<><Helmet><title>My Contacted Customers | ESAY - Engineering Sources Around You</title></Helmet><MyAccContactedCustomers   /></>);
                }else if(user && user.user_role==='2' && (user.category_ids==='6' || user.category_ids==='7')){
                    return (<><Helmet><title>My Contacted Users | ESAY - Engineering Sources Around You</title></Helmet><MyAccContactedCustomersEngWf   /></>);
                }   
                else{
                    return (<Navigate to="/" />)
                }
        
         
       
    }}   />  */}
                    <Route
                        path="contacted_customers"
                        element={
                            user ? (
                                (() => {
                                    if (
                                        user.user_role === '2' &&
                                        user.category_ids !== '6' &&
                                        user.category_ids !== '7'
                                    ) {
                                        return (
                                            <>
                                                <Helmet>
                                                    <title>
                                                        My Contacted Customers | ESAY - Engineering Sources Around You
                                                    </title>
                                                </Helmet>
                                                <MyAccContactedCustomers />
                                            </>
                                        );
                                    } else if (
                                        user.user_role === '2' &&
                                        (user.category_ids === '6' || user.category_ids === '7')
                                    ) {
                                        return (
                                            <>
                                                <Helmet>
                                                    <title>
                                                        My Contacted Users | ESAY - Engineering Sources Around You
                                                    </title>
                                                </Helmet>
                                                <MyAccContactedCustomersEngWf />
                                            </>
                                        );
                                    } else {
                                        return <Navigate to="/" />;
                                    }
                                })()
                            ) : (
                                <Navigate to="/" />
                            )
                        }
                    />

                    <Route exact path="updatepassword"
                        element={user ? <><Helmet><title>Update Password | ESAY - Engineering Sources Around You</title></Helmet><MyAccUpdatePassword /></> : <Navigate to="/" />} />
                    <Route exact path="upgradeprofile"
                        element={(user && user.user_role === '1'  && user.profile_done==='1') ? <><Helmet><title>Upgrade My Profile | ESAY - Engineering Sources Around You</title></Helmet><MyAccUpgradeProfile /></> : <Navigate to="/" />} />
                    {/* <Route exact path="updatedetails"
      render={()=>{
                if(user && user.user_role==='2' && user.category_ids!=='6' && user.category_ids!=='7' ){
                    return (<><Helmet><title>View / Edit My Details | ESAY - Engineering Sources Around You</title></Helmet><MyAccUpdateSupDetails   /></>);
                }else if(user && user.user_role==='2'&&  user.category_ids==='7' ){
                    return (<><Helmet><title>View / Edit My Details | ESAY - Engineering Sources Around You</title></Helmet><MyAccUpdateEngDetails  /></>);
                }else if(user && user.user_role==='2'&&  user.category_ids==='6' ){
                      return (<><Helmet><title>View / Edit My Details | ESAY - Engineering Sources Around You</title></Helmet><MyAccUpdateWfDetails  /></>);
                }else if(user && user.user_role==='1'  ){
                  return (<><Helmet><title>View / Edit My Details | ESAY - Engineering Sources Around You</title></Helmet><MyAccUpdateRUserDetails  /></>);
                }else{
                    return (<Navigate to="/" />)
                }
        
         
       
    }}   />    */}
                    <Route path="updatedetails"  element={
                            (user  && user.profile_done==='1') ? (
                                (() => {
                                    if (
                                        user.user_role === '2' &&
                                        user.category_ids !== '6' &&
                                        user.category_ids !== '7'
                                    ) {
                                        return (
                                            <>
                                                <Helmet>
                                                    <title>
                                                        View / Edit My Details | ESAY - Engineering Sources Around You
                                                    </title>
                                                </Helmet>
                                                <MyAccUpdateSupDetails />
                                            </>
                                        );
                                    } else if (user.user_role === '2' && user.category_ids === '7') {
                                        return (
                                            <>
                                                <Helmet>
                                                    <title>
                                                        View / Edit My Details | ESAY - Engineering Sources Around You
                                                    </title>
                                                </Helmet>
                                                <MyAccUpdateEngDetails />
                                            </>
                                        );
                                    } else if (user.user_role === '2' && user.category_ids === '6') {
                                        return (
                                            <>
                                                <Helmet>
                                                    <title>
                                                        View / Edit My Details | ESAY - Engineering Sources Around You
                                                    </title>
                                                </Helmet>
                                                <MyAccUpdateWfDetails />
                                            </>
                                        );
                                    } else if (user.user_role === '1') {
                                        return (
                                            <>
                                                <Helmet>
                                                    <title>
                                                        View / Edit My Details | ESAY - Engineering Sources Around You
                                                    </title>
                                                </Helmet>
                                                <MyAccUpdateRUserDetails />
                                            </>
                                        );
                                    } else {
                                        return <Navigate to="/" />;
                                    }
                                })()
                            ) : (
                                <Navigate to="/" />
                            )
                        }
                    />

                    <Route path="my_prods_servs"
                        element={(user && user.user_role === '2' && user.category_ids !== '6' && user.category_ids !== '7'  && user.profile_done==='1') ? <><Helmet><title>My Products / Services | ESAY - Engineering Sources Around You</title></Helmet><MyAccMyProds /></> : (<Navigate to="/" />)
                        } />

                    <Route path="add_prods_servs"
                        element={(user && user.user_role === '2' && user.category_ids !== '6' && user.category_ids !== '7'  && user.profile_done==='1') ? <><Helmet><title>Add Product / Service Details | ESAY - Engineering Sources Around You</title></Helmet><MyAccAddPro /></> : (<Navigate to="/" />)} />
                    <Route path="edit_prods_servs/:id"
                        element={(user && user.user_role === '2' && user.category_ids !== '6' && user.category_ids !== '7'  && user.profile_done==='1') ? <><Helmet><title>Edit Product / Service Details | ESAY - Engineering Sources Around You</title></Helmet><MyAccEditPro /></> : (<Navigate to="/" />)} />


                    <Route exact path="request_category_change"
                        element={(user && user.user_role === '2'  && user.profile_done==='1') ? <><Helmet><title>Request Category Change | ESAY - Engineering Sources Around You</title></Helmet><RequestCategoryChange /></> : <Navigate to="/" />} />
</Routes>
                </Grid>
            </Grid>
            <br /><br /><br />
        </div>
    </div>);
}