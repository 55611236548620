import {useEffect, useState} from "react";
import Axios from "axios";
import {Loading} from "./Loading";
import {Grid, Typography,useTheme,Card,CardContent,ButtonBase,CardMedia,makeStyles, Container} from '@material-ui/core';
import parse from 'html-react-parser';
import {Link} from 'react-router-dom';
import { Helmet  } from 'react-helmet-async';

const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";


function convertToSlug(Text)
{
    return Text.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');
}


const useStyles = makeStyles({
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 #ffd24d, 0 6px 20px 0 #ffd24d" },
      //"&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.19), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
    },
  });
export const Trends=()=>{
    const theme = useTheme();
    const classes = useStyles();
    const [loading,setLoading]=useState(true);
    const [trends,setTrends]=useState();
    const [metadata,setMetadata] = useState();

    useEffect(()=>{
      window.scrollTo(0, 0);
      Axios.post("/new_apis/getsinglemetadata", {'type':"trends"},{
        headers: { key: key },
       }).then((res) => {
          
         if(res.data.status){
           
          setMetadata(res.data.metadata[0])
         } else{
            setMetadata({'seo_title':"Home - ESAY | Contacts and Info in the World of Construction",
            'seo_desc':"Get all the information and contacts of various types of contractors, consultants, machinery/material suppliers, engineers, and workforce in the construction sector",
            "seo_keywords":"best  contractors in Telangana, schedule of rates, Tenders in India,   construction architect engineers and labor near me, latest trends in construction sector, top contractors and consultants in Hyderabad,   interior designers in Hyderabad, building construction materials and machinery suppliers, soil metal materials testing labs around me"});
         }
          
       });
        Axios.get("/new_apis/get-trends",{ 
          headers: { key: key },
         }).then((res) => {
            setTrends(res.data.trends);
             
            setLoading(false);
         }); 
                   
      },[]);

    return (<div>
         {metadata && <Helmet>
  <title>{metadata.seo_title}</title>
    <meta name="description" content={metadata.seo_desc} />
    <meta name="keywords" content={metadata.seo_keywords}></meta>
  </Helmet>}
        {loading?<Loading/>:<div className="container">
            <br/><br/>
            {/* <h5 class="divider gradient" contenteditable>Gradient Heading</h5> */}
            {/* <h2 class="divider donotcross" contenteditable>Do Not Cross</h2> */}
        <Typography variant="h4" className="divider line glow pageHeading" gutterBottom style={{color:theme.palette.primary.main}}>
           Trends 
     </Typography>
     <Container maxWidth="sm">
     <Typography variant="body2" className=" text-center pageSubtitle" gutterBottom style={{color:"white",margin:"10px",padding:"10px",  border:"1px solid #ffd24d",borderRadius:"100px",backgroundColor:theme.palette.secondary.main}}>
     <span style={{color:theme.palette.primary.main }}>Construction Trends:</span> Discover the Latest Innovations in the Industry.
      
      </Typography>
      </Container>
      <br/><br/>
      <Grid
  container
  direction="row"
  justify="flex-start"
  alignItems="flex-start"
  spacing={4}
>  
{trends.map((item ) => (
 <Grid item xl={3} lg={3} md={4} sm={6} xs={12} className="text-center" >
      <ButtonBase className={classes.nounderline}  component={Link} to={"/trends-list/trend/"+convertToSlug(item.blog_title)+"/"+item.blog_id}>
     <Card style={{width: 220, height:300,border:"2px groove #ffd24d" }} className={classes.cardshadow}>
                                
                                <CardMedia
                                style={{height: 140}}
                                image={item.blog_image}
                                title={item.blog_title} 
                                />
                                <CardContent>
                                <Typography variant="body2" style={{color:theme.palette.secondary.main}} gutterBottom>
                                {item.blog_title} 
                                  </Typography>
                                 
                                <Typography variant="body2" color="textSecondary" component="p" style={{textAlign:"left"}}>
                                 
                                
                                 Date: {item.added_date}
                                </Typography>
                                </CardContent>
                             
                            
                            </Card>
                            </ButtonBase>
 
</Grid>
))}
 </Grid>
<br/><br/><br/>
        </div>}
    </div>);
}