import {useEffect,useState} from "react";
import {Loading} from './Loading';
import Axios from "axios";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Container ,useTheme,Box,Typography,Card,CardMedia,CardActions} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {withStyles} from '@material-ui/core/styles';
import { Helmet  } from 'react-helmet-async';

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "& .MuiFormLabel-root":{
      color:"#ffd24d"
    },
     
    "& .MuiInputLabel-shrink":{
      
      transform: "translate(14px, -9px) scale(0.90)",
      transformOrigin: "top left",
      backgroundColor:"black",
      paddingLeft:"1%",
      paddingRight:"1%",
    },
    nounderline: {
        "&:hover": { textDecoration:"none"},
      },
      cardshadow: {
        "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
         
      }, 
     
  }
});
const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";

export const AboutUs=()=>{
  
  const theme = useTheme();
  const classes = useStyles();
  const [metadata,setMetadata] = useState();

  useEffect(()=>{
    window.scrollTo(0, 0);
    Axios.post("/new_apis/getsinglemetadata", {'type':"about"},{
      headers: { key: key },
     }).then((res) => {
        
       if(res.data.status){
         
        setMetadata(res.data.metadata[0])
       } else{
          setMetadata({'seo_title':"Home - ESAY | Contacts and Info in the World of Construction",
          'seo_desc':"Get all the information and contacts of various types of contractors, consultants, machinery/material suppliers, engineers, and workforce in the construction sector",
          "seo_keywords":"best  contractors in Telangana, schedule of rates, Tenders in India,   construction architect engineers and labor near me, latest trends in construction sector, top contractors and consultants in Hyderabad,   interior designers in Hyderabad, building construction materials and machinery suppliers, soil metal materials testing labs around me"});
       }
        
     });
  },[])

  const handleContextMenu = (e) => {
    e.preventDefault(); // Disable right-click
  };

  const handleCopy = (e) => {
    e.preventDefault(); // Disable copy
  };

return  (  <div>{metadata && <Helmet>
  <title>{metadata.seo_title}</title>
    <meta name="description" content={metadata.seo_desc} />
    <meta name="keywords" content={metadata.seo_keywords}></meta>
  </Helmet>}<Container maxWidth="md">
<Box boxShadow={3} p={3}  style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.secondary.main,marginTop:"5%",marginBottom:"5%",borderRadius:"10px" }}> 
<Typography variant="h5" className="text-center"  style={{color:theme.palette.primary.main   }}>
About ESAY
     
                        </Typography>
                        <hr style={{color:theme.palette.primary.main}}/>
                        <br/>
                        <Grid
                          container
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          spacing={1}
                          style={{ userSelect: "none", // CSS to disable text selection
                            MozUserSelect: "none", // For Firefox
                            WebkitUserSelect: "none", // For Safari
                            msUserSelect: "none", // For older IE
                            }}
                            onContextMenu={handleContextMenu}
                            onCopy={handleCopy}
                        >
                          <Grid item xs={12} >
                          <Typography variant="body2"   gutterBottom  style={{color:"white"   }}>
                              We are a group of Engineers at ESAY with over 35+ years of experience in both Govt. and Private Construction sector. The ESAY portal has an ever expanding database of the contacts in the construction sector that can be instrumental for your project and the latest construction related information would be available on our portal.
                             </Typography>
                          </Grid>
                          <Grid item xs={12} ></Grid>
                          <Grid item xs={12}>
                          <Typography variant="h5"  className="text-center" gutterBottom style={{color:theme.palette.primary.main   }}>
                            Our Services: 
     
                            </Typography>
                            <Typography variant="h6" gutterBottom  style={{color:"white"   }}>
                           <span style={{color:theme.palette.primary.main   }}> Contacts of:</span>
                           </Typography>
                            <ul >
                                <li style={{color:theme.palette.primary.main,marginTop:"1%"   }}><Typography variant="body2" >Consultants: 
                                <span style={{color:"white"   }}> The contacts of the various consultants for a range of sectors such as Architectural, Structural designing, Interior Designing, Ground water survey and many more will be provided.
                                </span>
                    </Typography> 
                    </li>
                    <li style={{color:theme.palette.primary.main,marginTop:"1%"   }}><Typography variant="body2" >Contractors: 
                                <span style={{color:"white"   }}> The contacts of various Contractors such as Civil, Electrical, Fabrication, Road Laying, Painting, Plumbing, Centering and many more will be provided.
                                </span>
                    </Typography> 
                    </li>
                    <li style={{color:theme.palette.primary.main ,marginTop:"1%"  }}><Typography variant="body2" >Suppliers for Material and Machinery: 
                                <span style={{color:"white"   }}> Our regular updated database provides the contacts with the list of suppliers and sources of various material /machinery that are essential for any construction related project in your area or city.


                                </span>
                    </Typography> 
                    </li>
                    <li style={{color:theme.palette.primary.main ,marginTop:"1%"  }}><Typography variant="body2" >Testing Laboratories: 
                                <span style={{color:"white"   }}> The construction sector demands the testing of materials especially for Govt. related projects and other major projects in private sector. The contact information regarding quality control labs and the standards that have to be met are available.
                                </span>
                    </Typography> 
                    </li>
                    <li style={{color:theme.palette.primary.main ,marginTop:"1%"  }}><Typography variant="body2" >Engineers: 
                                <span style={{color:"white"   }}> The contacts of Experienced / Retired Engineers and Freshers in Civil, Electrical, and Mechanical domain are also provided, so that they can be engaged by the registered Contractors or builders for their projects.
                                </span>
                    </Typography> 
                    </li>
                    <li style={{color:theme.palette.primary.main ,marginTop:"1%"  }}><Typography variant="body2" >Workforce: 
                                <span style={{color:"white"   }}> We also provide contacts of Masons and Manpower Suppliers such as plumbers, electricians, painters, carpenters and many more.
                                </span>
                    </Typography> 
                    </li>
                            </ul>
                            <br/>
                            <Typography variant="h6" gutterBottom  style={{color:"white"   }}>
                           <span style={{color:theme.palette.primary.main   }}> Engineering Info:</span>
                           </Typography>
                           <Typography variant="body2"   gutterBottom  style={{color:"white"   }}>
                              This is regarding the Construction sector such as Registration procedure for the Contractors, Various Norms, Govt. Rates & Standard Data, grade of material to be used, related technical information, Quality Control aspects, Various Standards set in the Construction sector and many more will be available and are updated at regular intervals.
                             </Typography>
                             <Typography variant="body2"   gutterBottom  style={{color:"white"   }}>
                              Fellow Engineers, Consultants, Builders & Contractors will have a ready refer regarding various industry standards that need to be followed, coupled with other aspects such as latest government norms and rules set for Construction sector as a whole.
                             </Typography>
                             <br/>
                            {/* <Typography variant="h6" gutterBottom  style={{color:"white"   }}>
                           <span style={{color:theme.palette.primary.main   }}>Real Estates:</span>
                           </Typography>
                           <Typography variant="body2"   gutterBottom  style={{color:"white"   }}>
                              ESAY provides you the list of Properties for Sale in Apartment, Villas & Independent Homes, Commercial Spaces, Agricultiral Lands, Plots, Warehouses, Commercial Spaces, etc in your Desired Locations. 


                             </Typography>
                             <br/> */}
                            <Typography variant="h6" gutterBottom  style={{color:"white"   }}>
                           <span style={{color:theme.palette.primary.main   }}>Trends:</span>
                           </Typography>
                           
                          <Typography variant="body2"   gutterBottom  style={{color:"white"   }}>
                              Information about the latest practices and trends in Construction are available.


                             </Typography>

                             <br/>
                            <Typography variant="h6" gutterBottom  style={{color:"white"   }}>
                           <span style={{color:theme.palette.primary.main   }}>Conversions:</span>
                           </Typography>
                           
                          <Typography variant="body2"   gutterBottom  style={{color:"white"   }}>
                              ESAY provides a conversion calculator for all the units in length, area, weight, volume, Wt/unit length, stress, velocity, discharge, moment, power, pressure, and temperature which are helpful for field engineers as well as engineering students.


                             </Typography>
                             <br/>
                            <Typography variant="h6" gutterBottom  style={{color:"white"   }}>
                           <span style={{color:theme.palette.primary.main   }}>Careers:</span>
                           </Typography>
                           
                          <Typography variant="body2"   gutterBottom  style={{color:"white"   }}>
                              Find all the Latest Job Openings in Both Public and Private sectors.


                             </Typography>
                           </Grid>
                         <Grid item xs={12}></Grid>
                          
                          <Grid item xs={12}>
                          <Typography variant="body2" className="text-center"   style={{color:theme.palette.primary.main}}>
                              ****
                            </Typography>
                          </Grid>

                        </Grid>
  </Box>
  </Container>
      
     
</div> );
}

 

 

 

 