
import "./App.css";
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider, } from '@material-ui/styles';
import { CssBaseline, useScrollTrigger, makeStyles, Zoom, Fab } from '@material-ui/core';
import { Home } from './components/Home';
import { LoadingLogo } from './components/LoadingLogo';
import { Terms } from './components/Terms';
import { Privacy } from './components/Privacy';
import { MyAccountList } from './components/MyAccount';
import { Navbar } from './components/navbar';
import { Trends } from './components/Trends';
import { AboutUs } from './components/Aboutus';
import { RealEstates } from './components/RealEstates';
import { ProdServ } from './components/ProdServ';
import { SingleRealEstate } from './components/SingleRealEstate';
import { EngWf } from './components/EngWf';
import { SearchResults } from './components/SearchResults';
import { TrendsList } from './components/TrendsList';
import { EngInfo } from './components/EngInfo';
import { Login } from './components/Login';
import { Signup } from './components/signup';
import { ContactNow } from './components/ContactUs';
import {NewSignup} from './components/NewSignUp'
import { Careers } from './components/Careers';
import { SingleCareer } from './components/SingleCareer';
import { Ackdg } from './components/Acknowledgments';
import { FAQs } from './components/FAQs';
import { Guide } from './components/Guide';
import { Conversions } from './components/Conversions';
import { ConversionsList } from './components/ConversionsList';
import { Footer } from './components/Footer';
import {QandA} from './components/QandA'
import {AwardsAndNews} from './components/AwardsAndNews'
import {SingleAwardAndNew} from './components/SingleAwardAndNews'
import {TutorialVideos} from './components/TutorialVideos'


import { UserContext, SnackbarContext, EngInfoContext } from "./components/userContext";
import { useState, useMemo, useEffect } from "react";
import Axios from "axios";
import { HelmetProvider } from 'react-helmet-async';
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import Alert from "@material-ui/lab/Alert";
import { Helmet } from 'react-helmet-async';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from "react-router-dom";


const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";

const theme = createTheme({
  palette: {
    primary: {
      light: "#ffd24d",
      main: "#ffd24d",
      dark: "#ffd24d",
      contrastText: "#000000",
    },

    secondary: {
      light: "#666666",
      main: "#2e2b2b",
      dark: "#666666",
      contrastText: "#ffffff",
    },
    white: {
      light: "white",
      main: "white",
      dark: "white",
      contrastText: "white",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(7),
  },
}));

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {

    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {

      anchor.scrollIntoView({ top: 0, behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

function App(props) {
  const [user, setUser] = useState(null);

  
  const [snack, setSnack] = useState({
    message: '',
    type: 'success',
    open: false,
    direction: "center"
  });

  const [engInfoList, setEngInfoList] = useState(null);

  const [loading, setLoading] = useState(true);
  const value = useMemo(() => ({ user, setUser }), [user, setUser]);



  const snackValue = useMemo(() => ({ snack, setSnack }), [snack, setSnack]);

  const [cats, setCats] = useState();
  const [metadata, setMetadata] = useState();
  const [subCats, setSubCats] = useState();


  const checkDevice = () => {
    // Check the platform using the userAgent
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      // If the device is Android
      window.location.href = "https://play.google.com/store/apps/details?id=com.esay.arawinzsoft";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // If the device is iOS
      window.location.href = "https://apps.apple.com/us/app/esay/id1490576735";
    } else {
      // console.log("Platform not supported for redirection.");
    }
  };


  useEffect(() => {
    checkDevice();
    getdata();
  }, []);



  const getdata = async () => {
    let tkn = localStorage?.getItem('estkn');
    await getLocation();


    await Axios.get("/new_apis/get-cats-data", {
      headers: { key: key },
    }).then((res) => {
      setCats(res?.data?.cats);

      setSubCats(res?.data?.subcats);

    });

    await Axios.get("/new_apis/get-new-session", {
      headers: { tkn: tkn },
    }).then((res) => {
      if (res?.data?.status) {
        setUser(res.data.data);
        getEngInfodata(res?.data?.data?.token, res.data.data?.user_id);
        setLoading(false);
      } else {
        setUser(res.data.data);
        setLoading(false);
        localStorage.removeItem("estkn");
      }
    });
  }



  const getEngInfodata = async (tkn, uid) => {
    const formData = {};
    var tkn = tkn;
    formData.user_id = uid;
    Axios.post("/new_apis/get_bookmarks", formData, {
      headers: { tkn: tkn },
    }).then((res) => {
      if (res.data.status == 1) {

        var arr = [];
        for (var i = 0; i < res.data.data.length; i++) {
          arr.push(res.data.data[i].id);

        }
        setEngInfoList(arr);

      } else {

      }

    });
  }

  const enginfoValue = useMemo(() => ({ engInfoList, setEngInfoList }), [engInfoList, setEngInfoList]);

  const getLocation = async () => {

    await navigator.geolocation.getCurrentPosition(async function (position) {
      if (position !== undefined) {


        let lat = position.coords.latitude;
        let long = position.coords.longitude;

        const data = await Axios.get("/new_apis/get-current-location", {
          params: {
            lat: lat, lag: long
          },
          headers: { key: key },
        }
        );
        /*
         var address=data.data.results[1].address_components;
         var address_text=[];
          
         for(var i=0;i<address.length-1;i++){
            address_text.push(address[i].long_name)
     
         }
          */
        localStorage.setItem('loc', data.data.loc);
      } else {
        localStorage.setItem('loc', "Hyderabad, Telangana, India");
      }
    }, function (err) {
      localStorage.setItem('loc', "Hyderabad, Telangana, India");
    })

  }

  const handleContextMenu = (e) => {
    e.preventDefault(); // Disable right-click
  };

  const handleCopy = (e) => {
    e.preventDefault(); // Disable copy
  };



  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="page-container" style={{ userSelect: "none", // CSS to disable text selection
          MozUserSelect: "none", // For Firefox
          WebkitUserSelect: "none", // For Safari
          msUserSelect: "none", // For older IE
          }}
          onContextMenu={handleContextMenu}
          onCopy={handleCopy}>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: snack.direction,
            }}
            autoHideDuration={3000}
            open={snack.open}
            onClose={() => {
              setSnack(prevdata => {
                return {
                  ...prevdata,
                  open: false
                }
              })
            }}
            TransitionComponent={Slide}
          >
            <Alert variant="filled" onClose={() => {
              setSnack(prevdata => {
                return {
                  ...prevdata,
                  open: false
                }
              })
            }} severity={snack.type}>
              {snack.message}
            </Alert>
          </Snackbar>
          {loading ? <LoadingLogo /> : (<div className="page-container"
          
          // style={{ backgroundImage: "url('/esay_bg.png')" }}
          
          >
            <SnackbarContext.Provider value={{ snack, setSnack }}>
              <UserContext.Provider value={value} >
                <EngInfoContext.Provider value={enginfoValue} >
                  <Router>
                    <Navbar cats={cats} subcats={subCats} />
                    <div class="content-wrap">

                      <Routes>

                        <Route path="/" element={<Home />} />


                        <Route path="/acknowledgments" element={<Ackdg />} />
                        <Route path="/terms" element={<Terms />} />
                        <Route path="/about" element={<AboutUs />} />
                        <Route path="/community-qa" element={<QandA />} />
                        <Route path="/contact-now" element={<ContactNow />} />
                        <Route path="/privacy_policy" element={<Privacy />} />
                        <Route path="/awards_news" element={<AwardsAndNews />} />
                        <Route path="/esay_tutorials_walkthroughs" element={<TutorialVideos />} />
                        <Route path="/faqs" element={<FAQs />} />

                        <Route path="/login" element={user ? <Navigate to="/" /> : <><Helmet>
                          <title>Login | ESAY - Engineering Sources Around You</title>

                        </Helmet><Login /></>} />

                        <Route path="/signup" element={user ? <Navigate to="/" /> : <NewSignup />} />
                        <Route path="/myaccount/*" element={user?<><Helmet><title>My Account | ESAY - Engineering Sources Around You</title></Helmet><MyAccountList /></>:<Navigate to="/" />} />
                    
                        {/* <Route path="/real-estates/:locn?/:catn?/:type?/:area?/:price?/:rent?" element={<RealEstates />} /> */}


                        <Route path="/trends" element={<Trends />} />
                        <Route path="/eng-info/*" element={<EngInfo />} />
                        <Route path="/careers" element={<Careers />} />
                        <Route path="/trends-list/*" element={<TrendsList />} />
                        <Route path="/conversions" element={<Conversions />} />
                        <Route path="/conversions-list/*" element={<ConversionsList />} />
                        <Route path="/searchresults/:locn?/:catn?/:subcat?/:searchn?" element={<SearchResults />} />

                        <Route path="/psdetails/:slug?/:id?"  element={<ProdServ />} />
                        {/* <Route path="/real-estate-detail/:slug?/:id?" element={<SingleRealEstate />} /> */}

                        <Route path="/job-details/:slug?/:id?" element={<SingleCareer />} />
                        <Route path="/ewdetails/:slug?/:id?" element={<EngWf />} />
                        <Route path="/article-details/:slug?/:id?" element={<SingleAwardAndNew />} />
                        <Route path="*" element={<Navigate to="/" />} />

                      </Routes>
                    </div>
                    <Footer />
                    <ScrollTop {...props}>
                      <Fab color="secondary" aria-label="scroll back to top" style={{ border: "1px solid #ffd24d" }}>
                        <KeyboardArrowUpIcon style={{ color: "#ffd24d" }} />
                      </Fab>
                    </ScrollTop>
                  </Router>
                </EngInfoContext.Provider>
              </UserContext.Provider>
            </SnackbarContext.Provider>
          </div>)}
        </div>
      </ThemeProvider>
    </HelmetProvider>);
}

export default App;
