import  Carousel   from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {Typography,useTheme,Grid, ButtonBase,Card,CardMedia,CardActions,makeStyles} from '@material-ui/core';
//import {useEffect} from "react";
import {Link} from 'react-router-dom'; 

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1275 },
    items: 5
  },
  tablet: {
    breakpoint: { max: 1275, min: 997 },
    items: 4
  },
  mobile: {
    breakpoint: { max: 997, min: 0 },
    items: 1
  }
  };
  const useStyles = makeStyles({
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 #ffd24d, 0 4px 8px 0 #ffd24d" },
      //"&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.19), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });
export const Recommended = (props) =>{
    const theme = useTheme();
    const classes = useStyles();
    const recdata=props.recs;
    return (<div className="text-center">
        <Typography variant="h5" className="divider line glow" gutterBottom style={{color:theme.palette.primary.main}}>
          RECOMMENDED
     </Typography>
     <br/><br/>
     <Carousel responsive={responsive}
     animation="slide"
     timeout={1200}
     autoPlay={true}
     showIndicators={true}
     showArrows={false}
     
      
     infinite={true}
     stopOnHover
     swipeable
     showThumbs={false}
     transitionTime={1200}
     statusFormatter={(current, total) => {
        
       return ``;
     }}
     
      autoPlaySpeed={2000}
      >
     {recdata.map((item, i) => (
          
           <ButtonBase className={classes.nounderline}
                                component={Link} to={"/psdetails/"+item?.product_slug+"/"+item?.product_id}
                            >
                            <Card className={classes.cardshadow} style={{width: "200px", height:"340px",border:"2px groove #ffd24d",backgroundColor:theme.palette.primary.main}}>
                            
                                <CardMedia
                                style={{height: 200}}
                                image={item.product_image}
                                title={item.product_name} 
                                />
                                 
                             
                            <CardActions>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                                //spacing={1}
                                >
                                    <Grid item xs={12}>
                                    <Typography variant="body2" className="text-center" style={{color:theme.palette.secondary.main,width:"100%",overflow:"hidden",fontWeight:"bold"}}>
                                    {(item.product_name).substring(0, 30)}
                                    {(item.product_name).length>30&&"..."}
                                   </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2" className="text-center" style={{color:theme.palette.secondary.main,width:"100%",textDecoration:"underline"}}>
                                    {(item.sub_category_name).substring(0, 30)}
                                    {(item.sub_category_name).length>30&&"..."}
                                    
                                   </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2" className="text-center" style={{color:theme.palette.secondary.main,width:"100%"}}>
                                   
                                    
                                   {(item.product_location_text).substring(0, 30)}
                                    {(item.product_location_text).length>30&&"..."}
                                   </Typography>
                                    </Grid>
                                </Grid>
                                 
                                  
                            </CardActions>
                            </Card>
                            </ButtonBase>
         
      ))}
     </Carousel>
     <br/>
    </div>);
}