import {useEffect,useState} from "react";
//import Loading from './Loading';
//import Axios from "axios";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Container,Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
 
export const ConvWeight=()=>{
     
     
    useEffect(()=>{
      window.scrollTo(0, 0);
    },[]);

return  (  <div>

<Grid
  container
  direction="column"
  justify="space-evenly"
  alignItems="center"
  spacing={3}
>
<Grid item xs={12}>
         
   </Grid>
<Grid item xs={12}>
        <Typography variant="h5" component="h4" className="text-center" color="primary" gutterBottom>
          WEIGHT 
        </Typography>
   </Grid>
  <Grid item xs={12}>
  <Weight />
  </Grid>
  <Grid item xs={12}>
   
  </Grid>
  <Grid item xs={12}>
  <SimpleCard/>
  </Grid>
  </Grid>
</div>

 );
}

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiFormLabel-root":{
        color:"#ffd24d"
      },
       
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"rgb(41, 38, 38)",
        paddingLeft:"1%",
        paddingRight:"1%",
      } 
       
    }
  
  });
  
  export default function SimpleCard() {
    const classes = useStyles();
    
  
    return (
        <Box  m={3}>
      <Card className={classes.root}  style={{ border:"2px groove #ffd24d"}}>
        <CardContent>
          
          <Typography variant="h6"  className="text-center">
           UNITS OF WEIGHT 
          </Typography>
          <br></br>
          <Typography variant="body2" >
          1 ounce = 28.35 grams; 1 pound = 453.59 grams The measurement of weights in English 
          systems is usually made in Ounces and pounds, with one ounce equal to 28.35 grams and one pound 
          equal to 453.9 grams. 1 Metric ton = 1.10 ton The other popular measurement of the weight is a Metric 
          ton in the English system with one metric ton equal to 1.10 times a ton (Metric system) that means a metric
           ton is 0.10 more than one ton. 1 lbf = 4.45 Newton The lbf is a measurement of force in the English system,
            with one lbf equal to 4.45 N. The Newton is the derived unit of force (Metric system) required to accelerate 
            one kilogram of mass at a rate of one metre per second. 
          The various other measurements of weight include maund, tola and seer.
          </Typography>
        </CardContent>
        
      </Card>
      </Box>
    );
  }
    
 


const Weight=()=>{
    const classes = useStyles();
  const [kip,setKip] = useState();
  const [kg,setKg] = useState();
  const [mt,setMT] = useState();
  const [pound,setPound] = useState();
  const [ton,setTon] = useState();
  const [ounce,setOunce] = useState();
  const [tola,setTola] = useState();
  const [gm,setGm] = useState();
  const [seer,setSeer] = useState();
  const [knew,setKnew] = useState();
  const [quint,setQuint] = useState();
  const [newton,setNewton] = useState();

  return (
      <Container maxWidth="lg">
      <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      spacing={4}
    >
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <TextField  className={classes.root}  size="small"  label="kip" variant="outlined" shrink="true" fullWidth 
              type="number"
              InputProps={{
                  style: {
                      color: "#ffd24d"
                  }
              }}
              InputLabelProps={{  shrink: true,focused:true }}
              value={kip}
              onChange={(e) => {
                var val = parseFloat(e.target.value);
                var val1 = parseFloat(e.target.value);
                setKip(val1);
                val=(val1*453.59237)
                setKg(val);
                val=val1*0.4563 
                setMT(val);
                val=val1*1000
                setPound(val);
                val=val1*0.5
                setTon(val);
                val=val1*16000.0
                setOunce(val);
                val=val1*38888.85
                setTola(val);
                val=val1*453592.37
                setGm(val);
                val=val1*486.11074852241 
                setSeer(val);
                val=val1*4.4482216 
                setKnew(val);
                val=val1*4.54
                setQuint(val);
                val=val1*4448.2216 
                setNewton(val);
                  
              }}
               
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField  className={classes.root}  size="small"  label="kilograms" variant="outlined" fullWidth
        InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          type="number"
          value={kg}
          onChange={(e) => {
                var val = parseFloat(e.target.value);
                var val1 = parseFloat(e.target.value);
                setKg(val1);
                val=(val1*0.0022)
                setKip(val);
                val=val1/1000
                setMT(val);
                val=val1*2.204
                setPound(val);
                val=val1*0.001102
                setTon(val);
                val=val1*35.274
                setOunce(val);
                val=val1*85.73522
                setTola(val);
                val=val1*1000.0
                setGm(val);
                val=val1*1.07169075
                setSeer(val);
                val=val1*0.00981
                setKnew(val);
                val=val1*0.01
                setQuint(val);
                val=val1*9.8
                setNewton(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField  className={classes.root}  size="small"  label="MT" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={mt}
          onChange={(e) => {
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setMT(val1);
            val=(val1*2.204622)
            setKip(val);
            val=val1*1000.0
            setKg(val);
            val=val1*2204.622621
            setPound(val);
            val=val1*1.10231
            setTon(val);
            val=val1*35273.962
            setOunce(val);
            val=val1*85735.260233
            setTola(val);
            val=val1*1000000.0
            setGm(val);
            val=val1*1071.6907529
            setSeer(val);
            val=val1*9.8066500
            setKnew(val);
            val=val1*10.0
            setQuint(val);
            val=val1*9806.6500286389
            setNewton(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField  className={classes.root}  size="small"  label="Pounds" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={pound}
          onChange={(e) => {
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setPound(val1);
            val=(val1*0.001)
            setKip(val);
            val=val1*0.4535 
            setKg(val);
            val=val1*0.00045
            setMT(val);
            val=val1*0.000456
            setTon(val);
            val=val*16.0
            setOunce(val);
            val=val1*38.88885988179
            setTola(val);
            val=val1*453.59237
            setGm(val);
            val=val1*0.48611074852241
            setSeer(val);
            val=val1*0.0044482216
            setKnew(val);
            val=val1*0.0045
            setQuint(val);
            val=val1*4.45
            setNewton(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField  className={classes.root}  size="small"  label="ton" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={ton}
          onChange={(e) => {
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setTon(val1);
            val=(val1*2.24)
            setKip(val);
            val=val1*1000 
            setKg(val);
            val=val1/1.102
            setMT(val);
            val=val1*2204.6
            setPound(val);
            val=val1*35274
            setOunce(val);
            val=val1*85735.2
            setTola(val);
            val=val1*1000000
            setGm(val);
            val=val1*1071.6
            setSeer(val);
            val=val1*9.964016384
            setKnew(val);
            val=val1*10
            setQuint(val);
            val=val1*9806.65
            setNewton(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField  className={classes.root}  size="small"  label="Ounce" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={ounce}
          onChange={(e) => {
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setOunce(val1);
            val=(val1*0.000062)
            setKip(val);
            val=val1/35.274
            setKg(val);
            val=val1*0.000028
            setMT(val);
            val=val1/16
            setPound(val);
            val=val1*0.000027
            setTon(val);
            val=val1/0.41143
            setTola(val);
            val=val1*28.35
            setGm(val);
            val=val1*0.03038
            setSeer(val);
            val=val1*0.000278
            setKnew(val);
            val=val1*0.00028
            setQuint(val);
            val=val1*0.00028
            setNewton(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField  className={classes.root}  size="small"  label="Tola" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={tola}
          onChange={(e) => {
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setTola(val1);
            val=(val1*0.0000257)
            setKip(val);
            val=val1*0.0116638125
            setKg(val);
            val=val1*0.0000117
            setMT(val);
            val=val1*0.0257143
            setPound(val);
            val=val1*0.0000117
            setTon(val);
            val=val1*0.4114289
            setOunce(val);
            val=val1*11.6638125
            setGm(val);
            val=val1*0.0125
            setSeer(val);
            val=val1*0.0001143829271871
            setKnew(val);
            val=val1*0.0001166
            setQuint(val);
            val=val1*0.114382971871626
            setNewton(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField  className={classes.root}  size="small"  label="Gm" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={gm}
          onChange={(e) => {
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setGm(val1);
            val=(val1*0.0000022046)
            setKip(val);
            val=val1*0.001
            setKg(val);
            val=val1*0.000001
            setMT(val);
            val=val1*0.002204622621
            setPound(val);
            val=val1*0.000001
            setTon(val);
            val=val1*0.0352739
            setOunce(val);
            val=val1*0.08573526
            setTola(val);
            val=val1*0.00107169
            setSeer(val);
            val=val1*0.00000980665
            setKnew(val);
            val=val1*0.00001
            setQuint(val);
            val=val1*0.00980665
            setNewton(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField  className={classes.root}  size="small"  label="Seer" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={seer}
          onChange={(e) => {
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setSeer(val1);
            val=(val1*0.002057144)
            setKip(val);
            val=val1*0.933105
            setKg(val);
            val=val1*0.0009331
            setMT(val);
            val=val1*2.057144391
            setPound(val);
            val=val1*0.000933105
            setTon(val);
            val=val1*32.91431026
            setOunce(val);
            val=val1*80.0
            setTola(val);
            val=val1*933.1
            setGm(val);
            val=val1*0.0091506341
            setKnew(val);
            val=val1*0.019062410
            setQuint(val);
            val=val1*9.1506341749
            setNewton(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField  className={classes.root}  size="small"  label="Kilo Newton" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={knew}
          onChange={(e) => {
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setKnew(val1);
            val=(val1*0.224808948)
            setKip(val);
            val=val1*101.97162
            setKg(val);
            val=val1*0.10197621
            setMT(val);
            val=val1*224.80894387096
            setPound(val);
            val=val1*0.1003611356566
            setTon(val);
            val=val1*3596.943079
            setOunce(val);
            val=val1*8742.56346
            setTola(val);
            val=val1*101971.621
            setGm(val);
            val=val1*109.28204328559
            setSeer(val);
            val=val1*1.0197
            setQuint(val);
            val=val1*1000.0
            setNewton(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField  className={classes.root}  size="small"  label="Quintal" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={quint}
          onChange={(e) => {
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setQuint(val1);
            val=(val1*0.22046)
            setKip(val);
            val=val1*100 
            setKg(val);
            val=val1*0.1
            setMT(val);
            val=val1*220.462
            setPound(val);
            val=val1*0.1
            setTon(val);
            val=val1*3527.4
            setOunce(val);
            val=val1*8573.5
            setTola(val);
            val=val1*100000
            setGm(val);
            val=val1*52.45926
            setSeer(val);
            val=val1*0.98067
            setKnew(val);
            val=val1*980.67
            setNewton(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField  className={classes.root}  size="small"  label="Newton" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={newton}
          onChange={(e) => {
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setNewton(val1);
            val=(val1*0.000224808943)
            setKip(val);
            val=val1*0.101971621
            setKg(val);
            val=val1*0.00011240444
            setMT(val);
            val=val1*0.2248089
            setPound(val);
            val=val1*0.0001124
            setTon(val);
            val=val1*3.5969431
            setOunce(val);
            val=val1*8.74256346
            setTola(val);
            val=val1*101.971621
            setGm(val);
            val=val1*0.109282043
            setSeer(val);
            val=val1*0.001
            setKnew(val);
            val=val1*0.001019
            setQuint(val);
              }
          }
           
        />
        </Grid>
  </Grid></Container>);
}