
import {useState,useEffect, useContext, React} from 'react';
import {Grid,Typography,useTheme,InputAdornment,IconButton, makeStyles,Box,TextField,Button,FormControl,Select,InputLabel,MenuItem, Container,Switch,Checkbox,Dialog,DialogTitle,DialogContent} from "@material-ui/core";
import {useNavigate,Link} from "react-router-dom";
import {Loading} from "./Loading";
import { Helmet  } from 'react-helmet-async';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LockIcon from '@material-ui/icons/Lock';
import PhoneIcon from '@material-ui/icons/Phone';
import { UserContext,SnackbarContext,EngInfoContext } from './userContext';
import {BackDropLoad} from "./LoadingBackDrop";
import { AutoCompLoc } from './AutoCompLoc.jsx';
import parse from 'html-react-parser';
import Axios from "axios";
 

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiFormLabel-root":{
        color:"#ffd24d"
      },
       
      "& .Mui-error":{
        color:"red  ",
       // borderColor:"red !important"
      },
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"#2e2b2b",
        paddingLeft:"1%",
        paddingRight:"1%",
      },
      
       
    },
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });


const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";
    

export const CompleteProfile=()=>{
    const history=useNavigate();
    const theme = useTheme();
    const classes = useStyles();

    const [loading,setLoading]=useState(true);

    const {user, setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);
    const {engInfoList,setEngInfoList} = useContext(EngInfoContext);

    const [metadata,setMetadata] = useState();

    const [cats,setCats]=useState();
    const [subcats,setSubcats]=useState();

    const [cat,setCat]=useState('0');
    const [subcat,setSubcat]=useState('0');

    const [supname,setSupname]=useState('');
    const [fname,setFname]=useState('');
    const [lname,setLname]=useState('');
    const [loc,setLoc] = useState("");
    const [email,setEmail]=useState('');
    const [num,setNum] = useState("");
    const [pass,setPass] = useState("");
    const [repass,setRePass] = useState("");
    const [wait,setWait] = useState(false);
    const [showPass,setShowPass] = useState(false);
    const [exp,setExp]=useState('');
    const [qual,setQual]=useState('');

    const [courses,setCourses]=useState('');
    
    const [showhide,setShowhide]=useState('1');
     
    const [error,setError] = useState(0);

    
    const [catDetails,setCatDetails]=useState('');
  


    useEffect(()=>{
      window.scrollTo(0, 0);
       
        getData();
     },[])
 
     const getData=()=>{
           
          
         Axios.get("/new_apis/getsignupdata",{ 
          headers: { key: "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#" }
         }).then((res) => {
                 if(res.data.status===1){
                     setCats(res.data.cats);
                     
                     setSubcats(res.data.subcats); 
                      
                     setLoading(false);
                 } 
                 
               
            }); 
     }


     const getCatDetails=(category)=>{
        setWait(true);
        const formData= {};
        formData.cat=category;
        Axios.post("/new_apis/cat_details",formData 
            ).then(  (res) => {

              if(res.data.status===true){
                setCatDetails(res.data.msg);
                setWait(false);
              }else{
                setWait(false);
              } 
        });

    }


     const updateLoc = (loc)=>{
        setLoc(loc);
     }

      



    const finishProfile=(e)=>{
        e.preventDefault();
          setError(0);
           
          if(cat==='0' ){
              setError(1);  
              setSnack({
                message: "Please Select your Category..",
                type: 'error',
                open: true,
                direction:"center"
              });
          }else if((cat==='6' || cat==='7') && subcat==='0'){
              setError(2);  
              setSnack({
                message: "Please Select your Sub-Category..",
                type: 'error',
                open: true,
                direction:"center"
              });
          }else if((cat!=='6' && cat!=='7' && cat!=='a') && supname===''){
            setError(3);  
            setSnack({
              message: "Enter Reg. Supplier / Service provider name..",
              type: 'error',
              open: true,
              direction:"center"
            });
          } else if((cat!=='6' && cat!=='7' && cat!=='a') && !email.includes('@')){
            setError(4);  
            setSnack({
              message: "Enter valid Email-ID..",
              type: 'error',
              open: true,
              direction:"center"
            });
          } else if(loc===''){
            setError(8);
            setSnack({
              message: 'Please select your Location from the Suggestions..',
              type: 'error',
              open: true,
              direction:"center"
            });
        } else{
           setWait(true);
           const formData= {};
           var tkn=user.token;
           formData.uid=user.user_id;
           formData.cat=cat;
           formData.supname=supname;
           formData.subcat=subcat;
           formData.email=email;
           formData.loc=loc;
           formData.qual=qual;
           formData.exp=exp;
           formData.showhide=showhide;
           formData.courses=courses;

          Axios.post("/new_apis/complete_profile",formData,{ 
              headers: { tkn: tkn  },
             }).then(  (res) => {
                 
                  if(res.data.status===1){
                      setWait(false); 
                      setSnack({
                        message: res.data.msg,
                        type: 'success',
                        open: true,
                        direction:"left"
                      });
                       
                      
                      setUser(res.data.user_data)
                      history('/');
                  }else if(res.data.status===2){
                    setWait(false);
                    setSnack({
                      message: res.data.msg,
                      type: 'error',
                      open: true,
                      direction:"center"
                    });
                  }
                  
                
             }); 
        } 
    }


    // const finishProfile=(e)=>{
    //   e.preventDefault();
    //       setError(0);
    //       if(otp===''){
    //         setError(11);
    //         setSnack({
    //           message: 'Please Enter your One Time Password...',
    //           type: 'error',
    //           open: true,
    //           direction:"center"
    //         });
    //       }else{
    //         setShowOtpBox(false);
    //         setWait(true);
    //         const formData= {};
           
    //        formData.cat=cat;
    //        formData.subcat=subcat;
    //        formData.fname=fname;
    //        formData.lname=lname;
    //        formData.mob=num;
    //        formData.pass=pass;
    //        formData.supname=supname;
    //        formData.email=email;
    //        formData.otp=otp;
    //        formData.loc=loc;
    //        formData.qual=qual;
    //        formData.exp=exp;
    //        formData.showhide=showhide;
    //        formData.courses=courses;
    //        formData.otptoken=otptoken;
    //       Axios.post("/new_apis/validateotp",formData,{ 
    //           headers: { key: "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#" },
    //          }).then(  (res) => {
                 
    //               if(res.data.status===1){
    //                 setWait(false);
    //                 setUser(res.data.user_data);
                     
    //                 localStorage.setItem('estkn',res.data.token);
    //                 getEngInfodata(res.data.token,res.data.user_data.user_id);
    //                 setSnack({
    //                   message: res.data.msg,
    //                   type: 'success',
    //                   open: true,
    //                   direction:"left"
    //                 });
    //                 history('/myaccount/profile');
                      

                       
    //               }else if(res.data.status===2){
    //                 setWait(false);
    //                 setShowOtpBox(true);
    //                 setSnack({
    //                   message: res.data.msg,
    //                   type: 'error',
    //                   open: true,
    //                   direction:"center"
    //                 });
    //               }
                  
                
    //          }); 
         
    //       }
    // }

    return (<div className="container">
         
                <Container maxWidth="md">
        <Box boxShadow={3} p={3}  style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.secondary.main, marginBottom:"10%",borderRadius:"10px"}}> 
        { loading ?  <Loading   />:(
        <div>
           
        <form onSubmit={finishProfile}>
        <Grid
        container
        direction="row"
            justify="space-between"
            alignItems="center"
        spacing={3}
        >
            <Grid item xs={12}>
                <Typography variant="h4" style={{color:theme.palette.primary.main}} className="text-center" gutterBottom>
                                COMPLETE YOUR PROFILE  
                </Typography>
                <hr style={{color:theme.palette.primary.main}}/>
            </Grid>    
            
            <Grid item xs={12}  sm={12} md={6} lg={6} xl={6}>
             <FormControl variant="outlined" className={classes.root}
                size="small"
                error={error===1&&true}
                fullWidth  >
                 <InputLabel >
                   Category *
                 </InputLabel>
                 <Select
                  // native
                   value={cat}
                   onChange={(e) => {
                     setCat(e.target.value);
                     setSubcat("0");
                     if(e.target.value!=='6' && e.target.value!=='7' && e.target.value!=='a'  ){
                      getCatDetails(e.target.value);
                     }
                   }}
                   style={{color:theme.palette.primary.main}}
                    error={error === 1 ? true : false}
                   label="Category"
                    
                 >
                   
                   <MenuItem key="0"  value="0">Please select a Category</MenuItem>
                   <MenuItem key="a"  value="a" style={{color:"white"}}>Regular User</MenuItem>
                   {cats &&
                     cats.map((cat) => {
                       return (
                         <MenuItem
                           key={cat.category_id}
                           value={cat.category_id}
                           color={"primary"}
                           style={{color:cat.category_id==='6'||cat.category_id==='7'?"white":theme.palette.primary.main}}
                         >
                           {cat.category_name}
                         </MenuItem>
                       );
                     })}
                 </Select>
               </FormControl>
             </Grid>
             {(cat!=='7' && cat!=='6' && cat!=='a' && catDetails!='') && (<Grid item xs={12}  sm={12} md={6} lg={6} xl={6}>
              <p style={{color:'#ffd24d'}}>{parse(catDetails)}</p>
             </Grid>)}
             {(cat==='7' || cat==='6') && (<Grid item xs={12}  sm={12} md={6} lg={6} xl={6}>
             <FormControl variant="outlined" className={classes.root}
                size="small"
                error={error===2&&true}
                fullWidth  >
                 <InputLabel >
                   Sub-Category *
                 </InputLabel>
                 <Select
                  // native
                   value={subcat}
                   onChange={(e) => {
                     setSubcat(e.target.value);
                     
                   }}
                   style={{color:theme.palette.primary.main}}
                    error={error === 1 ? true : false}
                   label="Category"
                    
                 >
                   
                   <MenuItem key="0"  value="0">Please select a Sub-Category</MenuItem>
                   {subcats &&
                     subcats.map((subcat) => {
                         if(cat===subcat.category_ids){
                            return (
                                <MenuItem
                                  key={subcat.sub_category_id}
                                  value={subcat.sub_category_id}
                                  color="primary"
                                >
                                  {subcat.sub_category_name}
                                </MenuItem>
                              );
                         }
                       
                     })}
                 </Select>
               </FormControl>
             </Grid>)}
             {(cat!=='7' && cat!=='6' && cat!=='0' && cat!=='a') && (<Grid item xs={12}  sm={12} md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label="Service Provider / Supplier Name *"  variant="outlined" fullWidth
                    size="small"
                         
                        error={error===3 && true }
                        type={ 'text'  }
                        value={supname}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                             
                        
                             
                        }}
                         
                        onChange={(e) => {
                                setSupname(e.target.value);

                        }}
                        
                    />
            </Grid>)}
             
             
            <Grid item xs={12}  sm={12} md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label={(cat!=='7' && cat!=='6' && cat!=='0' && cat!=='a')?"Email-ID *":"Email-ID (Optional)"} variant="outlined" fullWidth
                    size="small"
                         
                        error={error===4 && true }
                        type={ 'text' }
                        value={email}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                            
                        }}
                         
                        onChange={(e) => {
                                setEmail(e.target.value);

                        }}
                        
                    />
            </Grid>
             
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
       <AutoCompLoc error={error==8?true:false} loc={loc} setloc={updateLoc}/>
        
      </Grid>
            {(cat==='7'  ) && (<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label="Experience in Years (Optional)"  variant="outlined" fullWidth
                    size="small"
                         
                        
                        type={  'text' }
                        value={exp}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                             
                        
                             
                        }}
                         
                        onChange={(e) => {
                                setExp(e.target.value);

                        }}
                        
                    />
            </Grid>)}
            {(cat==='7'  ) && (<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label="Qualification (Optional)"  variant="outlined" fullWidth
                    size="small"
                         
                        
                        type={  'text' }
                        value={qual}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                             
                        
                             
                        }}
                         
                        onChange={(e) => {
                                setQual(e.target.value);

                        }}
                        
                    />
            </Grid>)}
            {( cat==='6'  ) && (<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label="Courses (Optional)"  variant="outlined" fullWidth
                    size="small"
                         
                        
                        type={  'text' }
                        value={courses}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                             
                        
                             
                        }}
                         
                        onChange={(e) => {
                            setCourses(e.target.value);

                        }}
                        
                    />
            </Grid>)}
            {(cat==='7' || cat==='6' ) && (<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <span style={{color:theme.palette.primary.main}}>Show / Hide your Profile from Public: </span>
            <br/>
            <Switch
              checked={showhide==='1'?true:false}
              onChange={()=>{
                  if(showhide==='1'){
                    setShowhide('0');
                  }else{
                    setShowhide('1'); 
                  }
                  
              }}
              color="primary"
              inputProps={{'aria-label':"primary checkbox"}}
              >

              </Switch><span style={{color:theme.palette.primary.main}}>Status: {showhide==='1'?(<span   >Show</span>):(<span   >Hide</span>)}</span>
            </Grid>)}
             
            <BackDropLoad wait={wait}/>
            <Grid item xs={12} className="text-center">
            <Button variant="contained" type="submit"  style={{width:"60%"}} color="primary" disabled={wait} className="linkstyle"  >{wait?"Please Wait..":"Finish Profile Setup"}</Button>
            </Grid>
        </Grid>
        </form></div>)}
        </Box>
        </Container>
    </div>);
}