import {useEffect,useState,useContext} from 'react';
import Axios from "axios";
import {Loading} from "./Loading";
import { Helmet  } from 'react-helmet-async';
import { UserContext } from './userContext';
import { ContactForm } from './ContactFormDailog';
import { LoginForm } from './LoginFormDailog';
import { BackDropLoad } from './LoadingBackDrop';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from "react-responsive-carousel";
import {Recommended} from "./Recommended";
import {Link,useNavigate, useParams} from 'react-router-dom';
import {Grid,Typography,useTheme,ButtonBase,Card,CardMedia,CardActions,makeStyles,Box,TextField,Button,FormControl,Select,InputLabel,MenuItem} from "@material-ui/core";
import parse from 'html-react-parser';
import "yet-another-react-lightbox/styles.css";
import Lightbox from "yet-another-react-lightbox";
 
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    PinterestShareButton,
    PinterestIcon,
   // RedditShareButton,
   // RedditIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
   // ViberShareButton,
    WhatsappShareButton,
    WhatsappIcon,XIcon,
    RedditShareButton,
    RedditIcon
    
  } from "react-share";

 

const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";
export const ProdServ=(props)=>{
    const id=useParams()
    const url=window.location.href;
    const theme = useTheme();
    const history=useNavigate();
    const [loading,setLoading]=useState(true);
    const [data,setData]=useState();
    const [show,setShow]=useState();
    const [images,setImages]=useState();
    const [recommended,setRecommended]=useState();

    const {user, setUser} = useContext(UserContext);

    const [showContactForm,setShowContactForm]=useState(false);
    const [showLoginForm,setShowLoginForm]=useState(false);
    const [wait,setWait]=useState(false);

    const [tooSlideImages, setTooSlideImages] = useState([]);

    const [open1, setOpen1] = useState(false);



    const [closeOnPullDown, setCloseOnPullDown] =  useState(true);
    const [closeOnBackdropClick, setCloseOnBackdropClick] = useState(true);

    const closeContactForm=()=>{
        setShowContactForm(false);
    }


    const openContactForm=()=>{
        
        setShowContactForm(true);
    }

    const closeLoginForm=()=>{
        setShowLoginForm(false);
    }


    const showbackgroundLoad=()=>{
        setWait(true);
    }

    const hidebackgroundLoad=()=>{
        setWait(false);
    }

    useEffect(()=>{
        
        setLoading(true);
        getData();
    },[id?.id]);


    const getData=()=>{
        window.scrollTo(0, 0);
        // var id = props.id;
        const formData= {};
        if(id?.id===0 || isNaN(id?.id)){
            history('/');
        }else{
           formData.id=id?.id;
           
        }
        Axios.post("/new_apis/get-single-prodserv",formData,{ 
            headers: { key: key },
           }).then((res) => {
               if(res.data.status){
                  setRecommended(res.data.recommended);
                  
                  setData(res.data.data[0]);
                  setImages(res.data.images);
                  setShow(res.data.show);
                  setLoading(false);
               }else  {
                  history('/');
               }
              
           });
    }


    const handleImageClick = (index) => {
        // Create a new array starting with the clicked image
        const newSlideImages = [
          ...images.slice(index),
          ...images.slice(0, index)
        ];
        setTooSlideImages(newSlideImages);
        setOpen1(true);
      };
    

 


    return (<div className="container">{loading?<Loading />:<div>
        {data && (<Helmet>
                    <title>{data.product_name} - {data.sub_category_name} | ESAY - Engineering Sources Around You</title>
                     
                </Helmet>)}
                <br/><br/>
        <Box  style={{ border:"5px solid #ffd24d",backgroundColor:"white",borderRadius:"5px"}}   p={3}>
        {data && (<Grid
            container
            direction="row"
  justify="flex-start"
  alignItems="flex-start"
            spacing={1}
            >
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                 
                 <Box p={3}  >
            {images && (<Carousel
      animation="slide"
      timeout={1200}
      autoPlay={false}
      showIndicators={false}
      showArrows={true}
      centerMode={true}
      centerSlidePercentage={100}
      infiniteLoop
      
      stopOnHover
      swipeable
      showThumbs={true}
      dynamicHeight={true}
      transitionTime={1200}
      statusFormatter={(current, total) => {
       // setActive(current - 1);
        return ``;
      }}
    >
      {images.map((item, i) => (
        <div  
        // className="MuiPaper-elevation1"
        style={{
           //margin:"10px",
                                          //border:"2px groove black",
                                          padding:"5px",
                                          
                                          borderRadius:"5px",
                                          cursor:'zoom-in'
                                    //    maxHeight:"300px"
                                        //filter: "blur(1px)",
                                        // display: "flex",
                                        // //width: "300px", 
                                        // alignItems: "center",
                                         
                                        // flexWrap:"wrap"
        }}

        onClick={() => {
          
            handleImageClick(i);
            
          }}
        >
        
              <img src={item.image_url} style={{borderRadius:"5px" ,maxHeight:"500px", height: "100%", objectFit: "contain"   }}   alt={data.sub_category_name}   />
    </div>
      ))}
    </Carousel>)}
    </Box>
            </Grid>    
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            
            {open1 && (
            
                      <Lightbox
                     
                        open={open1}
                        close={() => setOpen1(false)}
                        slides={tooSlideImages.map((src, index) => ({
                          src: src.image_url,
                          index,
                        }))}
                        controller={{ closeOnPullDown, closeOnBackdropClick }}
                        render={{
                            buttonPrev: tooSlideImages.length>1 ? undefined : () => null,
                            buttonNext: tooSlideImages.length>1 ? undefined : () => null,
                          }}
                      />
 
                    )}
            <Box   >
                <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="stretch"
                    spacing={1}
                    >
                        <Grid item xs={12}>
                         <br/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" className="text-center" gutterBottom style={{color:theme.palette.secondary.main   }}>
                                      { data.product_name }                
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2"   style={{color:theme.palette.secondary.main   }}>
                                      <b>Category:</b>  { data.category_name } - { data.sub_category_name }                 
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2"   style={{color:theme.palette.secondary.main   }}>
                                      <b>Location:</b>  { data.product_location_text }                   
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom  style={{color:theme.palette.secondary.main   }}>
                                      <b>Overview:</b>  { data.product_short_desc }                   
                        </Typography>
                    </Grid>
                    <BackDropLoad wait={wait}/>
                     {show && (<Grid item xs={12}>
                        <Typography variant="body2" gutterBottom  style={{color:theme.palette.secondary.main   }}>
                                      <b>Share Options:  </b>
                        <FacebookShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <FacebookIcon size={30} round={true}/>
                        </FacebookShareButton>      
                        <LinkedinShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <LinkedinIcon size={30} round={true}/>
                        </LinkedinShareButton>   
                        <TwitterShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <XIcon size={30} round={true}/>
                        </TwitterShareButton>    
                        <RedditShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <RedditIcon size={30} round={true}/>
                        </RedditShareButton>    
                         
                        <WhatsappShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <WhatsappIcon size={30} round={true}/>
                        </WhatsappShareButton>
                        <TelegramShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <TelegramIcon size={30} round={true}/>
                        </TelegramShareButton>
                        <EmailShareButton url={url} style={{paddingLeft:"5px",paddingRight:"5px"}}>
                            <EmailIcon size={30} round={true}/>
                        </EmailShareButton>
                        </Typography>
                        
                     </Grid>)}
                    {show&&(<Grid item xs={12}   className="text-center">
                        <Box m={2}>
                        <Button color="primary" 
                        variant="contained" 
                         style={{width:"70%"}}
                        className="linkstyle" 
                        onClick={()=>{
                             if(user){
                                setShowContactForm(true);
                                 
                             }else{
                                 setShowLoginForm(true);
                             }
                        }}
                        >
                         Contact Now
                        </Button>
                        </Box>
                    </Grid>)}
                    {!user && (<LoginForm open={showLoginForm} showload={showbackgroundLoad} hideload={hidebackgroundLoad} closeform={closeLoginForm} opencontactform={openContactForm} />)}
                    {user && ( <ContactForm open={showContactForm} showload={showbackgroundLoad} hideload={hidebackgroundLoad} closeform={closeContactForm} data={data} url={"prod"} />)}
                    <Grid item xs={12} style={{color:"black"}}>
                        <Typography variant="body1"  gutterBottom style={{color:theme.palette.secondary.main   }}>
                                      <b>Detailed Information:</b>                 
                        </Typography>
                        {parse(data.product_info)}
                    </Grid>
                </Grid>
                </Box>
            </Grid>
        </Grid>)}
        </Box>
        <br /> <br/>
        <Recommended recs={recommended}/>

    </div>}<br/><br/><br/></div>);

}