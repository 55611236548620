import {useEffect, useState} from "react";
import Axios from "axios";
import {Loading} from "./Loading";
import {Grid, Typography,useTheme,Card,CardContent,ButtonBase,CardMedia,makeStyles, Container,Box, Hidden} from '@material-ui/core';
import parse from 'html-react-parser';
import {Link} from 'react-router-dom';
import { Helmet  } from 'react-helmet-async';

const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";


function convertToSlug(Text)
{
    return Text.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');
}


const useStyles = makeStyles({
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 #ffd24d, 0 6px 20px 0 #ffd24d" },
      //"&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.19), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
    },
  });
export const AwardsAndNews=()=>{
    const theme = useTheme();
    const classes = useStyles();
    const [loading,setLoading]=useState(true);
    const [data, setData] = useState([]);
    const [metadata,setMetadata] = useState();

    useEffect(()=>{
      window.scrollTo(0, 0);
      Axios.post("/new_apis/getsinglemetadata", {'type':"awards"},{
        headers: { key: key },
       }).then((res) => {
          
         if(res.data.status){
           
          setMetadata(res.data.metadata[0])
         } else{
            setMetadata({'seo_title':"Home - ESAY | Contacts and Info in the World of Construction",
            'seo_desc':"Get all the information and contacts of various types of contractors, consultants, machinery/material suppliers, engineers, and workforce in the construction sector",
            "seo_keywords":"best  contractors in Telangana, schedule of rates, Tenders in India,   construction architect engineers and labor near me, latest trends in construction sector, top contractors and consultants in Hyderabad,   interior designers in Hyderabad, building construction materials and machinery suppliers, soil metal materials testing labs around me"});
         }
          
       });
        Axios.get("/new_apis/get-all-articles",{ 
          headers: { key: key },
         }).then((res) => {
            setData(res.data.articles);
             
            setLoading(false);
         }); 
                   
      },[]);


      const createSlug = (title) => {
        return title
            .toLowerCase() // Convert to lowercase
            .replace(/[^\w ]+/g, '') // Remove all non-word chars
            .replace(/ +/g, '-'); // Replace spaces with hyphens
    };

    return (<div>
                {metadata && <Helmet>
        <title>{metadata.seo_title}</title>
            <meta name="description" content={metadata.seo_desc} />
            <meta name="keywords" content={metadata.seo_keywords}></meta>
        </Helmet>}
        {loading?<Loading/>:
        <div className="container">
            <br/><br/>
           
            <Typography variant="h4" className="divider line glow pageHeading" gutterBottom style={{color:theme.palette.primary.main}}>
                Honors & Highlights 
            </Typography>
            <Container maxWidth="sm">
                <Typography variant="body2" className=" text-center pageSubtitle" gutterBottom style={{color:"white",margin:"10px",padding:"10px",  border:"1px solid #ffd24d",borderRadius:"100px",backgroundColor:theme.palette.secondary.main}}>
                    <span style={{color:theme.palette.primary.main }}>Awards and Media Coverage:</span> 
                    Celebrating Achievements and Industry Recognition. 
                </Typography>
            </Container>
            <br/><br/><br/><br/> 
            <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={10}
                    >
                        
                      {data.length !== 0 ?(<div>
                        <Grid item xs={12}>
                        {data.map((i) => {
                         let slug = createSlug(i.article_title);
                        return <ButtonBase
                        component={Link} className="article_card"
                        to={`/article-details/${slug}/${i.article_id}`}
                      > <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="stretch"
                         spacing={2}
                         
                        style={{margin:"0px"}}
                        >
                            <Grid item xs={12}><br/></Grid>
                            <Grid item xs={12}>
                        <h3 style={{color:"#ffd24d",fontWeight: 'normal',letterSpacing: '3px'
}}>{i.article_title}</h3>
                        </Grid>
                          {i.article_images.split(",").length ===1?(
                        <Grid item xs={12}>
                          <Box
                              style={{
                                width: "100%",
                                // paddingBottom: "70%",
                                height: "300px",  
                                 position: "relative",
                              }}
                            >
                            <img 
                                src={`/articles/${i.article_images.split(",")[0]
                            }`}
                          
                                alt={data.article_title}
                                style={{ width: "100%", height: "100%", cursor: "pointer",objectFit: "cover" }}
                            />
                        </Box>
                        </Grid>
                        ):(
                        <Grid item xs={12}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={1}
                          >
                           <Grid item xs={12} sm={12} md={6}> 
                           <Box
                              style={{
                                width: "100%",
                                // paddingBottom: "70%",
                                height: "300px",  
                                 position: "relative",
                                 
                              }}
                            >
                                      <img 
                                          src={`/articles/${i.article_images.split(",")[0]
                                      }`}
                                      
                                          alt={i.article_title}
                                          style={{ width: "100%", height: "100%", cursor: "pointer",objectFit: "cover" }}
                                      />
                            </Box>        
                           </Grid>
                           <Hidden smDown>
                            <Grid item  md={6}  > 
                            {  i?.article_images.split(",").length >3 ? <Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              spacing={1}
                              >
                                {  i?.article_images.split(",")
                              .slice(1, 5)
                              .map((j,  index) => (
                                <>
                               { index!== 3 && <Grid item   md={6}>
                                
                                <Box
                                    style={{
                                      width: "100%",
                                      height: "150px",  
                                      // paddingBottom: "70%",
                                      position: "relative",
                                    }}
                                  >
 
                                    <img
                                      src={`/articles/${j}`}
                                      
                                      style={{
                                        objectFit: "cover",
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        cursor: "pointer",
                                      }}
                                    />
                                </Box>
                               
                                </Grid>}
                                
                                { index=== 3 && <Grid item   md={6}>
                                
                                <Box
                                    style={{
                                      width: "100%",
                                      height: "150px",  
                                       position: "relative",
                                      cursor: "pointer",
                                    }}
                                    
                                  >
                                    
                                    <img
                                      src={`/articles/${j}`}
                                      
                                      style={{
                                        objectFit: "cover",
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        
                                        opacity:i.article_images.split(",").length > 5 ?"0.5":"1.0"   
                                      }}
                                    />
                                   {i?.article_images.split(",").length > 5 && <Typography
                                    variant="h2"
                                    style={{
                                      color: "#ffd24d",
                                      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                                      position: 'absolute', 
                                      top: '50%',           
                                      left: '50%',          
                                      transform: 'translate(-50%, -50%)',  
                                    }}
                                  >
                                    +
                                    {i?.article_images.split(",").length - 5}
                                  </Typography> }
                                </Box>
                               
                                </Grid>}
                                
                                </>
                              ))}

                              
                            </Grid>:<Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              spacing={1}
                              >
                                {  i?.article_images.split(",")
                              .slice(1, 3)
                              .map((j,  index) => (
                                <>
                               <Grid item   md={12}>
                                
                                <Box
                                    style={{
                                      width: "100%",
                                      height: "150px",  
                                      // paddingBottom: "70%",
                                      position: "relative",
                                    }}
                                  >
 
                                    <img
                                      src={`/articles/${j}`}
                                      
                                      style={{
                                        objectFit: "cover",
                                        position: "absolute",
                                        width: "100%",
                                        height: "100%",
                                        cursor: "pointer",
                                      }}
                                    />
                                </Box>
                               
                                </Grid> 
                                 
                                
                                </>
                              ))}

                              
                            </Grid>}
                            
                            </Grid>
                            </Hidden>             
                          </Grid>
                        </Grid>
                        )} 
                            <Grid item xs={12}>
                         
                        </Grid>
                        <Grid item xs={12}>
                            <p style={{fontWeight: 'normal',letterSpacing: '3px',textTransform: 'uppercase',textAlign:'right'}}><b style={{color:'#ffd24d'}}>Posted Date: </b> {i.article_published_date}</p>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <div class="article_title">
                                <h1 style={{color:"#ffd24d"}}>{i.article_title}
                                    <br/>
                                    <span style={{marginTop:"5px"}}><b>Posted Date: </b> {i.article_published_date}</span>
                                </h1>
                            </div>
                        </Grid> */}
                        <Grid item xs={12}>
                        <p className="article_desc">{parse(
                                    i.article_desc
                                      .slice(0, 600)
                                      .concat(["..."])

                        )}</p>
                        </Grid>
                        <Grid item xs={12} style={{borderBottom:"2px Solid #ffd24d"}}>

                        </Grid>
                        <Grid item xs={12}  >

                        </Grid>
                        </Grid></ButtonBase>
                      })}
                        </Grid>
                      </div>):(<div>
                        <Grid item xs={12}>
                            <p>No Articles Avaiable..</p>
                        </Grid>
                      </div>)}            
                    </Grid>
            <br/><br/><br/><br/><br/><br/>
        </div>}
    </div>);
}