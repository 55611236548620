import {useEffect,useState} from "react";
//import Loading from './Loading';
//import Axios from "axios";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Container,Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
 
export const ConvLength=()=>{
    
     
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[]);
   

return  (  <div>

<Grid
  container
  direction="column"
  justify="space-evenly"
  alignItems="center"
  spacing={3}
>
<Grid item xs={12}>
         
   </Grid>
<Grid item xs={12}>
        <Typography variant="h5"  className="text-center" color="primary" gutterBottom>
          LENGTH
        </Typography>
   </Grid>
  <Grid item xs={12}>
  <Length />
  </Grid>
  <Grid item xs={12}>
   
  </Grid>
  <Grid item xs={12}>
  <SimpleCard/>
  </Grid>
  </Grid>
    
</div>

 );
}

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiFormLabel-root":{
        color:"#ffd24d"
      },
       
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"rgb(41, 38, 38)",
        paddingLeft:"1%",
        paddingRight:"1%",
      } 
       
    }
  
  });
  
  export default function SimpleCard() {
    const classes = useStyles();
    
  
    return (
        <Box m={3} >
      <Card className={classes.root}  style={{ border:"2px groove #ffd24d"}}>
        <CardContent>
          
          <Typography variant="h6"  className="text-center">
           UNITS OF LENGTH 
          </Typography>
          <br></br>
          <Typography variant="body2"  >
          1 inch = 0.0254 Meters; 1 mile = 1.60934 kilometres This show that one mile (English system) 
          is approximately 1.61 times of a Kilometre (Metric system) 
          that means a mile is 0.61 more than one kilometre and one inch is 0.0254 times a meter.
          </Typography>
        </CardContent>
        
      </Card>
      </Box>
    );
  }
    
 



const Length=()=>{
    const classes = useStyles();
  const [feet,setFeet] = useState();
  const [met,setMet] = useState();
  const [inch,setInch] = useState();
  const [yard,setYard] = useState();
  const [mile,setMile] = useState();
  const [km,setKm] = useState();
  const [mm,setMm] = useState();
  const [fur,setFur] = useState();



  return (
      <Container maxWidth="lg">
      <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      spacing={4}
    >
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <TextField    className={classes.root}  size="small" label="Feet" variant="outlined" fullWidth 
              type="number"
              InputProps={{
                style: {
                    color: "#ffd24d"
                }
            }}
            InputLabelProps={{  shrink: true,focused:true }}

              value={feet}
              onChange={(e) => {
                 
                      var val = parseFloat(e.target.value);
                      var val1 = parseFloat(e.target.value);
                      setFeet(val1);
                      val=val1/3.281
                      setMet(val);
                      val=val1*12
                      setInch(val);
                      val=val1/3
                      setYard(val);
                      val=val1/5280
                      setMile(val);
                      val=val1/3280.84
                      setKm(val);
                      val=val1*304.8
                      setMm(val)
                      val=val1/660.001
                      setFur(val);
                  }
                  
              }
               
          />
           </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField   className={classes.root} size="small"   label="Meters" variant="outlined" fullWidth
          type="number"
          InputProps={{
            style: {
                color: "#ffd24d"
            }
        }}
        InputLabelProps={{  shrink: true,focused:true }}

          value={met}
          onChange={(e) => {
              
                  var val = parseFloat(e.target.value);
                  var val1 = parseFloat(e.target.value);
                  setMet(val1);
                  val=val1*3.281
                  setFeet(val);
                  val=val1*39.37
                  setInch(val);
                  val=val1*1.094
                  setYard(val);
                  val=val1/1609.344
                  setMile(val);
                  val=val1/1000
                  setKm(val);
                  val=val1*1000
                  setMm(val);
                  val=val1/201.168
                  setFur(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField   className={classes.root}  size="small"  label="Inches" variant="outlined" fullWidth
          type="number"
          InputProps={{
            style: {
                color: "#ffd24d"
            }
        }}
        InputLabelProps={{  shrink: true,focused:true }}

          value={inch}
          onChange={(e) => {
              
                  var val = parseFloat(e.target.value);
                  var val1 = parseFloat(e.target.value);
                  setInch(val1);
                  val=val1/12
                  setFeet(val);
                  val=val1/39.37
                  setMet(val);
                  val=val1/36
                  setYard(val);
                  val=val1/63360
                  setMile(val);
                  val=val1/39370.079
                  setKm(val);
                  val=val1*25.4
                  setMm(val);
                  val=val1/7920.016
                  setFur(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField    className={classes.root}  size="small" label="Yards" variant="outlined" fullWidth
          type="number"
          InputProps={{
            style: {
                color: "#ffd24d"
            }
        }}
        InputLabelProps={{  shrink: true,focused:true }}

          value={yard}
          onChange={(e) => {
              
                  var val = parseFloat(e.target.value);
                  var val1 = parseFloat(e.target.value);
                  setYard(val1);
                  val=val1*3
                  setFeet(val);
                  val=val1/1.094
                  setMet(val);
                  val=val1*36
                  setInch(val);
                  val=val1/1760
                  setMile(val);
                  val=val1/1093.613
                  setKm(val);
                  val=val1*914.4
                  setMm(val);
                  val=val1/220
                  setFur(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField   className={classes.root}  size="small"  label="Miles" variant="outlined" fullWidth
          type="number"
          InputProps={{
            style: {
                color: "#ffd24d"
            }
        }}
        InputLabelProps={{  shrink: true,focused:true }}

          value={mile}
          onChange={(e) => {
              
                  var val = parseFloat(e.target.value);
                  var val1 = parseFloat(e.target.value);
                  setMile(val1);
                  val=val1*5280
                  setFeet(val);
                  val=val1*1609.344
                  setMet(val);
                  val=val1*63360
                  setInch(val);
                  val=val1*1760
                  setYard(val);
                  val=val1*1.609
                  setKm(val);
                  val=val1*1609000
                  setMm(val);
                  val=val1*8
                  setFur(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField   className={classes.root}  size="small"  label="Kilometers" variant="outlined" fullWidth
          type="number"
          InputProps={{
            style: {
                color: "#ffd24d"
            }
        }}
        InputLabelProps={{  shrink: true,focused:true }}

          value={km}
          onChange={(e) => {
              
                  var val = parseFloat(e.target.value);
                  var val1 = parseFloat(e.target.value);
                  setKm(val1);
                  val=val1*3280.84
                  setFeet(val);
                  val=val1*1000
                  setMet(val);
                  val=val1*39370.079
                  setInch(val);
                  val=val1*1093.613
                  setYard(val);
                  val=val1/1.609
                  setMile(val);
                  val=val1*1000000
                  setMm(val);
                  val=val1*4.971 
                  setFur(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField  className={classes.root}  size="small"  label="Millimeters" variant="outlined" fullWidth
          type="number"
          InputProps={{
            style: {
                color: "#ffd24d"
            }
        }}
        InputLabelProps={{  shrink: true,focused:true }}

          value={mm}
          onChange={(e) => {
              
                  var val = parseFloat(e.target.value);
                  var val1 = parseFloat(e.target.value);
                  setMm(val1);
                  val=val1/304.8
                  setFeet(val);
                  val=val1/1000
                  setMet(val);
                  val=val1/25.4
                  setInch(val);
                  val=val1/914.4
                  setYard(val);
                  val=val1/1609000
                  setMile(val);
                  val=val1/1000000
                  setKm(val);
                  val=val1/201168.4
                  setFur(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField   className={classes.root} size="small"   label="Furlong" variant="outlined" fullWidth
          type="number"
          InputProps={{
            style: {
                color: "#ffd24d"
            }
        }}
        InputLabelProps={{  shrink: true,focused:true }}

          value={fur}
          onChange={(e) => {
              
                  var val = parseFloat(e.target.value);
                  var val1 = parseFloat(e.target.value);
                  setFur(val1);
                  val=val1*660.001
                  setFeet(val);
                  val=val1*201.168
                  setMet(val);
                  val=val1*7920.016
                  setInch(val);
                  val=val1*220
                  setYard(val);
                  val=val1/8
                  setMile(val);
                  val=val1/4.971
                  setKm(val);
                  val=val1*201168.4
                  setMm(val);
              }
          }
           
        />
        </Grid>
  </Grid></Container>);
}
