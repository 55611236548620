

import Backdrop from '@material-ui/core/Backdrop';
import {CircularProgress,Typography,Grid,useTheme,Container,Box} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

export const BackDropLoad=(props)=>{
    const theme = useTheme();
    const classes = useStyles();

   return( <Backdrop  className={classes.backdrop} open={props.wait} >
      <Container maxWidth="xs"> 
     <Box p={3} style={{backgroundColor:"#2e2b2b",borderRadius:"5px" }}> <Grid
  container
  direction="row"
  justify="flex-start"
  alignItems="center"
  //spacing={1}
>
    <Grid item xs={4} className="text-center">  <CircularProgress color="primary" /> </Grid>
    <Grid item xs={8} className="text-left"><Typography variant="h5" style={{color:theme.palette.primary.main}} >Please wait...</Typography>   </Grid>
    </Grid>

    </Box>
    </Container>
    </Backdrop>);
}