import {Dialog,Grid,Typography,TextField, DialogTitle,DialogContent,useTheme,makeStyles,ButtonBase,InputAdornment,FormControl,InputLabel,Select,MenuItem} from '@material-ui/core';

import {useState, useEffect, useContext} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { UserContext,SnackbarContext } from './userContext';
import {useNavigate,Link} from "react-router-dom";
import Axios from "axios";
import {CircularProgress} from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PersonIcon from '@material-ui/icons/Person';
import MenuBookIcon from '@material-ui/icons/MenuBook';
const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiFormLabel-root":{
        color:"#ffd24d"
      },
       
      "& .Mui-error":{
        color:"red  ",
       // borderColor:"red !important"
      },
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"#2e2b2b",
        paddingLeft:"1%",
        paddingRight:"1%",
      },
     
      
       
    },
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });


export const QuickSearch=(props)=>{
    
    const theme = useTheme();
    const classes = useStyles();
    const history=useNavigate();

    const [searchby, setSearchBy] = useState("1");

   

    const [open, setOpen] =  useState(false);
    const [options, setOptions] =  useState([]);
    const loadingLoc = open && options.length === 0;
  
    useEffect(() => {
      if (!open) {
        setOptions([]);
      }
    }, [open]);
  
      const onChangeHandle = async (value) => {
           
          await Axios.get("/new_apis/getquicksearch",{ params:{
            search:value,searchby:searchby
          },
           headers: { key: key },
         }).then((res) => {
             
            
             setOptions(res.data.data);
            
             
            
         });
         
          
  
          
       
       };

    return (<Dialog
        open={props.open}
        onClose={props.closeqs}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        
        PaperProps={{
            style: {
              backgroundColor: theme.palette.secondary.main,
              boxShadow: 'none',
               
            },
          }}
        //style={{backgroundColor:theme.palette.secondary.main}}
      >
           
          <DialogTitle id="alert-dialog-title"> <Typography variant="h4" className="text-center"  style={{color:theme.palette.primary.main   }}>
                                      Quick Search   
                        </Typography>
                        <hr style={{color:theme.palette.primary.main}}/>
                        </DialogTitle>
          <DialogContent style={{overflow:"hidden"}}>
           
           <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={3}
            >
                 <Grid item xs={12}>
                 <FormControl variant="outlined" className={classes.root}
                size="small"
               // error={error===2&&true}
                fullWidth  >
                 <InputLabel >
                  Search by *
                 </InputLabel>
                 <Select
                  // native
                   value={searchby}
                   onChange={(e) => {
                     setSearchBy(e.target.value);
                     
                   }}
                   style={{color:theme.palette.primary.main}}
                    
                   label="Search by"
                    
                 >
                   
                   <MenuItem key="1"  value="1">Search by Supplier / Service Provider Name</MenuItem>
                   <MenuItem key="2"  value="2">Search by Engineer / Workforce Name</MenuItem>
                   <MenuItem key="3"  value="3">Search by Info in EngInfo</MenuItem>
                     {/* })} */}
                 </Select>
               </FormControl>
            </Grid>
        <Grid item xs={12}>
                 <Autocomplete
                     id="location"
       
        disableClearable={true}
        defaultValue={props.searchtext}
        noOptionsText="No Results found.."
         open={open}
      
         loadingText="Fetching Results...."
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
            
          setOpen(false);
        }}
        
        onChange={(event, newValue) => {
         
           console.log(newValue);
           if(searchby==='1'){
              props.settext(newValue.name)
              history("/psdetails/"+newValue?.slug+"/"+newValue?.product_id)
              props.closeqs();
           }else if(searchby==='2'){
              props.settext(newValue.fname)
              history("/ewdetails/"+newValue?.cat+"/"+newValue?.user_id);
              props.closeqs();
           }else if(searchby==='3'){
              props.settext(newValue.title)
              history("/eng-info/info-detail/"+newValue?.slug+"/"+newValue?.id);
              props.closeqs();
           }
        }}
          
            getOptionSelected={(option, value) => option === value }
            /*
        getOptionSelected={(option,value) =>  {
            
          if(searchby==='1'){
             if(option.product_id===value.product_id){
              props.settext(option.name)
              history("/psdetails/"+option.slug+"/"+option.product_id)
              props.closeqs();
              return true;
             }  
          }else if(searchby==='2'){
            if(option.user_id===value.user_id){
              
              props.settext(option.fname)
              history("/ewdetails/"+option.cat+"/"+option.user_id);
              props.closeqs();
              return true;
            }
          }else if(searchby==='3'){
            if(option.id===value.id){
              props.settext(option.title)
              history("/eng-info/info-detail/"+option.slug+"/"+option.id);
              props.closeqs();
              return true;
            }
          }
             
            
            }
           }
            */
           
        getOptionLabel={(option) => {
          if(searchby==='1'){
            return option.name;
          }else if(searchby==='2'){
            return option.fname
          }else if(searchby==='3'){
            return option.title
          }
        }}
        
        options={options}
        loading={loadingLoc}
         

        
         
        renderInput={params => (
            <TextField
              {...params}
              label="Enter Name / Category *"
              //error={props.error}
              className={classes.root} 
              
              size="small"
              variant="outlined"
              value={props.searchtext}
              onChange={ev => {
                
                 
                  onChangeHandle(ev.target.value);
                 
              }}
              onFocus={()=>{
                   
                    onChangeHandle(props.searchtext);
                   
                
              }} 
               
              InputProps={{
                ...params.InputProps,
                style: {
                    color: "#ffd24d"
                },
                endAdornment: (
                  <>
                    {loadingLoc ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
          
          renderOption={(option) => {
           
           
            const matches = option;
            if(searchby==='1'){

            
            return (
               
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <PersonIcon className={classes.icon} />
                </Grid>
                <Grid item xs>
                {matches.name}
                  
    
                  <Typography variant="body2" color="textSecondary">
                    {matches.cat} - {matches.subcat}
                  </Typography>
                </Grid>
              </Grid>
              
            );
          }  else if(searchby==='2'){
            return (
            
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <PersonIcon className={classes.icon} />
                </Grid>
                <Grid item  >

                {matches.fname} {matches.lname}
    
                  <Typography variant="body2" color="textSecondary">
                    {matches.cat} - {matches.subcat}
                  </Typography>
                </Grid>
              </Grid>
              
            );
          } else if(searchby==='3'){
            return (
              
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <MenuBookIcon className={classes.icon} />
                </Grid>
                <Grid item xs>

                {matches.title} 
    
                  <Typography variant="body2" color="textSecondary">
                     {matches.parent}
                  </Typography>
                </Grid>
              </Grid>
               
            );
          }
          }}
           
      />
                 </Grid>

        <Grid item xs={12}>
            <br/>
        </Grid>         
                 
            </Grid>
             
            </DialogContent>
             
      </Dialog>);
}