import {useState,useContext} from 'react';
import {AppBar,Toolbar,Grid, Typography,Button,Container,Hidden,Drawer,List,ListItem,makeStyles,Menu,MenuItem,useTheme, Collapse} from '@material-ui/core';
 
import {QuickSearch} from './QuickSearch';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SearchIcon from '@material-ui/icons/Search';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ApartmentIcon from '@material-ui/icons/Apartment';
import MenuIcon from '@material-ui/icons/Menu';
import StraightenIcon from '@material-ui/icons/Straighten';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockIcon from '@material-ui/icons/Lock';
import ListIcon from '@material-ui/icons/List';
import EditIcon from '@material-ui/icons/Edit';
import PeopleIcon from '@material-ui/icons/People';
import HistoryIcon from '@material-ui/icons/History';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import {BackDropLoad} from "./LoadingBackDrop";
 import { UserContext,SnackbarContext } from './userContext';
import "../App.css";
import {Link,useNavigate, useLocation} from "react-router-dom";
import Axios from 'axios'; 
const useStyles = makeStyles({
    MuiDrawer: {
      backgroundColor: "#ffd24d"
    }
  });
  const $ = require("jquery");
export const Navbar=(props)=>{

  const location = useLocation();
  
  const theme = useTheme();
  const history=useNavigate();
  let cats=props?.cats;
  let subcats=props.subcats;
  const [wait,setWait] = useState(false);
  const classes = useStyles();
  const [loc,setLoc] = useState(localStorage.getItem('loc'));
  const [openDraw, setOpenDraw] = useState(false);

  const [openQuickSearch, setOpenQuickSearch] = useState(false);

  const [quickSearchText,setQuickSearchText] = useState("");



  const [showTopMyAccMenu,setShowTopMyAccMenu] = useState(true);
  const [showTopMyCatMenu,setShowTopMyCatMenu] = useState(true);

  const [showSideMyAccMenu,setShowSideMyAccMenu] = useState(true);
  const [showSideMyCatMenu,setShowSideMyCatMenu] = useState(true);

  const {user, setUser} = useContext(UserContext);
  const {snack, setSnack} = useContext(SnackbarContext);
  const [anchorEl, setAnchorEl] =  useState(null);


  const setQuickSearchTxt=(txt)=>{
      setQuickSearchText(txt);    
  }


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
     
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const openQS=()=>{
    setOpenQuickSearch(true)
  }

  const closeQS=()=>{
    setOpenQuickSearch(false)
  }

  $(document).click(function(e){

     
    if($(e.target).closest('#accbuttontop').length != 0){
      return false;
    }else{
      setShowSideMyAccMenu(true)
      //$('#accmenutop').hide();
    }

    if($(e.target).closest('#catbuttontop').length != 0){
      return false;
    }else{
      setShowSideMyCatMenu(true)
      //$('#accmenutop').hide();
    }
     
    
 });

 $("#accbuttontop").hover(function(e){
  setShowSideMyAccMenu(false)
 });

 $("#catbuttontop").hover(function(e){
  setShowSideMyCatMenu(false)
 });
  
 


 
   

  const logout=()=>{
    setWait(true)
    setAnchorEl(null);
    let tkn =   localStorage.getItem('estkn');
    Axios.get("/new_apis/logout", {
      headers: {  tkn: tkn },
     }).then((res) => {
        if(res.data.status){
          setUser(null);
          setWait(false);
         localStorage.removeItem("estkn");
         history('/');
         setSnack({
          message: "Logged out Successfully...",
          type: 'success',
          open: true,
          direction:"left"
        });
        }else{
          setUser(null);
          setWait(false);
          setSnack({
            message: "Something went wrong...",
            type: 'error',
            open: true,
            direction:"left"
          });
          localStorage.removeItem("estkn");
          history('/');
        }
     }); 
  }

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpenDraw(open);
  };

    return (<AppBar position="sticky" title={<img src="/assets/ESAY.png"/>}  id="back-to-top-anchor">
         <Container maxWidth="xl">
         <BackDropLoad wait={wait}/>
        <Toolbar>
        <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <QuickSearch open={openQuickSearch} searchtext={quickSearchText} settext={setQuickSearchTxt} openqs={openQS} closeqs={closeQS}  />
              <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
              <Link to="/"  className="anchorTag" title="ESAY Home">
                    <img
                    style={{height:"80px",width:"80px"}}
                    src={
                        "/assets/ESAY.png"
                    }
                alt="ESAY Logo"
                        /></Link>
              </Grid>
              <Hidden mdUp>
              <Button  variant="contained" color="primary" onClick={toggleDrawer(true)} style={{border:"1px solid black"}}>
                <MenuIcon   />
              </Button>
              <Drawer anchor={"left"} open={openDraw} onClose={toggleDrawer(false)} classes={{paper: classes.MuiDrawer}}>

              <List component="nav"  aria-label="contacts" style={{width:"250px",backgroundColor:"#ffd24d",color:"black"}}>
                  <ListItem>
                      <br/>
                      <br/>
                  </ListItem>
                  <ListItem button style={( location.pathname.startsWith("/searchresults/") || location.pathname.startsWith("/psdetails/") || location.pathname.startsWith("/ewdetails/"))?{backgroundColor:'white', border:"1px solid black",borderRadius:'5px'}:{}}  id="catbuttontop" className="linkstyle" onClick={()=>{
                    setShowTopMyCatMenu(!showTopMyCatMenu);
                  }}  >
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                    <ListIcon/>
                    </Grid>
                    
                    <Grid item><Typography component="h5" className='makecaps'  style={{textTransform: "capitalize"}} > 
                Categories&nbsp;
                    </Typography></Grid>
                    <Grid item><KeyboardArrowDownIcon  /></Grid>
                  </Grid>
                  </ListItem>
                  <Collapse in={!showTopMyCatMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding style={{backgroundColor:theme.palette.primary.main,border:`2px solid ${theme.palette.secondary.main}`,borderRadius:"2%"}}>
                    { cats && cats?.map((cat) => {return(<ListItem button component={Link}   onClick={toggleDrawer(false)} style={{ color:"black" ,borderBottom:"1px solid black"}} to={"/searchresults/"+loc+"/"+cat?.category_id}  >
                    <ArrowForwardIosIcon/>&nbsp;&nbsp;<span  className='makecaps' >{cat?.category_name}</span> 
                    </ListItem>
                     ) })} </List>
                  </Collapse>
                  <br/>
                  <ListItem button className="linkstyle" style={( location.pathname.startsWith("/eng-info/"))?{backgroundColor:'white', border:"1px solid black",borderRadius:"5px"}:{}}  component={Link} to={"/eng-info/home"} onClick={toggleDrawer(false)}>
                  <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                >
                    <Grid item>
                        <MenuBookIcon />
                    </Grid>
                    <Grid item> 
                    <Typography component="h5" className='makecaps' style={{textTransform: "capitalize"}}  > 
                    &nbsp;Eng Info
                    </Typography>
                    </Grid>
                </Grid>
                  </ListItem>
                  <br/>
                  <ListItem button style={(location.pathname==='/trends' ||location.pathname=== '/trends-list' || location.pathname.startsWith("/trends-list/trend/"))?{backgroundColor:'white', border:"1px solid black",borderRadius:"5px"}:{}} className="linkstyle" component={Link} to={"/trends"} onClick={toggleDrawer(false)}>
                  <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                >
                    <Grid item>
                        <TrendingUpIcon />
                    </Grid>
                    <Grid item>
                    <Typography component="h5" className='makecaps'  style={{textTransform: "capitalize"}} > 
                    &nbsp;Trends
                    </Typography>
                    </Grid>
                </Grid>
                  </ListItem>
                  <br/>
                  {/* <ListItem button className="linkstyle" style={( location.pathname.startsWith("/real-estates/") || location.pathname.startsWith("/real-estate-detail/"))?{backgroundColor:'white', border:"1px solid black",borderRadius:"5px"}:{}} component={Link} to={"/real-estates/"+loc} onClick={toggleDrawer(false)}>
                  <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                >
                    <Grid item>
                        <ApartmentIcon />
                    </Grid>
                    <Grid item>
                    <Typography component="h5" className='makecaps'  style={{textTransform: "capitalize"}} > 
                    &nbsp;Real Estate
                    </Typography>
                    </Grid>
                </Grid>
                  </ListItem>
                  <br/> */}
                  <ListItem button className="linkstyle" style={(location.pathname==='/conversions'  || location.pathname.startsWith("/conversions-list/"))?{backgroundColor:'white', border:"1px solid black",borderRadius:'5px'}:{}} component={Link} to={"/conversions"} onClick={toggleDrawer(false)}>
                  <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                >
                    <Grid item>
                        <StraightenIcon />
                    </Grid>
                    <Grid item>
                    <Typography component="h5" className='makecaps'  style={{textTransform: "capitalize"}} > 
                    &nbsp;Conversions
                    </Typography>
                    </Grid>
                </Grid>
                  </ListItem>
                  <br/>
                  <ListItem button className="linkstyle" style={(location.pathname==='/careers'  || location.pathname.startsWith("/job-details/"))?{backgroundColor:'white', border:"1px solid black",borderRadius:"5px"}:{}} component={Link} to={"/careers"} onClick={toggleDrawer(false)}>
                  <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                >
                    <Grid item>
                        <CardTravelIcon />
                    </Grid>
                    <Grid item>
                    <Typography component="h5" className='makecaps'  style={{textTransform: "capitalize"}} > 
                    &nbsp;Careers
                    </Typography>
                    </Grid>
                </Grid>
                  </ListItem>
                  <br/>
                   {/* <ListItem button className="linkstyle" component={Link} to={"/community-qa"} onClick={toggleDrawer(false)}>
                  <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                >
                    <Grid item>
                        <QuestionAnswerIcon  />
                    </Grid>
                    <Grid item>
                    <Typography component="h5" style={{textTransform: "capitalize"}} > 
                    &nbsp;Community Q&A
                    </Typography>
                    </Grid>
                </Grid>
                  </ListItem> */}
                   
                  <ListItem button className="linkstyle"  onClick={()=>{
                   toggleDrawer(false);
                   openQS();
                  }}>
                  <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                >
                    <Grid item>
                        <SearchIcon />
                    </Grid>
                    <Grid item>
                    <Typography component="h5" className='makecaps'  style={{textTransform: "capitalize"}} > 
                    &nbsp;Search
                    </Typography>
                    </Grid>
                </Grid>
                  </ListItem>
                  <br/>
                  <ListItem id="accbuttontop"  button style={( location.pathname.startsWith("/myaccount/") || location.pathname=='/login' || location.pathname=='/signup')?{backgroundColor:'white', border:"1px solid black",borderRadius:'5px'}:{}}  className="linkstyle"  onClick={()=>{
                    setShowTopMyAccMenu(!showTopMyAccMenu);
                  }}>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                      <Grid item>
                          <AccountCircleIcon />
                      </Grid>
                    <Grid item><Typography component="h5" className='makecaps'  style={{textTransform: "capitalize"}} > 
                    &nbsp;My Account&nbsp;
                    </Typography></Grid>
                    <Grid item>{showTopMyAccMenu ?(<KeyboardArrowDownIcon/>):(<KeyboardArrowUpIcon/>)} </Grid>
                  </Grid>
                  </ListItem>
                  <Collapse in={!showTopMyAccMenu} timeout="auto" unmountOnExit  className='makecapsDrawerMenu' >
                    <List component="div" disablePadding style={{backgroundColor:theme.palette.primary.main,border:`2px solid ${theme.palette.secondary.main}`,borderRadius:"2%"}}>
                    {(user && user.profile_done==='0') && (<ListItem component={Link}  button to={"/myaccount/complete_profile"} onClick={toggleDrawer(false)} style={{ color:"black",borderBottom:"1px solid black" }} ><CheckCircleIcon/>&nbsp;&nbsp;Finish Profile Setup</ListItem>)}
                        {user && (<ListItem button component={Link}  onClick={toggleDrawer(false)} style={{ color:"black",borderBottom:"1px solid black" }} to={"/myaccount/profile"}  ><AccountCircleIcon/>&nbsp;&nbsp;My Profile</ListItem>)}
                        
                        {(user && user.user_role==='1' && user.profile_done==='1'  ) && (<ListItem component={Link} onClick={toggleDrawer(false)} button style={{ color:"black",borderBottom:"1px solid black" }} to={"/myaccount/upgradeprofile"}  ><ArrowUpwardIcon/>&nbsp;&nbsp;Upgrade Profile</ListItem>)}
                     
                      {(user && user.user_role==='2' && user.category_ids!=='6' && user.category_ids!=='7'&& user.profile_done==='1' ) && (<ListItem button component={Link}  onClick={toggleDrawer(false)} to={"/myaccount/my_prods_servs"}  style={{ color:"black",borderBottom:"1px solid black" }} ><ListIcon/>&nbsp;&nbsp;My Products / Services</ListItem>)}
                     
                      {(user && user.user_role==='2' && user.category_ids!=='6' && user.category_ids!=='7'&& user.profile_done==='1' ) && (<ListItem button component={Link}  onClick={toggleDrawer(false)} to={"/myaccount/subscription"}  style={{ color:"black",borderBottom:"1px solid black" }} ><CardMembershipIcon/>&nbsp;&nbsp;My Subscription</ListItem>)}
                     
                      {user && (<ListItem component={Link} button  to={"/myaccount/my_bookmarks"} onClick={toggleDrawer(false)} style={{ color:"black",borderBottom:"1px solid black" }}  ><BookmarkIcon/>&nbsp;&nbsp;Eng.. Info Bookmarks</ListItem>)}
                    
                      {(user && user.user_role==='2' && user.profile_done==='1' ) && (<ListItem button component={Link} onClick={toggleDrawer(false)}  to={"/myaccount/contacted_customers"} style={{ color:"black" ,borderBottom:"1px solid black"}}  ><PeopleIcon/>&nbsp;&nbsp;Contacted {(user.category_ids==='6' || user.category_ids==='7')?"Users":"Customers"}</ListItem>)}
                      
                      {user && (<ListItem component={Link} button  to={"/myaccount/contact_history"} onClick={toggleDrawer(false)} style={{ color:"black",borderBottom:"1px solid black" }}  ><HistoryIcon/>&nbsp;&nbsp;Contact History</ListItem>)}
                       
                      {(user && user.user_role==='2' && user.profile_done==='1'  ) && (<ListItem component={Link} onClick={toggleDrawer(false)} button style={{ color:"black",borderBottom:"1px solid black" }} to={"/myaccount/request_category_change"}  ><ChangeCircleIcon/>&nbsp;&nbsp;Request Category Change</ListItem>)}

                        {(user && user.profile_done==='1') && (<ListItem component={Link}  button to={"/myaccount/updatedetails"} onClick={toggleDrawer(false)} style={{ color:"black",borderBottom:"1px solid black" }} ><EditIcon/>&nbsp;&nbsp;Update Details</ListItem>)}

                      {user ? (<ListItem component={Link}  button to={"/myaccount/updatepassword"} onClick={toggleDrawer(false)} style={{ color:"black",borderBottom:"1px solid black" }} ><LockIcon/>&nbsp;&nbsp;Update Password</ListItem>):(<ListItem onClick={toggleDrawer(false)} style={{ color:"black",borderBottom:"1px solid black" }} button component={Link} to={"/login"}><ExitToAppIcon/>&nbsp;&nbsp;Login</ListItem>)}
                       
                        {user ? ( <ListItem button style={{ color:"black",borderBottom:"1px solid black" }} onClick={()=>{toggleDrawer(false);logout();}}  ><ExitToAppIcon/>&nbsp;&nbsp;Logout</ListItem>):(<ListItem component={Link} button  to={"/signup"} onClick={toggleDrawer(false)}  style={{ color:"black",borderBottom:"1px solid black" }}><AccountCircleIcon/>&nbsp;&nbsp;SignUp</ListItem>)}
                      
                    </List>
                  </Collapse>
                   
                </List>
              </Drawer>
              </Hidden>
              <Hidden smDown>

               
              <Grid item xl={10} lg={10} md={10} sm={6} xs={6}>
              <Grid
                container
                direction="row"
                justify="space-evenly"
                // justify="flex-end"
                alignItems="start-end"
                // spacing={1}
                >
                 
                <Grid item >
                 
                
                <Button color="inherit"   style={( location.pathname.startsWith("/searchresults/") || location.pathname.startsWith("/psdetails/") || location.pathname.startsWith("/ewdetails/"))?{backgroundColor:'white', border:"1px solid black"}:{}} className="linkstyle" id="catbuttontop" title="Categories" onClick={()=>{
                  setShowSideMyCatMenu(!showSideMyCatMenu);
                }}>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item><ListIcon  /></Grid>
                    <Grid item><Typography className='makecaps' component="body2" style={{textTransform: "capitalize"}}  > 
                    &nbsp;Categories&nbsp;
                    </Typography></Grid>
                    <Grid item><KeyboardArrowDownIcon  /></Grid>
                  </Grid>
                
                    
                  </Button>
                  <Collapse in={!showSideMyCatMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding style={{zIndex:"-1",position:"absolute",borderRadius:"2%",backgroundColor:theme.palette.primary.main,border:`2px solid ${theme.palette.secondary.main}`,paddingTop:"2%",paddingBottom:"1%"}}>
                     
                    { cats && cats?.map((cat) => {return(<MenuItem button component={Link} className='makecaps' title={cat?.category_name}  onClick={toggleDrawer(false)} style={{ color:"black",borderBottom:"1px solid black" }} to={"/searchresults/"+loc+"/"+cat?.category_id}  >
                    <ArrowForwardIosIcon/>&nbsp;&nbsp;{cat?.category_name} </MenuItem>
                     ) })} </List>
                  </Collapse>
                  
                </Grid>
                <Grid item >
                <Button color="inherit" style={( location.pathname.startsWith("/eng-info/"))?{backgroundColor:'white', border:"1px solid black"}:{}} component={Link} to={"/eng-info/home"} className="linkstyle" title="Engineering Information" >
                <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                >
                    <Grid item>
                        <MenuBookIcon />
                    </Grid>
                    <Grid item>
                    <Typography component="body2" className='makecaps' style={{textTransform: "capitalize"}}  > 
                    &nbsp;Eng Info
                    </Typography>
                    </Grid>
                </Grid>
                  </Button>
                </Grid>
                <Grid item >
                <Button color="inherit"  component={Link} to={"/trends"} style={(location.pathname==='/trends' ||location.pathname=== '/trends-list' || location.pathname.startsWith("/trends-list/trend/"))?{backgroundColor:'white', border:"1px solid black"}:{}}  className="linkstyle" title="Trends in Construction">
                <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                >
                    <Grid item>
                        <TrendingUpIcon />
                    </Grid>
                    <Grid item>
                    <Typography component="body2" className='makecaps' style={{textTransform: "capitalize"}}  > 
                    &nbsp;Trends
                    </Typography>
                    </Grid>
                </Grid>
                    
                  </Button>
                </Grid>
                {/* <Grid item >
                <Button color="inherit" style={( location.pathname.startsWith("/real-estates/") || location.pathname.startsWith("/real-estate-detail/"))?{backgroundColor:'white', border:"1px solid black"}:{}} component={Link} to={"/real-estates/"+loc} className="linkstyle" title="Real Estates">
                <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                >
                    <Grid item>
                        <ApartmentIcon />
                    </Grid>
                    <Grid item>
                    <Typography component="body2" className='makecaps' style={{textTransform: "capitalize"}}  > 
                    &nbsp;Real Estate
                    </Typography>
                    </Grid>
                </Grid>
                    
                  </Button>
                </Grid> */}
                <Grid item >
                <Button color="inherit" style={(location.pathname==='/conversions'  || location.pathname.startsWith("/conversions-list/"))?{backgroundColor:'white', border:"1px solid black"}:{}} component={Link} to={"/conversions"} className="linkstyle" title="Conversions Calculator">
                <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-end"
                >
                    <Grid item>
                        <StraightenIcon />
                    </Grid>
                    <Grid item>
                    <Typography component="body2" className='makecaps' style={{textTransform: "capitalize"}}  > 
                    &nbsp;Conversions
                    </Typography>
                    </Grid>
                </Grid>
                    
                  </Button>
                </Grid>
                <Grid item >
                <Button color="inherit" style={(location.pathname==='/careers'  || location.pathname.startsWith("/job-details/"))?{backgroundColor:'white', border:"1px solid black"}:{}} component={Link} to={"/careers"} className="linkstyle" title="Careers">
                <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                >
                    <Grid item>
                        <CardTravelIcon />
                    </Grid>
                    <Grid item>
                    <Typography component="body2" className='makecaps' style={{textTransform: "capitalize"}}  > 
                    &nbsp;Careers
                    </Typography>
                    </Grid>
                </Grid>
                    
                  </Button>
                </Grid>
                {/* <Grid item >
                <Button color="inherit"  component={Link} to={"/community-qa"} className="linkstyle" title="Careers">
                <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                >
                    <Grid item>
                        <QuestionAnswerIcon />
                    </Grid>
                    <Grid item>
                    <Typography component="body2" style={{textTransform: "capitalize"}}  > 
                    &nbsp;Community Q&A
                    </Typography>
                    </Grid>
                </Grid>
                    
                  </Button>
                </Grid> */}
                <Grid item >
                <Button color="inherit"    className="linkstyle" onClick={openQS} title="Quick Search">
                <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                >
                    <Grid item>
                        <SearchIcon />
                    </Grid>
                     
                    <Grid item>
                    <Typography component="body2"  className='makecaps' style={{textTransform: "capitalize"}}  > 
                    &nbsp;Search
                    </Typography>
                    </Grid>
                </Grid>
                
                    
                  </Button>
                </Grid>
                <Grid item >
                <Button color="inherit" style={( location.pathname.startsWith("/myaccount/") || location.pathname=='/login' || location.pathname=='/signup')?{backgroundColor:'white', border:"1px solid black"}:{}}  id="accbuttontop"  className="linkstyle"  aria-controls="simple-menu"
                    aria-haspopup="true"
                     
                     onClick={()=>{
                       setShowSideMyAccMenu(!showSideMyAccMenu);
                     }}
                     title="My Account"
                     >
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                      <Grid item>
                          <AccountCircleIcon />
                      </Grid>
                    <Grid item><Typography component="body2"  className='makecaps' style={{textTransform: "capitalize"}}  > 
                    &nbsp;My Account&nbsp;
                    </Typography></Grid>
                    <Grid item><KeyboardArrowDownIcon  /></Grid>
                  </Grid>
                
                    
                  </Button>
                  <Collapse in={!showSideMyAccMenu} timeout="auto" unmountOnExit>
                    <List  className='makecaps' component="div" disablePadding style={{zIndex:"-1",position:"absolute",borderRadius:"3%",backgroundColor:theme.palette.primary.main,border:`2px solid ${theme.palette.secondary.main}`,paddingTop:"2%",paddingBottom:"1%"}}>
                    {(user&& user.profile_done==='0') && (<MenuItem component={Link}  button to={"/myaccount/complete_profile"}   style={{ color:"black",borderBottom:"1px solid black" }} title="Update Details"><CheckCircleIcon/>&nbsp;&nbsp;Finish Profile Setup</MenuItem>)}
                        {user && (<MenuItem button component={Link}   style={{ color:"black",borderBottom:"1px solid black" }} to={"/myaccount/profile"} title="My Profile" ><AccountCircleIcon/>&nbsp;&nbsp;My Profile</MenuItem>)}

                        {(user && user.user_role==='1' && user.profile_done==='1'  ) && (<MenuItem component={Link}  button style={{ color:"black",borderBottom:"1px solid black" }} to={"/myaccount/upgradeprofile"}  title="Upgrade Profile"><ArrowUpwardIcon/>&nbsp;&nbsp;Upgrade Profile</MenuItem>)}

                      {(user && user.user_role==='2' && user.category_ids!=='6' && user.category_ids!=='7' && user.profile_done==='1') && (<MenuItem button component={Link}    to={"/myaccount/my_prods_servs"}  style={{ color:"black" ,borderBottom:"1px solid black"}} title="My Products / Services"><ListIcon/>&nbsp;&nbsp;My Products / Services</MenuItem>)}

                      {(user && user.user_role==='2' && user.category_ids!=='6' && user.category_ids!=='7' && user.profile_done==='1') && (<MenuItem button component={Link}   to={"/myaccount/subscription"}  style={{ color:"black",borderBottom:"1px solid black" }} title="My Subscription"><CardMembershipIcon/>&nbsp;&nbsp;My Subscription</MenuItem>)}

                      {user && (<MenuItem component={Link} button  to={"/myaccount/my_bookmarks"}   style={{ color:"black",borderBottom:"1px solid black" }} title="Contact History" ><BookmarkIcon/>&nbsp;&nbsp;Eng.. Info Bookmarks</MenuItem>)}

                      {(user && user.user_role==='2' && user.profile_done==='1'  ) && (<MenuItem button component={Link}    to={"/myaccount/contacted_customers"} style={{ color:"black",borderBottom:"1px solid black" }}  title="Contacted Customers"><PeopleIcon/>&nbsp;&nbsp;Contacted {(user.category_ids==='6' || user.category_ids==='7')?"Users":"Customers"}</MenuItem>)}

                      {user && (<MenuItem component={Link} button  to={"/myaccount/contact_history"}   style={{ color:"black",borderBottom:"1px solid black" }} title="Contact History" ><HistoryIcon/>&nbsp;&nbsp;Contact History</MenuItem>)}

                      {(user && user.user_role==='2' && user.profile_done==='1'  ) && (<MenuItem component={Link}  button style={{ color:"black",borderBottom:"1px solid black" }} to={"/myaccount/request_category_change"}  title="Request Category Change"><ChangeCircleIcon/>&nbsp;&nbsp;Request Category Change</MenuItem>)}

                        {(user&& user.profile_done==='1') && (<MenuItem component={Link}  button to={"/myaccount/updatedetails"}   style={{ color:"black",borderBottom:"1px solid black" }} title="Update Details"><EditIcon/>&nbsp;&nbsp;Update Details</MenuItem>)}

                      {user ? (<MenuItem component={Link}  button to={"/myaccount/updatepassword"}   style={{ color:"black",borderBottom:"1px solid black" }} title="Update Password"><LockIcon/>&nbsp;&nbsp;Update Password</MenuItem>):(<MenuItem   style={{ color:"black",borderBottom:"1px solid black" }} button title="Login" component={Link} to={"/login"}><ExitToAppIcon/>&nbsp;&nbsp;Login</MenuItem>)}
                       
                        {user ? ( <MenuItem button style={{ color:"black",borderBottom:"1px solid black" }} onClick={()=>{ logout();}} title="Logout" ><ExitToAppIcon/>&nbsp;&nbsp;Logout</MenuItem>):(<MenuItem component={Link} button  to={"/signup"}  style={{ color:"black",borderBottom:"1px solid black" }} title="SignUp"><AccountCircleIcon/>&nbsp;&nbsp;SignUp</MenuItem>)}
                      
                    </List>
                  </Collapse>
              
                </Grid>
                
            </Grid>   
              </Grid>
              </Hidden>
          </Grid>
              
        </Toolbar>
        </Container>
    </AppBar>);
}