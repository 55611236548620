import {Grid,Box, Typography,useTheme,Button,Container,makeStyles,TextField,FormControl,Switch,CardMedia,Card,Tabs,Tab,CardActions} from '@material-ui/core';
import {useEffect,useState,useContext} from 'react';
import PropTypes from 'prop-types';
 
import {Loading} from "./Loading";
import {BackDropLoad} from "./LoadingBackDrop";
import { UserContext,SnackbarContext } from './userContext';
import {useNavigate} from "react-router-dom";
import Axios from "axios";
import moment from 'moment';

const useStyles = makeStyles({
     
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export const MyAccContactHistory =()=>{
    const history=useNavigate();
    const theme = useTheme();
    const classes = useStyles();
    const {user, setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);
    const [value, setValue] =  useState(0);
    const [loading,setLoading] = useState(true);
    const [prods,setProds] = useState([]);
    const [engs,setEngs] = useState([]);
    const [wfs,setWfs] = useState([]);
    const [res,setRes] = useState([]);

    useEffect(()=>{
        getData();
    },[])


    const getData=()=>{
        const formData={};
        var tkn=user.token;
        formData.uid=user.user_id;
        Axios.post("/new_apis/getcontacthistory",formData,{ 
            headers: { tkn: tkn },
           }).then((res) => {
              if(res.data.status===1){
                    setProds(res.data.prodserv);
                     
                    setEngs(res.data.eng);
                    setWfs(res.data.wf);
                    setRes(res.data.re);
                    setLoading(false);
              }else if(res.data.status===3){
                 
                history('/');
                 
                setSnack({
                    message: res.data.msg,
                    type: 'warning',
                    open: true,
                    direction:"left"
                  });
              }else if(res.data.status===4){
                setUser(null);
                history('/');
                localStorage.removeItem("estkn");
                setSnack({
                    message: res.data.msg,
                    type: 'warning',
                    open: true,
                    direction:"left"
                  });
              }
           });

    }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    return (<div>
        {loading?<Loading/>:(<Container maxWidth="md">
        <Box boxShadow={3} p={3}  style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.secondary.main ,borderRadius:"10px"}}> 
        <Grid
        container
        direction="row"
            justify="space-between"
            alignItems="center"
        spacing={3}
        >
            <Grid item xs={12}>
            <Typography variant="h4" className="text-center" gutterBottom style={{color:theme.palette.primary.main}}>
                 Contact History
            </Typography>
            <hr style={{color:theme.palette.primary.main}}/>
            </Grid>
            <Grid item xs={12}>
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" indicatorColor="secondary" variant="scrollable"
          scrollButtons="auto"  style={{backgroundColor:theme.palette.primary.main,color:theme.palette.secondary.main}}>
          <Tab label="Suppliers / Service Providers" {...a11yProps(0)} />
          {/* <Tab label="Real Estates" {...a11yProps(1)} /> */}
          <Tab label="Engineers" {...a11yProps(1)} />
          <Tab label="Workforce" {...a11yProps(2)} />
        </Tabs>
       <br/>
       <br/>
        <div
                role="tabpanel"
                hidden={value !== 0}
                >
                    {prods.length!==0? <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={4}
                   >
                       {prods.map((item) => (
                       <Grid item xs={12} sm={6} md={6} lg={4} xl={4} 
                       className="text-center"
                       >
                           <Card className={classes.cardshadow} style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.primary.main}}>
                            
                            
                           <CardMedia
                                style={{height: 200}}
                                image={item.image_url}
                                title={item.product_name} 
                                />
                         
                        <CardActions>
                         
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            >
                                  <Grid item xs={12}>
                                    <Typography variant="h6" className="text-center" style={{color:theme.palette.secondary.main,width:"100%",overflow:"hidden"}}>
                                    {item.product_name}
                                     
                                   </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="h7" className="text-center" style={{color:theme.palette.secondary.main,width:"100%",textDecoration:"underline"}}>
                                    {item.sub_category_name }
                                   </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="h7" className="text-center" style={{color:theme.palette.secondary.main,width:"100%" }}>
                                    {item.product_location_text }
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <br/>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2"   style={{color:theme.palette.secondary.main,width:"100%",textAlign:"left"}}>
                                   <b>Mobile no:</b> {item.product_contact_mobile}
                                     
                                    </Typography>
                                    </Grid>
                                    {(item.product_contact_email!==null && item.product_contact_email!=="")&&(<Grid item xs={12}>
                                    <Typography variant="body2"     style={{color:theme.palette.secondary.main,width:"100%",textAlign:"left"}}>
                                   <b>Email:</b> {item.product_contact_email }
                                     
                                    </Typography>
                                    </Grid>)}
                                    <Grid item xs={12}>
                                    <Typography variant="body2"   style={{color:theme.palette.secondary.main,width:"100%",textAlign:"left"}}>
                                   <b>Contacted Date:</b> {item.contact_date}  
                                     
                                    </Typography>
                                    </Grid>
                                </Grid>
                        </CardActions>
                        </Card>
                        </Grid>
                       ))}
                   </Grid>:(<Typography variant="body1" className="text-center">You haven't Contacted any Supplier / Service Provider till now.</Typography> )}
         </div>
        {/* <div
                role="tabpanel"
                hidden={value !== 1}
                >
                    {res.length!==0? <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={4}
                   >
                       {res.map((item) => (
                       <Grid item xs={12} sm={6} md={6} lg={4} xl={4} 
                       className="text-center"
                       >
                           <Card className={classes.cardshadow} style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.primary.main}}>
                            
                            
                           <CardMedia
                                style={{height: 200}}
                                image={item.re_image}
                                title={item.re_name} 
                                />
                         
                        <CardActions>
                         
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            >
                                  <Grid item xs={12}>
                                    <Typography variant="h6" className="text-center" style={{color:theme.palette.secondary.main,width:"100%",overflow:"hidden"}}>
                                    {item.re_name}
                                     
                                   </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="h7" className="text-center" style={{color:theme.palette.secondary.main,width:"100%",textDecoration:"underline"}}>
                                    {item.rst_name }
                                   </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="h7" className="text-center" style={{color:theme.palette.secondary.main,width:"100%" }}>
                                    <b>Status:</b> {item.re_status==='1'?"Active":"In-Active" }
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="h7" className="text-center" style={{color:theme.palette.secondary.main,width:"100%" }}>
                                    {item.re_loc }
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <br/>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2"   style={{color:theme.palette.secondary.main,width:"100%",textAlign:"left"}}>
                                   <b>Owner/Agent Name:</b> {item.re_cand}
                                     
                                    </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2"   style={{color:theme.palette.secondary.main,width:"100%",textAlign:"left"}}>
                                   <b>Mobile no:</b> {item.re_mob}
                                     
                                    </Typography>
                                    </Grid>
                                    {(item.re_email!==null && item.re_email!=="")&&(<Grid item xs={12}>
                                    <Typography variant="body2"     style={{color:theme.palette.secondary.main,width:"100%",textAlign:"left"}}>
                                   <b>Email:</b> {item.re_email }
                                     
                                    </Typography>
                                    </Grid>)}
                                    <Grid item xs={12}>
                                    <Typography variant="body2"   style={{color:theme.palette.secondary.main,width:"100%",textAlign:"left"}}>
                                   <b>Contacted Date:</b> {item.contact_date}  
                                     
                                    </Typography>
                                    </Grid>
                                </Grid>
                        </CardActions>
                        </Card>
                        </Grid>
                       ))}
                   </Grid>:(<Typography variant="body1" className="text-center">You haven't Contacted any Real Estate property till now.</Typography> )}
        </div> */}
        <div
                role="tabpanel"
                hidden={value !== 1}
                >
                    {engs.length!==0? <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={4}
                   >
                       {engs.map((item) => (
                       <Grid item xs={12} sm={6} md={6} lg={4} xl={4} 
                       className="text-center"
                       >
                           <Card className={classes.cardshadow} style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.primary.main}}>
                            
                            
                             
                         
                        <CardActions>
                         
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            >
                                  <Grid item xs={12}>
                                    <Typography variant="h6" className="text-center" style={{color:theme.palette.secondary.main,width:"100%",overflow:"hidden"}}>
                                    {item.user_full_name+" "+item.user_last_name }
                                     
                                   </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="h7" className="text-center" style={{color:theme.palette.secondary.main,width:"100%",textDecoration:"underline"}}>
                                    {item.sub_category_name }
                                   </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <br/>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2"   style={{color:theme.palette.secondary.main,width:"100%",textAlign:"left"}}>
                                   <b>Mobile no:</b> {item.user_mobile }
                                     
                                    </Typography>
                                    </Grid>
                                    {(item.user_email!==null && item.user_email!=="")&&(<Grid item xs={12}>
                                    <Typography variant="body2"     style={{color:theme.palette.secondary.main,width:"100%",textAlign:"left"}}>
                                   <b>Email:</b> {item.user_email }
                                     
                                    </Typography>
                                    </Grid>)}
                                    <Grid item xs={12}>
                                    <Typography variant="body2"   style={{color:theme.palette.secondary.main,width:"100%",textAlign:"left"}}>
                                   <b>Contacted Date:</b> {item.contact_date}  
                                     
                                    </Typography>
                                    </Grid>
                                </Grid>
                        </CardActions>
                        </Card>
                        </Grid>
                       ))}
                   </Grid>:(<Typography variant="body1" className="text-center">You haven't Contacted any Engineer till now.</Typography> )}
         </div>
        <div
                role="tabpanel"
                hidden={value !== 2}
                > {wfs.length!==0? <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={4}
                   >
                       {wfs.map((item) => (
                       <Grid item xs={12} sm={6} md={6} lg={4} xl={4} 
                       className="text-center"
                       >
                           <Card className={classes.cardshadow} style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.primary.main}}>
                            
                            
                             
                         
                        <CardActions>
                         
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                            >
                                  <Grid item xs={12}>
                                    <Typography variant="h6" className="text-center" style={{color:theme.palette.secondary.main,width:"100%",overflow:"hidden"}}>
                                    {item.user_full_name+" "+item.user_last_name }
                                     
                                   </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="h7" className="text-center" style={{color:theme.palette.secondary.main,width:"100%",textDecoration:"underline"}}>
                                    {item.sub_category_name }
                                   </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <br/>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2"   style={{color:theme.palette.secondary.main,width:"100%",textAlign:"left"}}>
                                   <b>Mobile no:</b> {item.user_mobile }
                                     
                                    </Typography>
                                    </Grid>
                                    {(item.user_email!==null && item.user_email!=="")&&(<Grid item xs={12}>
                                    <Typography variant="body2"     style={{color:theme.palette.secondary.main,width:"100%",textAlign:"left"}}>
                                   <b>Email:</b> {item.user_email }
                                     
                                    </Typography>
                                    </Grid>)}
                                    <Grid item xs={12}>
                                    <Typography variant="body2"   style={{color:theme.palette.secondary.main,width:"100%",textAlign:"left"}}>
                                   <b>Contacted Date:</b> {item.contact_date}  
                                     
                                    </Typography>
                                    </Grid>
                                </Grid>
                        </CardActions>
                        </Card>
                        </Grid>
                       ))}
                   </Grid>:(<Typography variant="body1" className="text-center">You haven't Contacted any Workforce till now.</Typography> )}
        </div>            
         
            </Grid>
        </Grid>
        </Box>
        </Container>)}
    </div>);
}