import {useEffect,useState } from "react";
 
import Axios from "axios";
import {Grid, Typography,useTheme, Button,makeStyles, Container} from '@material-ui/core';
 
import {Link} from 'react-router-dom';
import { Helmet  } from 'react-helmet-async';
const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";

const useStyles = makeStyles({
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
    },
  });
export const Conversions=()=>{
    const theme = useTheme();
    const classes = useStyles();
    const [metadata,setMetadata] = useState();
     
    useEffect(()=>{
      window.scrollTo(0, 0);
      Axios.post("/new_apis/getsinglemetadata", {'type':"conversions"},{
        headers: { key: key },
       }).then((res) => {
          
         if(res.data.status){
           
          setMetadata(res.data.metadata[0])
         } else{
            setMetadata({'seo_title':"Home - ESAY | Contacts and Info in the World of Construction",
            'seo_desc':"Get all the information and contacts of various types of contractors, consultants, machinery/material suppliers, engineers, and workforce in the construction sector",
            "seo_keywords":"best  contractors in Telangana, schedule of rates, Tenders in India,   construction architect engineers and labor near me, latest trends in construction sector, top contractors and consultants in Hyderabad,   interior designers in Hyderabad, building construction materials and machinery suppliers, soil metal materials testing labs around me"});
         }
          
       });
    },[]);
     

    return (<div>
         {metadata && <Helmet>
  <title>{metadata.seo_title}</title>
    <meta name="description" content={metadata.seo_desc} />
    <meta name="keywords" content={metadata.seo_keywords}></meta>
  </Helmet>}
        <div className="container">
            <br/>
            <br/>
        <Typography variant="h4" className="divider line glow pageHeading" gutterBottom style={{color:theme.palette.primary.main}}>
           Conversions 
     </Typography>
     <Container maxWidth="sm">
     <Typography variant="body2" className=" text-center pageSubtitle" gutterBottom style={{color:"white",margin:"10px",padding:"10px",  border:"1px solid #ffd24d",borderRadius:"100px",backgroundColor:theme.palette.secondary.main}}>
     <span style={{color:theme.palette.primary.main }}>Quickly Convert On the Go:</span> Your Essential Offline Tool for Field Calculations.
      </Typography>
      </Container>
      <br/><br/>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
        spacing={6}
    >
        <Grid item xl={2} lg={2} md={3} sm={4} xs={6}>
         <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/conversions-list/length"} className="linkstyle">Length</Button>
        </Grid>
        <Grid item xl={2} lg={2} md={3} sm={4} xs={6}>
         <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/conversions-list/area"} className="linkstyle">Area</Button>
        </Grid>
        <Grid item xl={2} lg={2} md={3} sm={4} xs={6}>
         <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/conversions-list/volume"} className="linkstyle">Volume</Button>
        </Grid>
        <Grid item xl={2} lg={2} md={3} sm={4} xs={6}>
         <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/conversions-list/weight"} className="linkstyle">Weight</Button>
        </Grid>
        <Grid item xl={2} lg={2} md={3} sm={4} xs={6}>
         <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/conversions-list/wtunitl"} className="linkstyle">Wt / unit L</Button>
        </Grid>
        <Grid item xl={2} lg={2} md={3} sm={4} xs={6}>
         <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/conversions-list/stress"} className="linkstyle">Stress</Button>
        </Grid>
        <Grid item xl={2} lg={2} md={3} sm={4} xs={6}>
         <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/conversions-list/velocity"} className="linkstyle">Velocity</Button>
        </Grid>
        <Grid item xl={2} lg={2} md={3} sm={4} xs={6}>
         <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/conversions-list/discharge"} className="linkstyle">Discharge</Button>
        </Grid>
        <Grid item xl={2} lg={2} md={3} sm={4} xs={6}>
         <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/conversions-list/moment"} className="linkstyle">Moment</Button>
        </Grid>
        <Grid item xl={2} lg={2} md={3} sm={4} xs={6}>
         <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/conversions-list/power"} className="linkstyle">power</Button>
        </Grid>
        <Grid item xl={2} lg={2} md={3} sm={4} xs={6}>
         <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/conversions-list/pressure"} className="linkstyle">Pressure</Button>
        </Grid>
        <Grid item xl={2} lg={2} md={3} sm={4} xs={6}>
         <Button variant="contained" component={Link} fullWidth={true} color="primary" to={"/conversions-list/temp"} className="linkstyle">Temperature</Button>
        </Grid>
    </Grid>
<br/><br/><br/>
        </div> 
    </div>);
}