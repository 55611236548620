import {useEffect,useState} from "react";
//import Loading from './Loading';
//import Axios from "axios";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Container,Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
 
export const ConvVelocity=()=>{
    
     
    useEffect(()=>{
      window.scrollTo(0, 0);
       
    },[]);

return  (  <div>
    
  
    <Grid
  container
  direction="column"
  justify="space-evenly"
  alignItems="center"
  spacing={3}
>
<Grid item xs={12}>
         
   </Grid>
<Grid item xs={12}>
        <Typography variant="h5" component="h4" className="text-center" color="primary" gutterBottom>
          VELOCITY 
        </Typography>
   </Grid>
  <Grid item xs={12}>
  <Velocity />
  </Grid>
  <Grid item xs={12}>
   
  </Grid>
  <Grid item xs={12}>
  <SimpleCard/>
  </Grid>
  </Grid>
    
</div>

 );
}

 
const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiFormLabel-root":{
        color:"#ffd24d"
      },
       
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"rgb(41, 38, 38)",
        paddingLeft:"1%",
        paddingRight:"1%",
      } 
       
    }
  
  });
  
  export default function SimpleCard() {
    const classes = useStyles();
    
  
    return (
        <Box  m={3}>
      <Card className={classes.root}  style={{ border:"2px groove #ffd24d"}}>
        <CardContent>
          
          <Typography variant="h6"  className="text-center">
           UNITS OF VELOCITY 

          </Typography>
          <br></br>
          <Typography variant="body2" >
          1ft/sec = 0.3048m/s; 1 miles per hour = 1.609km/hr The English system of measurement of the velocity of 1 ft/sec is equal to 0.3048 times the metres per second of the metric system of measurement. Similarly, one mile per hour is equal to 1.69 times the kilometre per hour.
          </Typography>
        </CardContent>
        
      </Card>
      </Box>
    );
  }
    
 



const Velocity=()=>{
    const classes = useStyles();
  const [ftsec,setFtsec] = useState();
  const [msec,setMsec] = useState();
  const [cmsec,setCmsec] = useState();
  const [milehr,setMilehr] = useState();
  const [kmhr,setKmhr] = useState();

  return (
      <Container maxWidth="lg">
      <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      spacing={4}
    >
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <TextField className={classes.root}  size="small"  label="ft/sec" variant="outlined" fullWidth 
              type="number"
              InputProps={{
                style: {
                    color: "#ffd24d"
                }
            }}
            InputLabelProps={{  shrink: true,focused:true }}

              value={ftsec}
              onChange={(e) => {
                  
                      var val = parseFloat(e.target.value);
                      var val1 = parseFloat(e.target.value);
                      setFtsec(val1);
                      val=val1/3.281
                      setMsec(val);
                      val=val1*30.48
                      setCmsec(val);
                      val=val1/1.467
                      setMilehr(val)
                      val=val1*1.097
                      setKmhr(val);
                  }
                  
              }
              
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField className={classes.root}  size="small"  label="m/sec" variant="outlined" fullWidth
          type="number"
          InputProps={{
            style: {
                color: "#ffd24d"
            }
        }}
        InputLabelProps={{  shrink: true,focused:true }}

          value={msec}
          onChange={(e) => {
              
                  var val = parseFloat(e.target.value);
                  var val1 = parseFloat(e.target.value);
                  setMsec(val1);
                  val=val1*3.281
                  setFtsec(val);
                  val=val1*100
                  setCmsec(val);
                  val= val1*2.237
                  setMilehr(val);
                  val=val1*3.6
                  setKmhr(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField className={classes.root}  size="small"  label="cm/sec" variant="outlined" fullWidth
          type="number"
          InputProps={{
            style: {
                color: "#ffd24d"
            }
        }}
        InputLabelProps={{  shrink: true,focused:true }}

          value={cmsec}
          onChange={(e) => {
             
                  var val = parseFloat(e.target.value);
                  var val1 = parseFloat(e.target.value);
                  setCmsec(val1);
                  val=val1/30.48
                  setFtsec(val);
                  val=val1/100
                  setMsec(val);
                  val= val1/44.704
                  setMilehr(val);
                  val=val1/27.778 
                  setKmhr(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField className={classes.root}  size="small"  label="miles/hour" variant="outlined" fullWidth
          type="number"
          InputProps={{
            style: {
                color: "#ffd24d"
            }
        }}
        InputLabelProps={{  shrink: true,focused:true }}

          value={milehr}
          onChange={(e) => {
              
                  var val = parseFloat(e.target.value);
                  var val1 = parseFloat(e.target.value);
                  setMilehr(val1);
                  val=val1*1.467
                  setFtsec(val);
                  val=val1/2.237
                  setMsec(val);
                  val=val1*44.704
                  setCmsec(val);
                  val=val1*1.609 
                  setKmhr(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField className={classes.root}  size="small"  label="km/hr" variant="outlined" fullWidth
          type="number"
          InputProps={{
            style: {
                color: "#ffd24d"
            }
        }}
        InputLabelProps={{  shrink: true,focused:true }}

          value={kmhr}
          onChange={(e) => {
             
                  var val = parseFloat(e.target.value);
                  var val1 = parseFloat(e.target.value);
                  setKmhr(val1);
                  val=val1/1.097
                  setFtsec(val);
                  val=val1/3.6
                  setMsec(val);
                  val=val1*27.7778
                  setCmsec(val);
                  val= val1/1.609
                  setMilehr(val);
              }
          }
           
        />
        </Grid>
  </Grid></Container>);
}