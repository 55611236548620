
import {Grid,Box, Typography,useTheme,Button,Container,makeStyles,TextField,FormControl,InputAdornment,InputLabel,Select,MenuItem,Card,CardMedia,DialogContent,Dialog,DialogTitle} from '@material-ui/core';
import {useEffect,useState,useContext} from 'react';
import PhoneIcon from '@material-ui/icons/Phone';
import { AutoCompLoc } from './AutoCompLoc.jsx';
import {Loading} from "./Loading";
import {BackDropLoad} from "./LoadingBackDrop";
import { UserContext,SnackbarContext } from './userContext';
import {useNavigate} from "react-router-dom";
import Axios from "axios";
import PublishIcon from '@material-ui/icons/Publish';
import Compressor from 'compressorjs';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiFormLabel-root":{
        color:"#ffd24d"
      },
       
      "& .Mui-error":{
        color:"red  ",
       // borderColor:"red !important"
      },
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"#2e2b2b",
        paddingLeft:"1%",
        paddingRight:"1%",
      },
     
      
       
    },
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });


export const MyAccAddPro =()=>{
    const theme = useTheme();
    const classes = useStyles();
    const history=useNavigate();

    const [loading,setLoading]=useState(true);

    
    const [subcats,setSubcats]=useState();

     
    const [subcat,setSubcat]=useState('0');

    const [images,setImages]=useState([]);

    const [pname,setPname]=useState('');
    const [email,setEmail]=useState('');
    const [mob,setMob]=useState('');
    const [loc,setLoc]=useState('');
    const [shortdesc,setShortdesc]=useState('');
    const [longdesc,setLongdesc]=useState('');
     

    const [showAlert,setShowAlert] = useState(true);

     
    const [error,setError] = useState(0);

    const [wait,setWait] = useState(false);
    const [uploadwait,setUploadWait] = useState(false);
     

    const {user, setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);


    useEffect(()=>{
        
       getData();
    },[])


    const updateLoc = (loc)=>{
        setLoc(loc);
     }


     let compImage =   (image) => {
      return new Promise((resolve,reject)=>{
        new Compressor(image, {
          quality: 0.8,  
          success: async (compressedResult) => {
            return resolve(compressedResult);  
          }
        });
      })
      
    };

 

    const getData=()=>{
        const formData={};
        var tkn=user.token;
        
        formData.role=user.user_role;
        formData.cat=user.category_ids;
        formData.uid=user.user_id
        Axios.post("/new_apis/getprodsubcats",formData,{ 
            headers: { tkn: tkn },
           }).then((res) => {
                if(res.data.status===1){
                     
                    setSubcats(res.data.subcats); 
                   
                    setLoading(false);
                } else if(res.data.status===2){
                  setSnack({
                    message: res.data.msg,
                    type: 'warning',
                    open: true,
                    direction:"left"
                  });
                } else if(res.data.status===3){
                  setSnack({
                    message: res.data.msg,
                    type: 'warning',
                    open: true,
                    direction:"left"
                  });
                  history('/');
                } else if(res.data.status===4){
                  setUser(null);
                  history('/');
                  localStorage.removeItem("estkn");
                  setSnack({
                      message: res.data.msg,
                      type: 'warning',
                      open: true,
                      direction:"left"
                    });
                }
                
              
           }); 
    }

     const addDetails=(e)=>{
        e.preventDefault();
         
        setError(0);
        if(pname===''){
            setError(1);  
              setSnack({
                message: "Please Enter Product / Service Name..",
                type: 'error',
                open: true,
                direction:"center"
              });
        }else if(subcat=='0'){
            setError(2);  
            setSnack({
              message: "Please Select the Sub-Category..",
              type: 'error',
              open: true,
              direction:"center"
            });
        }else if(mob.length!==10){
            setError(3);  
            setSnack({
              message: "Please Enter Valid 10-digit Mobile number..",
              type: 'error',
              open: true,
              direction:"center"
            });
        }else if(shortdesc===''){
            setError(4);  
            setSnack({
              message: "Please Enter Short Description..",
              type: 'error',
              open: true,
              direction:"center"
            });
        }else if(loc===''){
            setError(5);  
            setSnack({
              message: "Please Enter the Location..",
              type: 'error',
              open: true,
              direction:"center"
            });
        }else if(longdesc===''){
            setError(6);  
            setSnack({
              message: "Please Enter Long Description..",
              type: 'error',
              open: true,
              direction:"center"
            });
        }else if(images.length===0){
            setError(7);  
            setSnack({
              message: "Please Choose atleast one Image ..",
              type: 'error',
              open: true,
              direction:"center"
            });
        }else{
            setWait(true);
            var tkn=user.token;
            const formData=new FormData();
            formData.append('uid',user.user_id);
            formData.append('role',user.user_role);
            formData.append('cat',user.category_ids);
            formData.append('subcat',subcat);
            formData.append('pname',pname);
            formData.append('email',email);
            formData.append('mob',mob);
            formData.append('sdesc',shortdesc);
            formData.append('ldesc',longdesc);
            formData.append('loc',loc);
            
            for(var i=0;i<images.length;i++){
              
                formData.append('images',images[i].raw);
            }
            Axios.post("/new_apis/addproduct",formData,{ 
                headers: { tkn: tkn},
               }).then((res) => {
                    
                    if(res.data.status===1){
                        setWait(false);
                        history('/myaccount/my_prods_servs');
                        setSnack({
                          message: res.data.msg,
                          type: 'success',
                          open: true,
                          direction:"center"
                        });
                         
                    }else if(res.data.status===2){
                        setWait(false);
                        setSnack({
                          message: res.data.msg,
                          type: 'error',
                          open: true,
                          direction:"center"
                        });
                        if(res.data.field===1){
                          setError(1)
                        }else if(res.data.field===2){
                          setError(4)
                        }else if(res.data.field===3){
                          setError(6)
                        }else if(res.data.field===4){
                          setError(8)
                        }
                    }else if(res.data.status===3){
                      setWait(false);
                      history('/');
                      setSnack({
                        message: res.data.msg,
                        type: 'error',
                        open: true,
                        direction:"center"
                      });
                  }else if(res.data.status===4){
                        setUser(null);
                        setWait(false);
                        localStorage.removeItem("estkn");
                        history('/');
                        setSnack({
                          message: res.data.msg,
                          type: 'warning',
                          open: true,
                          direction:"center"
                        });

                    }
                    
                  
               }); 
        }
     }

     

     
    return (<div  >
         <Container maxWidth="md">
             { loading ?  <Loading   />:(
        <Box boxShadow={3} p={3}  style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.secondary.main ,borderRadius:"10px"}}> 
        <form onSubmit={addDetails}   >
       <Grid
        container
        direction="row"
            justify="space-between"
            alignItems="center"
        spacing={3}
        >
          <Dialog
        open={showAlert}
        onClose={()=>{
          setShowAlert(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
            style: {
              backgroundColor: theme.palette.secondary.main,
              boxShadow: 'none',
            },
          }}
         
      >
           
          <DialogTitle id="alert-dialog-title"> <Typography variant="h4" className="text-center"  style={{color:theme.palette.primary.main   }}>
                                 <ErrorOutlineIcon fontSize="large" />&nbsp;Important Note
                        </Typography>
                        <hr style={{color:theme.palette.primary.main}}/>
                        </DialogTitle>
          <DialogContent>
          
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={2}
            > <Grid item xs={12}>
              <Typography variant="body1" className="text-center" style={{color:theme.palette.primary.main   }}>
              Please note that the Title, Images and Descriptions provided must not contain contact details (Emails, contact numbers, locations etc). If found, they will be Altered or Rejected.
              </Typography>
            </Grid>
            <Grid item xs={12}   >
                 <br/>
                </Grid>
              <Grid item xs={12}  className="text-center" >
                <Button variant="contained" type="submit"  style={{width:"60%"}} fullWidth={false} color="primary"  className="linkstyle" onClick={()=>{
                  setShowAlert(false)
                }} >Okay, I Agree</Button>
                </Grid>
                <Grid item xs={12}   >
                 <br/>
                </Grid>
          </Grid>
            
            </DialogContent>
             
      </Dialog>
            <Grid item xs={12}>
            <Typography variant="h4" className="text-center" gutterBottom style={{color:theme.palette.primary.main}}>
                 Add Details
            </Typography>
            <hr style={{color:theme.palette.primary.main}}/>
            </Grid>
            <Grid item xs={12}  sm={12} md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label="Product / Service Name *"  variant="outlined" fullWidth
                    size="small"
                         
                        error={error===1 && true }
                        type={ 'text'  }
                        value={pname}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                             
                        
                             
                        }}
                         
                        onChange={(e) => {
                                setPname(e.target.value);

                        }}
                        
                    />
            </Grid>
             <Grid item xs={12}  sm={12} md={6} lg={6} xl={6}>
             <FormControl variant="outlined" className={classes.root}
                size="small"
                error={error===2&&true}
                fullWidth  >
                 <InputLabel >
                   Sub-Categories in {user.cat_name} *
                 </InputLabel>
                 <Select
                  // native
                   value={subcat}
                   onChange={(e) => {
                     setSubcat(e.target.value);
                     
                   }}
                   style={{color:theme.palette.primary.main}}
                    error={error === 1 ? true : false}
                   label="Category"
                    
                 >
                   
                   <MenuItem key="0"  value="0">Please select a Sub-Category</MenuItem>
                   {subcats &&
                     subcats.map((subcat) => {
                          
                            return (
                                <MenuItem
                                  key={subcat.sub_category_id}
                                  value={subcat.sub_category_id}
                                  color="primary"
                                >
                                  {subcat.sub_category_name}
                                </MenuItem>
                              );
                        
                       
                     })}
                 </Select>
               </FormControl>
             </Grid> 
             
              
            <Grid item xs={12}  sm={12} md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label={ "Email-ID"} variant="outlined" fullWidth
                    size="small"
                         
                         
                        type={ 'text' }
                        value={email}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                            
                        }}
                         
                        onChange={(e) => {
                                setEmail(e.target.value);

                        }}
                        
                    />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label="Mobile Number *"  variant="outlined" fullWidth
                    size="small"
                         
                        value={mob}
                        type="number"
                        error={error===3 && true }
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                            startAdornment:(<InputAdornment position="end">
                                <span style={{marginRight:"8px"}}>
                                <PhoneIcon color="primary"/>  
                                </span>
                             
                          </InputAdornment>),
                        }}
                        //InputLabelProps={{  shrink: true,focused:true }} 
                        
                        onChange={(e) => {
                                 
                                    setMob(e.target.value);
                                
                                

                        }}
                        
                    />
            </Grid>
            <Grid item xs={12}  sm={12} md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label={ "Short Description *"} variant="outlined" fullWidth
                    size="small"
                         
                        error={error===4 && true }
                        type={ 'text' }
                        value={shortdesc}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                            
                        }}
                         
                        onChange={(e) => {
                                setShortdesc(e.target.value);

                        }}
                        
                    />
            </Grid>
            
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <AutoCompLoc error={error==5?true:false} loc={loc} setloc={updateLoc}/>
                
            </Grid>
            <Grid item xs={12}  sm={12} md={6} lg={6} xl={6}>
            <TextField className={classes.root}  label={ "Long Description *"} variant="outlined" fullWidth
                    size="small"
                    multiline
                    rows={6}
                        error={error===6 && true }
                        type={ 'text' }
                        value={longdesc}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                            
                        }}
                         
                        onChange={(e) => {
                                setLongdesc(e.target.value);

                        }}
                        
                    />
            </Grid>
            <Grid item xs={12}  sm={12} md={6} lg={6} xl={6} className="text-center">
              <input accept="image/*,capture=camera" 
              capture="camera"
              style={{display:"none"}} 
              onChange={ async(e)=>{
                  if(e.target.files.length!=0){
                     
                    if(images.length+e.target.files.length<=3){
                         
                         var arr=[];
                        for(var i=0;i<e.target.files.length;i++){
                            var type= e.target.files[i].type;
                            if(type==="image/jpeg" || type==="image/jpg" ||type==="image/png" ){
                              const image=e.target.files[i];
                              var img= await compImage(image);  
                              arr.push({raw:img,preview:URL.createObjectURL(img)});  
                            }else{
                                alert("Please select only JPEG, JPG, PNG Images..")
                            }
                        }
                        setImages([...images,...arr]); 
                         
                    }else{
                        alert("Maximum Image limit is 3.");
                        
                    }
                }
                e.target.value=""
              }}
              id="contained-button-file"
              multiple
              type="file"
              />
              <label htmlFor="contained-button-file">
                    <Button variant="contained" color="primary" component="span">
                       <PublishIcon/>&nbsp;&nbsp;Upload Images
                    </Button>
              </label>
              {error===7 && (<span style={{color:"red"}}><br/><br/>Please Choose atleast one Image.</span>)}
              {error===8 && (<span style={{color:"red"}}><br/><br/>Ensure that the images uploaded do not contain any contact information.</span>)}
            </Grid>{
              uploadwait ? (<span style={{color:"white"}}><br/><br/>Adding Images...</span>)
              :(<>
             
            {(images.length!==0  ) ? (<Grid item xs={12}>
                <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={3}
                >
    
               {images.map((image)=>{
                   
                   return <Grid item>
                       <Card className={classes.cardshadow} style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.primary.main}}>
                       <CardMedia
                                style={{height: 200,width:200}}
                                image={image.preview}
                                title={image.preview} 
                                />  
                                <Button variant="contained" color="primary" component="span" style={{ border:"1px solid black"}} fullWidth onClick={()=>{
                                    
                                    setImages(images.filter(item => item.preview !== image.preview));
                                     
                                }}>
                       DELETE IMAGE
                    </Button>
                       </Card>
                       </Grid>
               })}
               </Grid>
            </Grid>):""} </>)
            }
             <BackDropLoad wait={wait}/>
            <Grid item xs={12}>
              
            </Grid>
            <BackDropLoad wait={wait}/>
            <Grid item xs={12} className="text-center">
            <Button variant="contained" type="submit"  style={{width:"60%"}} fullWidth={false} color="primary"  className="linkstyle" disabled={wait} >{wait?"Please Wait..":"Add Details"}</Button>
            </Grid>
    </Grid>
    </form>
    </Box>)}
    </Container> 
    </div>);

}