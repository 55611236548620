import {useEffect,useState} from "react";
//import Loading from './Loading';
//import Axios from "axios";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Container,Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
 
export const ConvPressure=()=>{
    
     
    useEffect(()=>{
         
      window.scrollTo(0, 0);
    },[]);

return  (  <div>
    
  
<Grid
  container
  direction="column"
  justify="space-evenly"
  alignItems="center"
  spacing={3}
>
<Grid item xs={12}>
         
   </Grid>
<Grid item xs={12}>
        <Typography variant="h5" component="h4" className="text-center" color="primary" gutterBottom>
          PRESSURE 
        </Typography>
   </Grid>
  <Grid item xs={12}>
  <Pressure />
  </Grid>
  <Grid item xs={12}>
   
  </Grid>
  <Grid item xs={12}>
  <SimpleCard/>
  </Grid>
  </Grid>
     
</div>

 );
}
 

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiFormLabel-root":{
        color:"#ffd24d"
      },
       
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"rgb(41, 38, 38)",
        paddingLeft:"1%",
        paddingRight:"1%",
      } 
       
    }
  
  });
  
  export default function SimpleCard() {
    const classes = useStyles();
    
  
    return (
        <Box m={3} >
      <Card className={classes.root}  style={{ border:"2px groove #ffd24d"}}>
        <CardContent>
          
          <Typography variant="h6"  className="text-center">
           UNITS OF PRESSURE 
          </Typography>
          <br></br>
          <Typography variant="body2"  >
          1lb/in2 = 0.068 bar The measurement of pressure of 1lb/in2 in the English System 
          is equal to 0.068 times the atmospheric pressure in the bar of the Metric system.
          </Typography>
        </CardContent>
        
      </Card>
      </Box>
    );
  }
    
 


const Pressure=()=>{
    const classes = useStyles();
    const [atm,setAtm] = useState();
    const [lbs,setLbs] = useState();
    const [ihg,setiHg] = useState();
    const [mhg,setmHg] = useState();
    const [ton,setTon] = useState();

    return (
        <Container maxWidth="lg">
        <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={4}
      >
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <TextField className={classes.root}  size="small" label="atmosphere" variant="outlined" fullWidth 
                type="number"
                InputProps={{
                  style: {
                      color: "#ffd24d"
                  }
              }}
              InputLabelProps={{  shrink: true,focused:true }}

                value={atm}
                onChange={(e) => {
                    
                        var val = parseFloat(e.target.value);
                        var val1 = parseFloat(e.target.value);
                        setAtm(val1);
                        val=(val1*14.696)
                        setLbs(val);
                        val=(val1*29.921)
                        setiHg(val);
                        val=(val1*760)
                        setmHg(val)
                        val=(val1*0.0010169093074024)
                        setTon(val);
                    }
                    
                }
                 
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <TextField className={classes.root}  size="small" label="lbs/inch2" variant="outlined" fullWidth
            type="number"
            InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}

            value={lbs}
            onChange={(e) => {
                
                    var val = parseFloat(e.target.value);
                    var val1 = parseFloat(e.target.value);
                    setLbs(val1);
                    val=(val1/14.696)
                    setAtm(val);
                    val=(val1*2.036)
                    setiHg(val);
                    val=(val1*51.715)
                    setmHg(val);
                    val=(val1*(775000))
                    setTon(val);
                }
            }
             
          />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <TextField className={classes.root}  size="small" label="inch of Hg" variant="outlined" fullWidth
            type="number"
            InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}

            value={ihg}
            onChange={(e) => {
               
                    var val = parseFloat(e.target.value);
                    var val1 = parseFloat(e.target.value);
                    setiHg(val1);
                    val=(val1/29.921)
                    setAtm(val);
                    val=(val1/2.036)
                    setLbs(val);
                    val=(val1*25.4)
                    setmHg(val);
                    val=(val1*0.000033986181236157) 
                    setTon(val);
                }
            }
             
          />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <TextField className={classes.root}  size="small" label="mm of Hg" variant="outlined" fullWidth
            type="number"
            InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}

            value={mhg}
            onChange={(e) => {
                
                    var val = parseFloat(e.target.value);
                    var val1 = parseFloat(e.target.value);
                    setmHg(val1);
                    val=(val1/760)
                    setAtm(val);
                    val=(val1/51.715)
                    setLbs(val);
                    val=(val1/25.4)
                    setiHg(val);
                    val=(val1*0.0000013380386468863) 
                    setTon(val);
                }
            }
             
          />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <TextField className={classes.root}  size="small" label="ton/cm2" variant="outlined" fullWidth
            type="number"
            InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}

            value={ton}
            onChange={(e) => {
               
                    var val = parseFloat(e.target.value);
                    var val1 = parseFloat(e.target.value);
                    setTon(val1);
                    val=(val1*967.8)
                    setAtm(val);
                    val=(val1/0.00006919656706979)
                    setLbs(val);
                    val=(val1/0.000033986181236157)
                    setiHg(val);
                    val=(val1/0.0000013380386468863)
                    setmHg(val);
                }
            }
             
          />
          </Grid>
    </Grid></Container>);
}