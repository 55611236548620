
import {Grid,Box, Typography,useTheme,Button,Container,makeStyles,FormControl,InputLabel,Select,MenuItem} from '@material-ui/core';
import {useEffect,useState,useContext} from 'react';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LockIcon from '@material-ui/icons/Lock';
import {BackDropLoad} from "./LoadingBackDrop";
import {Loading} from "./Loading";
import parse from 'html-react-parser';
import { UserContext,SnackbarContext } from './userContext';
import {useNavigate} from "react-router-dom";

import Axios from "axios";

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiFormLabel-root":{
        color:"#ffd24d"
      },
       
      "& .Mui-error":{
        color:"red  ",
       // borderColor:"red !important"
      },
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"#2e2b2b",
        paddingLeft:"1%",
        paddingRight:"1%",
      },
      "& .Mui-disabled":{
        color:"#ffd24d",
      }
      
       
    },
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }

export const MyAccSubscription =()=>{
    const theme = useTheme();
    const classes = useStyles();
    const history=useNavigate();
    

    
    const [error,setError] = useState(0);
    const [loading,setLoading] = useState(true);
    const [wait,setWait] = useState(false);
    const [subStatus,setSubStatus] = useState(false);
    const [subDate,setSubDate] = useState("");
    const [data,setData] = useState();
    const [subOff,setSubOff] = useState("");

    const [price, setPrice]=useState("0");
    const [packType, setPackType]=useState("0");
    const [packMonth, setPackMonth]=useState("0");
    const [packMonthsText, setPackMonthsText]=useState("0");
     
    const [orderID, setOrderID]=useState("");
    const [rzpid,setRzpId] = useState("");

    const {user, setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);
   
    

    var options = {
			key: "rzp_live_6FPbncuP1okNQk",
			currency: "INR",
			amount: String((parseInt(price)*18/100+parseInt(price))*100),
			order_id: orderID,
			name: 'ESAY Subscription',
      description: packMonthsText+" Subscription Plan.",
      payment_capture:'0',
			handler: function (response) {
        if (typeof response.razorpay_payment_id == 'undefined' ||  response.razorpay_payment_id < 1) {
          setSnack({
            message: "Payment Failed....",
            type: 'error',
            open: true,
            direction:"center"
          });
        }else{
            
          setRzpId(response.razorpay_payment_id)
          createSubscription(response.razorpay_payment_id)
        }
         
         
       
      },
			prefill: {
				name:user.name,
				email: user.user_email,
				contact: user.user_mobile
			}
		}


    useEffect(()=>{
      
        getData();
    },[])
     

    const getData= ()=>{
      
       
        const formData={};
        var tkn=user.token;
        
        formData.role=user.user_role;
        formData.cat=user.category_ids;
        formData.uid=user.user_id;
         
        Axios.post("/new_apis/getsubpackages",formData,{ 
            headers: { tkn: tkn },
           }).then((res) => {
            
                if(res.data.status===1){
                setData(res.data.data);
                
                setSubStatus(res.data.substatus);
                setSubDate(res.data.expdate);
                setSubOff(res.data.suboff[0].off_desc);
                setLoading(false);
                   
                } else if(res.data.status===3){
                  history('/');
                  setSnack({
                    message: res.data.msg,
                    type: 'error',
                    open: true,
                    direction:"center"
                  });
                }else if(res.data.status===4){
                  setUser(null);
                        
                        localStorage.removeItem("estkn");
                        history('/');
                  setSnack({
                    message: res.data.msg,
                    type: 'error',
                    open: true,
                    direction:"center"
                  });
                }   
                
              
           }); 
    }

    const subscribeNow=async(e)=>{
        e.preventDefault();
        setError(0);
        if(price==='0'){
            setError(1);
            setSnack({
                message: "Please choose a Subscription Plan..",
                type: 'error',
                open: true,
                direction:"center"
              });
        }else{
          
          setWait(true);
          const formData={};
          var tkn=user.token;
        
          formData.role=user.user_role;
          formData.cat=user.category_ids;
          formData.uid=user.user_id;
          formData.price=String((parseInt(price)*18/100+parseInt(price))*100);
         await Axios.post("/new_apis/createorderid",formData,{ 
            headers: { tkn: tkn },
           }).then((res) => {
              if(res.data.status===1){
                setOrderID(res.data.orderid)
                setWait(false)
                openRazorpay();
              } else if(res.data.status===3){
                history('/');
                setSnack({
                  message: res.data.msg,
                  type: 'error',
                  open: true,
                  direction:"center"
                });
              }else if(res.data.status===4){
                setUser(null);
                      
                      localStorage.removeItem("estkn");
                      history('/');
                setSnack({
                  message: res.data.msg,
                  type: 'error',
                  open: true,
                  direction:"center"
                });
              } 
                
                
              
           });
        
        
      // createSubscription();
      }
    }

    const createSubscription=async(paymentid)=>{
      setLoading(true)
      const formData={};
          var tkn=user.token;
        
          formData.role=user.user_role;
          formData.cat=user.category_ids;
          formData.uid=user.user_id;
          formData.packmonths=packMonth;
          formData.price=price;
          formData.rzpid=paymentid;
          formData.packtype=packType;
         await Axios.post("/new_apis/createsubscription",formData,{ 
            headers: { tkn: tkn },
           }).then((res) => {
              if(res.data.status===1){
                 getData();
                  
              }else if(res.data.status===3){
                history('/');
                setSnack({
                  message: res.data.msg,
                  type: 'error',
                  open: true,
                  direction:"center"
                });
              }else if(res.data.status===4){
                setUser(null);
                      
                      localStorage.removeItem("estkn");
                      history('/');
                setSnack({
                  message: res.data.msg,
                  type: 'error',
                  open: true,
                  direction:"center"
                });
              }   
                
              
           });
    }


    const openRazorpay=async()=>{
      const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
      const paymentObject = new window.Razorpay(options);
      paymentObject.open()
    }



    return (<div  >
        <Container maxWidth="md">
        { loading ?  <Loading   />:(<Box boxShadow={3} p={3}  style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.secondary.main,borderRadius:"10px" }}> 
        
       <Grid
        container
        direction="row"
            justify="space-between"
            alignItems="center"
        spacing={3}
        >
            <Grid item xs={12}>
            <Typography variant="h4" className="text-center" gutterBottom style={{color:theme.palette.primary.main}}>
                 My Subscription
            </Typography>
            <hr style={{color:theme.palette.primary.main}}/>
            </Grid>
             <Grid item xs={12}>
                <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={1}
                >
                    <Grid item xs={6}>
                    <Typography variant="h6" className="text-center"   style={{color:theme.palette.primary.main,textDecoration:"underline"}}>
                    Subscription Plan
                    </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6" className="text-center"   style={{color:theme.palette.primary.main,textDecoration:"underline"}}>
                            Expiry Date
                         </Typography>
                    </Grid>
                    <Grid item xs={6}>
                    <Typography variant="body1" className="text-center"   >
                         {data.name}
                    </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" className="text-center"    >
                            {subStatus?subDate:"Not Subscribed"}
                         </Typography>
                    </Grid>
                </Grid>
             </Grid>
             {!subStatus && (<Grid item xs={12}  >
             <hr style={{color:theme.palette.primary.main}}/>
                                    <Typography variant="body1" className="text-center" gutterBottom >
                        Choose a <b  style={{color:theme.palette.primary.main}} >Subscription Plan</b> from below to join the <b  style={{color:theme.palette.primary.main}}>ESAY</b> Community and reach your Desired Customers.
                    </Typography>
                    <br/>
                    <Container maxWidth="sm">   
                    <form onSubmit={subscribeNow}  >
                    <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={2}
            >
                <Grid item xs={12}>
                    <FormControl variant="outlined" className={classes.root}
                size="small"
                error={error===1&&true}
                fullWidth >
                 <InputLabel >
                   Choose a Subscription Plan *
                 </InputLabel>
                 <Select
                  // native
                   value={price.package_type_price}
                   onChange={(e) => {
                     setOrderID("");
                     if(e.target.value!=='0'){
                       
                      setPrice(e.target.value.package_type_price);
                      setPackType(e.target.value.package_type_price);
                      setPackMonth(e.target.value.package_type_months)
                      parseInt(e.target.value.package_type_months)<12 && setPackMonthsText(e.target.value.package_type_months+" Months")
                      parseInt(e.target.value.package_type_months)===12 && setPackMonthsText(String(parseInt(e.target.value.package_type_months)/12)+" Year")
                      parseInt(e.target.value.package_type_months)>12 && setPackMonthsText(String(parseInt(e.target.value.package_type_months)/12)+" Years")
                     }else{
                      setPrice("0");
                     }
                         
                      
                   }}
                   style={{color:theme.palette.primary.main}}
                    error={error === 1 ? true : false}
                   label="Category"
                    
                 >
                   
                   <MenuItem key="0"  value="0" >Choose a Plan</MenuItem>
                    {data.ptypes.map((da)=>{
                        return (<MenuItem key={da}  value={da}>
                            {parseInt(da.package_type_months)<12 && da.package_type_months+" Months"}
                            {parseInt(da.package_type_months)==12 && String(parseInt(da.package_type_months)/12)+" Year"}
                            {parseInt(da.package_type_months)>12 && String(parseInt(da.package_type_months)/12)+" Years"}
                            </MenuItem>);
                    })}
                 </Select>
               </FormControl>
               </Grid>
               {price !=='0' &&(
                   <Grid item xs={12}>
                   <Box p={3}  style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.secondary.main }}>
                   <Grid
                   container
                   direction="row"
                   justifyContent="space-between"
                   alignItems="flex-start"
                   spacing={1}
                   >
                       <Grid item xs={6}>
                       <Typography variant="body1"    >
                           Sub Total:
                        </Typography>
                       </Grid>
                       <Grid item xs={6}  >
                       <Typography variant="body1" style={{textAlign:"right"}}    >
                           Rs. {parseInt(price)}
                        </Typography>
                       </Grid>
                       <Grid item xs={6}>
                       <Typography variant="body1"     >
                           GST (18%):
                        </Typography>
                       </Grid>
                       <Grid item xs={6}  >
                       <Typography variant="body1"  style={{textAlign:"right"}}    >
                           Rs. {parseInt(price)*18/100}
                        </Typography>
                       </Grid>
                       <Grid item xs={6}>
                       <Typography variant="body1"   >
                           Total:
                        </Typography>
                       </Grid>
                       <Grid item xs={6}  >
                       <Typography variant="body1"  style={{textAlign:"right"}}   >
                           Rs. {parseInt(price)*18/100+parseInt(price)}
                        </Typography>
                       </Grid>
                   </Grid>
                   </Box></Grid>)}
               <Grid item xs={12} className="text-center">
               <Button variant="contained" type="submit"   style={{width:"60%"}}   color="primary"  className="linkstyle" disabled={wait} >{wait?"Please Wait..":"Subscribe Now"}</Button>
               </Grid>
               </Grid>
                    </form>
                   
                    </Container>   
             </Grid>)}
             <Grid item xs={12}>
             <hr style={{color:theme.palette.primary.main}}/>
             <Typography variant="body1"   >
             {parse(subOff)}
             </Typography>
             </Grid>
            <BackDropLoad wait={wait}/>
             
    </Grid>
    
    </Box>)}
    </Container>
    </div>);

}