import {useEffect,useState} from "react";
//import Loading from './Loading';
//import Axios from "axios";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Container,Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
 
export const ConvWtper=()=>{
   
     
    useEffect(()=>{
         
      window.scrollTo(0, 0);
    },[]);

return  (  <div>
    
   
    <Grid
  container
  direction="column"
  justify="space-evenly"
  alignItems="stretch"
  spacing={3}
>
<Grid item xs={12}>
         
   </Grid>
<Grid item xs={12}>
        <Typography variant="h5" component="h4" className="text-center" color="primary" gutterBottom>
          WEIGHT PER UNIT LENGTH 
        </Typography>
   </Grid>
  <Grid item xs={12}>
  <Wtperunit />
  </Grid>
  <Grid item xs={12}>
   
  </Grid>
  <Grid item xs={12}>
  <SimpleCard/>
  </Grid>
  </Grid>
</div>

 );
}

 
const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiFormLabel-root":{
        color:"#ffd24d"
      },
       
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"rgb(41, 38, 38)",
        paddingLeft:"1%",
        paddingRight:"1%",
      } 
       
    }
  
  });



  
  export default function SimpleCard() {
    const classes = useStyles();
    
  
    return (
        <Box  m={3}>
      <Card  style={{ border:"2px groove #ffd24d"}} >
        <CardContent>
          
          <Typography variant="h6"  className="text-center">
           UNITS OF WEIGHT PER UNIT LENGTH 
          </Typography>
          <br></br>
          <Typography variant="body2"  >
          1 lb per feet = 1.488 kilograms per meter In the English 
          system of measurement of lb per feet is equal to approximately 1.49 times the kilogram per meter (metric system).
          </Typography>
        </CardContent>
        
      </Card>
      </Box>
    );
  }
    
 


const Wtperunit=()=>{
    const classes = useStyles();
  const [lbinch,setLbinch] = useState();
  const [lbyard,setLbyard] = useState();
  const [lbfeet,setLbfeet] = useState();
  const [kgmet,setKgmet] = useState();
  
  return (
    <Container maxWidth="lg">
    <Grid
    container
    direction="row"
    justify="flex-start"
    alignItems="flex-start"
    spacing={4}>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <TextField  className={classes.root}  size="small" label="lb per inch" variant="outlined" fullWidth 
              type="number"
              InputProps={{
                style: {
                    color: "#ffd24d"
                }
            }}
            InputLabelProps={{  shrink: true,focused:true }}

              value={lbinch}
              onChange={(e) => {
                 
                      var val = parseFloat(e.target.value);
                      var val1 = parseFloat(e.target.value);
                      setLbinch(val1);
                      val=(val1*36)
                      setLbyard(val);
                      val=(val1*12)
                      setLbfeet(val);
                      val=(val1*17.858)
                      setKgmet(val);    
                  }
                  
              }
               
          />
           </Grid>
           <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <TextField  className={classes.root}  size="small"  label="lb per yard" variant="outlined" fullWidth
            type="number"
            InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}

            value={lbyard}
            onChange={(e) => {
                
                    var val = parseFloat(e.target.value);
                    var val1 = parseFloat(e.target.value);
                    setLbyard(val1);
                    val=(val1/36)
                    setLbinch(val);
                    val=(val1/3)
                    setLbfeet(val);
                    val=(val1*0.496054648)
                    setKgmet(val);
                }
            }
             
          />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <TextField  className={classes.root}  size="small" label="lb per feet" variant="outlined" fullWidth
            type="number"
            InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}

            value={lbfeet}
            onChange={(e) => {
                
                    var val = parseFloat(e.target.value);
                    var val1 = parseFloat(e.target.value);
                    setLbfeet(val1);
                    val=(val1/12)
                    setLbinch(val);
                    val=(val1*3)
                    setLbyard(val);
                    val=(val1*1.488164) 
                    setKgmet(val);
                }
            }
             
          />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <TextField  className={classes.root}  size="small"  label="kilograms per meter" variant="outlined" fullWidth
            type="number"
            InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}

            value={kgmet}
            onChange={(e) => {
                
                    var val = parseFloat(e.target.value);
                    var val1 = parseFloat(e.target.value);
                    setKgmet(val1);
                    val=(val1/17.858)
                    setLbinch(val);
                    val=(val1*0.671969) 
                    setLbfeet(val);
                    val=(val1*2.01590693)
                    setLbyard(val);
                }
            }
             
          />
          </Grid>
    </Grid></Container>);
}
