import {Grid, Typography,useTheme,Container,Box} from '@material-ui/core';
import { UserContext } from './userContext';
import {useContext} from 'react';
import {Link, useNavigate} from 'react-router-dom';

export const MyProfile=()=>{
    const history=useNavigate();
    const theme = useTheme();
    const {user, setUser} = useContext(UserContext);
   
    return (<div>
       <Container maxWidth="md">
        <Box boxShadow={3} p={3}  style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.secondary.main,borderRadius:"10px" }}> 
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
            spacing={3}
            >
             <Grid item xs={12}>
            <Typography variant="h4" className="text-center"  style={{color:theme.palette.primary.main}}>
                 Account Details 
            </Typography>
            <hr style={{color:theme.palette.primary.main}}/>
            </Grid>
            <Grid item xs={12}>
            <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="flex-start"
            spacing={2}
            >
                <Grid item >
                <Typography variant="body1" >
                <b>Name:</b> {user.name}
                </Typography>
                </Grid>
                <Grid item >
                <Typography variant="body1" >
                <b>Reg. Mobile no:</b> {user.user_mobile}
                </Typography>
                </Grid>
                <Grid item >
                {user.profile_done==='1' &&<Typography variant="body1" >
                <b>Category:</b> {user.user_role==='1'?"Regular user":user.cat_name}
                </Typography> }
                </Grid>
            </Grid>
            </Grid>
              
            { (user.user_role==='1' && user.profile_done==='1') && (
                <Grid item xs={12}>
                    <br/>
                    <Typography variant="h4" className="text-center"  style={{color:theme.palette.primary.main}}>
                    How to Upgrade your Profile ?
                    </Typography>
                 <hr style={{color:theme.palette.primary.main}}/>
                 <Typography variant="body1" >
                 If you want to Upgrade your Profile to Contractor / Consultant / Material Supplier / Machinery Supplier / Engineer / Workforce / Testing Lab Service provider, you can do it by selecting - <Link to="/myaccount/upgradeprofile" className="anchorTag"  style={{padding:10,color:"white",textDecoration:"underline"}}>'Upgrade Profile'</Link>.
                </Typography>
                </Grid>
            )} 
            {(user.user_role==='2' && user.category_ids!=='6' && user.category_ids!=='7' && user.profile_done==='1') && (
                <Grid item xs={12}>
                    <br/>
                    <Typography variant="h4" className="text-center"  style={{color:theme.palette.primary.main}}>
                    What to do?
                    </Typography>
                 <hr style={{color:theme.palette.primary.main}}/>
                 <Typography variant="body1" >
                    <ul>
                        <li>Add Details about your products/services from <Link to="/myaccount/my_prods_servs" className="anchorTag"  style={{padding:10,color:"white",textDecoration:"underline" }}>'My Products / Services'</Link>. </li>
                        <li> Your Added Product / Service will be active once it gets verified and approved by ESAY Backend team.</li>
                        <li>Choose a Subscription plan from <Link to="/myaccount/subscription" className="anchorTag"  style={{padding:10,color:"white",textDecoration:"underline" }}>'My Subscription'</Link> for your customers to reach you.</li>
                    </ul>
                    The Contact button of your Added Product / Service will be available once you choose a Subscription plan.  
                </Typography>
                </Grid>
            )} 
            {(user.user_role==='2' && (user.category_ids==='6' || user.category_ids==='7') && user.profile_done==='1') && (
                <Grid item xs={12}>
                    <br/>
                    <Typography variant="h4" className="text-center"  style={{color:theme.palette.primary.main}}>
                    How to change your Profile's Public visibilty? 
                    </Typography>
                 <hr style={{color:theme.palette.primary.main}}/>
                 <Typography variant="body1" >
                    You can change your profile's public visibilty (Show / Hide your profile from public) at <Link to="/myaccount/updatedetails" className="anchorTag"  style={{padding:10,color:"white",textDecoration:"underline" }}>'Update Details'</Link>.   
                </Typography>
                </Grid>
            )}

            { user.profile_done==='0' && (
                <Grid item xs={12}>
                    <br/>
                    <Typography variant="h4" className="text-center"  style={{color:theme.palette.primary.main}}>
                    Complete Your Profile!
                    </Typography>
                 <hr style={{color:theme.palette.primary.main}}/>
                 <Typography variant="body1" >
                 Finish setting up your profile to unlock all the features and enjoy more services tailored just for you! <Link to="/myaccount/complete_profile" className="anchorTag"  style={{padding:10,color:"white",textDecoration:"underline" }}>Click here</Link> to complete your profile now.   
                </Typography>
                </Grid>
            )} 
        </Grid>
        </Box>
       </Container>
    </div>);
}