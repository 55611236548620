import {Grid,Container,Box,TextField,Button,useTheme,Typography,makeStyles,FormControl,Label,Select,MenuItem,InputLabel} from '@material-ui/core';
import {useState,useContext,useEffect} from 'react';
import { UserContext,SnackbarContext } from './userContext';
import {BackDropLoad} from "./LoadingBackDrop";
import Axios from 'axios';
import { AutoCompLoc } from './AutoCompLoc.jsx';
import { Helmet  } from 'react-helmet-async';

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiFormLabel-root":{
        color:"#ffd24d"
      },
       
      "& .Mui-error":{
        color:"red  ",
       // borderColor:"red !important"
      },
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"#2e2b2b",
        paddingLeft:"1%",
        paddingRight:"1%",
      },
      
       
    },
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
       
    },
  
  });


const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";


export const ContactNow=()=>{

    const theme = useTheme();
    const classes = useStyles();

    
    const [name,setName] = useState("");
    const [email,setEmail] = useState("");
    const [mob,setMob] = useState("");
    const [sub,setSub] = useState("0");
    const [msg,setMsg] = useState("");
    const [wait,setWait] = useState(false);


    const [loc,setLoc]=useState('');

    const [error,setError] = useState(0);

    const [metadata,setMetadata] = useState();

    const {snack, setSnack} = useContext(SnackbarContext);

    useEffect(()=>{
      window.scrollTo(0, 0);
      Axios.post("/new_apis/getsinglemetadata", {'type':"contact"},{
        headers: { key: key },
       }).then((res) => {
          
         if(res.data.status){
           
          setMetadata(res.data.metadata[0])
         } else{
            setMetadata({'seo_title':"Home - ESAY | Contacts and Info in the World of Construction",
            'seo_desc':"Get all the information and contacts of various types of contractors, consultants, machinery/material suppliers, engineers, and workforce in the construction sector",
            "seo_keywords":"best  contractors in Telangana, schedule of rates, Tenders in India,   construction architect engineers and labor near me, latest trends in construction sector, top contractors and consultants in Hyderabad,   interior designers in Hyderabad, building construction materials and machinery suppliers, soil metal materials testing labs around me"});
         }
          
       });
    },[])

    const contactNow=(e)=>{
        e.preventDefault();
        setError(0);
        if(name===''){
            setError(1);
            setSnack({
                message: "Please Enter your name..",
                type: 'error',
                open: true,
                direction:"center"
              });
        }else if(mob.length!==10 ){
            setError(2);
            setSnack({
                message: "Please Enter a Valid 10-digit Mobile number..",
                type: 'error',
                open: true,
                direction:"center"
              });
        }else if(loc==='' ){
          setError(5);
          setSnack({
              message: "Please Give your Location..",
              type: 'error',
              open: true,
              direction:"center"
            });
      }else if(sub==='0' ){
            setSnack({
                message: "Please select your Subject..",
                type: 'error',
                open: true,
                direction:"center"
              });
            setError(3);
        }else if(msg===''){
            setSnack({
                message: "Please Enter your Message..",
                type: 'error',
                open: true,
                direction:"center"
              });
            setError(4);
        }else{
            setWait(true);
            
            const formData= {};
            formData.name=name;
            formData.email=email;
            formData.mob=mob;
            formData.loc=loc;
            formData.sub=sub;
            formData.msg=msg;
            Axios.post("/new_apis/contactnow",formData,{ 
                headers: { key: key },
               }).then(  (res) => {
                   
                    if(res.data.status ){
                      setWait(false);
                       
                      setSnack({
                        message: res.data.msg,
                        type: 'success',
                        open: true,
                        direction:"center"
                      });
                      
                        
  
                         
                    }else  {
                      setWait(false);
                       
                      setSnack({
                        message: res.data.msg,
                        type: 'error',
                        open: true,
                        direction:"center"
                      });
                    }
                    
                  
               }); 
        }
    }


    const updateLoc = (loc)=>{
      setLoc(loc);
   }


    return (<Container maxWidth="lg" > 
    {metadata && <Helmet>
  <title>{metadata.seo_title}</title>
    <meta name="description" content={metadata.seo_desc} />
    <meta name="keywords" content={metadata.seo_keywords}></meta>
  </Helmet>}
            <Box m={4}>
            <form onSubmit={contactNow}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center" 
                spacing={1}
                >
                <Grid item xs={12}>
                <Typography variant="h4" style={{color:theme.palette.primary.main}} className="text-center" gutterBottom>
                               CONTACT US
                </Typography>
                <hr style={{color:theme.palette.primary.main}}/>
                </Grid>    
                <Grid item xs={12} sm={12} md={6}>
                    <Box boxShadow={3} p={3}  style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.secondary.main,marginTop:"10%",marginBottom:"10%",borderRadius:"10px"}}> 
                    <Grid
                    container
                    direction="row"
                        justify="space-between"
                        alignItems="center"
                    spacing={3}
                    >
                        <Grid item xs={12}>
                            <Typography variant="h4" style={{color:theme.palette.primary.main}} className="text-center" gutterBottom>
                                            Give your Details
                            </Typography>
                            <hr style={{color:theme.palette.primary.main}}/>
                        </Grid> 
                        <Grid item xs={12}>
                        <TextField className={classes.root}  label="Name *"  variant="outlined" fullWidth
                    size="small"
                         
                        error={error===1 && true }
                        type={ 'text'  }
                        value={name}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                             
                        
                             
                        }}
                         
                        onChange={(e) => {
                                setName(e.target.value);

                        }}
                        
                    />
                        </Grid>
                        <Grid item xs={12}>
                        <TextField className={classes.root}  label="Mobile *"  variant="outlined" fullWidth
                    size="small"
                         
                        error={error===2 && true }
                        type={ 'number'  }
                        value={mob}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                             
                        
                             
                        }}
                         
                        onChange={(e) => {
                                setMob(e.target.value);

                        }}
                        
                    />
                        </Grid>
                        <Grid item xs={12}>
                        <TextField className={classes.root}  label="Email"  variant="outlined" fullWidth
                    size="small"
                         
                         
                        type={ 'text'  }
                        value={email}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                             
                        
                             
                        }}
                         
                        onChange={(e) => {
                                setEmail(e.target.value);

                        }}
                        
                    />
                        </Grid>
                        <Grid item xs={12}>
                        <AutoCompLoc error={error==5?true:false} loc={loc} setloc={updateLoc}/>
                        </Grid>
                        <Grid item xs={12}>
                        <FormControl variant="outlined" className={classes.root}
                size="small"
                error={error===3&&true}
                fullWidth  >
                 <InputLabel >
                   Subject *
                 </InputLabel>
                 <Select
                  // native
                   value={sub}
                   onChange={(e) => {
                     setSub(e.target.value);
                      
                   }}
                   style={{color:theme.palette.primary.main}}
                    error={error === 3 ? true : false}
                   label="Subject"
                    
                 >
                     <MenuItem key="0"  value="0" style={{color:"white"}}>Choose Enquiry for..</MenuItem>
                   <MenuItem key="Registration"  value="Registration" style={{color:"white"}}>Registration</MenuItem>
                   <MenuItem key="Subscription"  value="Subscription" style={{color:"white"}}>Subscription</MenuItem>
                   <MenuItem key="Real Estates"  value="Real Estates" style={{color:"white"}}>Real Estates</MenuItem>
                   <MenuItem key="Info in EngInfo"  value="Info in EngInfo" style={{color:"white"}}>Info in EngInfo</MenuItem>
                   <MenuItem key="Contacting a Supplier / Service Provider"  value="Contacting a Supplier / Service Provider / Engineer / Workforce" style={{color:"white"}}>Contacting a Supplier / Service Provider</MenuItem>
                   <MenuItem key="Others"  value="Others" style={{color:"white"}}>Others</MenuItem>
                 </Select>
               </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                        <TextField className={classes.root}  label="Message *"  variant="outlined" fullWidth
                    size="small"
                         
                    error={error===4 && true }
                        type={ 'text'  }
                        value={msg}
                         
                        InputProps={{
                            style: {
                                color: "#ffd24d"
                            },
                             
                        
                             
                        }}
                         multiline
                         rows={4}
                        onChange={(e) => {
                                setMsg(e.target.value);

                        }}
                        
                    />
                        </Grid>
                        <BackDropLoad wait={wait}/>
                        <Grid item xs={12} className="text-center">
                    <Button variant="contained" type="submit"  style={{width:"60%"}} color="primary" disabled={wait} className="linkstyle"  >
                    {wait?"Please Wait..":"Submit Details"}
                    </Button>
            </Grid>
                    </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                <Box   p={3}  style={{  marginTop:"10%",marginBottom:"10%"}}> 
                <Typography variant="body1"  className="text-center" gutterBottom>
                               {/* <b style={{color:theme.palette.primary.main}}>Address: </b>A-BLOCK, 3rd floor, Sandil, Jayabheri enclave, Gachibowli, Hyderabad - 500032. */}
                </Typography>
                <Typography variant="body1"  className="text-center" gutterBottom>
                               <b style={{color:theme.palette.primary.main}}>Email-Id: </b>contact@esay.in, contact@arawinz.com
                </Typography>
                </Box>
                </Grid>
                
            </Grid>
            </form>
            </Box>
    </Container>);
}