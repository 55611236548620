 
import {useState,useEffect, useContext,useLayoutEffect,React} from 'react';
 
import {Route,useNavigate, useParams} from "react-router-dom";
import {Grid,Typography,useTheme,ButtonBase,Card,CardMedia,CardActions,makeStyles,Box,TextField,Button,FormControl,Select,InputLabel,MenuItem} from "@material-ui/core";
import {Loading} from "./Loading";
import { Helmet  } from 'react-helmet-async';
import { UserContext,SnackbarContext } from './userContext';
import Axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from "react-responsive-carousel";
import { AutoCompLoc } from './AutoCompLoc.jsx';
import {Link} from 'react-router-dom';
 
 
const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "& .MuiFormLabel-root":{
      color:"#ffd24d"
    },
     
    "& .Mui-error":{
      color:"red  ",
     // borderColor:"red !important"
    },
    "& .MuiInputLabel-shrink":{
      
      transform: "translate(14px, -9px) scale(0.90)",
      transformOrigin: "top left",
      backgroundColor:"#2e2b2b",
      paddingLeft:"1%",
      paddingRight:"1%",
    } 
     
  },
  nounderline: {
    "&:hover": { textDecoration:"none"},
  },
  cardshadow: {
    "&:hover": { boxShadow: "0 4px 8px 0 #ffd24d, 0 4px 8px 0 #ffd24d" },
      //"&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.19), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
     
  },

});
const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";

 


export const SearchResults=(props)=>{
    const {locn,catn,subcat,searchn}=useParams()
    const paramData=useParams()
    const history=useNavigate();
    const theme = useTheme();
    const classes = useStyles();

   

    const [loading,setLoading]=useState(true);
    const {user,setUser} = useContext(UserContext);
    const {snack, setSnack} = useContext(SnackbarContext);
    const [slides,setSlides]=useState();
    const [cats,setCats]=useState();
    const [subcats,setSubcats]=useState();
    const [searchData,setSearchData]=useState(); 
    const [metaData,setMetaData]=useState(); 
    const [resultsNotFound,setResultsNotFound]=useState(false); 

    const [loc,setLoc] = useState("");
    const [cat,setCat] = useState("0");
    const [subCat,setSubcat] = useState("0");
    const [search,setSearch] = useState("");
    const [error,setError] = useState(0);

    const [size, setSize] = useState(0);
    const [active, setActive] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(()=>{
    setLoading(true);
    window.scrollTo(0, 0);
      var locn=String(paramData.locn);
      var cat=parseInt(paramData.catn);
      var subcat=parseInt(paramData.subcat);
      var search=String(paramData.searchn);

      
      const formData= {};
      if(cat===0 || isNaN(cat)){
          history('/');
      }else{
          formData.cat=String(cat);
           setCat(String(cat));
      }
      if(subcat!==0 && !isNaN(subcat)){
        formData.subcat=String(subcat);
        setSubcat(String(subcat));
        
      }

      if( String(locn) !=="undefined"){
        formData.loc=String(locn);
        setLoc(String(locn)); 
      }
      if(String(search) !=="undefined"){
        formData.search=String(search);
        setSearch(String(search)); 
      }
       
      
    Axios.post("/new_apis/get-search-data",formData,{ 
      headers: { key: key },
     }).then((res) => {
         if(res.data.status===1){
            setSlides(res.data.slides);
         
            setCats(res.data.cats);
            setMetaData(res.data.metadata);
            setSubcats(res.data.subcats);
            setSearchData(res.data.data);
             
            setSlides(res.data.slides);
            
             
            setResultsNotFound(res.data.notfound);
            setLoading(false);
         }else if(res.data.status===0){
            history('/');
         }
        
     }); 
               
  },[paramData]);

 

 
   const updateLoc = (loc)=>{
      setLoc(loc);
   }

    return (<div>
     {loading?<Loading />:<div>
           {metaData && (<Helmet>
                    <title>{metaData.seo_title} - Search Results | ESAY - Engineering Sources Around You</title>
                    <meta name="description" content={metaData.seo_desc} />
                    <meta name="keywords" content={metaData.seo_keywords}></meta>
                </Helmet>)}
                {slides.length==1  && (<div className="container">
                <div 
        
        style={{
           margin:"5px",
           
          textAlign:"center",
          borderRadius:"5px",
          
        }}
        >
        
              <img src={slides[0].image_url} className="img-fluid" style={{borderRadius:"5px",maxHeight: "300px",border:"2px groove #ffd24d"}} />
              
          
      
    </div>
                </div>)}
        {slides.length>1  && (<Carousel
      animation="slide"
      timeout={1200}
      autoPlay={true}
      showIndicators={true}
      showArrows={false}
      centerMode={true}
      centerSlidePercentage={size<960?100:50}
      infiniteLoop
      
      stopOnHover
      swipeable
      showThumbs={false}
       
      transitionTime={1200}
      statusFormatter={(current, total) => {
        setActive(current - 1);
        return ``;
      }}
    >
      {slides.map((item, i) => (
        <div id={`${
          i === active && `carousel_image_id_active`
        }`}
        className="MuiPaper-elevation1"
        style={{
           margin:"5px",
           border:"2px groove #ffd24d",
        //  paddingRight:"5px",
          borderRadius:"5px",
          filter: "blur(5px)",
        }}
        >
        
              <img src={item.image_url} style={{borderRadius:"5px",maxHeight: "300px"}} />
              
          
      
    </div>
      ))}
    </Carousel>)}
      
     <Box  style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.secondary.main,borderRadius:"10px"}}   p={3}>
   

   <Grid
 container
 direction="row"
 justify="space-evenly"
 alignItems="center"
 spacing={3}
>
 <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
       <AutoCompLoc error={error==1?true:false} loc={loc} setloc={updateLoc}/>
        
      </Grid>
 <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
   <FormControl variant="outlined" className={classes.root}
    size="small"
    error={error===2&&true}
   fullWidth required>
                 <InputLabel >
                   Category
                 </InputLabel>
                 <Select
                  // native
                   value={cat}
                   onChange={(e) => {
                     setCat(e.target.value);
                     setSubcat("0");
                     setSearch("");
                   }}
                   style={{color:theme.palette.primary.main}}
                   //error={errcode === 2 ? true : false}
                   label="Category"
                    
                 >
                   
                   <MenuItem key="0"  value="0">Please select a Category</MenuItem>
                   {cats &&
                     cats.map((cat) => {
                       return (
                         <MenuItem
                           key={cat.category_id}
                           value={cat.category_id}
                           color="primary"
                         >
                           {cat.category_name}
                         </MenuItem>
                       );
                     })}
                 </Select>
               </FormControl>
                
               </Grid>
 {cat!=='0'&&(<Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
 <FormControl variant="outlined" className={classes.root}
    size="small"
   fullWidth >
                 <InputLabel >
                   Sub Category
                 </InputLabel>
                 <Select
                   //native
                   value={subCat}
                   onChange={(e) => {
                     setSubcat(e.target.value);
                     setSearch("");
                   }}
                   style={{color:theme.palette.primary.main}}
                   //error={errcode === 2 ? true : false}
                   label="Sub Category"
                    
                 >
                   <MenuItem  key="0" value="0">Please select a Sub Category</MenuItem>
                   { (subcats && cat!=='0') &&
                     subcats.map((subcat) => {
                       if(cat===subcat.category_ids){
                         return (
                           <MenuItem
                             key={subcat.sub_category_id}
                             value={subcat.sub_category_id}
                             color="primary"
                           >
                             {subcat.sub_category_name}
                           </MenuItem>
                         );
                       }
                       
                     })}
                 </Select>
               </FormControl>
 </Grid>)}
 <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
 <TextField className={classes.root}  label="Search"  variant="outlined" fullWidth
      size="small"
           
          value={search}
          
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          //InputLabelProps={{  shrink: true,focused:true }} 
        
          onChange={(e) => {
                   setSearch(e.target.value);

          }}
           
      />
 </Grid>
 <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
 <Button variant="contained"   fullWidth={true} color="primary"  className="linkstyle" onClick={()=>{
   //searchNow();
   setError(0);
    if(loc===''){
      setError(1);
      setSnack({
        message: 'Please give your Location...',
        type: 'error',
        open: true,
        direction:"center"
      });
    }else if(cat==="0"){
      setError(2);
      setSnack({
        message: 'Please Select a Category...',
        type: 'error',
        open: true,
        direction:"center"
      });
    }else{
       
      history("/searchresults/"+loc+"/"+cat+"/"+subCat+"/"+search);
    }
    
   
 }} >APPLY FILTER</Button>
 </Grid>
 </Grid>
 </Box>
 <br/><br/>
     <Typography variant="h5" className="text-center divider line glow" gutterBottom style={{color:theme.palette.primary.main}}>
           {metaData.category_name} - Search Results{resultsNotFound && ": Not Found"} 
     </Typography>
     <br/>  
     {resultsNotFound && (<Typography variant="h5" className="text-center  " gutterBottom style={{color:theme.palette.primary.main}}>
           Similar Results 
     </Typography>)}
     <br/>
     <div className="container">
     {(searchData && metaData.category_id!=='6' && metaData.category_id!=='7' )  && (
         <Grid
         container
         direction="row"
         justify="flex-start"
         alignItems="flex-start"
         spacing={4}
        >
            {searchData.map((item) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} 
                 className="text-center"
                 >
                    <ButtonBase className={classes.nounderline}
                                component={Link} to={"/psdetails/"+item?.product_slug+"/"+item?.product_id}
                            >
                            <Card className={classes.cardshadow} style={{width: "210px",height:"300px", border:"2px groove #ffd24d",backgroundColor:theme.palette.primary.main}}>
                            
                                <CardMedia
                                style={{height: 180}}
                                image={item.product_image}
                                title={item.product_name} 
                                />
                                 
                             
                            <CardActions>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                                //spacing={1}
                                >
                                    <Grid item xs={12}>
                                    <Typography variant="body2" className="text-center" style={{color:theme.palette.secondary.main,width:"100%",overflow:"hidden",fontWeight:"bold"}}>
                                    {(item.product_name)?.substring(0, 30)}
                                    {(item.product_name)?.length>30&&"..."}
                                   </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2" className="text-center" style={{color:theme.palette.secondary.main,width:"100%",textDecoration:"underline"}}>
                                    {(item?.sub_category_name).substring(0, 30)}
                                    {(item?.sub_category_name).length>30&&"..."}
                                    
                                   </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2" className="text-center" style={{color:theme.palette.secondary.main,width:"100%"}}>
                                   
                                    
                                   {(item?.product_location_text).substring(0, 30)}
                                    {(item?.product_location_text).length>30&&"..."}
                                   </Typography>
                                    </Grid>
                                </Grid>
                                 
                                  
                            </CardActions>
                            </Card>
                            </ButtonBase>
                </Grid>
            ))}
           </Grid>
     )}
     {(searchData && (metaData.category_id==='6' || metaData.category_id==='7' ))  && (<Grid
         container
         direction="row"
         justify="flex-start"
         alignItems="flex-start"
         spacing={4}
        >
          {searchData.map((item) => (
                <Grid item xs={12} sm={6} md={6} lg={3} xl={3} 
                 className="text-center"
                 >
                    <ButtonBase className={classes.nounderline}
                                component={Link} to={"/ewdetails/"+item?.cat+"/"+item?.user_id}
                            >
                            <Card className={classes.cardshadow} style={{width: "250px",border:"2px groove #ffd24d",backgroundColor:theme.palette.primary.main}}>
                            
                                {/* <CardMedia
                                style={{height: 200}}
                                image={metaData.category_id==='6'?"https://esay.in/assets/uploads/images/p7.jpg":"https://esay.in/assets/uploads/images/p6.jpg" }
                                title={item.subcat} 
                                /> */}
                                 
                             
                            <CardActions>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                                //spacing={1}
                                >
                                    <Grid item xs={12}>
                                    <Typography variant="body2" className="text-center" style={{color:theme.palette.secondary.main,width:"100%",overflow:"hidden",fontWeight:"bold"}}>
                                    {(item.fname+" "+item.lname).substring(0, 30)}
                                    {(item.fname+" "+item.lname).length>30&&"..."}
                                   </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2" className="text-center" style={{color:theme.palette.secondary.main,width:"100%",textDecoration:"underline"}}>
                                    {(item.subcat+" - "+item.cat).substring(0, 30)}
                                    {(item.subcat+" - "+item.cat).length>30&&"..."}
                                    
                                   </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2" className="text-center" style={{color:theme.palette.secondary.main,width:"100%"}}>
                                   
                                    
                                   {(item.area_ids).substring(0, 30)}
                                    {(item.area_ids).length>30&&"..."}
                                   </Typography>
                                    </Grid>
                                </Grid>
                                 
                                  
                            </CardActions>
                            </Card>
                            </ButtonBase>
                </Grid>
            ))}
     </Grid>)}
     </div>
     <br/><br/><br/><br/>
     <br/>
     </div>} 
    </div>);
    
}

 