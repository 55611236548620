 
import {useState,useEffect, useContext,useLayoutEffect,React} from 'react';
 
import {Route,useNavigate, useParams} from "react-router-dom";
import {Grid,Typography,useTheme,ButtonBase,Card,CardMedia,CardActions,makeStyles,Box,TextField,Button,FormControl,Select,InputLabel,MenuItem,Slider} from "@material-ui/core";
import {Loading} from "./Loading";
import { Helmet  } from 'react-helmet-async';
import { UserContext,SnackbarContext } from './userContext';
import Axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from "react-responsive-carousel";
import { AutoCompLoc } from './AutoCompLoc.jsx';
import {Link} from 'react-router-dom';

 
function convertToSlug(Text)
{
    return Text.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');
}
const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ffd24d"
    },
    "& .MuiFormLabel-root":{
      color:"#ffd24d"
    },
     
    "& .Mui-error":{
      color:"red  ",
     // borderColor:"red !important"
    },
    "& .MuiInputLabel-shrink":{
      
      transform: "translate(14px, -9px) scale(0.90)",
      transformOrigin: "top left",
      backgroundColor:"#2e2b2b",
      paddingLeft:"1%",
      paddingRight:"1%",
    } 
     
  },
  nounderline: {
    "&:hover": { textDecoration:"none"},
  },
  cardshadow: {
    "&:hover": { boxShadow: "0 4px 8px 0 #ffd24d, 0 4px 8px 0 #ffd24d" },
      //"&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.19), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
     
  },

});
const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";

 


export const RealEstates=(props)=>{
  const {locn,catn,type,area,price,rent}=useParams()
  let paramData=useParams()
    const history=useNavigate();
    const theme = useTheme();
    const classes = useStyles();
    const [metadata,setMetadata] = useState();

   
    const {snack, setSnack} = useContext(SnackbarContext);

    const [loading,setLoading]=useState(true);
     
     
    const [cats,setCats]=useState();
    const [searchTitle,setSearchTitle]=useState(""); 
    const [searchData,setSearchData]=useState(); 
    
    const [resultsNotFound,setResultsNotFound]=useState(false); 

    const [loc,setLoc] = useState(localStorage.getItem('loc'));
    const [cat,setCat] = useState("0");
    const [propType,setPropType]=useState("a"); 
    
    const [sellers, setSellers] =useState();
     
    const [areaRange,setAreaRange]=useState([0, 30000]);

    const [priceRange, setPriceRange] =  useState([0, 3000]);

    const [rentRange, setRentRange] =  useState([0, 2000000]);

    const [error,setError] = useState(0);

    

  useEffect(()=>{
     setLoading(true);
    window.scrollTo(0, 0);
    Axios.post("/new_apis/getsinglemetadata", {'type':"realestates"},{
      headers: { key: key },
     }).then((res) => {
        
       if(res.data.status){
         
        setMetadata(res.data.metadata[0])
       } else{
          setMetadata({'seo_title':"Home - ESAY | Contacts and Info in the World of Construction",
          'seo_desc':"Get all the information and contacts of various types of contractors, consultants, machinery/material suppliers, engineers, and workforce in the construction sector",
          "seo_keywords":"best  contractors in Telangana, schedule of rates, Tenders in India,   construction architect engineers and labor near me, latest trends in construction sector, top contractors and consultants in Hyderabad,   interior designers in Hyderabad, building construction materials and machinery suppliers, soil metal materials testing labs around me"});
       }
        
     });
    // var loc=String(props?.loc);
    var cat=parseInt(paramData?.catn);
      const formData= {};
      if( String(paramData?.locn) !=="undefined"){
        formData.loc=String(paramData?.locn);
       
        setLoc(String(paramData?.locn)); 
      }
      if(!isNaN(cat) &&cat!==0){
        //history('/');
        formData.cat=String(cat);
         setCat(String(cat));
      }
      
      var type=String(props?.type);
      
      if(type!=='a' && type!=='undefined'){
         
        formData.type=type;
        setPropType(type);
      }  
       

      var area=String(paramData?.area).split(',');
      if(area.length==2){
        area[0]=parseInt(area[0]);
        area[1]=parseInt(area[1]);
        if(!isNaN(area[0]) && !isNaN(area[1])){
          formData.minarea=area[0];
          formData.maxarea=area[1];
          setAreaRange([parseInt(area[0]),parseInt(area[1])]);
        } 
      } 
      

      var price=String(paramData?.price).split(',');
      if(price.length==2){
        price[0]=parseInt(price[0]);
        price[1]=parseInt(price[1]);
        if(!isNaN(price[0]) && !isNaN(price[1])){
          formData.minprice=price[0];
          formData.maxprice=price[1];
          setPriceRange([parseInt(price[0]),parseInt(price[1])]);
        } 
      } 
      

      var rent=String(paramData?.rent).split(',');
      if(rent.length==2){
        rent[0]=parseInt(rent[0]);
        rent[1]=parseInt(rent[1]);
        if(!isNaN(rent[0]) && !isNaN(rent[1])){
          
          formData.minrent=rent[0];
          formData.maxrent=rent[1];
          setRentRange([parseInt(rent[0]),parseInt(rent[1])]);
        } 
      } 
      

    Axios.post("/new_apis/get-realestatecats",formData,{ 
      headers: { key: key },
     }).then((res) => {
         if(res.data.status){
            
         
            setCats(res.data.cats);
             
             
            setSearchData(res.data.results);
            setResultsNotFound(res.data.nofound);
            
            setSearchTitle(res.data.title);
            
             setSellers(res.data.sellers);
            
             setLoading(false);
         }else  {
            history('/');
         }
        
     }); 
               
  },[paramData]);

 
 
   const updateLoc = (loc)=>{
      setLoc(loc);
   }

    return (<div>
      {metadata && <Helmet>
  <title>{metadata.seo_title}</title>
    <meta name="description" content={metadata.seo_desc} />
    <meta name="keywords" content={metadata.seo_keywords}></meta>
  </Helmet>}
     {loading?<Loading />:<div>
            
                 
        
      
     <Box  style={{ border:"2px groove #ffd24d",backgroundColor:theme.palette.secondary.main,borderRadius:"10px"}} mt={3} p={3}>
   

   <Grid
 container
 direction="row"
 justify="space-evenly"
 alignItems="center"
 spacing={3}
>
 <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
       <AutoCompLoc error={error==1?true:false} loc={loc} setloc={updateLoc}/>
        
      </Grid>
 <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
   <FormControl variant="outlined" className={classes.root}
    size="small"
    error={error===2&&true}
   fullWidth  >
                 <InputLabel >
                   Category
                 </InputLabel>
                 <Select
                  // native
                   value={cat}
                   onChange={(e) => {
                     setCat(e.target.value);
                      
                   }}
                   style={{color:theme.palette.primary.main}}
                   //error={errcode === 2 ? true : false}
                   label="Category"
                    
                 >
                   
                   <MenuItem key="0"  value="0">Show All</MenuItem>
                   {cats &&
                     cats.map((cat) => {
                       return (
                         <MenuItem
                           key={cat.rst_id}
                           value={cat.rst_id}
                           color="primary"
                         >
                           {cat.rst_name}
                         </MenuItem>
                       );
                     })}
                 </Select>
               </FormControl>
                
               </Grid>
 
               <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
   <FormControl variant="outlined" className={classes.root}
    size="small"
    error={error===2&&true}
   fullWidth  >
                 <InputLabel >
                  Seller type
                 </InputLabel>
                 <Select
                  // native
                   value={propType}
                   onChange={(e) => {
                     setPropType(e.target.value);
                      
                      
                   }}
                   style={{color:theme.palette.primary.main}}
                   //error={errcode === 2 ? true : false}
                   label="Category"
                    
                 >
                   
                   <MenuItem  key="a"
                           value="a">All</MenuItem>
                           {sellers &&
                     sellers.map((seller) => {
                       return (
                         <MenuItem
                           key={seller.seller_title}
                           value={seller.seller_title}
                           color="primary"
                         >
                           {seller.seller_title}
                         </MenuItem>
                       );
                     })}
                            

                          
                         
                 </Select>
               </FormControl>
                
               </Grid>
               <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
               <Slider
        value={areaRange}
        onChange={(e,newValue)=>{
          
          setAreaRange(newValue);
        }}
        valueLabelDisplay="on"
        aria-labelledby="range-slider"
        //getAriaValueText={valuetext}
        valueLabelFormat={(value)=>{
          if(value<1000){
            return   `${parseInt(value)}`;
          }else{
            var crvalue=value/1000;
            return   `${parseInt(crvalue)} K`;
          }
          
        }}
         
        min={0}
        max={30000}
      />
      <Typography id="range-slider" color="primary" gutterBottom>
        Area Range in Square Feet - {areaRange[0]<1000?parseInt(areaRange[0]):parseInt(areaRange[0]/1000)+" K"} to {areaRange[1]<1000?parseInt(areaRange[1]):parseInt(areaRange[1]/1000)+" K"}
       </Typography>
  
 </Grid>
                <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
  
      <Slider
        value={priceRange}
        onChange={(e,newValue)=>{
          
          setPriceRange(newValue);
        }}
        valueLabelDisplay="on"
        aria-labelledby="range-slider"
        //getAriaValueText={valuetext}
        valueLabelFormat={(value)=>{
          if(value<100){
            return   `${parseInt(value)} L`;
          }else{
            var crvalue=value/100;
            return   `${parseInt(crvalue)} Cr`;
          }
          
        }}
         
        min={0}
        max={3000}
      />
      <Typography id="range-slider" color="primary" gutterBottom>
        Price Range - Rs. {priceRange[0]<100?parseInt(priceRange[0])+" Lakh":parseInt(priceRange[0]/100)+" Cr"} to Rs. {priceRange[1]<100?parseInt(priceRange[1])+" Lakh":parseInt(priceRange[1]/100)+" Cr"}
       </Typography>
 </Grid> 
 {/** 
 {propType=='2'&&(<Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
 
      <Slider
        value={rentRange}
        onChange={(e,newValue)=>{
          
          setRentRange(newValue);
        }}
        valueLabelDisplay="on"
        aria-labelledby="range-slider"
        //getAriaValueText={valuetext}
        valueLabelFormat={(value)=>{
          if(value<100000){
            return   `${parseInt(value/1000)} K`;
          }else{
            var crvalue=value/100000;
            return   `${parseInt(crvalue)} L`;
          }
          
        }}
         
        min={0}
        max={2000000}
      />
      <Typography id="range-slider" color="primary" gutterBottom>
        Rent Range - Rs. {rentRange[0]<100000?parseInt(rentRange[0]/1000)+" K":parseInt(rentRange[0]/100000)+" L"} to Rs. {rentRange[1]<100000?parseInt(rentRange[1])+" K":parseInt(rentRange[1]/100000)+" L"}
       </Typography>
 </Grid>)} 
 **/}
 <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
 <Button variant="contained"   fullWidth={true} color="primary"  className="linkstyle" onClick={()=>{
   //searchNow();
   setError(0);
    if(loc===''){
      setError(1);
      setSnack({
        message: 'Please give your Location...',
        type: 'error',
        open: true,
        direction:"center"
      });
    }else{
       setError(0);  
       var url= "/real-estates/"+loc+"/"+cat+"/"+propType;
       url+="/"+String(areaRange);
       url+="/"+String(priceRange);
       url+="/"+String(rentRange)
        
        
       history(url);
      
    }
    
   
 }} >Apply Filter</Button>
 </Grid>
 </Grid>
 </Box>
 <br/>  
 {resultsNotFound&&(<Typography variant="h5" className="text-center divider line glow" gutterBottom style={{color:theme.palette.primary.main}}>
         Search Results: Not Found  
     </Typography>)}
     <br/>
     {resultsNotFound && (<Typography variant="h5" className="text-center  " gutterBottom style={{color:theme.palette.primary.main}}>
           Similar Results 
     </Typography>)}
     <br/>
     <div className="container">
     {(searchData  )  && (
         <Grid
         container
         direction="row"
         justify="flex-start"
         alignItems="center"
         spacing={4}
        >
            {searchData.map((item) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} 
                 className="text-center"
                 >
                    <ButtonBase className={classes.nounderline}
                                component={Link} to={"/real-estate-detail/"+convertToSlug(item?.re_name)+"/"+item?.re_id}
                            >
                            <Card className={classes.cardshadow} style={{width: "210px",height:"350px",border:"2px groove #ffd24d",backgroundColor:theme.palette.primary.main}}>
                            
                                <CardMedia
                                style={{height: 200}}
                                image={item?.re_image}
                                title={item?.re_name} 
                                />
                                 
                             
                            <CardActions>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                                //spacing={1}
                                >
                                    <Grid item xs={12}>
                                    <Typography variant="body2" className="text-center" style={{color:theme.palette.secondary.main,width:"100%",overflow:"hidden",fontWeight:"bold"}}>
                                    {(item.re_name).substring(0, 30)}
                                    {(item.re_name).length>30&&"..."}
                                   </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2" className="text-center" style={{color:theme.palette.secondary.main,width:"100%",textDecoration:"underline"}}>
                                    {(item.rst_name).substring(0, 30)}
                                    {(item.rst_name).length>30&&"..."}
                                    
                                   </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2" className="text-center" style={{color:theme.palette.secondary.main,width:"100%"}}>
                                    {item.sale_rent==='1'?"Property For: Sale":"Property For: Rent"}
                                     
                                    
                                   </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2" className="text-center" style={{color:theme.palette.secondary.main,width:"100%"}}>
                                    Seller Type: {item.re_candtype}
                                     
                                    
                                   </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant="body2" className="text-center" style={{color:theme.palette.secondary.main,width:"100%"}}>
                                   
                                    
                                   {(item.re_loc).substring(0, 30)}
                                    {(item.re_loc).length>30&&"..."}
                                   </Typography>
                                    </Grid>
                                </Grid>
                                 
                                  
                            </CardActions>
                            </Card>
                            </ButtonBase>
                </Grid>
            ))}
           </Grid>
     )}
      
     </div>
     <br/><br/><br/><br/>
     <br/>
     </div>} 
    </div>);
    
}

 