import {useEffect,useState} from "react";
//import Loading from './Loading';
//import Axios from "axios";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Container,Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
 
export const ConvVolume=()=>{
    
     
    useEffect(()=>{
         
      window.scrollTo(0, 0);
    },[]);

return  (  <div>

<Grid
  container
  direction="column"
  justify="space-evenly"
  alignItems="center"
  spacing={3}
>
<Grid item xs={12}>
         
   </Grid>
<Grid item xs={12}>
        <Typography variant="h5" component="h4" className="text-center" color="primary" gutterBottom>
          VOLUME 
        </Typography>
   </Grid>
  <Grid item xs={12}>
  <Volume />
  </Grid>
  <Grid item xs={12}>
   
  </Grid>
  <Grid item xs={12}>
  <SimpleCard/>
  </Grid>
  </Grid>
   
</div>

 );
}
 

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiFormLabel-root":{
        color:"#ffd24d"
      },
       
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"rgb(41, 38, 38)",
        paddingLeft:"1%",
        paddingRight:"1%",
      } 
       
    }
  
  });
  
  export default function SimpleCard() {
    const classes = useStyles();
    
  
    return (
        <Box  m={3}>
      <Card className={classes.root}  style={{ border:"2px groove #ffd24d"}}>
        <CardContent>
          
          <Typography variant="h6"  className="text-center">
           UNITS OF VOLUME 
          </Typography>
          <br></br>
          <Typography variant="body2" >
          1 US gallon = 3.785 litres; 1 imperial gallon = 4.55 litres The popular measurement of the 
          fluid volume in English system is gallons with one US gallon is approximately equal to 3.8 
          litres (Metric system) that means the US gallon is 2.8 litres more than a metric litre and one imperial gallon 
          is equal to 4.55 litres which are 3.55 litres more than a metric litre.
          </Typography>
        </CardContent>
        
      </Card>
      </Box>
    );
  }
    
 


const Volume=()=>{
    const classes = useStyles();
  const [cubcm,setCubcm] = useState();
  const [cubmet,setCubmet] = useState();
  const [cubinch,setCubinch] = useState();
  const [cubfeet,setCubfeet] = useState();
  const [lit,setLit] = useState();
  const [imgall,setImgall] = useState();
  const [usgall,setUsgall] = useState();
  const [acefeet,setAcefeet] = useState();
  const [tmc,setTMC] = useState();

  return (
      <Container maxWidth="lg">
      <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      spacing={4}
    >
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <TextField className={classes.root}  size="small"  label="cubic centimeters" variant="outlined" shrink="true" fullWidth 
              type="number"
              InputProps={{
                  style: {
                      color: "#ffd24d"
                  }
              }}
              InputLabelProps={{  shrink: true,focused:true }}
              value={cubcm}
              onChange={(e) => {
                var val = parseFloat(e.target.value);
                var val1 = parseFloat(e.target.value);
                setCubcm(val1);
                val=(val1/1000000)
                setCubmet(val);
                val=val1/16.387 
                setCubinch(val);
                val=val1/28316.847
                setCubfeet(val);
                val=val1/1000
                setLit(val);
                val=val1/4546.09
                setImgall(val);
                val=val1/3785.412
                setUsgall(val);
                val=val1/1233000000
                setAcefeet(val);
                val=val1/28316846592000
                setTMC(val);
                  
              }}
               
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField className={classes.root}  size="small"  label="Cubic meters" variant="outlined" fullWidth
        InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          type="number"
          value={cubmet}
          onChange={(e) => {
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setCubmet(val1);
            val=(val1*1000000)
            setCubcm(val);
            val=val1*61023.744
            setCubinch(val);
            val=val1*35.315
            setCubfeet(val);
            val=val1*1000
            setLit(val);
            val=val1*219.969
            setImgall(val);
            val=val1*264.172
            setUsgall(val);
            val=val1/1233.48
            setAcefeet(val);
            val=val1/28316846.592
            setTMC(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField className={classes.root}  size="small"  label="cubic inches" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={cubinch}
          onChange={(e) => {
              
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setCubinch(val1);
            val=(val1*16.387)
            setCubcm(val);
            val=val1/61023.744
            setCubmet(val);
            val=val1/1728
            setCubfeet(val);
            val=val1/61.024
            setLit(val);
            val=val1/277.419
            setImgall(val);
            val=val1/231
            setUsgall(val);
            val=val1/75270000
            setAcefeet(val);
            val=val1/1728000000000 
            setTMC(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField className={classes.root}  size="small"  label="cubic feet" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={cubfeet}
          onChange={(e) => {
             
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setCubfeet(val1);
            val=(val1*28316.847)
            setCubcm(val);
            val=val1/35.315
            setCubmet(val);
            val=val1*1728 
            setCubinch(val);
            val=val1*28.317
            setLit(val);
            val=val1*6.229
            setImgall(val);
            val=val1*7.481
            setUsgall(val);
            val=val1/43559.935
            setAcefeet(val);
            val=val1/1000000000
            setTMC(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField className={classes.root}  size="small"  label="liters" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={lit}
          onChange={(e) => {
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setLit(val1);
            val=(val1*1000)
            setCubcm(val);
            val=val1/1000
            setCubmet(val);
            val=val1*61.024
            setCubinch(val);
            val=val1/28.317
            setCubfeet(val);
            val=val1/4.546
            setImgall(val);
            val=val1*0.264172
            setUsgall(val);
            val=val1*0.00739275475
            setAcefeet(val);
            val=val1/28316846592
            setTMC(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField className={classes.root}  size="small" label="Imperial gallons" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={imgall}
          onChange={(e) => {
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setImgall(val1);
            val=(val1*4546.09)
            setCubcm(val);
            val=val1/219.969
            setCubmet(val);
            val=val1*277.419
            setCubinch(val);
            val=val1/6.229
            setCubfeet(val);
            val=val1*4.546
            setLit(val);
            val=val1*1.201
            setUsgall(val);
            val=val1/271327.668
            setAcefeet(val);
            val=val1/6228835459.04283 
            setTMC(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField className={classes.root}  size="small"  label="us gallons" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={usgall}
          onChange={(e) => {
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setUsgall(val1);
            val=(val1*3785.412)
            setCubcm(val);
            val=val1/264.172 
            setCubmet(val);
            val=val1*231
            setCubinch(val);
            val=val1/7.481
            setCubfeet(val);
            val=val1*3.785
            setLit(val);
            val=val1/1.201
            setImgall(val);
            val=val1/325850.943
            setAcefeet(val);
            val=val1/7480519480.51948
            setTMC(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField className={classes.root}  size="small"  label="acer feet" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={acefeet}
          onChange={(e) => {
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setAcefeet(val1);
            val=(val1*1233000000)
            setCubcm(val);
            val=val1*1233.48 
            setCubmet(val);
            val=val1*75270000
            setCubinch(val);
            val=val1*43559.935
            setCubfeet(val);
            val=val1*1233000
            setLit(val);
            val=val1*271327.688
            setImgall(val);
            val=val1*325850.943
            setUsgall(val);
            val=val1*0.0000435
            setTMC(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField className={classes.root}  size="small"  label="TMC" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={tmc}
          onChange={(e) => {
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setTMC(val1);
            val=(val1*28316846592000)
            setCubcm(val);
            val=val1*28316846.592 
            setCubmet(val);
            val=val1*1728000000000
            setCubinch(val);
            val=val1*1000000000
            setCubfeet(val);
            val=val1*28316846592
            setLit(val);
            val=val1*6228835459.04283
            setImgall(val);
            val=val1*7480519480.51948
            setUsgall(val);
            val=val1*22956.8411386618
            setAcefeet(val);
              }
          }
           
        />
        </Grid>
  </Grid></Container>);
}
