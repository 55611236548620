import {useEffect, useState} from "react";
import Axios from "axios";
import {Loading} from "./Loading";
import {Grid, Typography,useTheme,Card,CardContent,ButtonBase,CardMedia,makeStyles, Container,Box, Hidden,Tabs, Tab} from '@material-ui/core';
import parse from 'html-react-parser';
import {Link} from 'react-router-dom';
import { Helmet  } from 'react-helmet-async';
import { filter } from "lodash";

const key = "dqweqwe123y97qhduiasd$@^!*#&bjasdja&^&@!#";


function convertToSlug(Text)
{
    return Text.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');
}


const useStyles = makeStyles({
    nounderline: {
      "&:hover": { textDecoration:"none"},
    },
    cardshadow: {
      "&:hover": { boxShadow: "0 4px 8px 0 #ffd24d, 0 6px 20px 0 #ffd24d" },
      //"&:hover": { boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.19), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" },
    },
  });
export const TutorialVideos=()=>{
    const theme = useTheme();
    const classes = useStyles();
    const [loading,setLoading]=useState(true);
    const [languages, setLanguages] = useState([]);
    const [data, setData] = useState([]);
    const [metadata,setMetadata] = useState();

    const [selectedLanguage, setSelectedLanguage] = useState('eng')

    // Filter videos by selected language
    const filteredVideos = data.filter(video => video.video_lang === selectedLanguage);

    useEffect(()=>{
      window.scrollTo(0, 0);
      Axios.post("/new_apis/getsinglemetadata", {'type':"tutorials"},{
        headers: { key: key },
       }).then((res) => {
          
         if(res.data.status){
           
          setMetadata(res.data.metadata[0])
         } else{
            setMetadata({'seo_title':"Home - ESAY | Contacts and Info in the World of Construction",
            'seo_desc':"Get all the information and contacts of various types of contractors, consultants, machinery/material suppliers, engineers, and workforce in the construction sector",
            "seo_keywords":"best  contractors in Telangana, schedule of rates, Tenders in India,   construction architect engineers and labor near me, latest trends in construction sector, top contractors and consultants in Hyderabad,   interior designers in Hyderabad, building construction materials and machinery suppliers, soil metal materials testing labs around me"});
         }
          
       });
        Axios.get("/new_apis/get-all-youtube-videos",{ 
          headers: { key: key },
         }).then((res) => {
            setData(res.data.videos);
            setLanguages(res.data.languages)
            setLoading(false);
         }); 
                   
      },[]);


      const createSlug = (title) => {
        return title
            .toLowerCase() // Convert to lowercase
            .replace(/[^\w ]+/g, '') // Remove all non-word chars
            .replace(/ +/g, '-'); // Replace spaces with hyphens
    };

    return (<div>
                {metadata && <Helmet>
        <title>{metadata.seo_title}</title>
            <meta name="description" content={metadata.seo_desc} />
            <meta name="keywords" content={metadata.seo_keywords}></meta>
        </Helmet>}
        {loading?<Loading/>:
        <div className="container">
            <br/><br/>
           
            <Typography variant="h4" className="divider line glow pageHeading" gutterBottom style={{color:theme.palette.primary.main}}>
            ESAY Walkthrough & Tutorials
            </Typography>
            <Container maxWidth="sm">
                <Typography variant="body2" className=" text-center pageSubtitle" gutterBottom style={{color:"white",margin:"10px",padding:"10px",  border:"1px solid #ffd24d",borderRadius:"100px",backgroundColor:theme.palette.secondary.main}}>
                    <span style={{color:theme.palette.primary.main }}>Learn and Explore:</span> 
                    Step-by-step Guides and Tutorials for Easy Navigation.
                </Typography>
            </Container>
            <br/><br/><br/> 
            <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={2}
                    >
                        <Grid item xs={12}>
                        <Tabs
                            value={selectedLanguage}  
                            onChange={(event, newValue) => setSelectedLanguage(newValue)} 
                            aria-label="language tabs"
                            indicatorColor="secondary"
                            variant="scrollable"
                            scrollButtons="auto"
                            style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.secondary.main }}
                            >
                            {languages.map((language, index) => (
                                <Tab 
                                key={language.key} 
                                value={language.key}  
                                label={language.value} 
                                />
                            ))}
                            </Tabs>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={4}
                                >
                                    {filteredVideos.map((video) => (
                                        <Grid item key={video.video_id} xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <div style={{padding:'20px',borderBottom : "1px solid #ffd24d",paddingBottom:'50px'}}>
                                                <div style={{ padding: "10px", marginBottom:"10px",textAlign: "left",paddingBottom:'20px' }}>
                                                <Typography variant="h6" gutterBottom style={{color:"#ffffff",fontWeight: 'normal',letterSpacing: '3px'}}>
                                                    {video.video_title}
                                                </Typography>
                                                <div
                                                    style={{
                                                        marginTop: "10px",
                                                        height: "5px", // Thickness of the line
                                                        width: "40%", // Length of the line
                                                        backgroundColor: "#ffd24d", // Amber color
                                                    }}
                                                    ></div>
                                                </div>
                                                <div style={{ position: "relative", paddingBottom: "56.25%", height: 0, overflow: "hidden" }}>
                                                    <iframe
                                                        src={video.video_url.replace("watch?v=", "embed/")} // Convert to embed URL
                                                        frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen
                                                        title={video.video_title}
                                                        style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        left: 0,
                                                        width: "100%",
                                                        height: "100%",
                                                        }}
                                                    ></iframe>
                                                </div>
                                                 
                                            </div>
                                        </Grid>))}
                            </Grid>  
                        </Grid>
                               
                    </Grid>
            <br/><br/><br/><br/><br/><br/>
        </div>}
    </div>);
}