import {useEffect,useState} from "react";
//import Loading from './Loading';
//import Axios from "axios";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Container,Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
 
export const ConvDischarge=()=>{
    
     
    useEffect(()=>{
         
      window.scrollTo(0, 0);
    },[]);

return  (  <div>

<Grid
  container
  direction="column"
  justify="space-evenly"
  alignItems="center"
  spacing={3}
>
<Grid item xs={12}>
         
   </Grid>
<Grid item xs={12}>
        <Typography variant="h5" component="h4" className="text-center" color="primary" gutterBottom>
          DISCHARGE 
        </Typography>
   </Grid>
  <Grid item xs={12}>
  <Discharge />
  </Grid>
  <Grid item xs={12}>
   
  </Grid>
  <Grid item xs={12}>
  <SimpleCard/>
  </Grid>
  </Grid>
      
    
</div>

 );
}

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffd24d"
      },
      "& .MuiFormLabel-root":{
        color:"#ffd24d"
      },
       
      "& .MuiInputLabel-shrink":{
        
        transform: "translate(14px, -9px) scale(0.90)",
        transformOrigin: "top left",
        backgroundColor:"rgb(41, 38, 38)",
        paddingLeft:"1%",
        paddingRight:"1%",
      } 
       
    }
  
  });
  
  export default function SimpleCard() {
    const classes = useStyles();
    
  
    return (
        <Box m={3} >
      <Card className={classes.root}  style={{ border:"2px groove #ffd24d"}}>
        <CardContent>
          
          <Typography variant="h6"  className="text-center">
           UNITS OF DISCHARGE 
          </Typography>
          <br></br>
          <Typography variant="body2"  >
          1 gl/sec = 3.785 litres The popular measurement of the discharge in the English 
          system is the gallons per second whichare 
          equal to 3.785 times a litre of discharge in the Metric system.
          </Typography>
        </CardContent>
        
      </Card>
      </Box>
    );
  }
     


const Discharge=()=>{
    const classes = useStyles();
  const [ftsec,setFtsec] = useState();
  const [msec,setMsec] = useState();
  const [glsec,setGlsec] = useState();
  const [litsec,setLitsec] = useState();
  const [ftday,setFtday] = useState();
  const [mday,setMday] = useState();
  const [glday,setGlday] = useState();
  const [litday,setLitday] = useState();

  return (
      <Container maxWidth="lg">
      <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      spacing={4}
    >
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <TextField   className={classes.root}  size="small"  label="ft3 / sec" variant="outlined" shrink="true" fullWidth 
              type="number"
              InputProps={{
                  style: {
                      color: "#ffd24d"
                  }
              }}
              InputLabelProps={{  shrink: true,focused:true }}
              value={ftsec}
              onChange={(e) => {
                      
                      var val = parseFloat(e.target.value);
                      var val1 = parseFloat(e.target.value);
                      setFtsec(val1);
                      val=(val1/35.315)
                      setMsec(val);
                      val=val1*7.481 
                      setGlsec(val);
                      val=val1*28.317
                      setLitsec(val);
                      val=val1*86400 
                      setFtday(val);
                      val=val1*2446.576 
                      setMday(val);
                      val=val1*646316.883
                      setGlday(val);
                      val=val1*2446575.5
                      setLitday(val);
                  
              }}
               
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField   className={classes.root}  size="small"  label="m3 / sec" variant="outlined" fullWidth
        InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          type="number"
          value={msec}
          onChange={(e) => {
              
                  var val = parseFloat(e.target.value);
                  var val1 = parseFloat(e.target.value);
                  setMsec(val1);
                  val=(val1*35.315)
                  setFtsec(val);
                  val=val1*264.172
                  setGlsec(val);
                  val=val1*1000
                  setLitsec(val);
                  val=val1*3051000
                  setFtday(val);
                  val=val1*86400
                  setMday(val);
                  val=val1*22820000
                  setGlday(val);
                  val=val1*86400000 
                  setLitday(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField    className={classes.root}  size="small" label="gl / sec" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={glsec}
          onChange={(e) => {
              
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setGlsec(val1);
            val=(val1/7.481)
            setFtsec(val);
            val=val1/264.172
            setMsec(val);
            val=val1*3.785
            setLitsec(val);
            val=val1*11550
            setFtday(val);
            val=val1*327.06
            setMday(val);
            val=val1*86400
            setGlday(val);
            val=val1*327059.5
            setLitday(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField   className={classes.root}  size="small"  label="lit / sec" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={litsec}
          onChange={(e) => {
             
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setLitsec(val1);
            val=(val1/28.317)
            setFtsec(val);
            val=val1/1000
            setMsec(val);
            val=val1/3.785
            setGlsec(val);
            val=val1*3051.187
            setFtday(val);
            val=val1*86.4
            setMday(val);
            val=val1*22824.465
            setGlday(val);
            val=val1*86400
            setLitday(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField   className={classes.root}  size="small"  label="ft3 / day" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={ftday}
          onChange={(e) => {
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setFtday(val1);
            val=(val1/86400)
            setFtsec(val);
            val=val1/3051000
            setMsec(val);
            val=val1/11550
            setGlsec(val);
            val=val1/3051.187
            setLitsec(val);
            val=val1/35.315
            setMday(val);
            val=val1*7.481
            setGlday(val);
            val=val1*28.317
            setLitday(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField    className={classes.root}  size="small" label="m3 / day" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={mday}
          onChange={(e) => {
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setMday(val1);
            val=(val1/2446.576)
            setFtsec(val);
            val=val1/86400
            setMsec(val);
            val=val1/327.06
            setGlsec(val);
            val=val1/86.4
            setLitsec(val);
            val=val1*35.315
            setFtday(val);
            val=val1*264.172
            setGlday(val);
            val=val1*1000
            setLitday(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField   className={classes.root}  size="small"  label="gl / day" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={glday}
          onChange={(e) => {
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setGlday(val1);
            val=(val1/646.883)
            setFtsec(val);
            val=val1/22820000
            setMsec(val);
            val=val1/86400
            setGlsec(val);
            val=val1/22824.465
            setLitsec(val);
            val=val1/7.481
            setFtday(val);
            val=val1/264.172
            setMday(val);
            val=val1*3.785
            setLitday(val);
              }
          }
           
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <TextField   className={classes.root}  size="small"  label="lit / day" variant="outlined" fullWidth
          type="number"
          InputProps={{
              style: {
                  color: "#ffd24d"
              }
          }}
          InputLabelProps={{  shrink: true,focused:true }}
          value={litday}
          onChange={(e) => {
            var val = parseFloat(e.target.value);
            var val1 = parseFloat(e.target.value);
            setGlday(val1);
            val=(val1/2446588.8)
            setFtsec(val);
            val=val1/86400000
            setMsec(val);
            val=val1/327024
            setGlsec(val);
            val=val1/86400
            setLitsec(val);
            val=val1/28.317
            setFtday(val);
            val=val1/1000
            setMday(val);
            val=val1/3.785
            setGlday(val);
              }
          }
           
        />
        </Grid>
  </Grid></Container>);
}